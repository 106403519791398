<p-toast></p-toast>
<div class="dashboard grid fluid">
    <div class="col-12 md:col-12">
        <div class="card">
            <h1 style="font-size: 16px">Purchase Order Requests</h1>
            <p-table
                #POTable
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [globalFilterFields]="[
                    'vendorName',
                    'locationNames',
                    'vendorID',
                    'estimatedCost',
                    'remainingBalance',
                    'status',
                    'managerRequested',
                    'poID'
                ]"
                [lazy]="true"
                [lazyLoadOnInit]="false"
                [loading]="isListLoading$ | async"
                [paginator]="true"
                [rows]="10"
                [rowsPerPageOptions]="[10, 15, 25, 50]"
                selectionMode="single"
                [showCurrentPageReport]="true"
                [totalRecords]="totalPOs"
                [value]="displayedPurchaseOrders"
                [(selection)]="selectedViewPO"
                (onRowSelect)="purchaseOrderSelect($event)"
                (onLazyLoad)="loadPOs($event)">
                <ng-template pTemplate="caption">
                    <div class="flex align-items-center justify-content-end">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input
                                    pInputText
                                    type="text"
                                    (input)="POTable.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="vendorName">
                            Vendor Name
                            <p-sortIcon field="vendorName"></p-sortIcon>
                        </th>
                        <th pSortableColumn="locationNames">
                            Locations
                            <p-sortIcon field="locationNames"></p-sortIcon>
                        </th>
                        <th pSortableColumn="vendorID">
                            Vendor ID
                            <p-sortIcon field="vendorID"></p-sortIcon>
                        </th>
                        <th pSortableColumn="estimatedCost">
                            Amount
                            <p-sortIcon field="estimatedCost"></p-sortIcon>
                        </th>
                        <th pSortableColumn="remainingBalance">
                            Remaining Balance
                            <p-sortIcon field="remainingBalance"></p-sortIcon>
                        </th>
                        <th pSortableColumn="status">
                            Status
                            <p-sortIcon field="status"></p-sortIcon>
                        </th>
                        <th pSortableColumn="managerRequested">
                            Date Requested
                            <p-sortIcon field="managerRequested"></p-sortIcon>
                        </th>
                        <th pSortableColumn="poID">
                            Purchase Order #
                            <p-sortIcon field="poID"></p-sortIcon>
                        </th>
                        <th pSortableColumn="dateRequested" style="display: none">Date Requested</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-purchaseorder>
                    <tr [pSelectableRow]="purchaseorder">
                        <td>{{ purchaseorder.vendorName }}</td>
                        <!-- <td>{{ vendorNames[purchaseorder.vendorID] }}</td> -->
                        <td class="text-truncate">{{ purchaseorder.locationNames }}</td>
                        <td>{{ purchaseorder.vendorID }}</td>
                        <td>{{ purchaseorder.estimatedCost | currency : 'USD' : 'symbol' }}</td>
                        <td>{{ purchaseorder.remainingBalance | currency : 'USD' }}</td>
                        <td>{{ purchaseorder.status }}</td>
                        <td>{{ purchaseorder.managerRequested | date : 'M/d/yyyy hh:mm a' }}</td>
                        <td>{{ purchaseorder.poID }}</td>
                        <td style="display: none">{{ purchaseorder.dateRequested }}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>

        <div *ngIf="selectedPO" class="px-4 md:px-6 lg:px-8">
            <p-confirmPopup></p-confirmPopup>
            <div class="surface-card p-4 shadow-2 border-round">
                <div class="font-medium text-3xl text-900 mb-3">Requested Purchase Order</div>
                <div class="text-500 mb-5">Last updated: {{ selectedPO.statusLastSet | date : 'medium' }}</div>
                <div class="grid grid-nogutter border-top-1 surface-border pt-2">
                    <div class="col-12 md:col-6 p-3">
                        <div class="text-500 font-medium mb-2">Vendor</div>
                        <div class="text-900">{{ purchaseOrderVendor.name }}</div>
                    </div>
                    <div class="col-12 md:col-6 p-3">
                        <div class="text-500 font-medium mb-2">Vendor ID</div>
                        <div class="text-900">{{ selectedPO.vendorID }}</div>
                    </div>
                    <div class="col-12 md:col-6 p-3">
                        <div class="text-500 font-medium mb-2">Estimated Cost</div>
                        <div class="text-900">{{ selectedPO.estimatedCost | currency : 'USD' : 'symbol' }}</div>
                    </div>
                    <div class="col-12 md:col-6 p-3">
                        <div class="text-500 font-medium mb-2">Remaining Amount</div>
                        <div class="text-900">{{ selectedPO.remainingBalance | currency : 'USD' }}</div>
                    </div>
                    <div class="col-12 md:col-6 p-3">
                        <div class="text-500 font-medium mb-2">Locations</div>
                        <div *ngFor="let location of selectedPO.locationsNameList" class="text-900">
                            {{ location }}
                        </div>
                    </div>
                    <div class="col-12 md:col-6 p-3">
                        <div class="text-500 font-medium mb-2">Invoice(s)</div>
                        <div *ngIf="!this.isLoadingService.isLoading({ key: 'po-invoices' }); else spinner">
                            <div *ngFor="let invoice of selectedPOInvoices" class="text-900">
                                {{ invoice.invoiceID }}
                            </div>
                        </div>
                    </div>

                    <div class="col-12 p-3" *ngIf="showRecurring">
                        <div class="text-500 font-medium mb-2">Recurring Summary</div>
                        <div class="text-900 line-height-3">{{ recurringDisplayMessage }}</div>
                    </div>

                    <div class="col-12 p-3" *ngIf="showRecurring">
                        <div class="text-500 font-medium mb-2">Recurring Schedule</div>
                        <div class="text-900 line-height-3">
                            <ul>
                                <li *ngFor="let oneDate of recurringDates">{{ oneDate | date : 'M/d/yy' }}</li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-12 p-3">
                        <div class="text-500 font-medium mb-2">Reason</div>
                        <div class="text-900 line-height-3" id="displayed-po-reason">{{ selectedPO.reason }}</div>
                    </div>
                    <div class="col-12 p-3">
                        <div class="text-500 font-medium mb-3">Files</div>
                        <div *ngIf="!this.isLoadingService.isLoading({ key: 'po-files' }); else spinner">
                            <div
                                *ngFor="let file of files"
                                class="flex md:justify-content-between md:align-items-center border-top-1 surface-border p-3 flex-column md:flex-row">
                                <div class="flex align-items-center">
                                    <span class="block pi pi-file mr-2"></span>

                                    <button class="file-download-link" (click)="downloadFile(file.id)">
                                        {{ file.originalFileName }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col flex justify-content-center">
                        <!-- Remove 'style' once primeng package is upgraded to 12.0 -->
                        <button
                            pButton
                            label="Close"
                            icon="pi pi-times"
                            style="width: auto"
                            (click)="confirmClose($event)"
                            [disabled]="selectedPO.status === 'closed'"></button>
                    </div>
                    <div class="col flex justify-content-center">
                        <button
                            pButton
                            label="Request Change"
                            icon="pi pi-times"
                            style="width: auto"
                            (click)="changePO($event)"
                            [disabled]="selectedPO.status !== 'pending' && selectedPO.status !== 'approved'"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #spinner>
    <p-progressSpinner></p-progressSpinner>
</ng-template>
