<div *ngIf="id > 0; else no_vendor_id">
    <app-view-vendor-info [id]=id></app-view-vendor-info>
</div>

<ng-template #no_vendor_id>

    <div class="p-grid p-ai-center vertical-container" style="height:70vh;">
        <div class="p-col">
            <div class="box"></div>
        </div>
        <div class="p-col">
            <div class="box">

                <p-card>
                    <ng-template pTemplate="header">
                        <h2>No Vendor ID</h2>
                    </ng-template>
                    Your account is not associated with a vendor record. You cannot edit any vendor information.
                    <ng-template pTemplate="footer">
                        Error: NO_VENDOR_ID
                    </ng-template>
                </p-card>

            </div>
        </div>
        <div class="p-col">
            <div class="box"></div>
        </div>
    </div>


</ng-template>

