//
// This is general purpose code that is framework agnostic and can be used in Loopback or Angular
// The source of truth for this file is:
// app/src/services/mpirecurring.mpilib.ts
//

import {DateTime} from 'luxon';
import {throwError} from 'rxjs';

// For Angular
import {RecurringAttributes} from '../app/api/models/mpi-custom-models';

// For Loopback
// import {RecurringAttributes} from '../../models/mpi-custom-models';

export class MPIRecurringMPILib {

    constructor() {

    }

    private addOccurence(frequency: string, date: DateTime, sequenceCounter: number) {
        switch (frequency) {
            case 'monthly':
                date = date.plus({months: sequenceCounter});
                break;
            case 'semimonthly':
                // This means twice a month

                // Figure out if this is the 1st, 2nd, 3rd.... Nth month
                // Starting with the 0th month as first
                const monthOccurrence = Math.floor(sequenceCounter / 2);

                // Is this the first occurrence of the month or the second?
                const isFirstMonthlyOccurrence = (sequenceCounter % 2);

                // Set the date into the correct month
                date = date.plus({months: monthOccurrence});

                // Do we need to add 2 weeks to calculate the next date for the month?
                if ( isFirstMonthlyOccurrence === 0) {
                    // No, this is even so the date is already set because it's the first
                    // occurrence of the month
                } else {
                    // this is odd, the second occurrence of the month so add 2 weeks
                    date = date.plus({weeks: 2});
                }

                break;
            case 'weekly':
                date = date.plus({weeks: sequenceCounter});
                break;
            case 'semiweekly':
                // Every 2 weeks
                date = date.plus({weeks: (sequenceCounter * 2)});
                break;
            case 'quarterly':
                // Quarterly means every 3 months
                date = date.plus({months: (sequenceCounter * 3)});
                break;
            case 'annually':
                date = date.plus({years: sequenceCounter});
                break;
            default:
                throw new Error('Unsupported frequency');
                break;
        }

        return date;
    }

    /**
     * Calculate a schedule of dates based on the attributes supplied using an end date.
     *
     * @param recurringAttributes
     */
    calculateScheduleEndDate(recurringAttributes: RecurringAttributes): DateTime[] {

        // Figure out when this schedule will start and stop
        if(!recurringAttributes.startDate) {
            throw new Error(`Recurring attributes must have an startDate`);
        }
        const startingDate = recurringAttributes.startDate;

        if(!recurringAttributes.endDate) {
            throw new Error(`Recurring attributes must have an endDate`);
        }
        const endingDate = recurringAttributes.endDate;

        // console.log(`Starting on ${startingDate} until ${endingDate}`);

        // Store all the computed occurences for return
        const dates: DateTime[] = [];

        // Count how many times we've calculated a date. Start at 0 because startDate
        // will be the first occurence.
        let sequenceCounter = 0;

        // Add the first occurence
        let date = startingDate.plus({months: sequenceCounter});

        // How often does each occur?
        const frequency = recurringAttributes.frequency;

        date = this.addOccurence(frequency, startingDate, sequenceCounter);

        // Loop as long as the dates we're calculating is less than the end date
        while (date < endingDate) {
            // console.log(`Sequence ${sequenceCounter} compared ${date} to ${endingDate}`);
            dates.push(date);
            sequenceCounter += 1;

            // Calculate the next occurence
            date = this.addOccurence(frequency, startingDate, sequenceCounter);
        }

        // Return the dates calculated
        return dates;
    }

    calculateScheduleByOccurrences(recurringAttributes: RecurringAttributes): DateTime[] {

        // Figure out when this schedule will start and stop
        const startingDate = recurringAttributes.startDate;
        if(!recurringAttributes.endCount) {
            throw new Error(`Recurring attributes must have an endCount`);
        }
        const endCount = recurringAttributes.endCount;

        // console.log(`Starting on ${startingDate} until ${endCount}`);

        // Store all the computed occurrences for return
        const dates: DateTime[] = [];

        // Count how many times we've calculated a date. Start at 0 because startDate
        // will be the first occurrence.
        let sequenceCounter = 0;

        // Add the first occurence
        let date = startingDate.plus({months: sequenceCounter});

        // How often does each occur?
        const frequency = recurringAttributes.frequency;

        date = this.addOccurence(frequency, startingDate, sequenceCounter);

        // Loop as long as the dates we're calculating is less than the end date
        while (sequenceCounter < endCount) {
            // console.log(`Sequence ${sequenceCounter} of ${endCount} is ${date}`);
            dates.push(date);
            sequenceCounter += 1;

            // Calculate the next occurrence
            date = this.addOccurence(frequency, startingDate, sequenceCounter);
        }

        // Return the dates calculated
        return dates;
    }

    /**
     * Given a JSON encoded recurringAttributes string convert it to an object.
     *
     * This is more than just calling JSON.parse because the date time strings need to be converted back to
     * DateTime objects.
     *
     * @param recurringAttributesAsString
     */
    parseAttributeString(recurringAttributesAsString: string): RecurringAttributes {
        const recurringAttributesToConvert = JSON.parse(recurringAttributesAsString);

        // Assign the parsed string to a typed object.
        const recurringAttributes: RecurringAttributes = {
            ...recurringAttributesToConvert
        };

        //
        // Handle the dates, convert from string to objects.
        //
        if (recurringAttributesToConvert.startDate) {
            recurringAttributes.startDate = DateTime.fromJSDate(new Date(recurringAttributesToConvert.startDate));
        }

        if (recurringAttributesToConvert.endDate) {
            recurringAttributes.endDate = DateTime.fromJSDate(new Date(recurringAttributesToConvert.endDate));
        }

        return recurringAttributes;
    }

    calculateSchedule(recurringAttributes: RecurringAttributes): DateTime[] {

        const endCondition = recurringAttributes.endCondition;

        // Hold a list of the calculated scheduled dates
        let dates: DateTime[] = [];

        switch (endCondition) {
            case 'date' :
                dates = this.calculateScheduleEndDate(recurringAttributes);
                break;
            case 'occurrences' :
                dates = this.calculateScheduleByOccurrences(recurringAttributes);
                break;
            default:
                throwError(`Unsupported end condition`);
        }

        const displayMessage = this.getScheduleAsDisplayString(recurringAttributes, dates);
        // Useful to debug any calcualted schedule.
        // console.log(displayMessage);

        return dates;

    }

    /**
     * Given a recurring schedule calculate the dates (if not supplied) and return a human readable string that
     * represents the schedule parameters.
     *
     * @param recurringAttributes
     * @param dates
     */
    getScheduleAsDisplayString(recurringAttributes: RecurringAttributes, dates?: DateTime[]): string {

        if (!dates || dates.length === 0) {
            // There are no dates so calculate it now.
            dates = this.calculateSchedule(recurringAttributes);
        }

        let msg = ``;
        msg += `starting ${dates[0].toFormat('MM/dd/yy')} ${recurringAttributes.frequency}`;

        let lastDateString = '';

        switch (recurringAttributes.endCondition) {
            case 'date':
                lastDateString = dates[dates.length - 1].toFormat('MM/dd/yy');
                msg += ` for ${dates.length} times until ${lastDateString}.`;
                break;
            case 'occurrences':
                const lastDate = dates[dates.length - 1];
                lastDateString = lastDate.toFormat('MM/dd/yy');
                msg += ` for ${recurringAttributes.endCount} times until ${lastDateString}.`;
                break;
            default:
                throwError(`Unsupported end condition`);
        }

        return msg;
    }

}
