import { Component, Input } from '@angular/core';
import { FormGroup, UntypedFormControl } from '@angular/forms';
import { ValidationService } from '../validation.service';

/**
 * Easily display matching error message under form field
 *
 * Taken from: https://coryrylan.com/blog/angular-form-builder-and-validation-management
 */

@Component({
    selector: 'app-control-messages',
    templateUrl: './control-messages.component.html',
    styleUrls: ['./control-messages.component.scss'],
    providers: [ValidationService],
})
export class ControlMessagesComponent {
    @Input() control: UntypedFormControl;
    constructor(private validation: ValidationService) {}

    get errorMessage() {
        for (const propertyName in this.control.errors) {
            // If the control has an error message from our ValidationService and it was touched then show the error
            if (this.control.errors.hasOwnProperty(propertyName) && this.control.touched) {
                return this.validation.getValidatorErrorMessage(propertyName, this.control.errors[propertyName]);
            }

            // If the control was not touched but has a general user defined error then show it anyway
            if (this.control.errors.hasOwnProperty('error') && !this.control.touched) {
                return this.validation.getValidatorErrorMessage('error', this.control.errors['error']);
            }
        }

        return null;
    }
}
