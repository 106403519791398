import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MPIAppService } from '../../services/mpiapp.service';
import { finalize } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LoginModel } from '../models/login.model';
import { IsLoadingService } from '@service-work/is-loading';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionService } from '../../session.service';
import { ValidationService } from '../validation.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

interface SelectedRoles {
    name: string;
    code: string;
}

interface VendorNameAndID {
    name: string;
    id: number;
}

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
    private subs: Subscription[];
    private payload: LoginModel;
    private userLogin: LoginModel;

    loginData = this.formBuilder.group({
        username: ['', Validators.required],
        password: ['', Validators.required],
    });

    invoiceParam: string;

    currentRole = '';
    selectedRole;
    roles: SelectedRoles[];

    isRequestFinished = false;
    isRequestSuccessful: boolean;
    failureMessage = '';

    isVendorSelection = false;
    applicableVendorIDs: number[];
    // Display name list with vendorIDs for logic
    vendorDisplayList: VendorNameAndID[];
    selectedVendor: any;

    debug: any;

    siteName = environment.siteName;
    env = environment;

    constructor(
        private mpiApp: MPIAppService,
        private session: SessionService,
        private isLoadingService: IsLoadingService,
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        private validationService: ValidationService,
        private route: ActivatedRoute
    ) {
        this.subs = [];
        if (this.session.checkSession()) {
            // already signed in
            this.router.navigate(['']);
        }
    }

    ngOnInit(): void {
        this.subs = [];
        if (this.env.devModeActive) {
            this.loginData.get('username').setValue(environment.devUsername);
            this.loginData.get('password').setValue(environment.devPassword);
        }

        this.subs.push(
            this.route.queryParams.subscribe((params) => {
                if (params.invoice) {
                    this.invoiceParam = params.invoice;
                }
            })
        );

        this.roles = [];

        const allRoles = this.mpiApp.allRoles();

        // console.log(allRoles);
        // console.log(this.roles);

        // for (let i = 0 ; i < allRoles.length; i++) {
        //     this.roles.push({
        //         name: allRoles[k],
        //         code: allRoles[k]
        //     });
        // }

        for (const role of allRoles) {
            this.roles.push({
                name: role,
                code: role,
            });
        }

        // for (const k in allRoles) {
        //
        // }

        // allRoles.forEach(function(v) {
        //
        //     this.roles.push({
        //         name: v,
        //         code: v
        //     });
        //
        // });

        this.selectedRole = 'na';

        // this.debug = this.mpiApp.listLocations('');
    }

    ngOnDestroy(): void {
        this.subs.forEach((sub) => {
            sub.unsubscribe();
        });

        const length = this.subs.length;
        for (let i = 0; i < length; ++i) {
            this.subs.pop();
        }
    }

    /*
    Developer option to force the menu to appear and reload with a certain role
     */
    devReloadMenu() {
        // Indicate the session is authenticated
        this.session.isAuthenticated = true;

        // Push the navigation action to trigger the load of app-main component
        this.router.navigate(['/']);

        // Tell the app-main component of the new role and that we're logged in
        this.mpiApp.currentRoles = [];
        this.mpiApp.currentRoles.push(this.selectedRole.name);
        this.mpiApp.changeMessage('logged-in');
    }

    login(id: string) {
        this.isRequestFinished = false;
        this.isRequestSuccessful = false;

        /* Validate all fields first */
        if (!this.loginData.valid) {
            this.validationService.validateAllFormFields(this.loginData);
            this.isRequestFinished = true;
            return;
        }

        //
        // Build the payload
        //
        this.payload = {};
        this.payload.email = this.loginData.get('username').value;
        this.payload.password = this.loginData.get('password').value;

        let loginPayload = {
            email: this.loginData.get('username').value,
            username: this.loginData.get('username').value,
            password: this.loginData.get('password').value,
            source: 'vendorportal',
        };

        //this.loginService.login(loginPayload);

        this.subs.push(
            this.isLoadingService.add(
                this.mpiApp
                    .login(this.payload)
                    .pipe(
                        finalize(() => {
                            this.isRequestFinished = true;
                        })
                    )
                    .subscribe({
                        next: (data) => {
                            this.session.saveToken(data['token']);
                            this.userLogin = data['profile'];

                            // By default the active role is the accounts default role.
                            this.userLogin.activeRole = this.userLogin.role;

                            if (this.userLogin.applicableVendors.length > 0) {
                                this.applicableVendorIDs = this.userLogin.applicableVendors;
                                this.vendorDisplayList = [];
                                const filter = {
                                    fields: { name: true, vendorID: true },
                                    where: {
                                        vendorID: {
                                            inq: this.applicableVendorIDs,
                                        },
                                    },
                                };
                                this.subs.push(
                                    this.mpiApp.getVendors(null, JSON.stringify(filter)).subscribe({
                                        next: (data) => {
                                            if (Array.isArray(data)) {
                                                // Multiple vendors were returned
                                                data.forEach((vend) => {
                                                    this.vendorDisplayList.push({
                                                        name: vend.name,
                                                        id: vend.vendorID,
                                                    });
                                                });
                                            } else {
                                                // A single vendor was returned
                                                this.vendorDisplayList.push({
                                                    name: data.name,
                                                    id: data.vendorID,
                                                });
                                            }
                                        },
                                        error: (err: HttpErrorResponse) => {
                                            console.error(err);
                                        },
                                        complete: () => {
                                            this.isVendorSelection = true;
                                        },
                                    })
                                );
                            } else {
                                this.navigatePostLogin(this.userLogin);
                            }
                            this.isRequestSuccessful = true;
                        },
                        error: (error) => {
                            console.error(error);
                            this.isRequestSuccessful = false;
                            this.failureMessage = error.message;
                            console.error('Error posting payment: ', this.failureMessage);
                        },
                        complete: () => {
                            // Only on success
                        },
                    }),
                { key: id }
            )
        );
    }

    navigatePostLogin(userLogin: LoginModel) {
        this.session.saveUserProfile(userLogin);

        // Indicate to the rest of the app that the user is logged in
        this.session.isAuthenticated = true;
        this.mpiApp.changeMessage('logged-in');

        // this.router.navigate(['vendor/info']);
        if (this.invoiceParam) {
            switch (userLogin.activeRole) {
                case 'admin':
                // If there is an invoice ID, then there is a new invoice
                // to approve, and the email that provides the ID is sent
                // to managers. Pass through as a manager.

                case 'manager':
                    this.router.navigate(['/manager/invoice/approval'], {
                        queryParams: { invoice: this.invoiceParam },
                    });
                    break;

                case 'corporate-office':
                    this.router.navigate(['/corporate/invoice/approval'], {
                        queryParams: { invoice: this.invoiceParam },
                    });
                    break;

                case 'accounts-payable':
                // accounts-payable goes to same URL as accounting.
                // Pass through

                case 'accounting':
                    this.router.navigate(['accounting/payable/approval'], {
                        queryParams: { invoice: this.invoiceParam },
                    });
                    break;
            }
        } else {
            switch (userLogin.activeRole) {
                case 'admin': {
                    this.router.navigate(['/users']);
                    break;
                }
                case 'manager': {
                    this.router.navigate(['/manager/dashboard']);
                    break;
                }
                case 'corporate-office': {
                    this.router.navigate(['/purchasing/approval']);
                    break;
                }
                case 'accounts-payable':
                case 'accounting': {
                    this.router.navigate(['/accounting/payable/approval']);
                    break;
                }
                default: {
                    this.router.navigate(['']);
                }
            }
        }
    }

    selectVendor(event: any) {
        this.isRequestFinished = false;
        this.isRequestSuccessful = false;

        this.payload.desiredVendor = event.value;

        this.subs.push(
            this.isLoadingService.add(
                this.mpiApp.switchVendor(this.payload).subscribe({
                    next: (data) => {
                        this.session.saveToken(data['token']);
                        const userLogin: LoginModel = data['profile'];

                        // By default the active role is the accounts default role.
                        userLogin.activeRole = userLogin.role;

                        this.navigatePostLogin(userLogin);
                    },
                    error: (err) => {},
                }),
                { key: 'switch-vendor' }
            )
        );
    }

    changeRole(e) {
        this.currentRole = this.selectedRole.name;
        this.mpiApp.setRole(this.currentRole);
    }

    isButtonLoading(id: string) {
        return this.isLoadingService.isLoading({ key: id });
    }
}
