/**
 * Exported service for use with primeng fileupload
 * Used to track the state for whether user has selected files to upload
 *
 * Inspired from: https://dev.to/avatsaev/simple-state-management-in-angular-with-only-services-and-rxjs-41p8
 */

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class FileUploadStateService {
    constructor() {}

    /**
     * Set initial state to false so user can't submit
     * Will be false for any new observer due to behavior subject
     *
     * Behavior Subject allows binding to the state with the submit button, as well as
     * synchronous retrevial of value in event function
     */
    private _files = new BehaviorSubject<boolean>(false);

    /* Observable to subscribe to for bool value */
    files$ = this._files.asObservable();

    /* update bool for whether there's files selected or not */
    private set files(val: boolean) {
        this._files.next(val);
    }

    /* public facing setter to files boolean */
    setFileState(isFiles: boolean) {
        this.files = isFiles;
    }

    getFileState() {
        return this._files.value;
    }
}
