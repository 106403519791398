import { httpErrorToast, httpSuccessToast } from 'src/helpers/http-toasts';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { Message, MessageService } from 'primeng/api';
import { MPIAppService } from './../../services/mpiapp.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ReactiveFormConfig, RxFormBuilder, required } from '@rxweb/reactive-form-validators';
import { FormRefundRequest } from 'src/_models/form-types/form-refund-request';
import { RefundRequestService } from 'src/services/refund-request.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SignaturePadComponent } from '../signature-pad/signature-pad.component';
import { SignatureService } from '../signature.service';
import { LocationsPartial, RefundCustomers, RefundCustomersPartial, RefundRequest } from '../api/models';
import { FileUploadStateService } from '../file-upload-state.service';
import { PermanentErrorService } from '../permanent-error.service';
import { FileUpload } from 'primeng/fileupload';
import { IsLoadingService } from '@service-work/is-loading';

@Component({
    selector: 'app-refund-request',
    templateUrl: './refund-request.component.html',
    styleUrls: ['./refund-request.component.scss'],
    providers: [MessageService],
})
export class RefundRequestComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();

    @ViewChild('fileInput') fileInput: FileUpload;

    refundRequestForm: FormGroup;
    signatureForm: FormGroup;

    signaturePad: SignaturePadComponent;
    @ViewChild(SignaturePadComponent, { static: false }) set component(component: SignaturePadComponent) {
        if (component) {
            this.signaturePad = component;
        }
    }

    customerResults: RefundCustomersPartial[] = [];
    locationSearchResults: LocationsPartial[] = [];

    currentCustomer: RefundCustomersPartial;

    error_msgs: Message[] = [];
    uploadInProgress$: Observable<boolean>;
    uploadURL: string = '';
    uploadedFiles: any[] = [];

    constructor(
        private refundService: RefundRequestService,
        private messageService: MessageService,
        private loadingService: IsLoadingService,
        private signatureService: SignatureService,
        private fb: RxFormBuilder,
        public mpiApp: MPIAppService,
        public selectedFilesState: FileUploadStateService,
        public permanentError: PermanentErrorService
    ) { }

    ngOnInit(): void {
        this.uploadInProgress$ = this.loadingService.isLoading$({ key: 'file-upload' });
        this.uploadURL = this.mpiApp.getURL('fileuploads');

        ReactiveFormConfig.set({
            validationMessage: {
                required: 'This field is required',
                minLength: 'Minimum length is {{0}}',
                maxLength: 'Maximum length is {{0}}',
                minNumber: 'Minimum length is 4 digits',
                maxNumber: 'Maximum length is 4 digits',
            },
        });

        this.refundRequestForm = this.fb.formGroup(FormRefundRequest);
        this.signatureForm = this.fb.group({
            signature: ['', Validators.required],
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    submitForm() {
        this.refundRequestForm.markAsDirty();
        this.refundRequestForm.markAllAsTouched();
        this.signatureForm.markAsDirty();
        this.signatureForm.markAllAsTouched();
        if (this.refundRequestForm.valid && this.signatureForm.valid) {
            /* upload files */
            if (this.selectedFilesState.getFileState()) {
                this.loadingService.add({ key: 'file-upload' });
                this.fileInput.upload();
            }

            // const refundObject: RefundRequest = this.refundRequestForm.value;

            // if (!refundObject.address2) {
            //     refundObject.address2 = '';
            // }
            // refundObject.managerSignature = refundObject.customerSignature;
            // refundObject.status = 'manager-approved';

            // if (!refundObject.customerID) {
            //     delete refundObject.customerID;
            // }
            // if (!refundObject.glCode) {
            //     delete refundObject.glCode;
            // }
            // console.log(refundObject);

            // this.refundService
            //     .submitRefundRequest(refundObject)
            //     .pipe(takeUntil(this.destroy$))
            //     .subscribe({
            //         next: (data) => {
            //             httpSuccessToast(
            //                 this.messageService,
            //                 `Refund Request for ${data.customerName} with Ticket #${data.ticketNumber} created.`
            //             );
            //             this.clearForm();
            //         },
            //         error: (err: HttpErrorResponse) => {
            //             httpErrorToast(this.messageService, err);
            //         },
            //         complete: () => {},
            //     });
        } else if (!this.refundRequestForm.valid) {
            const invalidFields = this.mpiApp.findInvalidControls(this.refundRequestForm);
            this.messageService.add({
                severity: 'error',
                summary: 'Refund Form Invalid',
                detail: `There are errors with the following fields: ` + invalidFields.join(`, `),
            });
        } else if (!this.signatureForm.valid) {
            this.messageService.add({
                severity: 'error',
                summary: 'Signature Required',
                detail: `Signature is Required`,
            });
        }
    }

    clearForm() {
        this.refundRequestForm.reset();
        this.currentCustomer = {};
        this.signaturePad.clearCanvas();
        this.signatureForm.reset();
    }

    /**
     * Save the latest version of the user string
     *
     * Take what's currently in our signature service and fill our signature form with it
     */
    saveSignature() {
        const signature: string = this.signatureService.getSignature();
        this.signatureForm.controls['signature'].setValue(signature);
        this.refundRequestForm.controls['customerSignature'].setValue(signature);
    }

    /**
     * Reset signature pad canvas
     */
    clearCanvas() {
        this.signaturePad.clearCanvas();
        this.signatureForm.reset();
        this.refundRequestForm.controls['customerSignature'].setValue(null);
        this.refundRequestForm.controls['managerSignature'].setValue(null);
    }

    searchCustomers(query: string) {
        const pattern = new RegExp('.*' + query + '.*', 'i');
        const filter = `{"where": {"name": {"regexp": "${pattern}"}}}`;

        this.refundService
            .getRefundCustomerList(filter)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (data) => {
                    console.table(data);
                    this.customerResults = data;
                },
                error: (err: HttpErrorResponse) => {
                    httpErrorToast(this.messageService, err);
                },
            });
    }

    selectCustomer(event: RefundCustomersPartial | string) {
        console.log(event);
        // customerID: Internal sequential id for database queries
        // custID: User-defined ID for the customer for displaying
        // Check if the user used the dropdown to select a customer, or just typed it in
        if (typeof event !== 'string') {
            console.log('Existing Customer');
            this.currentCustomer = event;
            this.refundRequestForm.get('customerName').setValue(event.name);
            this.refundRequestForm.get('customerID').setValue(event.id);
            this.refundRequestForm.get('custID').setValue(event.customerID);
            this.refundRequestForm.get('location').setValue(event.location);
        } else {
            if (event === '') {
                console.log('Empty');
                this.currentCustomer = {};
                this.refundRequestForm.get('customerName').setValue(null);
                this.refundRequestForm.get('customerID').setValue(null);
                // this.refundRequestForm.get('custID').setValue(null);
            } else if (!this.refundRequestForm.get('customerID').value) {
                console.log('New Customer');
                this.currentCustomer = {
                    name: event,
                    id: null,
                    customerID: this.refundRequestForm.get('custID').value,
                    location: this.refundRequestForm.get('location').value,
                };
                this.refundRequestForm.get('customerID').setValue(null);
            } else if (this.currentCustomer.name !== event) {
                console.log('Change from Existing to New Customer');
                this.currentCustomer = {
                    name: event,
                    id: null,
                };
                this.refundRequestForm.get('customerID').setValue(null);
            }
        }
    }

    searchLocations(query: string) {
        const pattern = new RegExp('.*' + query + '.*', 'i');
        const filter = `{"where": {"or": [{"name": {"regexp": "${pattern}"}},{"description": {"regexp": "${pattern}"}}]},"order":["name ASC"]}`;

        this.mpiApp.getLocations(filter).subscribe({
            next: (data) => {
                this.locationSearchResults = data;
            },
            error: (err) => {
                console.error(err);
            },
            complete: () => { },
        });
    }

    selectLocation(event: LocationsPartial | string) {
        // Check if the user used the dropdown to select a location, or just typed it in
        if (typeof event !== 'string') {
            if (event.name) {
                // Make sure only the location name is assigned to the form control after selection
                this.refundRequestForm.get('location').setValue(Number(event.name));
            }
        } else {
            if (event === '') {
                this.refundRequestForm.get('location').setValue(null);
            } else {
                this.refundRequestForm.get('location').setValue(Number(event));
            }
        }
    }

    // FILE UPLOAD FUNCTIONS

    onUpload(event) {
        /* reset form values so user can submit a new refund request */
        this.refundRequestForm.reset();
        this.refundRequestForm.markAsPristine();
        this.signaturePad.clearCanvas();
        this.signatureForm.reset();
        this.fileInput.clear();
        this.selectedFilesState.setFileState(false);
        this.permanentError.setError(false);
        this.loadingService.remove({ key: 'file-upload' });
        this.error_msgs = [];

        this.messageService.add({
            severity: 'success',
            summary: 'Refund Request submitted',
            detail: 'Files(s) successfully uploaded',
        });
    }

    uploadError(event) {
        console.log(event.error);

        /* get the loopback error message, nested in error objects */
        const loopback_error = event.error.error.error;
        const error_obj = {
            severity: 'error',
            summary: 'Refund Request submit failed',
            detail: 'Error: ' + loopback_error.message,
        };

        this.error_msgs = [error_obj];
        httpErrorToast(this.messageService, event);
        this.loadingService.remove({ key: 'file-upload' });
        this.permanentError.setError(true);
    }

    /**
     * Modify the uploaded files before the POST request goes out
     *
     * Append the multipart/formdata to the POST request, including new refund request data
     * @param $event onBeforeUpload event
     */
    modifyRefundUpload($event) {
        $event.formData.append('expirationDate', '2021-01-01');
        $event.formData.append('documentType', 'refund');

        /* Append new refund request data under refundJSON */
        const newRefundRequest: RefundRequest = this.refundRequestForm.value;
        newRefundRequest.status = 'manager-approved';
        newRefundRequest.managerSignature = newRefundRequest.customerSignature;

        if (!newRefundRequest.address2) {
            delete newRefundRequest.address2;
        }
        if (!newRefundRequest.customerID) {
            delete newRefundRequest.customerID;
        }
        if (!newRefundRequest.glCode) {
            delete newRefundRequest.glCode;
        }

        $event.formData.append('refundJSON', JSON.stringify(newRefundRequest));
    }

    /**
     * Double check selected files from uploader in select event
     *
     * Taken from: https://stackoverflow.com/a/53639699/14560781
     *
     * If at least 1 file is selected, requirement for 1 file to be uploaded is fulfilled
     * @param event onSelect() event
     */
    fileSelect(event) {
        if (event.files.length > 0) {
            this.selectedFilesState.setFileState(true);
        } else {
            this.selectedFilesState.setFileState(false);
        }
    }

    /**
     * Remove selected file from uploader
     * check if enough files are still selected to satisfy requirement for at least 1 file
     * @param event onRemove() event
     */
    fileRemove(event) {
        /**
         * weird behavior with primeng fileupload where after clicking remove on individual file,
         * in onRemove event fileInput.files.length still reports the clicked file
         *
         * Work around by subtracting 1 from number of files, assuming the file removed is still being reported
         * by the event
         */
        if (!this.fileInput || this.fileInput.files.length - 1 <= 0) {
            this.selectedFilesState.setFileState(false);
        }
    }

    /**
     * Clear selected files from uploader
     * Since all files are removed, requirement for at least 1 uploaded file is no longer fulfilled
     */
    fileClear() {
        this.selectedFilesState.setFileState(false);
    }
}
