<p-card styleClass="mb-5">
    <ng-template pTemplate="body">
        <p-table
            #openPOTable
            [value]="openPOs"
            [paginator]="true"
            [rows]="5"
            [rowsPerPageOptions]="[5, 10, 25, 50]"
            [loading]="openPOsLoading$ | async"
            [globalFilterFields]="[
                'managerName',
                'vendorName',
                'vendorID',
                'estimatedCost',
                'remainingBalance',
                'status',
                'managerRequested',
                'poID',
                'dateRequested'
            ]">
            <ng-template pTemplate="caption">
                <div class="p-d-flex p-ai-center p-jc-between">
                    <h1 class="my-0 py-0">
                        <strong> Open Purchase Orders </strong>
                    </h1>
                    <div>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input
                                pInputText
                                type="text"
                                (input)="openPOTable.filterGlobal($event.target.value, 'contains')"
                                placeholder="Search..." />
                        </span>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="managerName">Manager<p-sortIcon field="managerName"></p-sortIcon></th>
                    <th pSortableColumn="vendorName">Vendor Name<p-sortIcon field="vendorName"></p-sortIcon></th>
                    <th pSortableColumn="locationsNameList">
                        Locations<p-sortIcon field="locationsNameList"></p-sortIcon>
                    </th>
                    <th pSortableColumn="vendorID">Vendor ID<p-sortIcon field="vendorID"></p-sortIcon></th>
                    <th pSortableColumn="estimatedCost">Amount<p-sortIcon field="estimatedCost"></p-sortIcon></th>
                    <th pSortableColumn="remainingBalance">
                        Remaining Balance<p-sortIcon field="remainingBalance"></p-sortIcon>
                    </th>
                    <th pSortableColumn="status">Status<p-sortIcon field="status"></p-sortIcon></th>
                    <th pSortableColumn="managerRequested">
                        Date Requested<p-sortIcon field="managerRequested"></p-sortIcon>
                    </th>
                    <th pSortableColumn="poID">Purchase Order #<p-sortIcon field="poID"></p-sortIcon></th>
                    <th pSortableColumn="dateRequested" style="display: none">
                        Date Requested<p-sortIcon field="dateRequested"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-purchaseorder>
                <tr [pSelectableRow]="purchaseorder">
                    <td>
                        {{ purchaseorder.managerName }}
                    </td>
                    <td>{{ purchaseorder.vendorName }}</td>
                    <!-- <td>{{ vendorNames[purchaseorder.vendorID] }}</td> -->
                    <td class="text-truncate">{{ purchaseorder.locationsNameList }}</td>
                    <td>{{ purchaseorder.vendorID }}</td>
                    <td>{{ purchaseorder.estimatedCost | currency : 'USD' : 'symbol' }}</td>
                    <td>{{ purchaseorder.remainingBalance | currency : 'USD' }}</td>
                    <td>{{ purchaseorder.status }}</td>
                    <td>{{ purchaseorder.managerRequested | date : 'M/d/yyyy hh:mm a' }}</td>
                    <td>{{ purchaseorder.poID }}</td>
                    <td style="display: none">{{ purchaseorder.dateRequested }}</td>
                </tr>
            </ng-template>
        </p-table>
    </ng-template>
</p-card>

<!-- <div class="card">
    <app-open-manager-pos></app-open-manager-pos>
</div> -->

<p-card>
    <ng-template pTemplate="body">
        <p-table
            #invoiceTable
            [value]="invoices"
            [loading]="invoicesLoading$ | async"
            [globalFilterFields]="[
                'invoiceID',
                'vendorRecord.name',
                'locationsNameList',
                'dateCreated',
                'status',
                'datePaid',
                'amount'
            ]"
            selectionMode="single"
            [(selection)]="selectedInvoice"
            [paginator]="true"
            [rows]="5"
            [rowsPerPageOptions]="[5, 10, 25, 50]"
            [customSort]="true"
            (sortFunction)="customSort($event)"
            (onRowSelect)="selectInvoice($event)">
            <ng-template pTemplate="caption">
                <div class="p-d-flex p-ai-center p-jc-between">
                    <h1 class="my-0 py-0">
                        <strong> Open Invoices </strong>
                    </h1>
                    <div>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input
                                pInputText
                                type="text"
                                (input)="invoiceTable.filterGlobal($event.target.value, 'contains')"
                                placeholder="Search..." />
                        </span>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="vendorRecord.name">
                        Company
                        <p-sortIcon field="vendorRecord.name"></p-sortIcon>
                    </th>
                    <th pSortableColumn="locationsNameList">
                        Locations
                        <p-sortIcon field="locationsNameList"></p-sortIcon>
                    </th>
                    <th pSortableColumn="submittedDate">
                        Date Created
                        <p-sortIcon field="submittedDate"></p-sortIcon>
                    </th>
                    <th pSortableColumn="status">
                        Status
                        <p-sortIcon field="status"></p-sortIcon>
                    </th>
                    <th pSortableColumn="amount">
                        Amount
                        <p-sortIcon field="amount"></p-sortIcon>
                    </th>
                    <th pSortableColumn="dateCreated" style="display: none">Date Created</th>
                    <th pSortableColumn="datePaid" style="display: none">Date Paid</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-invoice>
                <tr [pSelectableRow]="invoice">
                    <td>{{ invoice.vendorRecord?.name }}</td>
                    <td class="text-truncate">
                        {{
                            invoice.locationsNameList.join(', ').length > 20
                                ? (invoice.locationsNameList.join(', ') | slice : 0 : 20) + '...'
                                : invoice.locationsNameList.join(', ')
                        }}
                    </td>
                    <td>
                        {{ invoice.submittedDate | date : 'M/d/yyyy hh:mm a' }}
                    </td>
                    <td>{{ invoice.status }}</td>
                    <td>{{ invoice.amount | currency : 'USD' }}</td>
                </tr>
            </ng-template>
        </p-table>
    </ng-template>
</p-card>
