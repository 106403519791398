<p-toast></p-toast>
<div class="dashboard grid p-fluid" *ngIf="loadingFinished; else spinner">
    <div class="col-12 md:col-12">
        <div class="card">
            <h1 style="font-size: 16px">Incoming Requests for New Vendor Accounts</h1>
            <p-table
                #vendorPendingTable
                [value]="requestsWithFiles"
                [globalFilterFields]="[
                    'vendorID',
                    'name',
                    'locationsNameListString',
                    'setupStatus.userAccountVerified',
                    'status',
                    'setupStatus.w9',
                    'setupStatus.insurance'
                ]"
                selectionMode="single"
                [(selection)]="selectedRequest"
                dataKey="vendorID"
                [paginator]="false"
                (onRowSelect)="onRowSelect($event)"
                (onRowUnselect)="onRowUnselect($event)">
                <ng-template pTemplate="caption">
                    <div class="flex align-items-center justify-content-end">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input
                                    pInputText
                                    type="text"
                                    (input)="vendorPendingTable.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="vendorID">
                            ID
                            <p-sortIcon field="vendorID"></p-sortIcon>
                        </th>
                        <th pSortableColumn="name">
                            Company
                            <p-sortIcon field="name"></p-sortIcon>
                        </th>
                        <th pSortableColumn="locationsNameListString">
                            Locations
                            <p-sortIcon field="locationsNameListString"></p-sortIcon>
                        </th>
                        <th pSortableColumn="setupStatus.userAccountVerified">
                            Invite Status
                            <p-sortIcon field="setupStatus.userAccountVerified"></p-sortIcon>
                        </th>
                        <th pSortableColumn="status">
                            Approval Status
                            <p-sortIcon field="status"></p-sortIcon>
                        </th>
                        <th pSortableColumn="setupStatus.w9">
                            W9 Status
                            <p-sortIcon field="setupStatus.w9"></p-sortIcon>
                        </th>
                        <th pSortableColumn="setupStatus.insurance">
                            Insurance Status
                            <p-sortIcon field="setupStatus.insurance"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-request>
                    <tr [pSelectableRow]="request">
                        <td>{{ request.vendorID }}</td>
                        <td>{{ request.name }}</td>
                        <td>
                            {{ request.locationsNameListString }}
                        </td>
                        <td>
                            {{ request.setupStatus.userAccountVerified }}
                            <div
                                *ngIf="
                                    this.vendorUserAccountVerified[request.vendorID] | async as resolvedUserInvite;
                                    else resolvingUserInvite
                                ">
                                {{ resolvedUserInvite }}
                            </div>
                            <ng-template #resolvingUserInvite> checking ... </ng-template>
                        </td>
                        <td>{{ request.status }}</td>
                        <td>{{ request.setupStatus.w9 }}</td>
                        <td>{{ request.setupStatus.insurance }}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<ng-template #spinner>
    <p-progressSpinner></p-progressSpinner>
</ng-template>

<p-dialog
    header="Vendor"
    [(visible)]="isSelected"
    [modal]="true"
    [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
    [style]="{ width: '60vw' }"
    (onHide)="onRowUnselect($event)">
    <app-view-vendor-info
        [(id)]="selectedRequest.vendorID"
        (filesApprovedEvent)="markFilesApproved($event)"
        [componentMode]="'accounting'">
    </app-view-vendor-info>

    <ng-template pTemplate="footer">
        <div class="mt-2">
            <p-button
                label="Approve"
                icon="pi pi-check"
                styleClass="p-button-success"
                (click)="handleApprove($event)"
                [disabled]="!filesApproved"></p-button>
            <p-button
                label="Deny"
                icon="pi pi-times"
                styleClass="p-button-danger"
                (click)="handleDeny($event)"></p-button>
            <p-button
                label="Cancel"
                icon="pi pi-times-circle"
                styleClass="p-button-outlined"
                (click)="onRowUnselect($event)"></p-button>
        </div>
    </ng-template>
</p-dialog>

<!--<input type="button" (click)="dumpFiles()" title="Dump Files">-->
