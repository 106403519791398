import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { browserTracingIntegration, init } from '@sentry/angular';

if (environment.production) {
    enableProdMode();
}

init({
    dsn: 'https://4bd4ef8a2bf24d2587d1824ae811f1f3@o300968.ingest.sentry.io/6512421',
    integrations: [browserTracingIntegration()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/mpihelptry\.com\/api/],
});

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
