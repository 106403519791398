import { Component, OnInit, OnDestroy } from '@angular/core';
import { VendorPurchaseRequest } from '../domain/vendor-purchase-request';
import { MessageService } from 'primeng/api';
import { PurchaseOrder } from '../api/models/purchase-order';
import { MPIAppService } from '../../services/mpiapp.service';
import { Vendor } from '../api/models/vendor';
import { FilePartial } from '../api/models/file-partial';
import { environment } from '../../environments/environment';
import { DateTime } from 'luxon';
import { finalize } from 'rxjs/operators';
import { PurchaseOrderWithVendor } from '../purchase-order-list/purchase-order-list.component';
import { Subscription } from 'rxjs';
import {MPIRecurringService} from '../../services/mpirecurring.service';
import {RecurringAttributes} from '../api/models/mpi-custom-models';

@Component({
    selector: 'app-purchase-order-approval',
    templateUrl: './purchase-order-approval.component.html',
    styleUrls: ['./purchase-order-approval.component.scss'],
    providers: [MessageService],
})
export class PurchaseOrderApprovalComponent implements OnInit, OnDestroy {
    private subs: Subscription[] = [];
    purchaseOrders: PurchaseOrderWithVendor[] = [];
    vendors: Vendor[] = [];
    purchaseOrderVendor: Vendor;
    selectedPO: PurchaseOrder;
    files: FilePartial[] = [];
    vendorNames = {};

    // If this is a recurring PO store the dates it occurs.
    recurringDates: DateTime[] = [];
    recurringDisplayMessage = '';

    // Resolved when the PO is selected for display to determine if the recurring fields
    // should be shown or not.
    showRecurring = false;

    env = environment;

    constructor(
        private messageService: MessageService,
        private mpiApp: MPIAppService,
        public mpiRecurring: MPIRecurringService,
    ) {}

    ngOnInit(): void {
        this.subs = [];
        // Only show pending requests by default.
        const filter = {
            where: {
                status: { eq: 'pending' },
            },
        };

        this.subs.push(
            this.mpiApp
                .getVendors()
                .pipe(
                    finalize(() => {
                        for (const vendor of this.vendors) {
                            this.vendorNames[vendor.vendorID] = vendor.name;
                        }

                        this.subs.push(
                            this.mpiApp.getPurchaseOrders(JSON.stringify(filter)).subscribe({
                                next: (data: PurchaseOrderWithVendor[]) => {
                                    this.purchaseOrders = data;

                                    this.purchaseOrders.forEach((po: PurchaseOrderWithVendor) => {
                                        po.vendorName = this.vendorNames[po.vendorID];
                                    });
                                },
                                error: (error) => {
                                    console.log(error);
                                },
                            })
                        );
                    })
                )
                .subscribe(
                    (data: Vendor[]) => {
                        this.vendors = data;
                    },
                    (error) => {
                        console.log(error);
                    }
                )
        );
    }

    ngOnDestroy(): void {
        this.subs.forEach((sub: Subscription) => {
            sub.unsubscribe();
        });
    }

    purchaseOrderSelect(event) {
        // match the selected purchase to a vendor so we can display vendor name
        this.purchaseOrderVendor = this.vendors.find((vendor: Vendor) => vendor.vendorID == this.selectedPO.vendorID);

        this.mpiApp
            .getFiles('purchaseOrder', this.selectedPO.purchaseOrderID.toString())
            .subscribe((data: FilePartial[]) => {
                this.files = data;
            });

        if (this.selectedPO.poType === 'recurring') {
            // Convert the attributes string back into an object
            const recurringAttributes = this.mpiRecurring.parseAttributeString(this.selectedPO.recurringAttributes);

            // Calculate the schedule of occurrences and message.
            this.recurringDates = this.mpiRecurring.calculateSchedule(recurringAttributes);
            this.recurringDisplayMessage = this.mpiRecurring.getScheduleAsDisplayString(recurringAttributes, this.recurringDates);

            this.showRecurring = true;
        } else {
            this.showRecurring = false;
        }

    }

    /**
     * NOTE: update with call to update purchase order once endpoint implemented
     */
    handleApprove(event) {
        const poID = this.selectedPO.purchaseOrderID.toString();
        const payload = { status: 'approved', statusLastSet: DateTime.now().toISO() };

        this.mpiApp
            .updatePurchaseOrder(poID, payload)
            .pipe(
                finalize(() => {
                    this.selectedPO = null;
                })
            )
            .subscribe(
                (data) => {
                    this.messageService.add({
                        severity: 'info',
                        summary: 'Purchase Order Approved',
                        detail: 'Purchase order successfully marked as approved',
                    });

                    const index = this.purchaseOrders.findIndex(
                        (obj) => obj.purchaseOrderID == this.selectedPO.purchaseOrderID
                    );
                    this.purchaseOrders[index].status = 'approved';
                },
                (error) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error Approving Purchase Order',
                        detail: 'Purchase order status update failed',
                    });
                }
            );
    }

    /**
     * NOTE: update with call to update purchase order once endpoint implemented
     */
    handleDeny(event) {
        const poID = this.selectedPO.purchaseOrderID.toString();

        const payload = { status: 'denied', statusLastSet: DateTime.now().toISO() };

        this.mpiApp
            .updatePurchaseOrder(poID, payload)
            .pipe(
                finalize(() => {
                    this.selectedPO = null;
                })
            )
            .subscribe(
                (data) => {
                    this.messageService.add({
                        severity: 'info',
                        summary: 'Purchase Order Denied',
                        detail: 'Purchase order successfully marked as denied',
                    });

                    const index = this.purchaseOrders.findIndex(
                        (obj) => obj.purchaseOrderID == this.selectedPO.purchaseOrderID
                    );
                    this.purchaseOrders[index].status = 'denied';
                },
                (error) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error Updating Purchase Order',
                        detail: 'Purchase order status update failed',
                    });
                }
            );
    }

    downloadFile(fileID: string | number) {
        this.mpiApp.showFile(String(fileID));
    }
}
