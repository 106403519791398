import { maxLength, maxNumber, minLength, minNumber, prop, required } from '@rxweb/reactive-form-validators';

export class FormRefundRequest {
    @required()
    location: string;

    @prop()
    glCode: string;

    @required()
    date: string;

    // Internal sequential customer ID
    @prop()
    customerID: number;

    // User defined customer ID
    // Managers and Corporate do not see this
    @prop()
    custID: string;

    @required()
    customerName: string;

    @required()
    reason: string;

    @required()
    transactionAmount: number;

    @required()
    last4CardNo: number;

    @required()
    ticketNumber: string;

    @required()
    requestedAmount: number;

    @prop()
    customerSignature?: string;

    @required()
    payableTo: string;

    @required()
    address: string;

    @prop()
    address2?: string;

    @required()
    city: string;

    @required()
    state: string;

    @required()
    zipcode: number;
}
