<p-toast></p-toast>
<div class="dashboard grid fluid">
    <div class="col-12 md:col-12">
        <div class="card">
            <h1 style="font-size: 16px">Incoming Purchase Order Requests</h1>
            <p-table
                #POApprovalTable
                [value]="purchaseOrders"
                [globalFilterFields]="[
                    'vendorName',
                    'locationsNameList',
                    'estimatedCost',
                    'status'
                ]"
                selectionMode="single"
                [(selection)]="selectedPO"
                (onRowSelect)="purchaseOrderSelect($event)"
                [paginator]="true"
                [rows]="5"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [showCurrentPageReport]="true">
                <ng-template pTemplate="caption">
                    <div class="flex align-items-center justify-content-end">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input
                                    pInputText
                                    type="text"
                                    (input)="POApprovalTable.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="vendorName">
                            Vendor
                            <p-sortIcon field="vendorName"></p-sortIcon>
                        </th>
                        <th pSortableColumn="locationsNameList">
                            Locations
                            <p-sortIcon field="locationsNameList"></p-sortIcon>
                        </th>
                        <th pSortableColumn="estimatedCost">
                            Amount
                            <p-sortIcon field="estimatedCost"></p-sortIcon>
                        </th>
                        <th pSortableColumn="status">
                            Status
                            <p-sortIcon field="status"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-purchaseorder>
                    <tr [pSelectableRow]="purchaseorder">
                        <td>{{ purchaseorder.vendorName }}</td>
                        <td>{{ purchaseorder.locationsNameList }}</td>
                        <td>{{ purchaseorder.estimatedCost | currency: 'USD':'symbol' }}</td>
                        <td>{{ purchaseorder.status }}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>

        <div *ngIf="selectedPO" class="px-4 md:px-6 lg:px-8">
            <div class="surface-card p-4 shadow-2 border-round">
                <div class="font-medium text-3xl text-900 mb-3">Requested Purchase Order</div>
                <div class="text-500 mb-5">Last updated: {{ selectedPO.statusLastSet | date: 'medium' }}</div>
                <div class="grid grid-nogutter border-top-1 surface-border pt-2">
                    <div class="col-12 md:col-6 p-3">
                        <div class="text-500 font-medium mb-2">Vendor</div>
                        <div class="text-900">{{ purchaseOrderVendor.name }}</div>
                    </div>
                    <div class="col-12 md:col-6 p-3">
                        <div class="text-500 font-medium mb-2">Vendor ID</div>
                        <div class="text-900">{{ selectedPO.vendorID }}</div>
                    </div>
                    <div class="col-12 md:col-6 p-3">
                        <div class="text-500 font-medium mb-2">Estimated Cost</div>
                        <div class="text-900">{{ selectedPO.estimatedCost | currency: 'USD':'symbol' }}</div>
                    </div>
                    <div class="col-12 md:col-6 p-3">
                        <div class="text-500 font-medium mb-2">Locations</div>
                        <div *ngFor="let location of selectedPO.locationsNameList" class="text-900">
                            {{ location }}
                        </div>
                    </div>

                    <div class="col-12 p-3" *ngIf="showRecurring">
                        <div class="text-500 font-medium mb-2">Recurring Summary</div>
                        <div class="text-900 line-height-3">{{ recurringDisplayMessage }}</div>
                    </div>

                    <div class="col-12 p-3" *ngIf="showRecurring">
                        <div class="text-500 font-medium mb-2">Recurring Schedule</div>
                        <div class="text-900 line-height-3">
                            <ul>
                                <li *ngFor="let oneDate of recurringDates ">{{oneDate | date:'M/d/yy'}}</li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-12 p-3">
                        <div class="text-500 font-medium mb-2">Reason</div>
                        <div class="text-900 line-height-3" id="displayed-po-reason">{{ selectedPO.reason }}</div>
                    </div>
                    <div class="col-12 p-3" *ngIf="files && files.length > 0; else noPOFiles">
                        <div class="text-500 font-medium mb-3">Files</div>
                        <div
                            *ngFor="let file of files"
                            class="flex md:justify-content-between md:align-items-center border-top-1 surface-border p-3 flex-column md:flex-row">
                            <div class="flex align-items-center">
                                <span class="block pi pi-file mr-2"></span>
                                <a class="file-download-link" (click)="downloadFile(file.id)">
                                    {{ file.originalFileName }} - {{ file.size }} bytes
                                </a>
                            </div>
                        </div>
                    </div>

                    <ng-template #noPOFiles>
                        <div class="col-12 p-3">
                            <div class="text-500 font-medium mb-3">Files</div>
                            <div>No Files Uploaded With PO Request.</div>
                        </div>
                    </ng-template>

                    <div class="col flex justify-content-end">
                        <!-- Remove 'style' once primeng package is upgraded to 12.0 -->
                        <button
                            pButton
                            type="button"
                            label="Approve"
                            icon="pi pi-check"
                            class="p-button-success"
                            style="width: auto"
                            (click)="handleApprove($event)"
                            [disabled]="selectedPO.status === 'approved'"></button>
                    </div>
                    <div class="col-1"></div>
                    <div class="col flex justify-content-begin">
                        <button
                            pButton
                            type="button"
                            label="Deny"
                            icon="pi pi-times"
                            class="p-button-danger"
                            style="width: auto"
                            (click)="handleDeny($event)"
                            [disabled]="selectedPO.status === 'denied'"></button>
                    </div>
                </div>
            </div>

            <!-- For debugging show the recurring attributes -->
<!--            <pre *ngIf="showRecurring">{{selectedPO | json}}</pre>-->

        </div>
    </div>
</div>
