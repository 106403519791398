<div *ngIf="user_role !== 'manager'" class="print-content print-container-relative">
    <h1>Open Purchase Orders By Manager</h1>
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-4">
            <p-selectButton [options]="reportOptions" [(ngModel)]="reportOptionsSelected"
                            optionLabel="name" [multiple]="true" (onChange)="changedReportOptions()"></p-selectButton>
        </div>

        <div class="p-col-12 p-md-6">

        </div>

        <div class="p-col-12 p-md-2">
            <p-button label="Generate Report" (onClick)="loadReport();"></p-button>
        </div>
    </div>

</div>

<h1 *ngIf="user_role === 'manager'" class="print-content print-container-relative">Open Purchase Orders</h1>
<div
    *ngIf="!this.isLoadingService.isLoading({ key: 'list' }); else spinner"
    class="print-content print-container-relative">
    <div *ngFor="let managerPOs of managerRequestedPOs | keyvalue; index as i" class="print-content"
        [ngClass]="isSelected('no-page-break') ? '' : 'page-break'">

        <p-table
            *ngIf="!this.isLoadingService.isLoading({ key: 'list' }); else spinner"
            #POTable
            [value]="managerPOs.value"
            selectionMode="single"
            [paginator]="false"
            [rows]="9999"
            [rowsPerPageOptions]="[10, 15, 25, 50]"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [showCurrentPageReport]="true">
            <ng-template pTemplate="caption">
                <div class="flex align-items-center justify-content-start">
                    <div>
                        <h2 *ngIf="this.users[managerPOs.key] | async as resolvedUserInvite; else resolvingUser">
                            {{ resolvedUserInvite.firstName }} {{ resolvedUserInvite.lastName }} -
                            {{ resolvedUserInvite.username }}
                        </h2>
                        <ng-template #resolvingUser>
                            <h2>{{ managerPOs.key }}</h2>
                        </ng-template>

                        <!--                    <h2>{{users[managerPOs.key].username | async}}</h2>-->
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="vendorName">Vendor Name</th>
                    <th pSortableColumn="locationsNameList">Locations</th>
                    <th pSortableColumn="vendorID">Vendor ID</th>
                    <th pSortableColumn="estimatedCost">Amount</th>
                    <th pSortableColumn="remainingBalance">Remaining Balance</th>
                    <th pSortableColumn="status">Status</th>
                    <th pSortableColumn="managerRequested">Date Requested</th>
                    <th pSortableColumn="poID">Purchase Order #</th>
                    <th pSortableColumn="dateRequested" style="display: none">Date Requested</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-purchaseorder>
                <tr [pSelectableRow]="purchaseorder">
                    <td>{{ purchaseorder.vendorName }}</td>
                    <!-- <td>{{ vendorNames[purchaseorder.vendorID] }}</td> -->
                    <td class="text-truncate">{{ purchaseorder.locationsNameList }}</td>
                    <td>{{ purchaseorder.vendorID }}</td>
                    <td>{{ purchaseorder.estimatedCost | currency: 'USD':'symbol' }}</td>
                    <td>{{ purchaseorder.remainingBalance | currency: 'USD' }}</td>
                    <td>{{ purchaseorder.status }}</td>
                    <td>{{ purchaseorder.managerRequested | date: 'M/d/yyyy hh:mm a' }}</td>
                    <td>{{ purchaseorder.poID }}</td>
                    <td style="display: none">{{ purchaseorder.dateRequested }}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<ng-template #spinner>
    <p-progressSpinner></p-progressSpinner>
</ng-template>
