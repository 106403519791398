import { Component, OnInit } from '@angular/core';
import { MPIAppService } from 'src/services/mpiapp.service';
import { Vendor } from '../api/models/vendor';
import { User } from '../api/models/user';
import { HttpErrorResponse } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { IsLoadingService } from '@service-work/is-loading';
import { Observable } from 'rxjs';
import { VendorWithUser } from '../../services/mpiapp.service';

@Component({
    selector: 'app-view-vendors',
    templateUrl: './view-vendors.component.html',
    styleUrls: ['./view-vendors.component.scss'],
    providers: [MessageService],
})
export class ViewVendorsComponent implements OnInit {
    vendors: VendorWithUser[];
    users: User[];
    selectedVendor: VendorWithUser;
    selectedVendorEmail: string;
    userVerified: boolean;
    isLoading: boolean = false;

    // TODO: integrate this boolean into the vendor API somehow so I can dynamically
    // tell when insurance info is optional or not. - PAS
    optionalInsuranceInfo: boolean = false;

    constructor(private mpiApp: MPIAppService, private messageService: MessageService) {}

    ngOnInit(): void {
        this.mpiApp.getVendors().subscribe((data: Vendor[]) => {
            this.vendors = data;
        });
    }

    resendInvitation(vendor: Vendor) {
        const new_status = { status: 'resend-invite', accountContactEmail: vendor.accountContactEmail };
        console.log('Status: ', new_status);
        this.isLoading = true;
        this.mpiApp.updateVendor(vendor.vendorID.toString(), new_status).subscribe(
            (data) => {
                this.isLoading = false;
                this.messageService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Vendor invitation sent',
                });
            },
            (error: HttpErrorResponse) => {
                this.isLoading = false;
                console.error(error);
                const loopback = error.error.error.message;
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: loopback,
                });
            }
        );
    }

    selectVendor(event: any) {
        this.selectedVendor = event.data;
        this.getUserStatus(this.selectedVendor);
    }

    unselectVendor(event: any) {
        this.selectedVendor = null;
    }

    getUserStatus(vendor: VendorWithUser) {
        return this.getAnyUserStatus(String(vendor.vendorID), 'vendor');
    }

    /**
     * Given a roleID and a role name get the User record.
     *
     * @param roleID
     * @param role
     */
    getAnyUserStatus(roleID: string, role: string) {
        const filter = {
            where: {
                and: [{ roleID }, { role }],
            },
        };

        this.mpiApp.getUsers(JSON.stringify(filter)).subscribe({
            next: (users: User[]) => {
                console.log('Associated User', users);
                if (users.length > 0) {
                    // this.selectedVendor.locationsNameList = users[0].locationsNameList;
                    this.selectedVendor.accountVerified = users[0].accountVerified === 1;
                }
            },
            error: (err: HttpErrorResponse) => {
                console.error(err.error);
            },
            complete: () => {},
        });
    }

}
