import {Component} from '@angular/core';
import { AppComponent } from './app.component';
import {AppMainComponent} from './app.main.component';

@Component({
    selector: 'app-config',
    template: `
        <!--
        <a style="cursor: pointer" id="layout-config-button" class="layout-config-button" (click)="onConfigButtonClick($event)">
            <i class="pi pi-cog"></i>
        </a>
        -->
        <div
            class="layout-config"
            [ngClass]="{ 'layout-config-active': appMain.configActive }"
            (click)="appMain.onConfigClick($event)">
            <h5>Input Style</h5>
            <div class="p-field-radiobutton">
                <p-radioButton
                    name="inputStyle"
                    value="outlined"
                    [(ngModel)]="app.inputStyle"
                    inputId="inputStyle1"></p-radioButton>
                <label for="inputStyle1">Outlined</label>
            </div>
            <div class="p-field-radiobutton">
                <p-radioButton
                    name="inputStyle"
                    value="filled"
                    [(ngModel)]="app.inputStyle"
                    inputId="inputStyle2"></p-radioButton>
                <label for="inputStyle2">Filled</label>
            </div>

            <hr />

            <h5>Ripple Effect</h5>
            <p-inputSwitch
                inputId="ripple-effect"
                [ngModel]="app.ripple"
                (onChange)="appMain.onRippleChange($event)"></p-inputSwitch>
        </div>
    `,
})
export class AppConfigComponent {
    constructor(public app: AppComponent, public appMain: AppMainComponent) {}

    onConfigButtonClick(event) {
        this.appMain.configActive = !this.appMain.configActive;
        this.appMain.configClick = true;
        event.preventDefault();
    }
}
