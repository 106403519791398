import { takeUntil } from 'rxjs/operators';
import { Observable, Subject, forkJoin } from 'rxjs';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { RefundRequestService } from 'src/services/refund-request.service';
import { SessionService } from 'src/session.service';
import { LoginModel } from '../models/login.model';
import { FilePartial, LocationsPartial, RefundCustomersPartial, RefundRequest } from '../api/models';
import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { httpErrorToast, httpSuccessToast } from 'src/helpers/http-toasts';
import { ReactiveFormConfig, RxFormBuilder, required, toInt } from '@rxweb/reactive-form-validators';
import { FormRefundRequest } from 'src/_models/form-types/form-refund-request';
import { SignaturePadComponent } from '../signature-pad/signature-pad.component';
import { SignatureService } from '../signature.service';
import { ValidationService } from '../validation.service';
import { RefundRequestPartial } from '../api/models';
import { MPIAppService } from 'src/services/mpiapp.service';
import { IsLoadingService } from '@service-work/is-loading';
import { DateTime } from 'luxon';
import { escapeRegExp } from 'src/helpers/escape-regexp';

@Component({
    selector: 'app-view-refund-requests',
    templateUrl: './view-refund-requests.component.html',
    styleUrls: ['./view-refund-requests.component.scss'],
    providers: [MessageService],
})
export class ViewRefundRequestsComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();
    user_profile: LoginModel;
    user_role: string = '';

    refundRequests: RefundRequestPartial[] = [];
    totalRefundRequests: number = 0;
    selectedRefund: RefundRequestPartial;

    defaultLazyLoadEvent: LazyLoadEvent = {
        rows: 10,
        first: 0,
        sortField: 'date',
        sortOrder: -1,
    };

    showPaidRefunds: boolean = false;
    showUnpaidRefunds: boolean = false;
    showDeniedRefunds: boolean = false;
    showApprovedRefunds: boolean = false;
    showPendingRefunds: boolean = true;

    refundDetailsShown: boolean = false;
    refundFilesShown: boolean = false;

    refundDetailsForm: UntypedFormGroup;

    refundFiles: FilePartial[] = [];

    loadingRefunds$: Observable<boolean>;
    refundFilesLoading$: Observable<boolean>;
    httpRequest$: Observable<boolean>;
    printRequest$: Observable<boolean>;

    locationSearchResults: LocationsPartial[] = [];
    customerResults: RefundCustomersPartial[] = [];
    currentCustomer: RefundCustomersPartial = {};

    canApprove: boolean = false;
    canDeny: boolean = false;

    managerApprovedDateTime: Date;
    corporateOfficeApprovedDateTime: Date;

    signatureRequired: boolean = false;
    public signaturePad: SignaturePadComponent;
    @ViewChild(SignaturePadComponent, { static: false }) set component(component: SignaturePadComponent) {
        if (component) {
            this.signaturePad = component;
        }
    }
    signatureForm = this.fb.group({
        signature: ['', Validators.required],
    });

    constructor(
        private mpiApp: MPIAppService,
        private refundService: RefundRequestService,
        private messageService: MessageService,
        private session: SessionService,
        private signatureService: SignatureService,
        private validationService: ValidationService,
        private loadingService: IsLoadingService,
        private fb: RxFormBuilder
    ) {}

    ngOnInit(): void {
        this.loadingRefunds$ = this.loadingService.isLoading$({ key: 'load-refunds' });
        this.refundFilesLoading$ = this.loadingService.isLoading$({ key: 'refund-files' });
        this.httpRequest$ = this.loadingService.isLoading$({ key: 'http-request' });
        this.printRequest$ = this.loadingService.isLoading$({ key: 'print-request' });
        ReactiveFormConfig.set({
            validationMessage: {
                required: 'This field is required',
                minLength: 'Minimum length is {{0}}',
                maxLength: 'Maximum length is {{0}}',
                minNumber: 'Minimum length is 4 digits',
                maxNumber: 'Maximum length is 4 digits',
            },
        });
        this.user_profile = this.session.getUserProfile();
        if (this.user_profile?.activeRole) {
            // Show the component based on the users activeRole
            this.user_role = this.user_profile.activeRole;
        }

        if (this.user_role) {
            switch (this.user_role) {
                case 'manager':
                    this.signatureRequired = false;
                    break;

                case 'corporate-office':
                    this.signatureRequired = true;
                    break;

                case 'accounting':
                    this.signatureRequired = false;
                    break;
            }
        }

        if (location.hash === '#/accounting/invoices') {
            this.showPaidRefunds = true;
        }

        this.getRefunds(null);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    setRefundFilter(event, type: string) {
        if (type === 'approved') {
            this.showApprovedRefunds = event.checked;
        } else if (type === 'denied') {
            this.showDeniedRefunds = event.checked;
        } else if (type === 'unpaid') {
            this.showUnpaidRefunds = event.checked;
        } else if (type === 'pending') {
            this.showPendingRefunds = event.checked;
        }

        if (this.selectedRefund) {
            this.deselectRefund();
            this.selectedRefund = null;
        }

        this.getRefunds(null);
    }

    getRefunds(event?: LazyLoadEvent) {
        let filterObj: any = {};
        if (location.hash === '#/accounting/invoices') {
            if (this.showUnpaidRefunds) {
                if (this.showDeniedRefunds) {
                    filterObj = { where: { status: { neq: 'paid' } }, order: ['date DESC'] };
                } else {
                    filterObj = {
                        where: { and: [{ status: { neq: 'paid' } }, { status: { neq: 'denied' } }] },
                        order: ['date DESC'],
                    };
                }
            } else if (this.showDeniedRefunds) {
                filterObj = { where: { status: 'denied' }, order: ['date DESC'] };
            } else {
                filterObj = { where: { status: 'paid' }, order: ['date DESC'] };
            }
        } else {
            switch (this.user_role) {
                case 'manager':
                    break;

                case 'corporate-office':
                    filterObj = { where: { status: 'manager-approved' }, order: ['date DESC'] };
                    break;

                case 'accounts-payable':
                    filterObj = { where: { status: 'approved' }, order: ['date DESC'] };
                    break;

                case 'accounting':
                    if (this.showDeniedRefunds && this.showApprovedRefunds) {
                        filterObj = {
                            where: { or: [{ status: 'approved' }, { status: 'denied' }] },
                            order: ['date DESC'],
                        };
                    } else if (!this.showDeniedRefunds && this.showApprovedRefunds) {
                        filterObj = { where: { status: 'approved' }, order: ['date DESC'] };
                    } else if (this.showDeniedRefunds && !this.showApprovedRefunds) {
                        filterObj = { where: { status: 'denied' }, order: ['date DESC'] };
                    } else {
                        filterObj = { where: { status: 'corporate-office-approved' }, order: ['date DESC'] };
                    }

                    if (!this.showDeniedRefunds && !this.showApprovedRefunds && !this.showPendingRefunds) {
                        this.refundRequests = [];
                        return;
                    }
                    break;
            }
        }

        if (event) {
            filterObj = this.addLazyEventToFilter(event, filterObj);
        } else {
            // No lazy load event was provided, so use the default so the entire invoice list is not returned
            filterObj['limit'] = this.defaultLazyLoadEvent.rows;
            filterObj['skip'] = this.defaultLazyLoadEvent.first;
            const sortOrder = this.defaultLazyLoadEvent.sortOrder > 0 ? 'ASC' : 'DESC';
            filterObj['order'] = [`${this.defaultLazyLoadEvent.sortField} ${sortOrder}`];
        }

        const filter: string = JSON.stringify(filterObj);
        const where: string = JSON.stringify(filterObj.where);

        const gettingRefundsCount$ = this.refundService.getRefundRequestsCount(where).pipe(takeUntil(this.destroy$));
        const gettingRefunds$ = this.refundService.getRefundRequests(filter).pipe(takeUntil(this.destroy$));

        this.loadingService.add(
            forkJoin([gettingRefundsCount$, gettingRefunds$])
                .subscribe({
                    next: (results) => {
                        this.totalRefundRequests = results[0];
                        this.refundRequests = results[1];
                        // httpSuccessToast(this.messageService, 'Loaded Refund Requests');
                    },
                    error: (err: HttpErrorResponse) => {
                        httpErrorToast(this.messageService, err);
                    },
                }),
            { key: 'load-refunds' }
        );
    }

    addLazyEventToFilter(event: LazyLoadEvent, filterObj: any): any {
        filterObj['limit'] = event.rows;
        filterObj['skip'] = event.first;

        if (event.sortField) {
            const sortOrder = event.sortOrder > 0 ? 'ASC' : 'DESC';
            filterObj['order'] = [`${event.sortField} ${sortOrder}`];
        }

        if (event.globalFilter) {
            const filterFields: any[] = [];
            const sanitizedFilter: string = escapeRegExp(event.globalFilter as string);

            const regexp = new RegExp('.*' + sanitizedFilter + '.*', 'i');
            filterFields.push({ ticketNumber: { regexp: `${regexp}` } });
            filterFields.push({ customerName: { regexp: `${regexp}` } });
            filterFields.push({ location: { regexp: `${regexp}` } });
            filterFields.push({ date: { regexp: `${regexp}` } });
            filterFields.push({ requestedAmount: { regexp: `${regexp}` } });
            filterFields.push({ status: { regexp: `${regexp}` } });

            let existingFilter: any = {};
            if (filterObj['where']) {
                if (filterObj['where']['and']) {
                    // Existing filter containd an and array
                    const existingAnd: any[] = filterObj['where']['and'] as Array<any>;
                    existingAnd.push({ or: filterFields });
                    filterObj['where'] = { and: existingAnd };
                } else if (filterObj['where']['or']) {
                    // Existing filter contained an or array
                    filterFields.concat(filterObj['where']['or'] as Array<any>);
                    filterObj['where'] = { or: filterFields };
                } else {
                    // Existing filter only had a plain where filter
                    filterObj['where'] = { and: [filterObj['where'], { or: filterFields }] };
                }
            } else {
                filterObj['where'] = { or: filterFields };
            }
        }

        return filterObj;
    }

    loadFilesForRefund() {
        this.refundFiles = [];
        this.refundFilesShown = false;

        const docType = 'refund';
        const docTypeID = String(this.selectedRefund.id);

        this.loadingService.add(
            this.mpiApp
                .getFiles(docType, docTypeID)
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                    next: (data: FilePartial[]) => {
                        this.refundFiles = data;
                        this.refundFilesShown = true;
                    },
                    error: (error: HttpErrorResponse) => {
                        httpErrorToast(this.messageService, error);
                    },
                }),
            { key: 'refund-files' }
        );
    }

    selectRefund(event: any) {
        this.signaturePad?.clearCanvas();
        this.signatureForm.reset();
        this.currentCustomer = {
            name: this.selectedRefund.customerName,
            id: this.selectedRefund.customerID,
            customerID: this.selectedRefund.custID,
            location: this.selectedRefund.location,
        };
        this.refundDetailsForm = this.fb.formGroup(FormRefundRequest, this.selectedRefund);

        if (location.hash === '#/accounting/invoices') {
            this.refundDetailsForm.disable();
        } else {
            this.refundDetailsForm.markAllAsTouched();
        }
        this.managerApprovedDateTime = new Date(this.selectedRefund.managerApproved);
        this.corporateOfficeApprovedDateTime = new Date(this.selectedRefund.corporateOfficeApproved);
        this.refundDetailsShown = true;

        this.canDeny = !(this.selectedRefund.status === 'denied');
        switch (this.user_role) {
            case 'manager':
                this.canApprove = !(this.selectedRefund.status === 'manager-approved');
                break;

            case 'corporate-office':
                this.canApprove = !(this.selectedRefund.status === 'corporate-office-approved');
                break;

            case 'accounting':
                this.canApprove = !(this.selectedRefund.status === 'approved');
                if (!this.selectedRefund.glCode) {
                    this.refundService
                        .getRefundCustomer(this.selectedRefund.customerID)
                        .toPromise()
                        .then((data) => {
                            this.refundDetailsForm.get('glCode').setValue(data.glCode);
                            this.selectedRefund.glCode = data.glCode;
                        });
                }
                break;

            case 'accounts-payable':
                this.canApprove = false;
                this.canDeny = false;
        }

        // console.log('selectedRefund: ', this.selectedRefund);
        this.loadFilesForRefund();
    }

    deselectRefund() {
        this.refundDetailsShown = false;
        this.selectedRefund = null;
        this.currentCustomer = {};
        this.refundDetailsForm.reset();
        this.signaturePad?.clearCanvas();
        this.signatureForm.reset();
    }

    updateRefundRequest(update: RefundRequestPartial) {
        return this.refundService.updateRefundRequest(update);
    }

    updateInfo() {
        let update: RefundRequestPartial = this.refundDetailsForm.value;
        update.id = this.selectedRefund.id;
        update.ticketNumber = String(this.refundDetailsForm.get('ticketNumber').value);

        update = this.removeNullProperties(update);

        this.updateRefundRequest(update)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (data) => {
                    httpSuccessToast(this.messageService, 'Refund Request Updated');
                },
                error: (err: HttpErrorResponse) => {
                    httpErrorToast(this.messageService, err);
                },
                complete: () => {
                    this.getRefunds();
                },
            });
    }

    async setStatus(newStatus: string, printDocs = false) {
        this.refundDetailsForm.markAsDirty();
        this.refundDetailsForm.markAllAsTouched();
        if (this.refundDetailsForm.valid || newStatus === 'denied') {
            const role_updates = {
                manager: { approved: 'manager-approved', denied: 'denied' },
                'corporate-office': { approved: 'corporate-office-approved', denied: 'denied' },
                accounting: { approved: 'approved', denied: 'denied', reopened: 'reopened' },
            };
            const profile_status = role_updates[this.user_role];

            let update: RefundRequestPartial = this.refundDetailsForm.value;
            update.id = this.selectedRefund.id;
            update.status = profile_status[newStatus];

            if (this.user_role === 'corporate-office') {
                // validate user signed off on invoice status
                if (!this.signatureForm.valid && newStatus !== 'denied') {
                    this.validationService.validateAllFormFields(this.signatureForm);
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Signature Invalid',
                    });
                } else {
                    update.corporateOfficeSignature = this.signatureForm.get('signature').value;

                    update = this.removeNullProperties(update);

                    this.loadingService.add(
                        this.updateRefundRequest(update)
                            .pipe(takeUntil(this.destroy$))
                            .subscribe({
                                next: (data) => {
                                    if (newStatus === 'approved') {
                                        httpSuccessToast(this.messageService, 'Refund Request Approved');
                                    } else {
                                        httpSuccessToast(this.messageService, 'Refund Request Denied');
                                    }
                                    if (printDocs) {
                                        this.printAllDocuments(false);
                                    }
                                },
                                error: (err: HttpErrorResponse) => {
                                    httpErrorToast(this.messageService, err);
                                },
                                complete: () => {
                                    this.refundDetailsShown = false;
                                    this.getRefunds();
                                },
                            }),
                        { key: 'http-request' }
                    );
                }
            } else if (this.user_role === 'accounting') {
                update = this.removeNullProperties(update);
                // Validate fields and approve
                this.loadingService.add({ key: 'http-request' });
                await this.updateRefundRequest(update)
                    .pipe(takeUntil(this.destroy$))
                    .toPromise()
                    .catch((error) => {
                        httpErrorToast(this.messageService, error);
                    })
                    .then((data) => {
                        if (newStatus === 'approved') {
                            httpSuccessToast(this.messageService, 'Refund Request Approved');
                        } else if (newStatus === 'denied') {
                            httpSuccessToast(this.messageService, 'Refund Request Denied');
                        }
                        if (printDocs) {
                            this.printAllDocuments(false, true);
                        }
                    })
                    .finally(() => {
                        if (!printDocs) {
                            this.refundDetailsShown = false;
                        }
                        this.getRefunds();
                        //if (!printDocs) this.loadingService.remove({ key: 'http-request' });
                    });
                this.loadingService.remove({ key: 'http-request' });
            }
        } else {
            const invalidFields = this.mpiApp.findInvalidControls(this.refundDetailsForm);
            this.messageService.add({
                severity: 'error',
                summary: 'Form is Invalid',
                detail: `There are errors with the following fields: ` + invalidFields.join(`, `),
            });
        }
    }

    /**
     * Save the latest version of the user string
     *
     * Take what's currently in our signature service and fill our signature form with it
     */
    saveSignature() {
        const signature: string = this.signatureService.getSignature();
        this.signatureForm.controls['signature'].setValue(signature);
    }

    /**
     * Reset signature pad canvas
     */
    clearCanvas() {
        this.signaturePad.clearCanvas();
        this.signatureForm.reset();
    }

    searchLocations(event) {
        const pattern = new RegExp('.*' + event.query + '.*', 'i');
        const filter = `{"where": {"or": [{"name": {"regexp": "${pattern}"}},{"description": {"regexp": "${pattern}"}}]},"order":["name ASC"]}`;

        this.mpiApp.getLocations(filter).subscribe({
            next: (data) => {
                this.locationSearchResults = data;
            },
            error: (err) => {
                console.error(err);
            },
            complete: () => {},
        });
    }

    selectLocation(event: LocationsPartial | string) {
        // Check if the user used the dropdown to select a location, or just typed it in
        if (typeof event !== 'string') {
            if (event.name) {
                // Make sure only the location name is assigned to the form control after selection
                this.refundDetailsForm.get('location').setValue(Number(event.name));
            }

            //this.generateGLCode(event.name);
        } else {
            this.refundDetailsForm.get('location').setValue(Number(event));
            //this.generateGLCode(event);
        }
    }

    searchCustomers(query: string) {
        const pattern = new RegExp('.*' + query + '.*', 'i');
        const filter = `{"where": {"name": {"regexp": "${pattern}"}}}`;

        this.refundService
            .getRefundCustomerList(filter)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (data) => {
                    console.table(data);
                    this.customerResults = data;
                },
                error: (err: HttpErrorResponse) => {
                    httpErrorToast(this.messageService, err);
                },
            });
    }

    selectCustomer(event: RefundCustomersPartial | string, onBlur = false) {
        // Check if the user used the dropdown to select a customer, or just typed it in
        if (typeof event !== 'string') {
            console.log('Existing Customer');
            this.currentCustomer = event;
            this.refundDetailsForm.get('customerName').setValue(event.name);
            this.refundDetailsForm.get('customerID').setValue(event.id);
            this.refundDetailsForm.get('custID').setValue(event.customerID);
            this.refundDetailsForm.get('glCode').setValue(event.glCode);
        } else {
            if (event === '') {
                console.log('Empty');
                this.currentCustomer = {};
                this.refundDetailsForm.get('customerName').setValue(null);
                this.refundDetailsForm.get('customerID').setValue(null);
                // this.refundDetailsForm.get('custID').setValue(null);
            } else if (!this.refundDetailsForm.get('customerID').value) {
                console.log('New Customer');
                this.currentCustomer = {
                    name: event,
                    id: null,
                    customerID: this.refundDetailsForm.get('custID').value,
                    location: this.refundDetailsForm.get('location').value,
                };
                this.refundDetailsForm.get('customerID').setValue(null);
            } else if (this.currentCustomer.name === event) {
                console.log('No Changes');
            } else if (this.currentCustomer.name !== event) {
                console.log('Change from Existing to New Customer', this.currentCustomer.name);
                this.currentCustomer = {
                    name: event,
                    id: null,
                };
                this.refundDetailsForm.get('customerID').setValue(null);
            }
        }
    }

    printAllDocuments(previewOnly = false, hideDetails = false) {
        this.loadingService.add(
            this.refundService
                .printAllDocuments(this.selectedRefund.id)
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                    next: (mergedPDF: any) => {
                        const blob: any = new Blob([mergedPDF], { type: 'application/pdf; charset=utf-8' });
                        const url = window.URL.createObjectURL(blob);

                        if (previewOnly) {
                            // Open PDF view in new window for viewing only
                            window.open(url, '_blank');
                        } else {
                            const safeTicketNumber = this.selectedRefund.ticketNumber.toString();
                            const safeCustomerName = this.selectedRefund.customerName.replace(/[^a-z0-9]/gi, '_');
                            const safeLocation = 'Loc-' + this.selectedRefund.location.toString();
                            // Build the filename
                            const filename =
                                safeCustomerName +
                                '_' +
                                safeTicketNumber +
                                '_' +
                                safeLocation +
                                '_' +
                                DateTime.now().toFormat('MM-dd-yyyy');

                            // Create a hidden anchor to download the PDF with the custom filename
                            const downloadLink = document.createElement('a');
                            downloadLink.href = url;
                            downloadLink.download = filename;
                            document.body.appendChild(downloadLink);

                            // Open PDF view in new window for viewing
                            window.open(url, '_blank');
                            // Activate the hidden anchor to download PDF with custom filename
                            downloadLink.click();
                        }
                    },
                    error: (err: HttpErrorResponse) => {
                        httpErrorToast(this.messageService, err);
                    },
                    complete: () => {
                        httpSuccessToast(this.messageService, 'Document Printed');

                        if (hideDetails) {
                            this.refundDetailsShown = false;
                        }
                    },
                }),
            { key: ['print-request', 'http-request'] }
        );
    }

    downloadFile(fileID: string) {
        this.mpiApp.showFile(fileID);
    }

    removeNullProperties(refund: RefundRequestPartial): RefundRequestPartial {
        if (!refund.glCode) {
            delete refund.glCode;
        }
        if (!refund.address2) {
            delete refund.address2;
        }
        if (!refund.customerID) {
            delete refund.customerID;
        }
        if (!refund.custID) {
            delete refund.custID;
        }

        return refund;
    }

    // generateGLCode(locationName: string) {
    //     this.refundDetailsForm.get('glCode').setValue(this.mpiApp.glCodeGenerator.generateGLCode(locationName, ''));
    //     console.log(this.mpiApp.glCodeGenerator.findGLCodeForLocation(locationName));
    // }
}
