/**
 * Default accounting role component
 */

import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-approve-vendor-invoice',
    templateUrl: './approve-vendor-invoice.component.html',
    styleUrls: ['./approve-vendor-invoice.component.scss'],
})
export class ApproveVendorInvoiceComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
