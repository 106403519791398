import { HttpErrorResponse } from '@angular/common/http';
import { MessageService } from 'primeng/api';

export function httpErrorToast(messageService: MessageService, err: HttpErrorResponse) {
    if (err.status === 422) {
        let errorDetails: string = '';
        for (const detail of err.error.error.details as any[]) {
            errorDetails += String(detail.path).substring(1) + ',';
        }

        messageService.add({
            severity: 'error',
            summary: 'Missing Fields',
            detail: errorDetails,
        });
    } else {
        messageService.add({
            severity: 'error',
            summary: 'HTTP ' + err.status?.toString(),
            detail: err.message,
        });
    }
}

export function httpSuccessToast(messageService: MessageService, msg: string) {
    messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: msg,
    });
}
