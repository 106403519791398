import { Component, Input, ElementRef, AfterViewInit, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { switchMap, takeUntil, pairwise } from 'rxjs/operators';
import { SignatureService } from '../signature.service';

/**
 * Signature pad taken from:
 * https://medium.com/@tarik.nzl/creating-a-canvas-component-with-free-hand-drawing-with-rxjs-and-angular-61279f577415
 */

@Component({
    selector: 'app-signature-pad',
    templateUrl: './signature-pad.component.html',
    styleUrls: ['./signature-pad.component.scss'],
})
export class SignaturePadComponent implements AfterViewInit {
    @ViewChild('canvas') public canvas: ElementRef;

    @Input() public width = 500;
    @Input() public height = 210;

    private cx: CanvasRenderingContext2D;
    private canvasEl: HTMLCanvasElement;

    constructor(private signatureService: SignatureService) {}

    public ngAfterViewInit() {
        this.canvasEl = this.canvas.nativeElement;
        this.cx = this.canvasEl.getContext('2d');

        this.canvasEl.width = this.width;
        this.canvasEl.height = this.height;

        this.cx.lineWidth = 3;
        this.cx.lineCap = 'round';
        this.cx.strokeStyle = '#000';

        this.captureEvents(this.canvasEl);
    }

    private captureEvents(canvasEl: HTMLCanvasElement) {
        // this will capture all mousedown events from the canvas element
        fromEvent(canvasEl, 'mousedown')
            .pipe(
                switchMap((e) => {
                    // after a mouse down, we'll record all mouse moves
                    return fromEvent(canvasEl, 'mousemove').pipe(
                        // we'll stop (and unsubscribe) once the user releases the mouse
                        // this will trigger a 'mouseup' event
                        takeUntil(fromEvent(canvasEl, 'mouseup')),
                        // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
                        takeUntil(fromEvent(canvasEl, 'mouseleave')),
                        // pairwise lets us get the previous value to draw a line from
                        // the previous point to the current point
                        pairwise()
                    );
                })
            )
            .subscribe((res: [MouseEvent, MouseEvent]) => {
                const rect = canvasEl.getBoundingClientRect();

                // previous and current position with the offset
                const prevPos = {
                    x: res[0].clientX - rect.left,
                    y: res[0].clientY - rect.top,
                };

                const currentPos = {
                    x: res[1].clientX - rect.left,
                    y: res[1].clientY - rect.top,
                };

                // this method we'll implement soon to do the actual drawing
                this.drawOnCanvas(prevPos, currentPos);

                // on every draw update, save the latest signature version to our service
                this.signatureService.setSignature(this.canvasEl.toDataURL());
            });

        fromEvent(canvasEl, 'touchstart')
            .pipe(
                switchMap((e) => {

                    // Prevent the touch from scrolling the screen.
                    e.preventDefault();

                    // after a mouse down, we'll record all mouse moves
                    return fromEvent(canvasEl, 'touchmove').pipe(
                        // we'll stop (and unsubscribe) once the user releases the mouse
                        // this will trigger a 'mouseup' event
                        takeUntil(fromEvent(canvasEl, 'touchend')),
                        // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
                        pairwise()
                    );
                })
            )
            .subscribe((res: [TouchEvent, TouchEvent]) => {

                const rect = canvasEl.getBoundingClientRect();

                // previous and current position with the offset
                const prevPos = {
                    x: res[0].touches[0].clientX - rect.left,
                    y: res[0].touches[0].clientY - rect.top,
                };

                const currentPos = {
                    x: res[1].touches[0].clientX - rect.left,
                    y: res[1].touches[0].clientY - rect.top,
                };

                // this method we'll implement soon to do the actual drawing
                this.drawOnCanvas(prevPos, currentPos);

                // on every draw update, save the latest signature version to our service
                this.signatureService.setSignature(this.canvasEl.toDataURL());
            });

    }

    private drawOnCanvas(prevPos: { x: number; y: number }, currentPos: { x: number; y: number }) {
        if (!this.cx) {
            return;
        }

        this.cx.beginPath();

        if (prevPos) {
            this.cx.moveTo(prevPos.x, prevPos.y); // from
            this.cx.lineTo(currentPos.x, currentPos.y);
            this.cx.stroke();
        }
    }

    public clearCanvas() {
        this.cx.clearRect(0, 0, this.canvasEl.width, this.canvasEl.height);
    }
}
