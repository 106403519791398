import { Component, OnInit, ViewChild } from '@angular/core';
import { MPIAppService } from '../../services/mpiapp.service';
import { MessageService } from 'primeng/api';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { FileUpload } from 'primeng/fileupload';
import { FileUploadStateService } from '../file-upload-state.service';
import { Vendor } from '../api/models/vendor';
import { DateTime } from 'luxon';
import { IsLoadingService } from '@service-work/is-loading';
import { Observable, Subscription } from 'rxjs';
import { RecurringAttributes } from '../api/models/mpi-custom-models';
import { MPIRecurringService } from '../../services/mpirecurring.service';

// UI type for what type of PO this will be.
interface POForTypes {
    name: string;
    code: string;
}

@Component({
    selector: 'app-purchase-order-request',
    templateUrl: './purchase-order-request.component.html',
    styleUrls: ['./purchase-order-request.component.scss'],
    providers: [MessageService, FileUploadStateService],
})
export class PurchaseOrderRequestComponent implements OnInit {
    @ViewChild('fileInput') fileInput: FileUpload;

    minDate: Date;
    minQuarterDate: Date;

    locations: any[];

    uploadedFiles: any[] = [];

    // Initialize this to an empty array so if the control tries to filter or interact with the data before it's
    // loaded an undefined error doesn't occur.
    vendors: Vendor[] = [];
    filteredVendors: any[];

    uploadURL = '';

    // Default list of PO types
    poForOptions: POForTypes[] = [
        { name: 'Vendor', code: 'vendor' },
        { name: 'Reimbursement', code: 'reimbursement' },
    ];
    selectedPOFor: POForTypes;

    showRecurringExpenses = false;
    recurringOptions = ['Monthly', 'Bi-monthly', 'Semi-weekly', 'Weekly', 'Quarterly', 'Annually'];
    recurringAttributes: RecurringAttributes;
    biMonthlyOptions = ['1st and 15th', '16th and End of Month'];

    selectedOption = '';
    inputPlaceholder = '';
    inputModel = '';
    inputModel2 = '';
    inputOccurrences = '';
    isNumOccurrences = false;
    endDateOrOccurrences = '';

    // Human-readable display of the recurring schedule.
    recurringDisplayMessage = '';

    /**
     * Reactive form for inputs
     *
     * estimated cost amount restricted to (2^31)-1
     */
    poForm = this.formBuilder.group({
        selectedVendor: ['', Validators.required],
        estimatedCost: [null, [Validators.required, Validators.min(0), Validators.max(2147483647)]],
        selectedLocations: ['', Validators.required],
        reason: ['', Validators.required],
        allowMultipleInvoices: [false],
    });

    recurringExpensesForm: UntypedFormGroup;

    constructor(
        public mpiApp: MPIAppService,
        private messageService: MessageService,
        private formBuilder: UntypedFormBuilder,
        public selectedFilesState: FileUploadStateService,
        public isLoadingService: IsLoadingService,
        public mpiRecurring: MPIRecurringService
    ) {}

    ngOnInit(): void {
        this.recurringExpensesForm = this.formBuilder.group({
            amount: [0, Validators.required],
            quarterlyDate: [''],
            startDate: [''],
            bimonthlyDates: [''],
            endDate: [''],
            numOccurrences: [0],
        });

        const today = new Date();
        this.minDate = today;

        // Set the default type of PO to vendor on load
        this.selectedPOFor = {
            name: 'Vendor',
            code: 'vendor',
        };

        this.uploadURL = this.mpiApp.getURL('fileuploads');

        this.mpiApp.getLocations().subscribe((locations) => (this.locations = locations));

        // Only verified vendors can be used for requesting purchase orders.
        const filter = {
            where: {
                status: 'verified',
            },
        };

        this.mpiApp.getVendors(null, JSON.stringify(filter)).subscribe((data: Vendor[]) => {
            // https://stackoverflow.com/questions/39850339/how-to-sort-object-array-based-on-key-in-typescript
            data.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
            this.vendors = data;
        });
    }

    changeRecurringOption(event: any) {
        switch (event.value) {
            case 'Monthly':
                this.inputPlaceholder = 'Day of the Month';
                break;

            case 'Bi-monthly':
                this.inputPlaceholder = 'First Day of the Month';
                break;

            case 'Weekly':
                this.inputPlaceholder = 'Day of the Week';
                break;

            case 'Quarterly':
                this.inputPlaceholder = 'Day of the Month';
                break;
        }
    }

    changeDeadline(isOccurrences: boolean) {
        const isValid = this.completedRecurringForm();

        if (isValid) {
            const endDate = this.recurringExpensesForm.get('endDate').value;
            const startDate = this.recurringExpensesForm.get('startDate').value;
            const occurrenceCost = this.recurringExpensesForm.get('amount').value;

            if (isOccurrences) {
                this.recurringAttributes = {
                    // Default to monthly
                    frequency: 'monthly',
                    endCondition: 'occurrences',
                    endCount: this.recurringExpensesForm.get('numOccurrences').value,
                    startDate: DateTime.fromJSDate(startDate),
                    occurrenceCost,
                };
            } else {
                this.recurringAttributes = {
                    // Default to monthly
                    frequency: 'monthly',
                    endCondition: 'date',
                    startDate: DateTime.fromJSDate(startDate),
                    endDate: DateTime.fromJSDate(endDate),
                    occurrenceCost,
                };
            }

            // Convert the selected schedule into the name needed for the recurring calculations.
            switch (this.selectedOption) {
                case 'Semi-weekly':
                    this.recurringAttributes.frequency = 'semiweekly';
                    break;
                case 'Bi-monthly':
                    this.recurringAttributes.frequency = 'semimonthly';
                    break;
                case 'Monthly':
                    this.recurringAttributes.frequency = 'monthly';
                    break;
                case 'Weekly':
                    this.recurringAttributes.frequency = 'weekly';
                    break;
                case 'Quarterly':
                    this.recurringAttributes.frequency = 'quarterly';
                    break;
                case 'Annually':
                    this.recurringAttributes.frequency = 'annually';
                    break;
                default:
                    break;
            }

            // Calculate the dates this recurring PO will last so we know how many there are
            const dates = this.mpiRecurring.calculateSchedule(this.recurringAttributes);

            // Calcualte the cost of each occurence to get the total
            const singleOccurrenceCost = this.recurringExpensesForm.get('amount').value;
            const estimateCost = dates.length * singleOccurrenceCost;

            // Show the user how much the entire recurring sequence will be
            this.poForm.patchValue({ estimatedCost: estimateCost });

            // Calculate the message the user will see on the screen
            this.recurringDisplayMessage = `This purchase order will
             occur ${this.mpiRecurring.getScheduleAsDisplayString(this.recurringAttributes, dates)}`;
        }
    }

    // Occurs when the recurring payments option changes
    changeSetupRecurringPayments() {
        // Reset the estimated cost because the recurring option is calculated
        this.poForm.patchValue({ estimatedCost: 0 });
    }

    completedRecurringForm(): boolean {
        let isValid = true;
        // Check if the user has selected a recurring option
        if (this.selectedOption === '') {
            isValid = false;
        } else {
            // Check if the user entered an amount
            if (this.recurringExpensesForm.get('amount').value === 0) {
                isValid = false;
            } else {
                switch (this.selectedOption) {
                    case 'Bi-monthly':
                        // If Bi-monthly is selected, check if the user picked an option
                        if (this.recurringExpensesForm.get('bimonthlyDates').value === '') {
                            isValid = false;
                        }
                        break;

                    case 'Quarterly':
                        // If Quarterly is selected, check if the user picked a month
                        if (this.recurringExpensesForm.get('quarterlyDate').value === '') {
                            isValid = false;
                        }
                        break;

                    default:
                        // If Monthly, Weekly, or Semi-weekly, check if a start date was entered
                        const startDate = this.recurringExpensesForm.get('startDate').value;
                        if (startDate === '') {
                            isValid = false;
                        }
                        break;
                }

                if (this.isNumOccurrences) {
                    // If a number of occurrences is selected, check that it isn't zero
                    const numOccurrences = Number(this.recurringExpensesForm.get('numOccurrences').value);
                    if (numOccurrences === 0) {
                        isValid = false;
                    }
                } else {
                    // If an end date is selected, check that a date was picked in the future
                    if (
                        this.recurringExpensesForm.get('endDate').value === '' ||
                        this.recurringExpensesForm.get('endDate').value <=
                            this.recurringExpensesForm.get('startDate').value
                    ) {
                        isValid = false;
                    }
                }
            }
        }

        return isValid;
    }

    onUpload(event) {
        this.messageService.add({
            severity: 'info',
            summary: 'Purchase Order Submitted',
            detail: 'Files(s) successfully uploaded',
        });

        // Mark that the submit process is completed.
        this.isLoadingService.remove({ key: 'submit-po-request' });

        /* reset form values so user can submit new invoice */
        this.poForm.reset();
        this.recurringExpensesForm.reset();
        this.fileInput.clear();
        this.selectedFilesState.setFileState(false);
    }

    uploadError(event) {
        console.log(event.error);

        // Mark that the submit process is completed.
        this.isLoadingService.remove({ key: 'submit-po-request' });

        /* get the loopback error message, nested in error objects */
        const loopback_error = event.error.error.error;
        this.messageService.add({
            severity: 'error',
            summary: 'Purchase Order Submit Failed',
            detail: 'Error: ' + loopback_error.message,
        });
    }

    filterVendor(queryStr: string) {
        const filtered: any[] = [];
        const query = queryStr;
        for (let i = 0; i < this.vendors.length; i++) {
            const vendor = this.vendors[i];
            if (vendor.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                filtered.push(vendor);
            }
        }

        this.filteredVendors = filtered;
    }

    /**
     * Modify the uploaded files before the POST request goes out
     *
     * Append the multipart/formdata to the POST request, including new invoice data
     * @param $event onBeforeUpload event
     */
    modifyPOUpload($event) {
        // --------------------------------------------------
        // NOTE: Insurance Expiration currently required by backend for file upload
        // Remove when API is updated
        // --------------------------------------------------
        $event.formData.append('expirationDate', '2021-01-01');

        $event.formData.append('documentType', 'purchaseOrder');

        // Change the list of applicable locations to an array of location IDs
        const applicableLocationsObj = this.poForm.get('selectedLocations').value;
        const locationList: number[] = [];
        applicableLocationsObj.forEach((item) => {
            locationList.push(item.locationID);
        });

        const selectedVendor = this.poForm.get('selectedVendor');
        const selectedVendorID = selectedVendor.value.vendorID;

        let recurringExpenseObj = {};

        if (this.showRecurringExpenses) {
            // recurringExpenseObj = this.recurringExpensesForm.value;
            recurringExpenseObj = this.recurringAttributes;
        }

        /* Append new invoice data under invoiceJSON */
        let newPurchaseOrder = {};

        // This are the minimum require properties to submit a PO
        const basePurchaseOrder = {
            vendorID: selectedVendorID,
            estimatedCost: this.poForm.get('estimatedCost').value,
            locationsNameList: locationList,
            reason: this.poForm.get('reason').value,
            status: 'pending',
            statusLastSet: DateTime.now().toISO(),
            allowMultipleInvoices: this.poForm.get('allowMultipleInvoices').value,
        };

        // Decide if we need to expand the PO submittion
        if (this.showRecurringExpenses) {
            // Yes, because we have recurring expenses, so take the existing base PO values and add the new properties.
            newPurchaseOrder = {
                ...basePurchaseOrder,
                recurringAttributes: recurringExpenseObj,
                poType: 'recurring',
            };
        } else {
            // No, the base PO becomes the new PO to send.
            newPurchaseOrder = basePurchaseOrder;
        }

        $event.formData.append('purchaseOrderJSON', JSON.stringify(newPurchaseOrder));
    }

    /**
     * Double check selected files from uploader in select event
     *
     * Taken from: https://stackoverflow.com/a/53639699/14560781
     *
     * If at least 1 file is selected, requirement for 1 file to be uploaded is fulfilled
     * @param event onSelect() event
     */
    fileSelect(event) {
        if (event.files.length > 0) {
            this.selectedFilesState.setFileState(true);
        } else {
            this.selectedFilesState.setFileState(false);
        }
    }

    /**
     * Remove selected file from uploader
     * check if enough files are still selected to satisfy requirement for at least 1 file
     * @param event onRemove() event
     */
    fileRemove(event) {
        /**
         * weird behavior with primeng fileupload where after clicking remove on individual file,
         * in onRemove event fileInput.files.length still reports the clicked file
         *
         * Work around by subtracting 1 from number of files, assuming the file removed is still being reported
         * by the event
         */
        if (!this.fileInput || this.fileInput.files.length - 1 <= 0) {
            this.selectedFilesState.setFileState(false);
        }
    }

    /**
     * Clear selected files from uploader
     * Since all files are removed, requirement for at least 1 uploaded file is no longer fulfilled
     */
    fileClear() {
        this.selectedFilesState.setFileState(false);
    }

    /**
     * Submit form with invoice data and upload files
     *
     * Taken from: https://stackoverflow.com/a/52132957/14560781
     * @param event click event for 'Submit Request' button
     */
    async submit(event) {
        let isEncrypted: boolean = false;
        const fileList = this.fileInput.files;

        for (const file of fileList) {
            await file.text().then((fileString) => {
                isEncrypted = fileString.includes('Encrypt');
            });
        }

        if (!isEncrypted) {
            // Mark that the upload process began. .upload doesn't return anything so handle the completion
            // in the .onUpload event (callback)
            this.isLoadingService.add({ key: 'submit-po-request' });
            this.fileInput.upload();
        } else {
            this.messageService.add({
                severity: 'error',
                summary: 'Purchase Order Submit Failed',
                detail: 'Error: Encrypted PDFs are not supported',
            });
        }
    }

    /**
     * Occurs when the user changes what type of PO this is for.
     */
    async changePOFor() {
        // When the slected option is for a reimbursement then clear any selected vendor
        if (this.selectedPOFor.code === 'reimbursement') {
            // Since it's for a reimbursement we need to lookup the vendorID.
            const vendorRec = await this.mpiApp.getOrCreateReimbursementVendor().toPromise();
            const selectedVendorID = vendorRec.vendorID;
            this.poForm.patchValue({ selectedVendor: { name: 'Reimbursement', vendorID: selectedVendorID } });
        } else {
            // Not for a reimbursement so we can just get the selected vendorID. Reset it so the user can select it again.
            this.poForm.patchValue({ selectedVendor: 0 });
        }
    }
}
