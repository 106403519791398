<div class="login-body" style="background:white;padding-top:25px;background-image:none;">
    <div id="login-wrapper">
        <p-messages [(value)]="msgs"></p-messages>

        <div id="login-container">
            <span class="title">MPI Vendor Portal Password Reset</span>

            <div *ngIf="idCheckFinished; then entryForm else spinner">

            </div>
        </div>

        <div class="login-footer">MPI Vendor Portal</div>
    </div>
</div>

<ng-template #entryForm>
    <div *ngIf="isInviteValid; then inviteForm else inviteNotValid">
    </div>
</ng-template>

<ng-template #inviteForm>
    <div class="grid p-fluid flex justify-content-center">
        <div class="col-12" *ngIf="!isPostSuccessful">
            You have requested to reset your password on {{siteName}}. Please enter your new account password.
        </div>
        <form [formGroup]="changePassword">
            <div *ngIf="!isPostSuccessful" class="field mt-4">
                <span class="p-float-label">
                    <p-password [inputId]="resetpassword" formControlName="password" required [toggleMask]="true"
                        [strongRegex]=this.pattern></p-password>
                    <label for="resetpassword">Password</label>
                    <app-control-messages [control]="changePassword.get('password')"></app-control-messages>
                </span>
            </div>
            <div *ngIf="!isPostSuccessful" class="field mt-4">
                <span class="p-float-label">
                    <p-password [inputId]="confirmpassword" formControlName="confirmPassword" required
                        [toggleMask]="true" [feedback]="false"></p-password>
                    <label for="confirmpassword">Confirm Password</label>
                    <app-control-messages [control]="changePassword.get('confirmPassword')"></app-control-messages>
                </span>
                <!--Second control messages for passwords must match error on whole group-->
                <app-control-messages [control]="changePassword"></app-control-messages>
            </div>
        </form>

        <div *ngIf="!isPostSuccessful" class="col-12">
            <p-button type="button" label="Submit" (onClick)="submit()" [loading]="!isPatchrequestFinished">
            </p-button>
        </div>

        <div *ngIf="isPostSuccessful" class="col-12">
            <div>
                <strong class="success-text">Password successfully reset. You can now log in with your new
                    password.</strong>
            </div>
            <div>
                <a class="text-sm" routerLink="../../">Return to sign in</a>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #inviteNotValid>
    <div class="flex flex-column justify-content-center">
        <div class="col-12">
            This password reset link on {{siteName}} is no longer valid.
        </div>
        <div>
            <a class="text-sm" routerLink="../../">Return to sign in</a>
        </div>
    </div>
</ng-template>

<ng-template #spinner>
    <p-progressSpinner></p-progressSpinner>
</ng-template>