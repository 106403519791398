import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Message, MessageService } from 'primeng/api';
import { MPIAppService } from '../../services/mpiapp.service';
import { DateTime } from 'luxon';
import { FileUpload } from 'primeng/fileupload';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { FileUploadStateService } from '../file-upload-state.service';
import { PermanentErrorService } from '../permanent-error.service';
import { PurchaseOrder } from '../api/models/purchase-order';
import { Vendor } from '../api/models/vendor';
import { GLCodeGenerator } from '../../shared/gl-code-generation';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { LocationField } from '../view-invoices/location_field';
import { InvoiceWithDateStrings } from '../view-invoices/view-invoices.component';
import { ValidationService } from '../validation.service';
import { IsLoadingService } from '@service-work/is-loading';
import { catchError, map, retry, takeUntil, timeout } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { LocationsPartial } from '../api/models';
import { SessionService } from 'src/session.service';
import { LoginModel } from '../models/login.model';

// UI type for what type of invoice this will be.
interface ForTypes {
    name: string;
    code: string;
}

@Component({
    selector: 'app-submit-vendor-invoice',
    templateUrl: './submit-vendor-invoice.component.html',
    styleUrls: ['./submit-vendor-invoice.component.scss'],
    providers: [MessageService, FileUploadStateService, PermanentErrorService],
})
export class SubmitVendorInvoiceComponent implements OnInit, OnDestroy {
    private subs: Subscription[];
    @ViewChild('fileInput') fileInput: FileUpload;

    purchaseOrders: PurchaseOrder[];
    filteredPOs: any[];

    vendors: Vendor[];
    vendorForSelectedPO: Vendor;
    vendorMap: object = {};
    remainingAmount = '';

    user_role = 'manager';
    user_profile: LoginModel;
    hasExpiredInsurance: boolean = false;

    uploadedFiles: any[] = [];
    uploadURL = '';

    invoiceDate = '';
    invoiceID = '';
    dueDate = '';

    enteredAmountSum: number = 0;
    invoiceToShow: InvoiceWithDateStrings;
    invoiceToShowVendor: Vendor; // Vendor of the shown invoice.
    selectedInvoice: InvoiceWithDateStrings;

    requestInProgress = false;
    printRequestInProgress = false;

    glForm = this.formBuilder.group({
        locations: this.formBuilder.array([]),
    });

    // Default list of invoice types
    forOptions: ForTypes[] = [
        { name: 'Vendor', code: 'vendor' },
        { name: 'Reimbursement', code: 'reimbursement' },
    ];
    selectedFor: ForTypes;

    error_msgs: Message[] = [];
    uploadInProgress: boolean = false;
    locations: any[];
    locationSearchResults: LocationsPartial[];

    invoiceForm: UntypedFormGroup;

    constructor(
        private messageService: MessageService,
        public mpiApp: MPIAppService,
        private formBuilder: UntypedFormBuilder,
        private session: SessionService,
        public selectedFilesState: FileUploadStateService,
        public permanentError: PermanentErrorService,
        private validationService: ValidationService,
        public isLoadingService: IsLoadingService
    ) {
        this.subs = [];
    }

    ngOnInit(): void {
        /**
         * invoice amount restricted to (2^31)-1
         */
        this.invoiceForm = this.formBuilder.group({
            selectedPO: ['', Validators.required],
            allowMultipleInvoices: [false],
            invoiceAmount: [null, [Validators.required, Validators.min(0), Validators.max(2147483647)]],
            invoiceID: [null, [Validators.required]],
        });

        this.locationSearchResults = [];
        // Set the default type of PO to vendor on load
        this.selectedFor = {
            name: 'Vendor',
            code: 'vendor',
        };

        this.user_profile = this.session.getUserProfile();
        if (this.user_profile?.activeRole) {
            // Show the component based on the users activeRole
            this.user_role = this.user_profile.activeRole;
        }

        this.uploadURL = this.mpiApp.getURL('fileuploads');

        this.subs.push(
            this.mpiApp.getLocations().subscribe((data: any[]) => {
                this.locations = data;
            })
        );

        if (this.user_role === 'vendor') {
            if (this.user_profile?.roleID) {
                // Set the required insurance flag for submission purposes.
                this.subs.push(
                    this.mpiApp.getVendor(this.user_profile.roleID.toString()).subscribe({
                        next: (data: Vendor) => {
                            this.user_profile.insuranceRequired = data.insuranceDocumentRequired;
                            this.session.saveUserProfile(this.user_profile);
                        },
                        error: (err: HttpErrorResponse) => {
                            console.error(err);
                        },
                    })
                );
                // Get the vendor's files to check for expired insurance
                this.subs.push(
                    this.mpiApp.getVendorSpecificFiles(this.user_profile.roleID.toString(), 'insurance').subscribe({
                        next: (files) => {
                            // Assume the vendor has no approved documents
                            this.hasExpiredInsurance = true;

                            if (this.user_profile.insuranceRequired) {
                                // Vendor is required to have an approved insurance document
                                files.forEach((file) => {
                                    // If there is an approved document, allow the vendor to submit invoices
                                    if (file.verificationStatus === 'approved') {
                                        this.hasExpiredInsurance = false;
                                    }
                                });
                            } else {
                                // Vendor is not required to have insurance, so disable the flag
                                this.hasExpiredInsurance = false;
                            }

                            if (this.hasExpiredInsurance) {
                                this.invoiceForm.disable();
                            }
                        },
                        error: (err) => {
                            console.error(err);
                        },
                        complete: () => {},
                    })
                );
            }
        }

        // Initialize the GL Code list in case it's undefined
        this.subs.push(
            this.mpiApp.initGLCodeList().subscribe({
                error: (err) => {
                    console.error(err);

                    this.messageService.add({
                        severity: 'error',
                        summary: 'Could not get the gl code list.',
                    });
                },
            })
        );

        this.getApprovedPOs();

        this.subs.push(
            /* Build a map of vendor IDs to vendor names so that we can display the real vendor name in autocomplete dropdown */
            this.mpiApp.getVendors().subscribe((data: Vendor[]) => {
                // For easy access save the list of vendor records.
                this.vendors = data;
                data.forEach((element) => (this.vendorMap[element.vendorID] = element.name));
            })
        );
    }

    ngOnDestroy(): void {
        this.subs.forEach((sub) => {
            sub.unsubscribe();
        });

        const length = this.subs.length;
        for (let i = 0; i < length; ++i) {
            this.subs.pop();
        }
    }

    getApprovedPOs() {
        /*
            Get approved POs but hide recurring (master templates)
            poType null is assumed to be single.
        */
        const filter = {
            where: {
                or: [
                    {
                        and: [{ poType: 'recurrence' }, { status: 'approved' }],
                    },
                    {
                        and: [{ poType: 'single' }, { status: 'approved' }],
                    },
                    {
                        and: [{ poType: null }, { status: 'approved' }],
                    },
                ],
            },
        };

        this.subs.push(
            this.mpiApp.getPurchaseOrders(JSON.stringify(filter)).subscribe((data: PurchaseOrder[]) => {
                this.purchaseOrders = [];

                // Alter the returned list of invoices
                for (let i = 0; i < data.length; i++) {
                    const po = data[i];

                    if (po.poID === 'PENDING' || po.poID === '') {
                        // Build an encoded ID for the display
                        po.poID = this.mpiApp.idToHash(po.purchaseOrderID);
                    }
                    this.purchaseOrders.push(po);
                }
            })
        );
    }

    findLocation(location: string): string {
        const result = this.locations ? this.locations.find((loc) => {
            return loc.name === location;
        }) : null;

        if (!result) {
            return 'location not found';
        }

        let description: string;

        if (result.description) {
            description = result.description;
        } else {
            description = 'location not found';
        }

        let response: string = description + ' - ';

        if (result.address !== '' && result.address !== null) {
            response +=
                result.address +
                ', ' +
                (result.city !== null ? result.city : 'N/A') +
                ', ' +
                (result.state !== null ? result.state : 'N/A') +
                ' ' +
                (result.zip !== null ? result.zip : 'N/A');
        } else {
            response += 'No address saved';
        }

        return response;
    }

    filterPO(queryStr: string) {
        // Reset the previous list of found POs
        const filtered: any[] = [];

        // The text we are searching for
        const query = queryStr.toLowerCase();

        for (let i = 0; i < this.purchaseOrders.length; i++) {
            const po = this.purchaseOrders[i];

            let vendorName = this.vendorMap[po.vendorID];
            if (!vendorName) {
                vendorName = '';
            }

            // See if the current PO matches one of the values we're searching against.
            if (
                po.poID.toString().toLowerCase().indexOf(query) !== -1 ||
                vendorName.toString().toLowerCase().indexOf(query) !== -1 ||
                po.locationsNameList.toString().toLowerCase().indexOf(query) !== -1 ||
                po.remainingBalance.toString().toLowerCase().indexOf(query) !== -1
            ) {
                // This PO matches our search
                filtered.push(po);
            }
        }
        this.filteredPOs = filtered;
    }

    /**
     * Whenever the amount is changed (onBlur)
     */
    changedAmount() {
        if (this.selectedFor.code === 'reimbursement') {
            // Reimbursements will always have a remaining amount for whatever the reimbursement value was
            const control = this.invoiceForm.get('invoiceAmount');
            this.remainingAmount = control.value;

            const remainingAmountDecimal = Number(this.mpiApp.stripCurrency(String(this.remainingAmount)));

            // set a validator to check if the entered amounts sum to the total amount of the invoice
            this.glformLocationArray.setValidators([this.validationService.amountSum(remainingAmountDecimal)]);
        } else {
            // Not a reimbursement, the selected PO will update the remaininga mount.
        }
    }

    changeAmount() {
        const now = DateTime.now();

        this.invoiceDate = now.toString();
        this.dueDate = now.plus({ days: 30 }).toString();
    }

    setRemainingAmount(event) {
        console.log(event);
        this.remainingAmount = event.remainingBalance;
    }

    selectedOpenPO(event) {
        const selectedPO = this.invoiceForm.get('selectedPO');
        const vendorID = selectedPO.value.vendorID;

        // Reimbursements might not have a list of vendors.
        if (this.vendors?.length > 0) {
            // Find the vendor record for the selected PO
            for (const vendor of this.vendors) {
                if (vendor.vendorID === vendorID) {
                    this.vendorForSelectedPO = vendor;
                    break;
                }
            }
        }

        let id: string;
        if (this.vendorForSelectedPO.type === 'reimbursement') {
            // Calculate the invoice ID based on business rule format.
            const now = DateTime.now();
            id = 'PC-' + now.toFormat('MMddyy');

            const userIDString = atob(this.session.getToken().split('.')[1]);
            const userID = JSON.parse(userIDString).id;

            const regex = new RegExp(id + '.*');
            // TODO: Get the user id somehow so the data is filtered to only PCs that this user has done.
            const filter = `{"where":{"and":[{"invoiceID":{"regexp":"${regex}"}},{"managerApprovedUserID":"${userID}"}]}}`;

            this.mpiApp.getPCID(filter).subscribe({
                next: (data) => {
                    if (data.length >= 1) {
                        id += '-' + (data.length + 1);
                    }

                    this.invoiceForm.patchValue({ invoiceID: id });
                },
                error: (err) => {},
            });
        } else {
            // Require the user to specify the invoice ID.
            id = '';
            this.invoiceForm.patchValue({ invoiceID: id });
        }
    }

    onUpload(event) {
        /* reset form values so user can submit new invoice */
        this.invoiceForm.reset();
        this.glformLocationArray.reset();
        this.glForm.reset();
        this.fileInput.clear();
        this.invoiceDate = '';
        this.invoiceID = '';
        this.dueDate = '';
        this.selectedFilesState.setFileState(false);
        this.permanentError.setError(false);
        this.uploadInProgress = false;
        this.error_msgs = [];
        this.getApprovedPOs();
        this.changeFor();

        this.messageService.add({
            severity: 'success',
            summary: 'Invoice submitted',
            detail: 'Files(s) successfully uploaded',
        });
    }

    uploadError(event) {
        console.log(event.error);

        /* get the loopback error message, nested in error objects */
        const loopback_error = event.error.error.error;
        const error_obj = {
            severity: 'error',
            summary: 'Invoice submit failed',
            detail: 'Error: ' + loopback_error.message,
        };

        this.error_msgs = [error_obj];
        this.uploadInProgress = false;
        this.permanentError.setError(true);
    }

    /**
     * Modify the uploaded files before the POST request goes out
     *
     * Append the multipart/formdata to the POST request, including new invoice data
     * @param $event onBeforeUpload event
     */
    modifyInvoiceUpload($event) {
        // --------------------------------------------------
        // NOTE: Insurance Expiration currently required by backend for file upload
        // Remove when API is updated
        // --------------------------------------------------
        $event.formData.append('expirationDate', '2021-01-01');

        $event.formData.append('documentType', 'invoice');

        const selectedPO = this.invoiceForm.get('selectedPO');

        // console.log('Invoice upload: ', this.glformLocationArray.value);

        const locationPayload = this.mpiApp.normalizeLocationDistribution(this.glformLocationArray, this.locations);

        // Remove all spaces from the invoice ID
        const trimmedInvoiceID: string = String(this.invoiceForm.get('invoiceID').value).replace(/\s/g, '');
        console.log('Trimmed Invoice ID: ', trimmedInvoiceID);
        console.log('Significant Digits: ', this.getSignificantDigits(trimmedInvoiceID));
        
        // console.log(locationPayload);
        /* Append new invoice data under invoiceJSON */
        const newInvoice = {
            status: 'submitted',
            amount: this.invoiceForm.get('invoiceAmount').value,
            vendorID: selectedPO.value.vendorID,
            submittedDate: this.invoiceDate,
            locationsNameList: locationPayload,
            scheduledPaymentDate: this.dueDate,
            purchaseOrderID: selectedPO.value.purchaseOrderID,
            allowMultipleInvoices: this.invoiceForm.get('allowMultipleInvoices').value,
            invoiceID: trimmedInvoiceID,
            invoiceIdSignificantDigits: this.getSignificantDigits(trimmedInvoiceID),
            glCode: '0',
            paidDate: '',
        };

        if (this.selectedFor.code !== 'reimbursement') {
            // Since this isn't a reimbursement don't send this property
            delete newInvoice.locationsNameList;
        }

        $event.formData.append('invoiceJSON', JSON.stringify(newInvoice));
    }

    /**
     * Search for all occurances of digits within an invoice ID and return
     * them combined as a string. A very simple regex is used to search for
     * any occurences of digits within the ID, and then those results are joined
     * together into a single string.
     * 
     * @param invoiceID The invoice ID to extract digits from
     * @returns A string of just the digits from the invoice ID.
     */
    getSignificantDigits(invoiceID: string): string {
        const results = invoiceID.match(/[^_\W]+/g);

        if (results) {
            // Join the results into a single string, then strip all leading zeros
            return results.join('').replace(/^0+/, '');
        } else {
            return ''
        }
    }

    /**
     * Double check selected files from uploader in select event
     *
     * Taken from: https://stackoverflow.com/a/53639699/14560781
     *
     * If at least 1 file is selected, requirement for 1 file to be uploaded is fulfilled
     * @param event onSelect() event
     */
    fileSelect(event) {
        if (event.files.length > 0) {
            this.selectedFilesState.setFileState(true);
        } else {
            this.selectedFilesState.setFileState(false);
        }
    }

    /**
     * Remove selected file from uploader
     * check if enough files are still selected to satisfy requirement for at least 1 file
     * @param event onRemove() event
     */
    fileRemove(event) {
        /**
         * weird behavior with primeng fileupload where after clicking remove on individual file,
         * in onRemove event fileInput.files.length still reports the clicked file
         *
         * Work around by subtracting 1 from number of files, assuming the file removed is still being reported
         * by the event
         */
        if (!this.fileInput || this.fileInput.files.length - 1 <= 0) {
            this.selectedFilesState.setFileState(false);
        }
    }

    /**
     * Clear selected files from uploader
     * Since all files are removed, requirement for at least 1 uploaded file is no longer fulfilled
     */
    fileClear() {
        this.selectedFilesState.setFileState(false);
    }

    /**
     * Get the total sum of all location cost distribution lines
     */
    sumLocationCostDistribution() {
        let amount = 0;

        for (const locationControl of this.glformLocationArray.controls) {
            amount += locationControl.get('amount').value;
        }

        // Round the result to the required number of decimals.
        amount = Number(amount.toFixed(2));

        return amount;
    }

    /**
     * Submit form with invoice data and upload files
     *
     * Taken from: https://stackoverflow.com/a/52132957/14560781
     * @param event click event for 'Submit Request' button
     */
    async submit(event) {
        /* validate invoice amount is less than remaining amount on selected purchase order */

        const invoiceAmount = this.invoiceForm.get('invoiceAmount').value;

        if (invoiceAmount === 0) {
            const errorObj = {
                severity: 'error',
                summary: 'No Amount Specified',
                detail: 'Cannot submit a zero dollar invoice. Please enter an invoice amount.',
            };

            this.error_msgs = [errorObj];

            return;
        }

        if (this.selectedFor.code === 'reimbursement') {
            // When submitting a reimbursement ensure that the total amount matches the location distribution amounts.

            // console.log(this.glformLocationArray.value);
            const locationCostDistributionAmount = this.sumLocationCostDistribution();

            // console.log(`Comparing ${locationCostDistributionAmount} against ${invoiceAmount}`);

            if (locationCostDistributionAmount !== invoiceAmount) {
                const errorObj = {
                    severity: 'error',
                    summary: `Amount Doesn't Match`,
                    detail: `The invoice amount must match the location cost distribution total of ${locationCostDistributionAmount}`,
                };

                this.error_msgs = [errorObj];

                return;
            }
        }

        if (invoiceAmount > this.remainingAmount) {
            const errorObj = {
                severity: 'error',
                summary: 'Invoice submit failed',
                detail: 'Please make sure invoice amount is not greater than remaining purchase order balance',
            };

            this.error_msgs = [errorObj];

            return;
        }

        if (!this.fileInput.hasFiles()) {
            const errorObj = {
                severity: 'error',
                summary: 'Invoice submit failed',
                detail: 'Please make sure you provide one or more invoice files',
            };

            this.error_msgs = [errorObj];

            return;
        }

        /* upload files */
        if (this.selectedFilesState.getFileState()) {
            let isEncrypted: boolean = false;
            const fileList = this.fileInput.files;

            for (const file of fileList) {
                await file.text().then((fileString) => {
                    isEncrypted = fileString.includes(`/Encrypt`);
                });
            }

            if (!isEncrypted) {
                // Mark that the upload process began. .upload doesn't return anything so handle the completion
                // in the .onUpload event (callback)
                this.uploadInProgress = true;
                this.fileInput.upload();
            } else {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Invoice Submit Failed',
                    detail: 'Error: Encrypted PDFs are not supported',
                });
                return;
            }
        }
    }

    /**
     * Occurs when the user changes what type of PO this is for.
     */
    async changeFor() {
        // Whenever the form type changes clear these values
        this.invoiceForm.patchValue({ invoiceAmount: '0' });
        this.remainingAmount = '0';

        // When the slected option is for a reimbursement then clear any selected vendor
        if (this.selectedFor.code === 'reimbursement') {
            // Since it's for a reimbursement we need to lookup the vendorID.

            // This works but doesn't wait
            // const vendorRec = await this.mpiApp.getOrCreateReimbursementVendor().subscribe({
            //     next: (data) => {
            //         return data;
            //     },
            //     error: (error) => {
            //         console.error(error);
            //         this.messageService.add({
            //             severity: 'error',
            //             summary: 'Unable To Get Reimbursement Record',
            //             detail: 'Error ' + error.status + ': ' + error.error.error.message,
            //         });
            //     },
            //     complete: () => {
            //     },
            // })

            this.isLoadingService.add({ key: 'getOrCreateReimbursementVendor' });

            const vendorRec = await this.mpiApp
                .getOrCreateReimbursementVendor()
                .pipe(
                    // takeUntil(this.componentIsDestroyed$),
                    // takeUntil(this.cancelRestCall$),
                    timeout(30000),
                    retry(0)
                )
                .toPromise()
                .catch((error) => {
                    console.error(error);
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Unable To Get Reimbursement Record',
                        detail: 'Error ' + error.status + ': ' + error.error.error.message,
                    });
                });

            this.isLoadingService.remove({ key: 'getOrCreateReimbursementVendor' });

            // On error an empty record will be returned. Change back to vendor.
            if (!vendorRec) {
                this.selectedFor = {
                    name: 'Vendor',
                    code: 'vendor',
                };
                return;
            }

            const selectedVendorID = vendorRec.vendorID;

            // Indicate that this is the vendor our PO will be for
            this.vendorForSelectedPO = vendorRec;
            this.invoiceForm.patchValue({ selectedPO: { name: 'Reimbursement', vendorID: selectedVendorID } });

            this.invoiceToShowVendor = {
                name: 'Reimbursement',
                defaultGLCode: this.vendorForSelectedPO.defaultGLCode,
            };

            // Trigger the code that indicates a PO is selected. Normally this is triggered when a PO is selected but
            // reimbursements don't actually require a PO to be selected since the PO will be created as needed
            // when the invoice is submitted.
            this.selectedOpenPO(null);
        } else {
            // Not for a reimbursement so we can just get the selected vendorID. Reset it so the user can select it again.
            this.invoiceForm.patchValue({ selectedPO: 0 });
            this.invoiceForm.patchValue({ invoiceID: '' });
            this.vendorForSelectedPO = null;
        }
    }

    /**
     * Simplify getting the gl form locations array for the template
     */
    get glformLocationArray(): UntypedFormArray {
        return this.glForm.get('locations') as UntypedFormArray;
    }

    debugLocations() {
        const locations = this.glForm.get('locations') as UntypedFormArray;
        // console.log(locations);
        for (const location of locations.controls) {
            console.log(location);
        }
    }

    addLocation() {
        this.addLocationFormGroup(
            'glForm',
            '',
            0,
            this.mpiApp.glCodeGenerator.generateGLCode('', this.invoiceToShowVendor.defaultGLCode)
        );
    }

    /**
     * Add a new location form control to the locations form array
     * Set its inital value of the location to the string provided
     *
     * Adapted from: https://stackoverflow.com/a/59118097/14560781
     *
     * @param formType which form to add location to
     * @param location location name string
     * @param amount previously entered amount, if available
     * @param glCode previously entered glCode, if available
     */
    addLocationFormGroup(formType: string, location: string, amount?: number, glCode?: string) {
        if (formType === 'amountForm') {
            // main form array for all the locations in this invoice
            const locations = this.glformLocationArray;

            let new_location = this.createLocationFormGroup();
            new_location.controls['location'].setValue(location);
            if (amount) {
                new_location.controls['amount'].setValue(amount);
            }

            locations.push(new_location);
        } else if (formType === 'glForm') {
            const locations = this.glformLocationArray;

            let new_location = this.createGlFormGroup();
            new_location.controls['location'].setValue(location);
            new_location.controls['amount'].setValue(amount);
            if (glCode) {
                new_location.controls['glCode'].setValue(glCode);
                // console.log(glCode);
            }

            locations.push(new_location);
        }
    }

    /**
     * Create a formgroup with a location and amount
     * Meant for each location an invoice applies to
     *
     * Used only by addLocationFormGroup()
     * @returns new formgroup for locations amount form
     */
    private createLocationFormGroup(): UntypedFormGroup {
        return new UntypedFormGroup({
            location: new UntypedFormControl('', [Validators.required]),
            amount: new UntypedFormControl(null, [Validators.required]),
        });
    }

    // Set the default GL code for all locations that don't have an existing GL code.
    setDefaultGLCode() {
        // Get the GL form locations
        const locations = this.glForm.get('locations') as UntypedFormArray;
        for (let i = 0; i < locations.value.length; i++) {
            if (locations.value[i].glCode === '') {
                // If the glCode for the location isn't set then set the default

                // This sets the model but doesn't update the control
                // locations.value[i].glCode = this.invoiceToShowVendor.defaultGLCode;

                // Get the location form control
                const locationControl = locations.controls[i];
                // Get the glCode form control inside the location
                const glCodeControl = locationControl.get('glCode');

                let updateValue = this.mpiApp.glCodeGenerator.generateGLCode(
                    locations.value[i].location,
                    this.invoiceToShowVendor.defaultGLCode
                );

                // If there is no mapping, just use the vendor's GL Code
                if (updateValue === '') {
                    updateValue = this.invoiceToShowVendor.defaultGLCode;
                }

                // Update the value on the form
                glCodeControl.setValue(updateValue);
            }
        }
    }

    deleteLocation(index: number) {
        // console.log(index);
        this.glformLocationArray.removeAt(index);
    }

    public generateGLCode(locationName: string, index: number) {
        let updateValue = this.mpiApp.glCodeGenerator.generateGLCode(
            locationName,
            this.invoiceToShowVendor.defaultGLCode
        );

        if (updateValue === '') {
            updateValue = this.invoiceToShowVendor.defaultGLCode;
        }

        this.glformLocationArray.controls[index].get('glCode').setValue(updateValue);
    }

    saveLocations() {
        // Save the changes by patching the updated values back to the server.
        let update = {};
        const location_payload = this.mpiApp.normalizeLocationDistribution(this.glformLocationArray, this.locations);
        let invoice_fields = JSON.parse(this.selectedInvoice.fields) || {};
        invoice_fields['locationDistribution'] = location_payload;
        update['fields'] = JSON.stringify(invoice_fields);

        this.patchUpdatedInvoice(update, true);
    }

    searchLocations(query: string) {
        const pattern = new RegExp('.*' + query + '.*', 'i');
        const filter = `{"where": {"or": [{"name": {"regexp": "${pattern}"}},{"description": {"regexp": "${pattern}"}}]},"order":["name ASC"]}`;

        this.mpiApp.getLocations(filter).subscribe({
            next: (data) => {
                this.locationSearchResults = data;
            },
            error: (err) => {
                console.error(err);
            },
            complete: () => {},
        });
    }

    selectLocation(event: LocationsPartial | string, index: number) {
        // Check if the user used the dropdown to select a location, or just typed it in
        if (typeof event !== 'string') {
            const thing = event;
            console.log(thing);
            if (event.name) {
                // Make sure only the location name is assigned to the form control after selection
                this.glformLocationArray.at(index).get('location').setValue(event.name);
            }

            this.generateGLCode(event.name, index);
        } else {
            // The user manually typed a location. Is it a valid location?
            const foundLocation = this.findLocation(event);
            if (foundLocation === 'location not found') {
                // Not a valid location, set an error in the form control
                const errs: ValidationErrors = {
                    error: 'Invalid location name',
                };
                this.glformLocationArray.at(index).get('location').setErrors(errs);
            }
            this.generateGLCode(event, index);
        }

        // Generate the GL Code for the associated location name
    }

    /**
     * Create new formgroup for GL form
     * Used for each location in the invoice
     *
     * @returns new formgroup for GL form
     */
    private createGlFormGroup(): UntypedFormGroup {
        return new UntypedFormGroup({
            location: new UntypedFormControl('', [Validators.required]),
            glCode: new UntypedFormControl('', [Validators.required]),
            amount: new UntypedFormControl(null, [Validators.required]),
        });
    }

    // Send updated PATCH request to server
    patchUpdatedInvoice(update, dontHideDetails = false) {
        this.requestInProgress = true;
        this.mpiApp.updateInvoice(this.invoiceToShow.id, update).subscribe({
            next: (data) => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Invoice update successful',
                    detail: 'Invoice status updated',
                });
                // this.invoiceDetailsShown = false;
            },
            error: (error) => {
                console.error(error);
                this.messageService.add({
                    severity: 'error',
                    summary: 'Invoice update failed',
                    detail: 'Error ' + error.status + ': ' + error.error.error.message,
                });
            },
            complete: () => {
                this.requestInProgress = false;
                if (!dontHideDetails) {
                    // deselect invoice to remove updated invoice from grid
                    this.deselectInvoice();
                }
                // reload invoices
                // this.getInvoices();
            },
        });
    }

    /**
     * Called when invoice row in unselected
     *
     * Close the invoice details section and set our selected invoice to null
     */
    deselectInvoice() {
        console.log('deselect');
        // this.invoiceDetailsShown = false;
        this.invoiceToShow = null;
    }
}
