<!-- Select the payments excel file -->
<div class="dashboard grid p-fluid" *ngIf="(selectableInvoicesLoading$ | async)">
    <div class="col-12 md:col-12">
        <div class="card">
            <div *ngIf="(selectableInvoicesLoading$ | async)">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i> Loading Approved Payments...
            </div>
        </div>
    </div>
</div>
<div class="dashboard grid p-fluid">
    <div class="col-12 md:col-12">
        <div class="card">
            <h1 style="font-size:16px">Import Payments File:</h1>
            <div class="flex flex-row">
                <p-fileUpload #fileUpload mode="basic" accept=".csv" customUpload="true"
                    (uploadHandler)="uploadCSV($event)" [showUploadButton]="false" chooseLabel="Browse" [auto]="true"
                    [disabled]="(activeSubs$ | async) > 0">
                </p-fileUpload>
                <button pButton pRipple type="button" label="Clear" class="p-button-danger p-button-text"
                    style="width:auto" (click)="clearFile(fileUpload)" [disabled]="(activeSubs$ | async) > 0"></button>
            </div>

        </div>
    </div>
</div>

<p-table #fileRowTable [value]="excelFileRows"
    [globalFilterFields]="['check date','check number','check type','check amount', 'invoice amount', 'invoice number', 'vendor name', 'vendor number']"
    responsiveLayout="scroll" [paginator]="true" [rows]="10" [rowHover]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
    <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-between mb-2">
            <div></div>
            <div>
                <span class="p-input-icon-left ">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="fileRowTable.filterGlobal($event.target.value, 'contains')"
                        placeholder="Search..." />
                </span>
            </div>
        </div>
        <p-progressBar *ngIf="totalHTTPCalls && totalHTTPCalls > 0" [value]="invoicesRemaining"
            [showValue]="true"></p-progressBar>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th pSortableColumn="check date">Check Date
                <p-sortIcon field="check date"></p-sortIcon>
            </th>
            <th pSortableColumn="check number">Check Number
                <p-sortIcon field="check number"></p-sortIcon>
            </th>
            <th pSortableColumn="check type">Check Type
                <p-sortIcon field="check type"></p-sortIcon>
            </th>
            <th pSortableColumn="check amount">Check Amount
                <p-sortIcon field="check amount"></p-sortIcon>
            </th>
            <th pSortableColumn="invoice amount">Invoice Amount
                <p-sortIcon field="invoice amount"></p-sortIcon>
            </th>
            <th pSortableColumn="vendor number">Vendor Number
                <p-sortIcon field="vendor number"></p-sortIcon>
            </th>
            <th pSortableColumn="vendor name">Vendor Name
                <p-sortIcon field="vendor name"></p-sortIcon>
            </th>
            <th pSortableColumn="invoice number">Invoice Number
                <p-sortIcon field="invoice number"></p-sortIcon>
            </th>
            <th>
                Matched Invoice
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-fileRow>
        <tr>
            <td>{{fileRow['check date']}}</td>
            <td>{{fileRow['check number']}}</td>
            <td>{{fileRow['check type']}}</td>
            <td>{{fileRow['check amount'] | currency}}</td>
            <td>{{fileRow['invoice amount'] | currency}}</td>
            <td>{{fileRow['vendor number']}}</td>
            <td>{{fileRow['vendor name']}}</td>
            <td>{{fileRow['invoice number']}}</td>
            <td>
                <div *ngIf="(fileRow.matchedInvoice | async) as matchedInvoice; else loading">
                    <!-- Show the previously matched batch id info if the row was already imported -->
                    <div class="block" *ngIf="matchedInvoice.prevMatch; else elseBlock">
                        <button pButton type="button" label="Previous Match" icon="pi pi-external-link" iconPos="right"
                            (click)="showPreviousMatchDialog(fileRow)">
                        </button>
                    </div>
                    <!-- Else if we just matched an invoice on import, show the invoice info -->
                    <ng-template #elseBlock>
                        <div class="block" *ngIf="matchedInvoice.invoice; else selectButton">
                            <button pButton type="button" class="p-button-success" label="Matched Invoice"
                                icon="pi pi-external-link" iconPos="right" (click)="setMatchedInvoice(fileRow)">
                            </button>
                        </div>
                    </ng-template>
                    <!-- Otherwise, show button to have user select an invoice to match with manually -->
                    <ng-template #selectButton>
                        <div class="block">
                            <button pButton type="button" class="p-button-warning" label="Select Invoice"
                                icon="pi pi-external-link" iconPos="right"
                                [loading]="(activeSubs$ | async) > 0 || (selectableInvoicesLoading$ | async)"
                                (click)="showSelectInvoiceDialog(fileRow)"></button>
                        </div>
                    </ng-template>
                </div>

                <ng-template #loading>
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                </ng-template>
            </td>
        </tr>
    </ng-template>
</p-table>

<!-- Dialog to display a matched invoice details-->
<p-dialog header="Matched Invoice" [(visible)]="viewInvoiceDisplay" [style]="{width: '50vw'}" [modal]="true">
    <p-table [value]="matchedInvoice" styleClass="p-datatable-gridlines" responsiveLayout="scroll">
        <ng-template pTemplate="header">
            <tr>
                <th>Invoice ID</th>
                <th>Invoice Amount</th>
                <th>Vendor ID</th>
                <th>Vendor Name</th>
                <th>Purchase Order ID</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-invoice>
            <tr>
                <td>{{invoice.invoiceID}}</td>
                <td>{{invoice.amount | currency}}</td>
                <td>{{invoice.vendorRecord.accountingSystemVendorID}}</td>
                <td>{{invoice.vendorRecord.accountContactFirstName}} {{invoice.vendorRecord.accountContactLastName}}
                </td>
                <td>{{mpiApp.idToHash(invoice.purchaseOrderID)}}</td>
            </tr>
        </ng-template>
    </p-table>
</p-dialog>

<!-- Display the info for when a payment when previously matched -->
<p-dialog header="Payment Previously Matched Details" [(visible)]="previousMatchDisplay" [style]="{width: '50vw'}"
    [modal]="true">
    <p-table [value]="prevMatchInfo" styleClass="p-datatable-gridlines" responsiveLayout="scroll">
        <ng-template pTemplate="header">
            <tr>
                <th>File Name</th>
                <th>Import Date</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-splitInfo>
            <tr>
                <td>{{splitInfo[0]}}</td>
                <td>{{splitInfo[1]}}</td>
            </tr>
        </ng-template>
    </p-table>
</p-dialog>

<!-- Dialog to select an invoice to match manually -->
<p-dialog header="Select Unpaid Invoice to Match With Excel Payment" [(visible)]="selectInvoiceDisplay"
    [style]="{width: '60vw'}" [modal]="true">
    <p-table #invoiceTable [value]="selectableInvoices" selectionMode="single" [(selection)]="selectedInvoice"
        responsiveLayout="scroll" dataKey="id"
        [globalFilterFields]="['invoiceID','amount','vendorRecord.accountingSystemVendorID','vendorRecord.name', 'purchaseOrderID']">
        <ng-template pTemplate="caption">
            <div class="flex align-items-center justify-content-between">
                <div class="col-3">
                    <span class="p-input-icon-left ">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text"
                            (input)="invoiceTable.filterGlobal($event.target.value, 'contains')"
                            placeholder="Search..." />
                    </span>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="display: none;">Internal ID</th>
                <th pSortableColumn="invoiceID">Invoice ID
                    <p-sortIcon field="invoiceID"></p-sortIcon>
                </th>
                <th pSortableColumn="amount">Invoice Amount
                    <p-sortIcon field="amount"></p-sortIcon>
                </th>
                <th pSortableColumn="vendorRecord.accountingSystemVendorID">Vendor ID
                    <p-sortIcon field="vendorRecord.accountingSystemVendorID"></p-sortIcon>
                </th>
                <th pSortableColumn="vendorRecord.name">Vendor Company
                    <p-sortIcon field="vendorRecord.name"></p-sortIcon>
                </th>
                <th pSortableColumn="purchaseOrderID">Purchase Order ID
                    <p-sortIcon field="purchaseOrderID"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-invoice>
            <tr [pSelectableRow]="invoice">
                <td style="display: none;">{{invoice.id}}</td>
                <td>{{invoice.invoiceID}}</td>
                <td>{{invoice.amount | currency}}</td>
                <td>{{invoice.vendorRecord.accountingSystemVendorID}}</td>
                <td>{{invoice.vendorRecord.name}}</td>
                <td>{{mpiApp.idToHash(invoice.purchaseOrderID)}}</td>
            </tr>
        </ng-template>
    </p-table>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="closeSelectInvoiceDialog()" label="Cancel" styleClass="p-button-text">
        </p-button>
        <p-button icon="pi pi-check" (click)="manuallyMatchInvoice()" label="Save" styleClass="p-button-text"
            [disabled]="!selectedInvoice"></p-button>
    </ng-template>
</p-dialog>