<!--<app-file-verification-control [fileID]="'111'">-->
<!--</app-file-verification-control>-->

<!--<app-view-vendor-info [id]=52 [componentMode]="'accounting'"></app-view-vendor-info>-->
<h1>DEV JAB</h1>
<!--<app-change-purchase-order></app-change-purchase-order>-->

<h1>Recurring Payments</h1>


<button type="button" (click)="show()" pButton icon="pi pi-info-circle" label="Show"></button>

<button type="button" (click)="getSinglePO('94')" pButton icon="pi pi-info-circle" label="Get PO"></button>

<h1>Get or Create Reimbursement Id</h1>
<button type="button" (click)="getOrCreateReimbursementPO()" pButton icon="pi pi-info-circle" label="Get Or Create Reimbursement Record"></button>
