import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { StringIterator } from 'cypress/types/lodash';
import { finalize } from 'rxjs/operators';
import { MPIAppService } from 'src/services/mpiapp.service';
import { convertToObject } from 'typescript';
import { User } from '../api/models/user';
import { IdCheck } from './IdCheck';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ValidationService } from '../validation.service';
import { Message, MessageService } from 'primeng/api';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    providers: [MessageService],
})
export class ChangePasswordComponent implements OnInit {
    siteName = environment.siteName;

    msgs: Message[] = [];

    idCheckFinished = false;
    isInviteValid = false;

    isPostSuccessful = false;
    isPostFinished = false;

    isPatchrequestFinished = true;

    resetID: string;
    idCheck: IdCheck;

    changePassword = this.formBuilder.group(
        {
            password: ['', [Validators.required, this.validationService.passwordStrengthValidator()]],
            confirmPassword: ['', Validators.required],
        },
        { validators: this.validationService.matchingPasswords('password', 'confirmPassword') }
    );

    pattern =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-#!$@%^&*()_+|~=`{}\[\]:";'<>?,.\/ ])[A-Za-z\d-#!$@%^&*()_+|~=`{}\[\]:";'<>?,.\/ ]{8,}$/;

    constructor(
        private route: ActivatedRoute,
        private mpiApp: MPIAppService,
        private formBuilder: UntypedFormBuilder,
        private validationService: ValidationService
    ) {}

    ngOnInit(): void {
        this.resetID = this.route.snapshot.queryParams['id'];
        this.getResetStatus();
    }

    getResetStatus() {
        this.mpiApp
            .getUserID(this.resetID)
            .pipe(
                finalize(() => {
                    this.idCheckFinished = true;
                    if (this.idCheck.userID) {
                        this.isInviteValid = true;
                    }
                })
            )
            .subscribe((data) => {
                this.idCheck = data;
            });
    }

    submit() {
        if (!this.changePassword.valid) {
            this.validationService.validateAllFormFields(this.changePassword);
            return;
        }

        const payload = {
            userID: this.idCheck.userID,
            resetID: this.resetID,
            password: this.changePassword.get('password').value,
        };

        this.isPatchrequestFinished = false;
        this.mpiApp.resetUserPassword(payload).subscribe(
            (data) => {
                this.isPostSuccessful = true;
                this.isPatchrequestFinished = true;
            },
            (err) => {
                this.msgs = [
                    {
                        severity: 'error',
                        summary: 'Failure Resetting Password',
                        detail: 'Please try resetting your password again',
                    },
                ];
                console.error(err);
                this.isPatchrequestFinished = true;
            }
        );
    }
}
