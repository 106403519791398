import { LoginModel } from './../../models/login.model';
import { Component, OnInit } from '@angular/core';
import { MPIAppService, PurchaseOrdersAndVendors } from '../../../services/mpiapp.service';
import { Subscription } from 'rxjs';
import { PurchaseOrderWithVendor } from '../../purchase-order-list/purchase-order-list.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { IsLoadingService } from '@service-work/is-loading';
import { DialogService } from 'primeng/dynamicdialog';
import { Vendor } from '../../api/models/vendor';
import { PurchaseOrder } from '../../api/models/purchase-order';
import { User } from '../../api/models/user';
import { SessionService } from 'src/session.service';

export interface ManagerRequestedPOs {
    [key: string]: PurchaseOrder[];
}

@Component({
    selector: 'app-open-manager-pos',
    templateUrl: './open-manager-pos.component.html',
    styleUrls: ['./open-manager-pos.component.scss'],
    providers: [ConfirmationService, MessageService, DialogService],
})
export class OpenManagerPosComponent implements OnInit {
    private subs: Subscription[];
    purchaseOrders: PurchaseOrderWithVendor[];

    // Key an object of keys containing an array of POs. Each key is a manager ID.
    managerRequestedPOs: ManagerRequestedPOs = {};

    // A list of user objects that will be looked up while the query is running
    users: Promise<User>[] = [];

    // The report options the user can choose
    reportOptions = [
        { name: `No Page Break on Manager`, code: 'no-page-break' },
        { name: 'Show Scheduled POs', code: 'show-scheduled' },
    ];

    // The choosen report options from reportOptions
    reportOptionsSelected = [];

    vendors: Vendor[];
    vendorNames = {};

    user_profile: LoginModel;
    user_role: string;

    constructor(
        private messageService: MessageService,
        private mpiApp: MPIAppService,
        private confirmationService: ConfirmationService,
        public isLoadingService: IsLoadingService,
        public dialogService: DialogService,
        private session: SessionService
    ) {}

    ngOnInit(): void {
        this.subs = [];

        this.purchaseOrders = [];

        this.user_profile = this.session.getUserProfile();
        this.user_role = this.user_profile?.activeRole;

        if (this.user_role === 'manager') {
            this.loadReport();
        }
    }

    loadReport() {
        this.purchaseOrders = [];

        let poFilter: any;

        if (this.user_role === 'manager') {
            // If the role is a manager then only show their POs

            const userIDString = atob(this.session.getToken().split('.')[1]);
            const userID: string = JSON.parse(userIDString).id;
            poFilter = {
                where: {
                    and: [
                        { status: { eq: 'approved' } },
                        { managerRequesterUserID: userID },
                        { poType: { neq: 'recurring' } },
                    ],
                },
            };
        } else {
            // If the role is not a manager these filteres are applied

            // This is the default filter but could be replaced by more specific options
            poFilter = {
                where: {
                    and: [{ status: 'approved' }, { poType: { neq: 'recurring' } }],
                },
            };

            if (this.isSelected(`show-scheduled`)) {
                // Change the filter to include scheduled POs
                poFilter = {
                    where: {
                        and: [
                            { or: [{ status: 'approved' }, { status: 'scheduled' }] },
                            { poType: { neq: 'recurring' } },
                        ],
                    },
                };
            }
        }

        const poFilterStr = JSON.stringify(poFilter);

        // Get a list of all vendors and pos to populate the grid.
        this.subs.push(
            this.isLoadingService.add(
                this.mpiApp
                    .getPurchaseOrdersAndVendors(poFilterStr)
                    .subscribe((POsAndVendors: PurchaseOrdersAndVendors) => {
                        this.clearReport();

                        POsAndVendors.pos.forEach((e, i, a) => {
                            if (a[i].poID === '' || a[i].poID === 'PENDING') {
                                a[i].poID = this.mpiApp.idToHash(a[i].purchaseOrderID);
                            }

                            // Do we have an array of POs for this manager?
                            if (this.managerRequestedPOs[e.managerRequesterUserID]) {
                                // We've already seen this manager so push it into the existing list of purchase orders
                                // that belong to that manager.
                                this.managerRequestedPOs[e.managerRequesterUserID].push(e);
                            } else {
                                // We have not seen a PO for this manager before so look it up.

                                // Create a promise that will return the user object for the user who owns this PO
                                this.users[e.managerRequesterUserID] = new Promise((resolve) => {
                                    // Our promise is the result of a subscription to find the user
                                    this.mpiApp.getUser(e.managerRequesterUserID).subscribe((user) => {
                                        // Our information has been found, return it to the promise
                                        resolve(user);
                                    });
                                });

                                // Add the PO to the list of POs for the manager while the name is being looked up.
                                this.managerRequestedPOs[e.managerRequesterUserID] = [e];
                            }
                        });

                        // Assign all the POs and Vendors to locally scoped component variable
                        this.purchaseOrders = POsAndVendors.pos;
                        this.vendors = POsAndVendors.vendors;

                        // Setup a vendor name to vendor ID map
                        for (const vendor of this.vendors) {
                            this.vendorNames[vendor.vendorID] = vendor.name;
                        }

                        // Assign the vendor name to each vendor ID in the list of POs
                        this.purchaseOrders.forEach((po) => {
                            const date = new Date(po.managerRequested);
                            po.vendorName = this.vendorNames[po.vendorID];
                            po.dateRequested = date.toLocaleString();
                        });
                    }),
                { key: 'list' }
            )
        );
    }

    /**
     * Given a code string see if it's one of the selected report options
     * @param code
     */
    isSelected(code: string) {
        // Loop over all the report options selected and see if the report option code matches
        // the code we're looking for.
        for (const option of this.reportOptionsSelected) {
            if (option.code === code) {
                // Yes, return early.
                return true;
            }
        }

        // No, not found
        return false;
    }

    changedReportOptions() {
        // When the report options change we want to clear the loaded report.
        this.clearReport();
    }

    clearReport() {
        this.purchaseOrders = [];
        this.managerRequestedPOs = {};
    }
}
