import { Component, OnInit } from '@angular/core';
import { MPIAppService } from '../services/mpiapp.service';
import { environment } from '../environments/environment';
import { SessionService } from '../session.service';
import { Router } from '@angular/router';

export interface DropdownSelection {
    name: string;
    code: string;
}

@Component({
    selector: 'app-menu',
    template: `
        <div class="p-fluid" *ngIf="selectableRoles.length > 0">
            <div class="p-grid p-field">
                <div class="col-12">
                    <label for="role-selection" class="menu-text">Available Roles</label>
                </div>

                <div class="col-12">
                    <p-dropdown
                        [options]="selectableRoles"
                        placeholder="Select a role"
                        inputId="role-selection"
                        [(ngModel)]="selectedRole"
                        [showClear]="false"
                        optionLabel="name"
                        (onChange)="changeRole($event)">
                    </p-dropdown>
                </div>
            </div>
        </div>

        <div class="menu">
            <ul class="layout-menu">
                <li app-menuitem *ngFor="let item of model; let i = index" [item]="item" [index]="i" [root]="true"></li>
            </ul>
        </div>
    `,
    styles: [
        `
            .menu-text {
                color: white;
            }
        `,
    ],
})
export class AppMenuComponent implements OnInit {
    model: any[];

    env = environment;

    selectableRoles: DropdownSelection[] = [];

    // The selected role is the model of the input control bound to this property
    selectedRole: { name: string; code: string };

    constructor(private mpiApp: MPIAppService, private session: SessionService, private router: Router) { }

    ngOnInit() {
        this.model = this.mpiApp.getMenuModel();

        let selectedRoleChanged = false;

        // What was the active role code in the user profile after last load?
        const activeRoleCode = this.session.getUserProfile()?.activeRole;

        // What are the other roles the user is a member of?
        const allowedRoles = this.session.getUserProfile()?.allowedRoles;

        // If we have allowed roles in the session load them into the component
        if (allowedRoles) {
            for (const entry of allowedRoles) {
                if (entry !== 'accounts-payable') {
                    // Refactor the allowedRoles into the format needed for the menu
                    const thisRole = {
                        name: entry,
                        code: entry,
                    };

                    // Add the role into the menu
                    this.selectableRoles.push(thisRole);

                    if (thisRole.code === activeRoleCode) {
                        // This role code is our current activeRoleCode so set the menu to
                        // reflect so active role
                        this.selectedRole = thisRole;

                        // Indicate that after the menu is built it should reload for the active role
                        selectedRoleChanged = true;

                        // Indicate to the rest of the app what our active role is.
                        this.mpiApp.activeRole = entry;
                    }
                }
            }
        } else {
            // Even if there were no roles the active role should always be set.
            this.mpiApp.activeRole = activeRoleCode;
        }

        // Restore the list of all possible the roles the user has access to.
        this.mpiApp.currentRoles = allowedRoles;

        this.mpiApp.currentMessage.subscribe((message) => {
            if (message && message === 'reload-menu') {
                this.reload();
            }
        });

        if (selectedRoleChanged) {
            this.reload(activeRoleCode);
        }
    }

    reload(forRole = '') {
        this.model = this.mpiApp.getMenuModel(forRole);
    }

    changeRole(event) {
        // Set the role selected
        this.selectedRole = event.value;

        // Get the name of the role
        const role = event.value.code;

        // Indicate the session is authenticated
        this.session.isAuthenticated = true;

        // Indicate what our active role is
        const profile = this.session.getUserProfile();

        // Save the active role in the profile
        profile.activeRole = role;
        // And also ensure the rest of the app knows what the active role is
        this.mpiApp.activeRole = role;

        this.session.saveUserProfile(profile);

        // Push the navigation action to trigger the load of app-main component
        this.router.navigate(['/']);

        // Tell the app-main component of the new role and that we're logged in
        this.mpiApp.currentRoles = profile.allowedRoles;
        this.mpiApp.changeMessage('reload-menu');
    }
}
