import { Injectable } from '@angular/core';
import { LocationSetting } from 'src/services/mpiapp.service';

// To avoid circular dependencies. Location GL Code session handling
// must be defined here
const LOCATION_GL_CODES: string = 'vendorportal_locationGlCodes';

@Injectable({
    providedIn: 'root',
})
export class GLCodeGenerator {
    private locationGlCodes: LocationSetting[] = [];

    constructor() {
        // if (sessionStorage.getItem('locationGlCodes')) {
        //     this.locationGlCodes = JSON.parse(sessionStorage.getItem('locationGlCodes'));
        // } else {
        //     this.mpiApp.getSetting('locations').subscribe({
        //         next: (data) => {
        //             this.locationGlCodes = JSON.parse(data.value);
        //             sessionStorage.setItem('locationGlCodes', JSON.stringify(this.locationGlCodes));
        //         },
        //         error: (err) => {
        //             console.error(err);
        //         },
        //         complete: () => {},
        //     });
        // }
    }

    get glCodeList(): LocationSetting[] {
        return this.locationGlCodes;
    }

    set glCodeList(codeList: LocationSetting[]) {
        this.locationGlCodes = codeList;
        // Set the sessionStorage item and internal list with the settings location list
        sessionStorage.setItem(LOCATION_GL_CODES, JSON.stringify(this.locationGlCodes));
    }

    /**
     *
     * @param locationName Location name/number of the GL Code in question.
     * @param vendorGLCode GL Code of the vendor for this GL Code.
     * @returns String of the newly generated GL Code from the mapping, or an empty string if no mapping was found.
     */
    public generateGLCode(locationName: string, vendorGLCode: string): string {
        let generatedCode: string = '';
        let foundLocation: LocationSetting;

        if (sessionStorage.getItem(LOCATION_GL_CODES)) {
            this.locationGlCodes = JSON.parse(sessionStorage.getItem(LOCATION_GL_CODES));

            if (locationName !== '') {
                // Search the new list for the matching location name
                if (this.locationGlCodes) {
                    foundLocation = this.locationGlCodes.find((code) => code.location === locationName);

                    if (foundLocation) {
                        generatedCode = vendorGLCode + '-' + foundLocation.glCode;
                    }
                }
            }
        } else {
            // TEST: sessionStorage should already be filled from the constructor
            // Get list of location gl codes to store in sessionStorage
            // this.mpiApp.getSetting('locations').subscribe({
            //     next: (data) => {
            //         this.locationGlCodes = JSON.parse(data.value);
            //         sessionStorage.setItem('locationGlCodes', JSON.stringify(this.locationGlCodes));
            //     },
            //     error: (err) => {
            //         console.error(err);
            //     },
            //     complete: () => {
            //         if (locationName !== '') {
            //             // Search the new list for the matching location name
            //             if (this.locationGlCodes) {
            //                 foundLocation = this.locationGlCodes.find((code) => code.location === locationName);
            //                 if (foundLocation) {
            //                     generatedCode = vendorGLCode + '-' + foundLocation.glCode;
            //                 }
            //             }
            //         }
            //     },
            // });
        }

        return generatedCode;
    }

    public findGLCodeForLocation(location: string) {
        let match = '';

        // New method to find GL Code
        let locationMatch = this.locationGlCodes.find((code: LocationSetting) => {
            return code.location === location;
        });

        if (locationMatch !== undefined) {
            match = locationMatch.glCode;
        }

        return match;

        // Return the GL Code for the specified location name
        // return locationMatch.glCode;
    }
}
