<p-card header="Edit Document Retention Length" [style]="{ 'margin-top': '2em' }">
    <div class="p-grid p-fluid p-d-flex">
        <div class="p-col-3 p-as-center">
            <button
                pButton
                pRipple
                type="button"
                label="Show expired documents"
                (click)="showExpiredDocuments()"
                [loading]="requestInProgress === true"></button>
        </div>
        <div class="p-col-3 p-as-center">
            <button
                pButton
                pRipple
                type="button"
                label="Remove expired documents"
                class="p-button-danger"
                [disabled]="requestInProgress === true"
                (click)="removeExpiredDocuments()"></button>
        </div>
    </div>
    <div *ngIf="displayExpiredDocuments === true">
        <p-table
            [value]="expiredDocuments"
            [paginator]="true"
            [rows]="10"
            [showCurrentPageReport]="true"
            responsiveLayout="scroll"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} documents"
            [rowsPerPageOptions]="[10, 25, 50]">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="originalFileName">
                        Filename <p-sortIcon field="originalFileName"></p-sortIcon>
                    </th>
                    <th pSortableColumn="docType">Document Type <p-sortIcon field="docType"></p-sortIcon></th>
                    <th pSortableColumn="vendorName">Vendor <p-sortIcon field="vendorName"></p-sortIcon></th>
                    <th pSortableColumn="expirationDate">
                        Expiration Date <p-sortIcon field="expirationDate"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-file>
                <tr>
                    <td>{{ file.originalFileName }}</td>
                    <td>{{ file.docType }}</td>
                    <td>
                        <div *ngIf="file.vendorName; else findingVendorName">
                            {{ file.vendorName }}
                        </div>
                        <ng-template #findingVendorName> searching ... </ng-template>
                    </td>
                    <td>{{ file.expirationDate }}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-card>
