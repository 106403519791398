import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { VendorSetupRequest } from '../domain/vendor-setup-request';
import { Invoice } from '../api/models/invoice';
import { MPIAppService } from '../../services/mpiapp.service';
import { Message, MessageService } from 'primeng/api';
import { UntypedFormBuilder } from '@angular/forms';
import { saveAs as importedSaveAs } from 'file-saver';
import { InvoiceWithDateStrings } from '../view-invoices/view-invoices.component';
import { HttpErrorResponse } from '@angular/common/http';
import { RefundRequest } from '../api/models';
import { RefundRequestService } from 'src/services/refund-request.service';
import { httpErrorToast, httpSuccessToast } from 'src/helpers/http-toasts';

@Component({
    selector: 'app-export-approved-invoices',
    templateUrl: './export-approved-invoices.component.html',
    styleUrls: ['./export-approved-invoices.component.scss'],
    providers: [MessageService],
})
export class ExportApprovedInvoicesComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();
    errorMsgs: Message[] = [];

    invoices: InvoiceWithDateStrings[] = [];
    refunds: RefundRequest[] = [];
    selectedInvoice: Invoice;
    selectedRefund: RefundRequest;

    requests: VendorSetupRequest[];

    constructor(
        private mpiapp: MPIAppService,
        private refundService: RefundRequestService,
        private messageService: MessageService,
        private formBuilder: UntypedFormBuilder,
        public mpiApp: MPIAppService
    ) {}

    ngOnInit(): void {
        this.getInvoices();
        this.getRefunds();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    getInvoices(): void {
        // Show only approved invoices that aren't part of a payment batch and haven't previously been exported.
        const filter = {
            where: {
                and: [
                    { status: { eq: 'approved' } },
                    { paymentBatchID: { eq: null } },
                    { exportApprovedExportDate: { eq: null } },
                ],
            },
            order: ['submittedDate DESC'],
        };

        this.mpiapp
            .getInvoices(JSON.stringify(filter))
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (invoices: InvoiceWithDateStrings[]) => {
                    this.invoices = invoices;

                    invoices.forEach((invoice: InvoiceWithDateStrings) => {
                        const date = new Date(invoice.statusLastSet);
                        invoice.dateCreated = date.toLocaleString();
                    });
                },
                error: (err: HttpErrorResponse) => {
                    httpErrorToast(this.messageService, err);
                },
            });
    }

    getRefunds() {
        const filter = {
            where: {
                and: [{ status: { eq: 'approved' } }, { exportApprovedExportDate: { eq: null } }],
            },
            order: ['date DESC'],
        };

        this.refundService
            .getRefundRequests(JSON.stringify(filter))
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (data) => {
                    this.refunds = data;
                },
                error: (err: HttpErrorResponse) => {
                    httpErrorToast(this.messageService, err);
                },
            });
    }

    exportApprovedInvoices() {
        const invoiceIDs: string[] = [];

        for (let i = 0; i < this.invoices.length; i++) {
            const invoice = this.invoices[i];
            if (invoice.status === 'approved') {
                invoiceIDs.push(String(invoice.id));
                invoice.status = 'exported';
            }
        }

        console.log(invoiceIDs);

        if (invoiceIDs.length === 0) {
            this.messageService.add({
                severity: 'warning',
                summary: 'Nothing to Export',
                detail: 'There are no invoices available for export.',
            });

            return;
        }

        this.mpiapp
            .exportInvoices(invoiceIDs)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: any) => {
                    importedSaveAs(response, 'approved invoice export.csv');
                },
                error: (error: HttpErrorResponse) => {
                    httpErrorToast(this.messageService, error);
                },
            });
    }

    exportApprovedRefunds() {
        const refundIDs: string[] = [];

        for (const refund of this.refunds) {
            if (refund.status === 'approved') {
                refundIDs.push(String(refund.id));
                refund.status = 'exported';
            }
        }

        if (refundIDs.length === 0) {
            this.messageService.add({
                severity: 'warning',
                summary: 'Nothing to Export',
                detail: 'There are no refunds available for export.',
            });

            return;
        }

        console.table(refundIDs);

        this.refundService
            .exportRefunds(refundIDs)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (data) => {
                    importedSaveAs(data, 'approved refund export.csv');
                    httpSuccessToast(this.messageService, 'Refunds Exported');
                },
                error: (err: HttpErrorResponse) => {
                    httpErrorToast(this.messageService, err);
                },
            });
    }
}
