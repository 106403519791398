<p-toast></p-toast>
<p-card header="Vendors with Files Pending Approval" [style]="{ 'margin-top': '2em' }">
    <p-table
        [value]="vendorList"
        [paginator]="true"
        [rows]="10"
        dataKey="id"
        selectionMode="single"
        [(selection)]="selectedVendor"
        (onRowSelect)="selectVendor($event)">
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="name">Company <p-sortIcon field="name"></p-sortIcon></th>
                <th pSortableColumn="status">Status <p-sortIcon field="status"></p-sortIcon></th>
                <th pSortableColumn="fileNames">Filename(s) <p-sortIcon field="fileNames"></p-sortIcon></th>
                <th pSortableColumn="fileDates">File Date(s) <p-sortIcon field="fileDates"></p-sortIcon></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-vendor>
            <tr [pSelectableRow]="vendor">
                <td>
                    <h5>{{ vendor.name }}</h5>
                </td>
                <td>{{ vendor.status }}</td>
                <td>
                    <ul>
                        <li *ngFor="let filename of vendor.fileNames">{{ filename }}</li>
                    </ul>
                </td>
                <td>
                    <ul>
                        <li *ngFor="let filedate of vendor.fileDates">{{ filedate | date: 'short' }}</li>
                    </ul>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-card>
