import { Component, OnInit, OnDestroy } from '@angular/core';
import { IsLoadingService } from '@service-work/is-loading';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { finalize, takeUntil } from 'rxjs/operators';
import { ValidationService } from '../validation.service';
import { Message, MessageService } from 'primeng/api';
import { MPIAppService } from 'src/services/mpiapp.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.scss'],
    providers: [MessageService],
})
export class PasswordResetComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();
    isRequestFinished = false;
    isRequestSuccessful: boolean;

    msgs: Message[] = [];

    resetForm = this.formBuilder.group({
        username: ['', Validators.required],
    });

    constructor(
        private formBuilder: UntypedFormBuilder,
        private isLoadingService: IsLoadingService,
        private validationService: ValidationService,
        public mpiApp: MPIAppService
    ) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    isButtonLoading(id: string) {
        return this.isLoadingService.isLoading({ key: id });
    }

    submit(id: string) {
        this.isRequestFinished = false;
        this.isRequestSuccessful = false;

        /* Validate all fields first */
        if (!this.resetForm.valid) {
            this.validationService.validateAllFormFields(this.resetForm);
            this.isRequestFinished = true;
            return;
        }
        // send password recovery email
        const payload = this.resetForm.get('username').value;

        const response = this.isLoadingService.add(
            this.mpiApp
                .requestPasswordReset(payload)
                .pipe(
                    takeUntil(this.destroy$),
                    finalize(() => {
                        this.isRequestFinished = true;
                    })
                )
                .subscribe(
                    (data: any) => {
                        console.log(data);
                        // For security, only give notification that the request made it to the backend
                        // Any response means it made it
                        if (data.status === 'received') {
                            this.msgs = [
                                {
                                    severity: 'success',
                                    summary: 'Request Sent',
                                    detail: 'Check your email for the password reset link',
                                },
                            ];
                        } else if (String(data.status).includes('Attendant Portal')) {
                            this.msgs = [
                                {
                                    severity: 'warn',
                                    summary: '',
                                    detail: data.status,
                                },
                            ];
                        }
                        this.isRequestSuccessful = true;
                    },
                    (error) => {
                        this.msgs = [
                            {
                                severity: 'error',
                                summary: 'Request Failed',
                                detail: 'Please try submission again',
                            },
                        ];
                        this.isRequestSuccessful = false;
                        console.error(error);
                    },
                    () => {
                        // Only on success
                    }
                ),
            { key: id }
        );
    }
}
