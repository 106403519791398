<div class="login-body" style="background:white;padding-top:25px;background-image:none;">
    <div id="login-wrapper">

        <div id="login-container">
            <span class="title">MPI Vendor Portal Sign-up</span>

            <div *ngIf="isRequestFinished; then entryForm else spinner">

            </div>
        </div>

        <div class="login-footer">MPI Vendor Portal</div>
    </div>
</div>

<ng-template #entryForm>
    <div *ngIf="isInviteValid; then inviteForm else inviteNotValid">
    </div>
</ng-template>

<ng-template #inviteForm>
    <form [formGroup]="inviteAcceptance">
    <div class="p-grid p-fluid">
        <div class="p-col-12" *ngIf="!isPostSuccessful">
            You have been invited to create an account on the {{siteName}}. Please choose a username for your account and password.
        </div>
        <div class="p-col-12" *ngIf="!isPostSuccessful">
            <input type="text" autocomplete="off" pInputText placeholder="Username" formControlName="username"/>
            <app-control-messages [control]="inviteAcceptance.get('username')">
            </app-control-messages>
        </div>
        <div class="p-col-12" *ngIf="!isPostSuccessful">
            <input type="password" autocomplete="off" pInputText placeholder="Password" formControlName="password"/>
            <app-control-messages [control]="inviteAcceptance.get('password')">
            </app-control-messages>
        </div>
<!--        <div class="p-col-12 p-md-6">-->
<!--            <span class="remember-me">Remember Me</span>-->
<!--            <input type="checkbox">-->
<!--        </div>-->
<!--        <div class="p-col-12 p-md-6">-->
<!--            <a href="#">Forgot Password</a>-->
<!--        </div>-->
        <div class="p-col-12">
            <p-button label="Complete Registration" class="p-mr-2 p-mb-2"
                      [loading]="isButtonLoading('completeInvite')"
                      (onClick)="completeInvite('completeInvite')"
                      *ngIf="!isPostSuccessful"
                      [disabled]="isPostSuccessful"></p-button>

            <div *ngIf="isPostFinished">
                <div *ngIf="isPostSuccessful; else requestFailed">
                    <strong class="success-text">Invitation successfully accepted. You can now login using the specified username and password.!</strong>
                </div>

                <ng-template #requestFailed>
                    <strong class="red-text">
                        {{ failureMessage }}
                    </strong>
                </ng-template>
            </div>
        </div>
    </div>
    </form>
</ng-template>

<ng-template #inviteNotValid>
    <div class="p-grid p-fluid">
        <div class="p-col-12">
            The invitation to {{siteName}} is no longer valid.
        </div>
    </div>
</ng-template>


<ng-template #spinner>
    <p-progressSpinner></p-progressSpinner>
</ng-template>
