import { Vendor } from './../../api/models/vendor';
import { MessageService } from 'primeng/api';
import { MPIAppService } from 'src/services/mpiapp.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { File } from 'src/app/api/models';

export interface FileWithVendor extends File {
    vendorName?: string;
}

@Component({
    selector: 'app-expired-documents',
    templateUrl: './expired-documents.component.html',
    styleUrls: ['./expired-documents.component.scss'],
    providers: [MessageService],
})
export class ExpiredDocumentsComponent implements OnInit {
    private subs: Subscription[];

    expiredDocuments: FileWithVendor[];

    yearsForRetention: number;

    displayExpiredDocuments = false;
    requestInProgress = false;

    constructor(private mpiApp: MPIAppService, private messageService: MessageService) {}

    ngOnInit(): void {
        this.subs = [];

        this.subs.push(
            this.mpiApp.getSetting('numYearsExpiredRetention').subscribe({
                next: (data) => {
                    this.yearsForRetention = parseInt(data.value);
                },
            })
        );
    }

    showExpiredDocuments() {
        this.displayExpiredDocuments = true;
        this.requestInProgress = true;
        const toRemoveDate = DateTime.now().minus({ years: this.yearsForRetention }).toISO();
        let filter = {
            where: {
                and: [{ expirationDate: { lt: toRemoveDate } }, { or: [{ docType: 'insurance' }, { docType: 'w9' }] }],
            },
            fields: { content: false },
        };
        this.subs.push(
            this.mpiApp.getAllFiles(JSON.stringify(filter)).subscribe({
                next: (data) => {
                    this.expiredDocuments = data;
                    const vendorList: number[] = [];

                    for (const doc of this.expiredDocuments) {
                        if (!vendorList.includes(doc.docTypeID)) {
                            vendorList.push(doc.docTypeID);
                        }
                    }

                    vendorList.sort((a, b) => a - b);

                    let vendorFilter = {};

                    this.subs.push(
                        this.mpiApp.getVendors().subscribe({
                            next: (data: Vendor[]) => {
                                const vendors: Vendor[] = data;

                                this.expiredDocuments.forEach((doc) => {
                                    let vendor = vendors.find((vend) => vend.vendorID === doc.docTypeID);

                                    if (vendor) {
                                        doc.vendorName = vendor.name;
                                    } else {
                                        doc.vendorName = 'No Name Found';
                                    }
                                });
                            },
                            error: (err: HttpErrorResponse) => {
                                console.error(err);
                                this.messageService.add({
                                    severity: 'error',
                                    summary: 'Failed to get Vendors',
                                    detail: 'Error ' + err.status + ': ' + err.error.error.message,
                                });
                                this.requestInProgress = false;
                            },
                        })
                    );
                },
                error: (err: HttpErrorResponse) => {
                    console.error(err);
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Failed to get Expired Documents',
                        detail: 'Error ' + err.status + ': ' + err.error.error.message,
                    });
                    this.requestInProgress = false;
                },
                complete: () => {
                    this.requestInProgress = false;
                },
            })
        );
    }

    removeExpiredDocuments() {
        this.displayExpiredDocuments = false;
        this.requestInProgress = true;

        for (const doc of this.expiredDocuments) {
            this.subs.push(
                this.mpiApp.deleteFile(doc.id).subscribe({
                    error: (err: HttpErrorResponse) => {
                        console.error(err);
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Failed to Delete Document',
                            detail: 'Error ' + err.status + ': ' + err.error.error.message,
                        });
                    },
                    complete: () => {
                        console.log('Deleted Document ', doc.id);
                    },
                })
            );
        }

        this.requestInProgress = false;
    }
}
