import { Component, OnInit } from '@angular/core';
import {SessionService} from '../../session.service';
import {MPIAppService} from '../../services/mpiapp.service';
import {DialogService} from 'primeng/dynamicdialog';
import {ChangePurchaseOrderComponent} from '../change-purchase-order/change-purchase-order.component';
import {PurchaseOrder} from '../api/models/purchase-order';
import {MPIRecurringService} from '../../services/mpirecurring.service';
import {RecurringAttributes} from '../api/models/mpi-custom-models';
import {DateTime} from 'luxon';

@Component({
  selector: 'app-devjab',
  templateUrl: './devjab.component.html',
  styleUrls: ['./devjab.component.scss'],
    providers: [DialogService]
})
export class DEVJABComponent implements OnInit {

  constructor(
      public mpiApp: MPIAppService,
      private session: SessionService,
      public dialogService: DialogService,
      public recurringService: MPIRecurringService,
  ) { }

  ngOnInit(): void {
      // this.session.checkSession();
      // this.mpiApp.currentRoles.push('accounting');

      const startDateString = '2022-08-07 00:00:00';
      const endDateString = '2024-05-05 00:00:00';

      //
      // Test the recurring by end date criteria
      //

      const monthly: RecurringAttributes = {
          frequency: 'monthly',
          endCondition: 'date',
          startDate: DateTime.fromFormat(startDateString, 'yyyy-MM-dd HH:mm:ss', { zone: 'UTC' }),
          endDate: DateTime.fromFormat(endDateString, 'yyyy-MM-dd HH:mm:ss', { zone: 'UTC' }),
          occurrenceCost: 10.00,
      };

      const monthlySchedule = this.recurringService.calculateSchedule(monthly);
      // console.log(monthly);
      // console.log(monthlySchedule);

      const semiMonthly = monthly;
      semiMonthly.frequency = 'semiweekly';
      const semiMonthlySchedule = this.recurringService.calculateSchedule(semiMonthly);
      // console.log(semiMonthly);
      // console.log(semiMonthlySchedule);

      const semimonthly = monthly;
      semimonthly.frequency = 'semimonthly';
      const semimonthlySchedule = this.recurringService.calculateSchedule(semimonthly);
      // console.log(semimonthly);
      // console.log(semimonthlySchedule);

      const weekly = monthly;
      weekly.frequency = 'weekly';
      const semiWeeklySchedule = this.recurringService.calculateSchedule(weekly);
      // console.log(semiWeekly);
      // console.log(semiWeeklySchedule);

      const quarterly = monthly;
      quarterly.frequency = 'quarterly';
      const quarterlySchedule = this.recurringService.calculateSchedule(quarterly);
      // console.log(quarterly);
      // console.log(quarterlySchedule);

      const annually = monthly;
      annually.frequency = 'annually';
      const annuallySchedule = this.recurringService.calculateSchedule(annually);
      // console.log(annually);
      // console.log(annuallySchedule);

      //
      // Test the reccuring by number of occurences
      //

      const scheduleByCountMonthly: RecurringAttributes = {
          frequency: 'monthly',
          endCondition: 'occurrences',
          startDate: DateTime.fromFormat(startDateString, 'yyyy-MM-dd HH:mm:ss', { zone: 'UTC' }),
          endCount: 12,
          occurrenceCost: 10,
      };

      const monthlyByCountSchedule = this.recurringService.calculateSchedule(scheduleByCountMonthly);
      // console.log(monthlyByCountSchedule);
      // console.log(scheduleByCountMonthly);

      const semiByCountMonthly = scheduleByCountMonthly;
      semiByCountMonthly.frequency = 'semiweekly';
      const semiMonthlyScheduleByCount = this.recurringService.calculateSchedule(semiByCountMonthly);
      // console.log(semiByCountMonthly);
      // console.log(semiMonthlyScheduleByCount);

      const semimonthlyByCount = scheduleByCountMonthly;
      semimonthlyByCount.frequency = 'semimonthly';
      const semimonthlyScheduleByCount = this.recurringService.calculateSchedule(semimonthlyByCount);
      // console.log(semimonthlyByCount);
      // console.log(semimonthlyScheduleByCount);

      const weeklyByCount = scheduleByCountMonthly;
      weeklyByCount.frequency = 'weekly';
      const semiWeeklyScheduleByCount = this.recurringService.calculateSchedule(weeklyByCount);
      // console.log(weeklyByCount);
      // console.log(semiWeeklyScheduleByCount);

      const quarterlyByCount = scheduleByCountMonthly;
      quarterlyByCount.frequency = 'quarterly';
      const quarterlyScheduleByCount = this.recurringService.calculateSchedule(quarterlyByCount);
      // console.log(quarterlyByCount);
      // console.log(quarterlyScheduleByCount);

      const annuallyByCount = scheduleByCountMonthly;
      annuallyByCount.frequency = 'annually';
      const annuallyScheduleByCount = this.recurringService.calculateSchedule(annuallyByCount);
      // console.log(annuallyByCount);
      // console.log(annuallyScheduleByCount);


  }

    show() {
        const ref = this.dialogService.open(ChangePurchaseOrderComponent, {
            data: {
                poID: '99',
            }
        });
    }

    getSinglePO(id: string) {

        const filter = {
            where: {
                purchaseOrderID: id,
            },
        };

        this.mpiApp.getPurchaseOrders(JSON.stringify(filter)).subscribe((data: PurchaseOrder[]) => {
            console.log(data);
        });

    }

    async getOrCreateReimbursementPO() {

        const vendorRec = await this.mpiApp.getOrCreateReimbursementVendor().toPromise();
        console.log(vendorRec);

    }

}
