import { IsLoadingService } from '@service-work/is-loading';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { MPIAppService } from 'src/services/mpiapp.service';
import { File, Vendor } from '../api/models';
import { Router } from '@angular/router';

export interface FilesWaitingForApproval {
    id: number;
    name?: string;
    status?: string;
    fileNames?: string[];
    fileDates?: string[];
}

@Component({
    selector: 'app-files-waiting-approval',
    templateUrl: './files-waiting-approval.component.html',
    styleUrls: ['./files-waiting-approval.component.scss'],
    providers: [MessageService],
})
export class FilesWaitingApprovalComponent implements OnInit, OnDestroy {
    private subs: Subscription[];

    fileList: File[];
    vendorList: FilesWaitingForApproval[];
    selectedVendor: FilesWaitingForApproval;

    constructor(
        private mpiApp: MPIAppService,
        private messageService: MessageService,
        private loadingService: IsLoadingService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.subs = [];
        this.vendorList = [];
        let filter = {
            where: {
                and: [
                    {
                        verificationStatus: 'pending',
                    },
                    {
                        or: [
                            {
                                docType: 'w9',
                            },
                            {
                                docType: 'insurance',
                            },
                        ],
                    },
                ],
            },
            fields: {
                content: false,
            },
        };
        this.loadingService.add({ key: 'getFilesAndVendors' });
        this.subs.push(
            this.mpiApp.getAllFiles(JSON.stringify(filter)).subscribe({
                next: (data) => {
                    console.log(data);
                    this.fileList = data;

                    for (const doc of this.fileList) {
                        let vend = this.vendorList.findIndex((vendor) => {
                            return vendor.id === doc.docTypeID;
                        });

                        if (vend >= 0) {
                            // The vendor is already in the list
                            if (this.vendorList[vend].fileNames) {
                                this.vendorList[vend].fileNames.push(doc.originalFileName);
                            } else {
                                this.vendorList[vend].fileNames = [doc.originalFileName];
                            }

                            if (this.vendorList[vend].fileDates) {
                                this.vendorList[vend].fileDates.push(doc.expirationDate);
                            } else {
                                this.vendorList[vend].fileDates = [doc.expirationDate];
                            }
                        } else if (doc.docTypeID !== 0) {
                            // The vendor is not in the list, so add it
                            let newVendor: FilesWaitingForApproval = {
                                id: doc.docTypeID,
                                status: 'pending',
                                fileNames: [doc.originalFileName],
                                fileDates: [doc.expirationDate],
                            };

                            this.vendorList.push(newVendor);
                        }
                    }

                    this.subs.push(
                        this.mpiApp.getVendors().subscribe({
                            next: (data: Vendor[]) => {
                                const vendors: Vendor[] = data;

                                this.vendorList.forEach((vendorItem) => {
                                    let vendor = vendors.find((vend) => vend.vendorID === vendorItem.id);

                                    if (vendor) {
                                        vendorItem.name = vendor.name;
                                    } else {
                                        vendorItem.name = 'No Name Found';
                                    }
                                });
                            },
                            error: (err: HttpErrorResponse) => {
                                console.error(err);
                                this.messageService.add({
                                    severity: 'error',
                                    summary: 'Failed to get Vendors',
                                    detail: 'Error ' + err.status + ': ' + err.error.error.message,
                                });
                            },
                        })
                    );
                },
                error: (err: HttpErrorResponse) => {
                    console.error(err);
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Failed to get Files',
                        detail: 'Error ' + err.status + ': ' + err.error.error.message,
                    });
                    this.loadingService.remove({ key: 'getFilesAndVendors' });
                },
                complete: () => {
                    this.loadingService.remove({ key: 'getFilesAndVendors' });
                },
            })
        );
    }

    ngOnDestroy(): void {
        this.subs.forEach((sub) => {
            sub.unsubscribe();
        });
        for (let i = 0; i < this.subs.length; i++) {
            this.subs.pop();
        }
    }

    selectVendor(event: any) {
        this.router.navigate(['/accounting/vendor/approval'], {
            queryParams: { vendor: event.data.id },
        });
    }
}
