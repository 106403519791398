import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// import { environment } from '@environments/environment';
// import { CurrentUser, CurrentUserAdapter } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    // private currentUserSubject: BehaviorSubject<CurrentUser>;
    // public currentUser: Observable<CurrentUser>;
    //
    // private currentAuthLevelSubject: BehaviorSubject<String>;
    // public currentAuthLevel: Observable<String>;
    //
    // public consumerUrl: string;
    // public authData: string;
    //
    // // An indicator to determine how well the user was authenticated:
    // // anonymous - No authentication
    // // partial - Minimal auth for the quick pay component
    // // full - Fully authenticated and logged in
    // public level = new BehaviorSubject<String>('anonymous');

    constructor(
        private httpService: HttpClient,
        private router: Router,
        // private currentUserAdapter: CurrentUserAdapter
    ) {
        // this.currentUserSubject = new BehaviorSubject<CurrentUser>(JSON.parse(sessionStorage.getItem('currentUser')));
        // this.currentUser = this.currentUserSubject.asObservable();
        //
        // this.currentAuthLevelSubject = new BehaviorSubject<String>('anonymous');
        // this.currentAuthLevel = this.currentAuthLevelSubject.asObservable();
    }

    // public setLevel(newLevel: String): void {
    //     this.currentAuthLevelSubject.next(newLevel);
    //     this.level.next(newLevel);
    // }
    //
    // public getLevel(): String {
    //     return this.level.getValue();
    // }
    //

    // Development stub program
    public get currentUserValue() {
         return null;
    }

    // Todo implement
    // public get currentUserValue(): CurrentUser {
    //      return this.currentUserSubject.value;
    // }

    // /*
    // There is no resource for authentication on the API by default.
    // We need to modify this so that it just sets user.authdata to the provided strings and try it.
    // */
    // authenticate(username: string, password: string) {
    //     // Create a new header containing the credentials, backend will reply with 401 if unauthorized
    //     const headers = new HttpHeaders().set('Authorization', 'Basic ' + window.btoa(username + ':' + password));
    //     // return this.http.post<any>(`${environment.apiUrl}/users/authenticate`, { username, password })
    //     return this.httpService.get<any>(`${environment.apiUrl}`, { headers: headers })
    //         .pipe(map(apiData => {
    //             // We should be authenticated now
    //
    //             // This is too soon to set the auth level or the menu appears while the app is still loading
    //             // this.setLevel('full');
    //
    //             console.log('authentication.service: Login Success');
    //             apiData.authdata = window.btoa(username + ':' + password);
    //             // Make another call to the consumer endpoint
    //             if (apiData._links.consumer) {
    //                 console.log('authentication.service:authenticate(): ', apiData._links.consumer.href);
    //                 return apiData;
    //             } else {
    //                 console.error('authentication.service:authenticate(): Error has occurred');
    //                 return false;
    //             }
    //         }));
    // }
    //
    // postMetric() {
    //
    //     // Signal to the API that our login is valid
    //     let payload = {
    //         id : "DebtorLoginValid",
    //     }
    //
    //     // We don't need to send the entire log object because this message id is restricted by the API which can only
    //     // set the msg and vars.
    //     /*
    //     let payload = {
    //         msg : "Debtor Login valid",
    //         id : "DebtorLoginValid",
    //         vars : {
    //             userid: "abc",
    //             srcip: '127.0.0.1'
    //         }
    //     }
    //     */
    //
    //     return this.httpService.post<any>(`${environment.apiUrl}/metric`, payload);
    // }
    //
    // getConsumer(auth: any, username: string, password: string) {
    //     const headers = new HttpHeaders().set('Authorization', 'Basic ' + window.btoa(username + ':' + password));
    //     this.consumerUrl = auth._links.consumer.href;
    //     // alert('consumerUrl: ' + this.consumerUrl);
    //     /*
    //         map((data: any[]) => data.map((item: any[]) => this.userAdapter.adapt(item))),
    //         map((data: User[]) => this.userAdapter.adapt(data)),
    //         public ncbFileNumber: string,
    //         public lastFourFile: number,
    //         public nameGuarantor: string,
    //         public userName: string,
    //         public password: string,
    //         public currentEmail: string,
    //         public lastFourSSN: string,
    //         public comPreference: string, // Communication Preference
    //         public offers: Offer[],
    //         public authdata: string
    //     */
    //     return this.httpService.get<any>(`${this.consumerUrl}`, { headers: headers })
    //         .pipe(map((consumer: CurrentUser) => {
    //                 // Add some auth-related data to the consumer object
    //                 consumer.authdata = auth.authdata;
    //                 consumer.userName = username;
    //                 // Run the consumer data through the adapter
    //                 consumer = this.currentUserAdapter.adapt(consumer);
    //                 // console.log('authentication.service:consumer data: ', consumer);
    //                 sessionStorage.setItem('currentUser', JSON.stringify(consumer));
    //                 this.currentUserSubject.next(consumer);
    //                 return consumer;
    //             })
    //         );
    // }
    //
    // getConsumerUsingCookie(consumerURL: string) {
    //     this.consumerUrl = consumerURL;
    //
    //     return this.httpService.get<any>(`${this.consumerUrl}`)
    //         .pipe(map((consumer: CurrentUser) => {
    //
    //                 // Add some auth-related data to the consumer object
    //
    //                 //consumer.authdata = auth.authdata;
    //                 // consumer.userName = username;
    //                 // Run the consumer data through the adapter
    //                 consumer = this.currentUserAdapter.adapt(consumer);
    //                 // console.log('authentication.service:consumer data: ', consumer);
    //                 sessionStorage.setItem('currentUser', JSON.stringify(consumer));
    //                 this.currentUserSubject.next(consumer);
    //                 return consumer;
    //             })
    //         );
    // }
    //
    // /*
    //     Function to post the verification code and email from the account-verify form.
    //     PATCH method to /account/<id>
    //     {"emailVerificationCode" : <code>}
    // */
    // verifyCode(data) {
    //     // https://vc-dev.prog.ncbi.com/auth/verification?code=P7KU4K
    //     return this.httpService.patch(`${environment.apiUrl}/account/${data.accountID}`, JSON.stringify(data));
    //     /*
    //             .subscribe(data => {
    //                 // If API is providing a link to the consumer, then log them in.
    //                 if (data.href) {
    //                     this.getConsumer(data.href, username, password).subscribe(consumer => {
    //                         console.log('authentication.service:consumer subscribe: ', consumer);
    //                         return consumer;
    //                     });
    //                 } else {
    //                     return this.currentUser;
    //                 }
    //     */
    // }
    //
    // resetPassword(data) {
    //     return this.httpService.post(`${environment.apiUrl}/account?reset=true`, JSON.stringify(data));
    // }
    //
    // login(username: string, password: string) {
    //     // Authenticate with the API
    //     return this.authenticate(username, password).subscribe(data => {
    //         // alert('Hitting authentication.service->login->authenticate');
    //         if (data.href) {
    //             this.getConsumer(data.href, username, password).subscribe(consumer => {
    //                 console.log('authentication.service:consumer subscribe: ', consumer);
    //                 return consumer;
    //             });
    //         } else {
    //             return this.currentUser;
    //         }
    //     });
    // }
    //
    //
    // /*
    // Called when a session needs to be logged out. Some error services might also call this on network error.
    // */
    // logout(doReload: boolean = true) {
    //     // remove user from local storage to log user out
    //     sessionStorage.removeItem('currentUser');
    //     // Temporary catch to stop the loop that occurs when there's a network error
    //     if (this.getLevel() !== 'anonymous') {
    //         this.httpService.delete<any>(`${environment.apiUrl}/session`).subscribe();
    //         this.setLevel('anonymous');
    //
    //         // Does the caller want the screen to completely reload? Doing so may clear any user messages that were
    //         // displayed as a result of a communication error.
    //         if (doReload) {
    //             window.location.reload();
    //         }
    //     }
    //     // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    //     this.currentUserSubject.next(null);
    // }
}
