<p-toast></p-toast>
<div class="dashboard p-grid p-fluid">
    <div class="p-col-12 p-md-12">
        <div class="card">
            <h1 style="font-size: 16px">Submitted Invoices</h1>
            <p-table #invoiceTable selectionMode="single" [value]="invoices" [globalFilterFields]="[
                    'invoiceId',
                    'submittedDate',
                    'status',
                    'amount']" [paginator]="true" [rows]="10" [lazy]="true" [lazyLoadOnInit]="false"
                [loading]="loadingInvoices$ | async" [totalRecords]="totalInvoices" [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} invoices"
                [(selection)]="selectedInvoice"
                (onLazyLoad)="getInvoices($event)">
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="invoiceId">
                            Invoice #
                            <p-sortIcon field="submittedDate"></p-sortIcon>
                        </th>
                        <th pSortableColumn="submittedDate">
                            Date
                            <p-sortIcon field="submittedDate"></p-sortIcon>
                        </th>
                        <th pSortableColumn="status">
                            Status
                            <p-sortIcon field="status"></p-sortIcon>
                        </th>
                        <th pSortableColumn="amount">
                            Amount
                            <p-sortIcon field="amount"></p-sortIcon>
                        </th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-invoice>
                    <tr [pSelectableRow]="invoice" (click)="displayTimeline(invoice)">
                        <td>{{ invoice.invoiceId }}</td>
                        <td>{{ invoice.submittedDate | date: 'medium' }}</td>
                        <td>{{ invoice.status }}</td>
                        <td>{{ invoice.amount | currency: 'USD' }}</td>
                        <td style="text-align: center">
                            <button pButton type="button" icon="pi pi-search"></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<div class="p-grid">
    <div class="p-col-12">
        <div class="card">
            <h4>Invoice Payment Process</h4>
            <p-timeline [value]="eventTimeline" align="alternate" styleClass="customized-timeline">
                <ng-template pTemplate="marker" let-event>
                    <span class="custom-marker p-shadow-2" [style.backgroundColor]="event.color">
                        <i [ngClass]="event.icon"></i>
                    </span>
                </ng-template>
                <ng-template pTemplate="content" let-event>
                    <p-card [header]="event.status" [subheader]="event.date | date: 'long'">
                        {{ event.description }}
                    </p-card>
                </ng-template>
            </p-timeline>
        </div>
    </div>
</div>