import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MPIAppService } from 'src/services/mpiapp.service';
import { Vendor } from '../api/models/vendor';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';

export interface VendorSortable extends Vendor {
    locationsNameListString: string;
}

@Component({
    selector: 'app-vendor-approval',
    templateUrl: './vendor-approval.component.html',
    styleUrls: ['./vendor-approval.component.scss'],
    providers: [MessageService],
})
export class VendorApprovalComponent implements OnInit, OnDestroy {
    private subs: Subscription[];
    requests: Vendor[];
    selectedRequest: Vendor;
    isSelected: boolean = false;
    vendorParam: number;

    filesApproved: boolean = true;

    constructor(private mpiApp: MPIAppService, private messageService: MessageService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.subs = [];
        this.selectedRequest = {
            name: '',
        };
        this.subs.push(
            this.route.queryParams.subscribe((params) => {
                // Check to see if a vendor ID is specified in the URL
                if (params.vendor) {
                    // There will be a vendor to show the user after the table loads
                    this.vendorParam = parseInt(params.vendor);
                }
            })
        );

        this.subs.push(
            this.mpiApp.getVendors().subscribe({
                next: (data: Vendor[]) => {
                    this.requests = data;
                    this.createStaticLocationNameList();
                },
                error: (err: HttpErrorResponse) => {
                    const errMsg: string = err.error.error.message ? err.error.error.message : 'No Message';
                    console.error(err);
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Failed to get Vendors',
                        detail: 'Error ' + err.status + ': ' + errMsg,
                    });
                },
                complete: () => {
                    if (this.vendorParam) {
                        this.selectedRequest = this.requests.find((req) => req.vendorID === this.vendorParam);
                        this.onRowSelect({});
                    }
                },
            })
        );
    }
    ngOnDestroy(): void {
        this.subs.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    createStaticLocationNameList() {
        this.requests.forEach((vendor: VendorSortable) => {
            vendor.locationsNameListString = '';
            vendor.locationsNameListString = vendor.locationsNameListVar.join(',');
        });
    }

    onRowSelect(event) {
        this.isSelected = true;
    }

    onRowUnselect(event) {
        this.isSelected = false;
        this.selectedRequest = {
            name: '',
        };
    }

    handleApprove(event) {
        const approve = { status: 'verified' };
        if (this.selectedRequest.vendorID) {
            this.subs.push(
                this.mpiApp.updateVendor(this.selectedRequest.vendorID.toString(), approve).subscribe(
                    (data) => {
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Verified',
                            detail: 'Vendor successfully approved',
                        });
                    },
                    (error) => {
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: 'Vendor approval failed',
                        });
                    }
                )
            );
        }
    }

    handleDeny(event) {
        const approve = { status: 'verification-denied' };
        if (this.selectedRequest.vendorID) {
            this.subs.push(
                this.mpiApp.updateVendor(this.selectedRequest.vendorID.toString(), approve).subscribe(
                    (data) => {
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Denied',
                            detail: 'Vendor successfully denied',
                        });
                    },
                    (error) => {
                        console.log(error);
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: 'Vendor denial failed',
                        });
                    }
                )
            );
        }
    }

    /**
     * Consume file approved event from child view-vendor-info component
     *
     * @param event boolean for whether files all files are approved or denied
     */
    markFilesApproved(event) {
        this.filesApproved = event;
    }
}
