import { HttpErrorResponse } from '@angular/common/http';
import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
    OnDestroy,
    ElementRef,
    ViewChild,
    AfterViewInit,
} from '@angular/core';
import { Message, MessageService } from 'primeng/api';
import { UntypedFormBuilder, UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { MPIAppService } from '../../services/mpiapp.service';
import { FilePartial } from '../api/models/file-partial';
import { Vendor } from '../api/models/vendor';
import { environment } from '../../environments/environment';
import { finalize } from 'rxjs/operators';
import { ValidationService } from '../validation.service';
import { SessionService } from 'src/session.service';
import { LoginModel } from '../models/login.model';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import states from './states.json';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-view-vendor-info',
    templateUrl: './view-vendor-info.component.html',
    styleUrls: ['./view-vendor-info.component.scss'],
    providers: [MessageService],
})
export class ViewVendorInfoComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    @Input() id: number;
    @Input() componentMode = 'view';
    @Output() filesApprovedEvent = new EventEmitter<boolean>();
    @ViewChild('insuranceUploadContainer') insuranceUploadContainer: ElementRef;

    private subs: Subscription[];

    errorMsgs: Message[] = [];

    requestInProgress: boolean = false;
    allFilesApproved = new BehaviorSubject<boolean>(true);
    allFilesApproved$: Observable<boolean> = this.allFilesApproved;
    accountPending: boolean;
    approvedFilesSubscription: Subscription;

    states: any[];
    insuranceFilesExpirationValid = new BehaviorSubject<boolean>(false);

    selectedState = '';
    insuranceFiles: FilePartial[];
    w9Files: FilePartial[];

    env = environment;

    user_profile: any;
    user_role: string;

    uploadedInsuranceFiles: any[] = [];
    uploadedW9Files: any[] = [];
    uploadURL = '';
    vendorName = '';

    // This will indicate whether the component can manage file approvals
    fileVerificationMode = false;

    // TODO: integrate this boolean into the vendor API somehow so I can dynamically
    // tell when insurance info is optional or not. - PAS
    optionalInsuranceInfo: boolean = false;

    form: UntypedFormGroup;

    insuranceForm: UntypedFormGroup;

    /**
     * Use with files array prototype every to check if a file is approved
     * deleted or archived documents can also be considered 'approved' so they can be skipped
     *
     * @param file File to check verification status of
     * @returns boolean whether file's verificationStatus property equals approved
     */
    fileReviewed = (file: FilePartial) => {
        let result = false;

        // Reviews file to ensure it is not pending or expired
        if (
            file.verificationStatus === 'approved' ||
            file.verificationStatus === 'archived' ||
            file.verificationStatus === 'deleted' ||
            file.verificationStatus === 'denied' ||
            file.verificationStatus === 'expired'
        ) {
            result = true;
        } else {
            result = false;
        }

        // console.log(`Checking status ${file.verificationStatus} result ${result}`, file);

        return result;
    };

    constructor(
        private messageService: MessageService,
        private formBuilder: UntypedFormBuilder,
        public mpiApp: MPIAppService,
        private validationService: ValidationService,
        private session: SessionService
    ) {
        this.subs = [];
    }

    ngAfterViewInit() {
        // This works but is added inside div
        // this.insuranceUploadContainer.nativeElement.insertAdjacentHTML('beforeend','<p>Must Select Date</p>');
        // This works and adds it as a sibbling next to the div
        // this.insuranceUploadContainer.nativeElement.insertAdjacentHTML('afterend', '<div class="float-expiration-warning">Must Select Date</div>');
    }

    ngOnInit(): void {
        this.user_profile = this.session.getUserProfile();
        if (this.user_profile?.activeRole) {
            // Show the component based on the users activeRole
            this.user_role = this.user_profile.activeRole;
        }

        this.form = this.formBuilder.group(
            {
                accountContactFirstName: ['', Validators.required],
                accountContactLastName: ['', Validators.required],
                accountContactPhone: [
                    '',
                    [
                        Validators.required,
                        Validators.minLength(10),
                        Validators.maxLength(10),
                        Validators.pattern('^[0-9]*$'),
                    ],
                ],
                accountContactEmail: ['', [Validators.required, Validators.email]],
                billingContactPhone: [
                    '',
                    [
                        Validators.required,
                        Validators.minLength(10),
                        Validators.maxLength(10),
                        Validators.pattern('^[0-9]*$'),
                    ],
                ],
                billingContactEmail: ['', [Validators.required, Validators.email]],
                billingAddress1: ['', Validators.required],
                billingAddress2: [''],
                billingCity: ['', Validators.required],
                billingState: ['', [Validators.required, this.validationService.stateValidator()]],
                billingZip: [
                    '',
                    [
                        Validators.required,
                        Validators.minLength(5),
                        Validators.maxLength(5),
                        Validators.pattern('^[0-9]*$'),
                    ],
                ],
                insuranceDocumentRequired: [false],
                name: [{ value: '', disabled: this.componentMode !== 'accounting' }, [Validators.required]],
            },
            { updateOn: 'blur' }
        );

        this.insuranceForm = this.formBuilder.group({
            insuranceExpiration: ['', [this.validationService.expirationDateValidator()]],
        });

        const profile: LoginModel = this.session.getUserProfile();
        if (profile?.role) {
            if (this.mpiApp.canPerformRole('accounting')) {
                this.fileVerificationMode = true;
            }
        }

        this.states = states;

        this.uploadURL = this.mpiApp.getURL('fileuploads');

        /*
        if (environment.devModeActive) {
            this.form.controls['insuranceExpiration'].setValue('12/31/22');
        }
        */

        this.approvedFilesSubscription = this.allFilesApproved.subscribe((value: boolean) => {
            this.filesApprovedEvent.emit(value);
        });

        if (this.componentMode === 'accounting') {
            // When in the accounting mode we can also show these fields for use and updating
            this.form.addControl('defaultGLCode', new UntypedFormControl('', Validators.required));
            this.form.addControl('accountingSystemVendorID', new UntypedFormControl('', Validators.required));
            // this.form.addControl('requireInsuranceInfo', new FormControl(false));
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        // OnChanges hook runs before OnInit
        // thus fetch changes if we're receiving new values or if it's the component loading for the first time
        if ((changes.id && changes.id.currentValue) || changes.isFirstChange) {
            this.fetchInfo(this.id.toString());
        }
    }

    ngOnDestroy() {
        this.subs.forEach((sub) => {
            sub.unsubscribe();
        });
        for (let i = 0; i < this.subs.length; i++) {
            this.subs.pop();
        }
        // unsubscribe to our behaviorsubject to avoid memory leaks with subscriber list
        if (this.approvedFilesSubscription) {
            this.approvedFilesSubscription.unsubscribe();
        }
    }

    get isInsuranceRequired(): boolean {
        return this.form.controls['insuranceDocumentRequired'].value as boolean;
    }

    /**
     * Fetch all info to be displayed for the vendor
     *
     * Called by onChanges initially and then again whenever input ID is changed
     * @param id VendorID
     */
    fetchInfo(id: string) {
        this.mpiApp.getVendors(id).subscribe(
            (data: Vendor) => {
                for (const field in this.form.controls) {
                    if (field === 'billingState') {
                        this.form.controls[field].setValue({ code: data[field] });
                    } else {
                        this.form.controls[field].setValue(data[field]);
                    }
                }

                // file expiration date won't change its status until the input field has a change
                // we want it to be valid if a valid expiration date is pulled from the database
                // thus validate now and update
                this.insuranceForm.controls['insuranceExpiration'].setValue(data['insuranceExpiration']);
                if (this.insuranceForm.get('insuranceExpiration').valid) {
                    this.insuranceFilesExpirationValid.next(true);
                }

                // store vendor name so that blank Vendor model can fill required name property
                this.vendorName = data.name;

                // vendor account is pending if account as a whole has not been approved
                this.accountPending = data.status !== 'verified';
            },
            (error) => {
                console.log(error);
            }
        );

        this.mpiApp.getVendorSpecificFiles(id, 'insurance').subscribe(
            (data: FilePartial[]) => {
                this.insuranceFiles = data;
                let convertDate: DateTime;

                this.insuranceFiles.forEach((insFile) => {
                    convertDate = DateTime.fromISO(insFile.expirationDate);
                    insFile.expirationDate = convertDate.toLocaleString(DateTime.DATE_SHORT);
                });

                // publish new value if status of all files being approved has changed

                // Check if any files are pending, return false if so
                const filesReviewed: boolean = data.every(this.fileReviewed);
                // Check if there is at least one approved insurance file
                const anyApprovedFiles: boolean =
                    data.find((file) => {
                        return file.verificationStatus === 'approved';
                    }) !== undefined;

                this.archiveExpiredFiles(anyApprovedFiles, filesReviewed);

                // If all files pass the review, and there is an approved file when insurance is required, assign true
                // else assign false
                const finalFileListStatus: boolean =
                    filesReviewed && (this.isInsuranceRequired === false ? true : anyApprovedFiles === true);

                if ((finalFileListStatus && this.allFilesApproved.value) !== this.allFilesApproved.value) {
                    this.allFilesApproved.next(this.allFilesApproved.value && finalFileListStatus);
                }
            },
            (error) => {
                console.log(error);
            }
        );

        this.mpiApp.getVendorSpecificFiles(id, 'w9').subscribe(
            (data: FilePartial[]) => {
                this.w9Files = data;

                // publish new value if status of all files being reviewed has changed
                // Since these are W9 files, having an approved W9 is optional, so only check
                // if it is pending or expired.
                const filesReviewed = data.every(this.fileReviewed);
                if ((filesReviewed && this.allFilesApproved.value) !== this.allFilesApproved.value) {
                    this.allFilesApproved.next(this.allFilesApproved.value && filesReviewed);
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    /**
     * Consume file status update event from file-verification-control component
     *
     * When user clicks approve/deny button, consume the event of the new status and update the specific
     * file in its array
     * @param event new file status
     * @param fileID id of file to change
     * @param fileType which file type the changed file belongs to, either insurance or w9
     */
    async fileStatusUpdate(event, fileID: number, fileType: string) {
        // optimistically update array that contains the fileID to the new value

        if (fileType === 'insurance') {
            const insurance_index = this.insuranceFiles.findIndex((obj) => obj.id === fileID);
            if (insurance_index != -1) {
                this.insuranceFiles[insurance_index].verificationStatus = event.newStatus;
                this.insuranceFiles[insurance_index].note = event.note;
            } else {
                console.error('Error: failed to find insurance file with fileID: ', fileID);
            }
        }
        if (fileType === 'w9') {
            const w9_index = this.w9Files.findIndex((obj) => obj.id === fileID);
            if (w9_index !== -1) {
                this.w9Files[w9_index].verificationStatus = event.newStatus;
                this.w9Files[w9_index].note = event.note;
            } else {
                console.error('Error: failed to find w9 file with fileID: ', fileID);
            }
        }

        // for all the files in the list see that every document is condsidered to be approved
        const filesReviewed = this.insuranceFiles.every(this.fileReviewed);
        // Check if there is at least one approved insurance file
        const anyApprovedFiles: boolean =
            this.insuranceFiles.find((file) => {
                return file.verificationStatus === 'approved';
            }) !== undefined;

        // Archive any denied documents if there is at least one approved insurance file
        await this.archiveDeniedFiles(anyApprovedFiles, filesReviewed);
        await this.archiveExpiredFiles(anyApprovedFiles, filesReviewed);

        // If all files pass the review, and there is an approved file when insurance is required, assign true
        // else assign false
        const insurance_approval: boolean =
            filesReviewed && (this.isInsuranceRequired === false ? true : anyApprovedFiles === true);

        const w9_approval = this.w9Files.every(this.fileReviewed);

        // publish update with new value for whether all files are approved
        this.allFilesApproved.next(insurance_approval && w9_approval);
    }

    private async archiveDeniedFiles(anyApprovedFiles: boolean, filesReviewed: boolean) {
        if (anyApprovedFiles && filesReviewed) {
            // Archive any denied files since there is at least one approved file
            for (const file of this.insuranceFiles) {
                if (file.verificationStatus === 'denied') {
                    file.verificationStatus = 'archived';

                    await this.mpiApp
                        .setFileMetaData(file.id.toString(), 'verificationStatus', file.verificationStatus)
                        .toPromise();
                }
            }

            // Remove the archived files from the insurance file list
            this.insuranceFiles = this.insuranceFiles.filter((file) => {
                return file.verificationStatus !== 'archived';
            });
        }
    }

    private async archiveExpiredFiles(anyApprovedFiles: boolean, filesReviewed: boolean) {
        // console.log(anyApprovedFiles, filesReviewed);
        if (anyApprovedFiles && filesReviewed) {
            // Archive any expired documents that expired at least 30 days ago
            for (const file of this.insuranceFiles) {
                if (file.verificationStatus === 'expired') {
                    const expDate: DateTime = DateTime.fromJSDate(new Date(file.expirationDate));
                    const todayMinus30 = DateTime.local().minus({ days: 30 });

                    if (expDate < todayMinus30) {
                        file.verificationStatus = 'archived';
                        await this.mpiApp
                            .setFileMetaData(file.id.toString(), 'verificationStatus', file.verificationStatus)
                            .toPromise();
                    }
                }
            }

            // Remove the archived files from the insurance file list
            this.insuranceFiles = this.insuranceFiles.filter((file) => {
                return file.verificationStatus !== 'archived';
            });
        }
    }

    updateInsuranceFileExpDate(insFile: FilePartial) {
        const newDate: DateTime = DateTime.fromJSDate(new Date(insFile.expirationDate));
        const newDateString = newDate.toUTC().toISO();

        this.subs.push(
            this.mpiApp.setFileMetaData(insFile.id.toString(), 'expirationDate', newDateString).subscribe({
                error: (err: HttpErrorResponse) => {
                    console.error('Update Insurance File Exp Date:', err);
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error Updating Info',
                        detail: 'Error: ' + err.error.message,
                    });
                },
            })
        );
    }

    modifyInsuranceUpload($event) {
        // Before uploading add the expiration date of the files into the upload

        const insuranceExpiration = this.insuranceForm.get('insuranceExpiration').value.trim();

        // if (insuranceExpiration === '') {
        //     debugger;
        // }

        $event.formData.append('expirationDate', insuranceExpiration);
        $event.formData.append('documentType', 'insurance');
    }

    modifyW9Upload($event) {
        $event.formData.append('expirationDate', this.insuranceForm.get('insuranceExpiration').value);
        $event.formData.append('documentType', 'w9');
    }

    changedExpirationDate() {
        this.insuranceFilesExpirationValid.next(this.insuranceForm.get('insuranceExpiration').valid);
    }

    insuranceUpload(event) {
        // For each of the files uploaded add the item into the grid
        for (const file of event.files) {
            this.insuranceFiles.push({
                originalFileName: file.name,
                verificationStatus: 'pending',
            });
        }

        this.messageService.add({ severity: 'info', summary: 'File Uploaded', detail: '' });

        // new file will have status pending, so show warning for pending files
        const filesReviewed = this.insuranceFiles.every(this.fileReviewed);
        // Check if there is at least one approved insurance file
        const anyApprovedFiles: boolean =
            this.insuranceFiles.find((file) => {
                return file.verificationStatus === 'approved';
            }) !== undefined;

        // If all files pass the review, and there is an approved file when insurance is required, assign true
        // else assign false
        const insurance_approval: boolean =
            filesReviewed && (this.isInsuranceRequired === false ? true : anyApprovedFiles === true);

        const w9_approval = this.w9Files.every(this.fileReviewed);

        // publish update with new value for whether all files are approved
        this.allFilesApproved.next(insurance_approval && w9_approval);
    }

    W9Upload(event) {
        // For each of the files uploaded add the item into the grid
        for (const file of event.files) {
            this.w9Files.push({
                originalFileName: file.name,
                verificationStatus: 'pending',
            });
        }

        this.messageService.add({ severity: 'info', summary: 'File Uploaded', detail: '' });

        // new file will have status pending, so show warning for pending files
        const insurance_approval = this.insuranceFiles.every(this.fileReviewed);
        const w9_approval = this.w9Files.every(this.fileReviewed);

        // publish update with new value for whether all files are approved
        this.allFilesApproved.next(insurance_approval && w9_approval);
    }

    completeInvite() {
        /* Validate all fields first */
        if (!this.form.valid && this.componentMode !== 'accounting') {
            this.validationService.validateAllFormFields(this.form);

            this.errorMsgs = [
                ...this.errorMsgs,
                {
                    severity: 'error',
                    summary: 'Invalid Form',
                    detail: 'Please correct all errors and resubmit',
                },
            ];
            return;
        }

        const payload: Vendor = { name: this.vendorName };

        for (const field in this.form.controls) {
            // console.log(`${field}: ${this.form.get(field).value}`);
            if (field === 'billingState') {
                if (this.form.get(field).value.code) {
                    payload[field] = this.form.get(field).value.code;
                } else {
                    payload[field] = '';
                }
            } else if (field === 'billingAddress2' && !this.form.get(field).value) {
                // fields billingAddress2 and insuranceExpiration aren't required
                // default value is null and creates error in loopback, so change to empty string
                payload[field] = '';
            } else if (field === 'insuranceDocumentRequired' && !this.form.get(field).value) {
                // Insurance document required not specified so default to false.
                payload[field] = false;
            } else {
                // No specific rules when getting the value of this control

                if (this.form.get(field).value) {
                    payload[field] = this.form.get(field).value;
                } else {
                    // Control is null so send an empty string
                    payload[field] = '';
                }
            }
        }

        // TODO: Use Vendor property instead of this.optionalInsuranceInfo
        if (!this.optionalInsuranceInfo) {
            for (const field in this.insuranceForm.controls) {
                // console.log(`${field}: ${this.insuranceForm.get(field).value}`);
                if (!this.insuranceForm.get(field).value) {
                    payload[field] = '';
                } else {
                    payload[field] = this.insuranceForm.get(field).value;
                }
            }
        }

        this.requestInProgress = true;
        this.mpiApp
            .updateVendor(this.id.toString(), payload)
            .pipe(
                finalize(() => {
                    this.requestInProgress = false;
                    this.errorMsgs = [];
                })
            )
            .subscribe(
                (data) => {
                    this.messageService.add({ severity: 'success', summary: 'Information Updated', detail: '' });
                },
                (error) => {
                    const loopback_error = error.error.error;
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error Updating Info',
                        detail: 'Error: ' + loopback_error.message,
                    });
                    console.log(error);
                }
            );
    }

    downloadFile(fileID: string) {
        this.mpiApp.showFile(fileID);
    }
}
