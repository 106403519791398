import {Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {throwError} from 'rxjs';
import {SessionService} from '../session.service';
import {catchError, map, retry} from 'rxjs/operators';
import { Observable } from 'rxjs';

export class MPIGeneralError extends Error {
    name: string;
    instance: string;
    type: string;
    timestamp: string;
    traceID: string;
    statusCode: number;
    message: string;

    constructor(
        statusCode: number,
        title: string,
        detail: string,
        type: string,
        traceID: string,
    ) {
        super(detail);
        this.name = title;
        this.traceID = traceID;
        this.timestamp = 'now';
        this.type = type;
        this.statusCode = statusCode;
        this.message = detail;
    }
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private sessionService: SessionService,
        ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): any {

        const token = this.sessionService.getToken();
        const refreshToken = this.sessionService.getRefreshToken();

        if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + token
                }
            });
        }

        // console.log('Current headers');
        // console.log(request.headers);

        // if (!request.headers.has('Content-Type') && !request.headers.has('content-type')) {
        //     request = request.clone({
        //         setHeaders: {
        //             'content-type': 'application/json'
        //         }
        //     });
        // }
        //
        // request = request.clone({
        //     headers: request.headers.set('Accept', 'application/json')
        // });

        // This basically bypasses the intercepts below
        // return next.handle(request);


        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // console.log('event--->>>', event);
                } else {
                    // console.log('Not a httpresponse');
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                console.log('auth interceptor caught' , error);

                if (error.status === 409) {

                    const appError = new MPIGeneralError(
                        error.status,
                        'Unauthorized',
                        'Access to this resource was not allowed',
                        'access-denied',
                        '',
                    );

                    return throwError(appError);

                    // console.log('Unauthorized error');
                    // return throwError('Not authorized');


                    // if (error.error.error === 'invalid_token') {
                    //     // TODO Not implemented
                    //     // https://www.djamware.com/post/5f8f99673b3daf2033c40cac/angular-10-tutorial-oauth2-login-and-refresh-token#angular-http-interceptor
                    //     // this.sessionService.refreshToken({refresh_token: refreshToken})
                    //     //     .subscribe(() => {
                    //     //         location.reload();
                    //     //     });
                    // } else {
                    //     this.router.navigate(['login']).then(_ => console.log('redirect to login'));
                    // }
                }

                //
                // Unhandled error
                //

                return throwError(error);
            }));
    }

}
