import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RefundCustomers, RefundRequest, RefundRequestPartial } from 'src/app/api/models';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class RefundRequestService {
    private requestURL = environment.apiBaseURL + '/refund-requests';
    private customerURL = environment.apiBaseURL + '/refund-customers';

    constructor(private http: HttpClient) {}

    submitRefundRequest(request: RefundRequestPartial): Observable<RefundRequest> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };
        console.log('Posting request to server.', request);
        const payload = JSON.stringify(request);
        return this.http.post<RefundRequest>(this.requestURL, payload, httpOptions);
    }

    getRefundRequestsCount(where?: string): Observable<number> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        let url: string = this.requestURL + '/count';
        if (where) {
            url += '?where=' + where;
        }

        return this.http.get<any>(url, httpOptions).pipe(map((res) => res.count as number));
    }

    getRefundRequests(filter?: string): Observable<RefundRequest[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        let url = this.requestURL;
        if (filter) {
            url += '?filter=' + filter;
        }

        return this.http
            .get<RefundRequest[]>(url, httpOptions)
            .pipe(catchError(this.handleError<RefundRequest[]>('getRefundRequests', [])));
    }

    getRefundRequest(id: number): Observable<RefundRequest> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        const url = this.requestURL + `/${id}`;

        return this.http
            .get<RefundRequest>(url, httpOptions)
            .pipe(catchError(this.handleError<RefundRequest>('getRefundRequest')));
    }

    updateRefundRequest(refund: RefundRequestPartial): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        const url = this.requestURL + `/${refund.id}`;
        const payload = JSON.stringify(refund);
        console.log(refund);

        return this.http.patch(url, payload, httpOptions);
    }

    getRefundCustomerList(filter?: string): Observable<RefundCustomers[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        let url = this.customerURL;

        if (filter) {
            url += '?filter=' + filter;
        }

        return this.http
            .get<RefundCustomers[]>(url, httpOptions)
            .pipe(catchError(this.handleError<RefundCustomers[]>('getCustomerList', [])));
    }

    getRefundCustomer(custID: number): Observable<RefundCustomers> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        let url = this.customerURL + `/${custID}`;

        return this.http.get<RefundCustomers>(url, httpOptions);
    }

    printAllDocuments(refundID: number): Observable<any> {
        const httpOptions: any = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            responseType: 'blob',
        };

        const url = this.requestURL + `/${refundID}/docs`;

        return this.http.get(url, httpOptions);
    }

    /*
    Given a list of refund IDs mark them as exported and return the download.
     */
    exportRefunds(refundIDs: string[]): Observable<any> {
        const payload = {
            list: refundIDs,
        };

        const payloadStr = JSON.stringify(payload);

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            responseType: 'blob' as const,
        };

        const url = this.requestURL + '/export-approved';

        return this.http.post(url, payloadStr, httpOptions);
    }

    /**
     * Handle HTTP operation that failed
     * Let app continue
     *
     * From live example of Angular Tour of Heroes: https://angular.io/generated/live-examples/toh-pt6/stackblitz.html
     * @param operation - name of operation which failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);

            return of(result as T);
        };
    }
}
