<p-toast></p-toast>
<p-messages [(value)]="errorMsgs"></p-messages>
<form [formGroup]="vendorInvite">
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <h5>Add Vendor</h5>
                <div class="p-fluid formgrid grid">
                    <div class="field col-12">
                        Enter the vendor information to invite a vendor to register on the portal.
                    </div>
                    <div class="field col-12 md:col-6">
                        <label for="company-name">Company Name</label>
                        <input id="company-name" type="text" aria-describedby="company-name-help" pInputText
                            formControlName="name" required>
                        <app-control-messages [control]="vendorInvite.get('name')"></app-control-messages>
                    </div>

                    <div class="field col-12 md:col-6">
                        <label for="firstName">Contact First Name</label>
                        <input id="firstName" type="text" pInputText formControlName="accountContactFirstName">
                        <app-control-messages [control]="vendorInvite.get('accountContactFirstName')">
                        </app-control-messages>
                    </div>

                    <div class="field col-12 md:col-6">
                        <label for="lastName">Contact Last Name</label>
                        <input id="lastName" type="text" pInputText formControlName="accountContactLastName">
                        <app-control-messages [control]="vendorInvite.get('accountContactLastName')">
                        </app-control-messages>
                    </div>

                    <div class="field col-12 md:col-6">
                        <label for="email">Email Address</label>
                        <input id="email" type="text" pInputText formControlName="accountContactEmail">
                        <app-control-messages [control]="vendorInvite.get('accountContactEmail')">
                        </app-control-messages>
                    </div>

                    <div class="field col-12 md:col-3">
                        <label for="locations">Vendor Eligible Locations</label>
                        <p-multiSelect [options]="locations" formControlName="selectedLocations"
                                       name="selectedLocations" ngDefaultControl
                            defaultLabel="Location(s)" optionLabel="name" class="multiselect-custom">
                            <ng-template let-value pTemplate="selectedItems">
                                <div class="location-item location-item-value"
                                    *ngFor="let option of vendorInvite.controls['selectedLocations'].value">
                                    <div>{{option.name}} - {{option.description}}</div>
                                </div>
                                <div
                                    *ngIf="!vendorInvite.controls['selectedLocations'].value || vendorInvite.controls['selectedLocations'].value.length == 0">
                                    Select Locations
                                </div>
                            </ng-template>
                            <ng-template let-location pTemplate="item">
                                <div class="location-item">
                                    <div>{{location.name}} - {{location.description}}</div>
                                </div>
                            </ng-template>
                        </p-multiSelect>
                        <app-control-messages [control]="vendorInvite.get('selectedLocations')"></app-control-messages>
                    </div>

                    <div class="col-10"></div>

                    <div class="col flex justify-content-end">
                        <p-button label="Send Invitation" class="mr-2 mb-2" (onClick)="submitForm($event)"
                            [loading]="requestInProgress" [disabled]="requestInProgress">
                        </p-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>