import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmailLogs } from 'src/app/api/models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmailLogService {
  private http = inject(HttpClient);
  private apiUrl: string = environment.apiBaseURL + '/email-logs';

  constructor() { }

  getEmailLogs(filter?: string): Observable<EmailLogs[]> {
    let url = this.apiUrl;

    if (filter?.length > 0) {
      url += '?filter=' + filter;
    }

    return this.http.get<EmailLogs[]>(url);
  }

  countEmailLogs(where?: string) {
    let url = this.apiUrl + '/count';

    if (where?.length > 0) {
      url += '?where=' + where;
    }

    return this.http.get<{ count: number }>(url);
  }
}
