import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/session.service';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {NoopAnimationsModule} from '@angular/platform-browser/animations';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss'],
    providers: [DynamicDialogRef, NoopAnimationsModule]
})
export class LogoutComponent {
    constructor(private session: SessionService, private router: Router) {
        // assume we don't care whether they're logged in already or not
        // just clear local session no matter what and redirect
        session.logout();
        this.router.navigate(['login']);
    }
}
