import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-paid-invoices',
  templateUrl: './view-paid-invoices.component.html',
  styleUrls: ['./view-paid-invoices.component.scss']
})
export class ViewPaidInvoicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
