<h1>This Component Is Not Used</h1>
<app-view-invoices></app-view-invoices>

<div class="dashboard p-grid p-fluid">

    <div class="p-col-12 p-md-12">
        <div class="unified-row p-grid p-fluid">

            <div class="p-col-2">
            </div>

            <div class="p-col-8">

            </div>

            <div class="p-col-2">
                <button pButton type="button" label="Export" class="p-mr-2 p-mb-2" onclick="window.location.href='/assets/mpi-dev/exported-approved-invoices.csv'"></button>
            </div>

        </div>
    </div>

</div>

