import { Injectable } from '@angular/core';
import { LoginModel } from './app/models/login.model';
import { MPIAppService } from './services/mpiapp.service';

const ACCESS_TOKEN = 'vendorportal_access_token';
const REFRESH_TOKEN = 'vendorportal_refresh_token';
const USER_PROFILE = 'vendorportal_user_profile';
const LOCATION_GL_CODES = 'vendorportal_locationGlCodes';
const PENDING_CACHE = 'vendorportal_vendor_pending_cache';

@Injectable({
    providedIn: 'root',
})
export class SessionService {
    // Assume that the app is not authenticated
    public isAuthenticated = false;
    public isPortalSelection = true;

    constructor(private mpiApp: MPIAppService) {}

    getToken(): string {
        return sessionStorage.getItem(ACCESS_TOKEN);
    }

    getRefreshToken(): string {
        return sessionStorage.getItem(REFRESH_TOKEN);
    }

    saveToken(token): void {
        sessionStorage.setItem(ACCESS_TOKEN, token);
    }

    saveRefreshToken(refreshToken): void {
        sessionStorage.setItem(REFRESH_TOKEN, refreshToken);
    }

    removeToken(): void {
        sessionStorage.removeItem(ACCESS_TOKEN);
    }

    removeRefreshToken(): void {
        sessionStorage.removeItem(REFRESH_TOKEN);
    }

    saveUserProfile(profile: LoginModel): void {
        const profileStr = JSON.stringify(profile);
        sessionStorage.setItem(USER_PROFILE, profileStr);
    }

    getUserProfile(): LoginModel {
        const profile: LoginModel = JSON.parse(sessionStorage.getItem(USER_PROFILE));
        return profile;
    }

    getLocationGLCodes(): string {
        return sessionStorage.getItem(LOCATION_GL_CODES);
    }

    saveLocationGLCodes(codes: string) {
        sessionStorage.setItem(LOCATION_GL_CODES, codes);
    }

    getPendingCache(): string {
        return sessionStorage.getItem(PENDING_CACHE);
    }

    savePendingCache(cache: string) {
        sessionStorage.setItem(PENDING_CACHE, cache);
    }

    /*
    Clear the current session variables.
     */
    logout() {
        this.isAuthenticated = false;
        this.isPortalSelection = true;
        sessionStorage.removeItem(USER_PROFILE);
        sessionStorage.removeItem(REFRESH_TOKEN);
        sessionStorage.removeItem(ACCESS_TOKEN);

        /* Reset current roles to empty so that user roles don't persist between login */
        this.mpiApp.currentRoles = [];
        this.mpiApp.activeRole = '';
    }

    /*
    Check if a session token is present and activate it
     */
    checkSession(): boolean {
        if (this.isAuthenticated) {
            this.isPortalSelection = false;
            // We're already authenticated so return early.
            return this.isAuthenticated;
        }

        const token = sessionStorage.getItem(ACCESS_TOKEN);

        if (token === null || token.length === 0) {
            this.logout();
            return this.isAuthenticated;
        }

        const jsonToken = JSON.parse(atob(token.split('.')[1]));
        const expiryMs = jsonToken.exp * 1000;

        const now = new Date();

        if (now.getTime() > expiryMs) {
            // Token has expired
            this.logout();
            return this.isAuthenticated;
        }

        // Token exists and is valid

        // Indicate that the user is logged in so the menu reloads
        this.mpiApp.changeMessage('logged-in');

        this.isAuthenticated = true;
        this.isPortalSelection = false;

        return this.isAuthenticated;
    }
}
