export const environment = {
  production: true,
  siteName: 'MPI Vendor Site',
  devModeActive: false,
  devMenuActive: false,
  devUsername: '',
  devPassword: '',
  apiBaseURL: 'https://mpihelptry.com/api',
  // Only update the version number in the package.json.
  // It will auto-update here.
  version: require('../../package.json').version,
};
