import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MPIAppService } from '../../services/mpiapp.service';
import { IsLoadingService } from '@service-work/is-loading';
import { ControlMessagesComponent } from '../control-messages/control-messages.component';
import {catchError, finalize, map} from 'rxjs/operators';
import { InvitationPartial } from '../api/models/invitation-partial';
import {Observable, of, throwError} from 'rxjs';
import {nodeDebugInfo} from '@angular/compiler-cli/src/ngtsc/util/src/typescript';
import {ValidationService} from '../validation.service';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
    selector: 'app-invite-acceptance',
    templateUrl: './invite-acceptance.component.html',
    styleUrls: ['./invite-acceptance.component.scss'],
    providers: [ DynamicDialogConfig, DynamicDialogRef ],
})
export class InviteAcceptanceComponent implements OnInit {
    siteName = environment.siteName;
    code = '';
    isInviteValid = false;
    invitationID = '';

    devModeActive = environment.devModeActive;

    isRequestFinished = false;
    isRequestSuccessful: boolean;

    isPostFinished = false;
    isPostSuccessful: boolean;

    failureMessage = '';

    inviteAcceptance = this.formBuilder.group(
        {
            username: ['', Validators.required],
            password: ['', Validators.required],
        },
        {
            updateOn: 'blur',
        },
    );

    constructor(
        private route: ActivatedRoute,
        private formBuilder: UntypedFormBuilder,
        public mpiApp: MPIAppService,
        private isLoadingService: IsLoadingService,
        private validationService: ValidationService
    ) {}

    ngOnInit(): void {
        this.invitationID = this.route.snapshot.queryParams['id'];
        this.getInvite(this.invitationID);
    }

    getInvite(id: string) {

        this.isRequestFinished = false;
        this.isRequestSuccessful = false;

        this.isPostFinished = false;
        this.isPostSuccessful = false;

        /*
        if (this.inviteVendor.invalid) {
            let m = 'Please check all of the fields';
            console.log(m);
            this.failureMessage = m;
            this.isRequestFinished = true;
            return;
        } else {
            console.log('Control are valid');
        }
         */

        const response = this.isLoadingService.add(
            this.mpiApp
                .getInvite(id)
                .pipe(
                    finalize(() => {
                        this.isRequestFinished = true;
                    }),
                )
                .subscribe(
                    (data: InvitationPartial) => {
                        this.isRequestSuccessful = true;

                        // console.log(data);

                        if (data.accountVerified === 0 || data.accountVerified === null) {
                            this.isInviteValid = true;

                            if (this.devModeActive) {
                                console.log('Setting default passwords while in devmode');
                                let f = 'username';
                                this.inviteAcceptance.get(f).setValue(environment.devUsername);

                                f = 'password';
                                this.inviteAcceptance.get(f).setValue(environment.devPassword);
                            }
                        }
                    },
                    (error) => {
                        this.isRequestSuccessful = false;
                        this.failureMessage = error.message;
                    },
                    () => {
                        // Only on success
                    },
                ),
            { key: id },
        );
    }

    isButtonLoading(id: string) {
        return this.isLoadingService.isLoading({ key: id });
    }

    completeInvite(id: string) {
        this.isRequestFinished = false;
        this.isRequestSuccessful = false;
        this.isPostFinished = false;

        this.validationService.validateAllFormFields(this.inviteAcceptance);

        let m = '';

        if (this.inviteAcceptance.invalid) {
            m = 'Please check all of the fields';
            this.failureMessage = m;
            this.isRequestFinished = true;
            return;
        } else {
            this.failureMessage = m;
            // console.log('Control are valid');
        }


        //
        // Build the payload
        //
        const invite = {} as InvitationPartial;
        let f = '';

        f = 'username';
        invite[f] = this.inviteAcceptance.get(f).value;

        f = 'password';
        invite[f] = this.inviteAcceptance.get(f).value;

        invite.accountVerified = 1;

        invite.invitationID = this.invitationID;

        const response = this.isLoadingService.add(

        this.mpiApp
            .inviteVendorAccept(this.invitationID, invite).pipe(
            finalize(() => {
                this.isRequestFinished = true;
                this.isPostFinished = true;
            })).subscribe(
            (data) => {
                this.isRequestSuccessful = true;
                this.isPostSuccessful = true;

                let f = 'username';
                this.inviteAcceptance.get(f).setValue('');

                f = 'password';
                this.inviteAcceptance.get(f).setValue('');

            },
            (error) => {
                this.isRequestSuccessful = false;
                this.failureMessage = this.mpiApp.getAPIErrorForDisplay(error);
            },
            () => {
                // Only on success
            },
        )
                ,
            { key: id }
        );
    }
}
