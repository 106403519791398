import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * Store a user signature for use between signature pad component and view-invoices component
 *
 * Signature pad component will store its latest signature version in the service
 * View invoices component will get the signature from the service when user is done
 */

@Injectable({
    providedIn: 'root',
})
export class SignatureService {
    constructor() {}

    private _signature = new BehaviorSubject<string>('');
    public signature$: Observable<string> = this._signature.asObservable();

    private set signature(val: string) {
        this._signature.next(val);
    }

    setSignature(newSignature: string) {
        this.signature = newSignature;
    }

    getSignature() {
        return this._signature.value;
    }
}
