<p-toast></p-toast>

<div class="grid p-fluid">
    <div class="col-12">
        <div class="card">
            <p-toolbar>
                <div class="p-toolbar-group-left">
                    <h5 class="m-0">User Management</h5>
                </div>
                <div class="p-toolbar-group-right">
                    <p-button label="New User" icon="pi pi-plus" (click)="openNewUser()"></p-button>
                </div>
            </p-toolbar>
            <p-table #userTable [value]="users" [globalFilterFields]="['username', 'email', 'role', 'roleID']"
                [resizableColumns]="true" responsiveLayout="scroll" [paginator]="true" [rows]="10" [rowHover]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [showCurrentPageReport]="true">
                <ng-template pTemplate="caption">
                    <div class="flex align-items-center justify-content-between">
                        <div></div>
                        <div class="col-3">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text"
                                    (input)="userTable.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pResizableColumn>Actions</th>
                        <th pResizableColumn pSortableColumn="username">
                            Username
                            <p-sortIcon field="username"></p-sortIcon>
                        </th>
                        <th pResizableColumn pSortableColumn="firstName">
                            Name
                            <p-sortIcon field="firstName"></p-sortIcon>
                        </th>
                        <th pResizableColumn pSortableColumn="email">
                            Email
                            <p-sortIcon field="email"></p-sortIcon>
                        </th>
                        <th pResizableColumn pSortableColumn="role">
                            Role
                            <p-sortIcon field="role"></p-sortIcon>
                        </th>
                        <th pResizableColumn pSortableColumn="allowedRoles">
                            Available Roles
                            <p-sortIcon field="allowedRoles"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-user>
                    <tr>
                        <td>
                            <p-splitButton *ngIf="user.role === 'vendor'; else elseBlock" label="Actions"
                                icon="pi pi-cog" [model]="vendorItems" appendTo="body"
                                (click)="setSelectedUser(user)"></p-splitButton>
                            <ng-template #elseBlock>
                                <p-splitButton label="Actions" icon="pi pi-cog" [model]="items" appendTo="body"
                                    (click)="setSelectedUser(user)"></p-splitButton>
                            </ng-template>
                        </td>
                        <td class="mpi-text-truncate">
                            {{ user.username }}
                            <p-tag *ngIf="user.authorizationSource" severity="info"
                                value="{{ user.authorizationSource }}"></p-tag>
                        </td>
                        <td class="mpi-text-truncate">{{ user.firstName }} {{ user.lastName }}</td>
                        <td class="mpi-text-truncate">
                            {{ user.email }}
                        </td>
                        <td class="mpi-text-truncate">
                            {{ user.role }}
                        </td>
                        <td>
                            {{ user.allowedRoles?.length > 0 ? user.allowedRoles : 'none' }}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<!--New User Dialog-->
<p-dialog id="add-user-dialog" [(visible)]="userDialog" [style]="{ width: '450px' }" header="Add User" [modal]="true"
    styleClass="p-fluid">
    <ng-template pTemplate="content">
        <p-messages [(value)]="errorMsgs"></p-messages>
        <form [formGroup]="newUserForm">
            <div class="field m-5">
                <p-dropdown [options]="roles" formControlName="role" required optionLabel="name" optionValue="code"
                    placeholder="Select Role"></p-dropdown>
                <app-control-messages [control]="newUserForm.get('role')"></app-control-messages>
            </div>
            <div class="field m-5">
                <p-multiSelect [options]="locations" optionValue="name" optionLabel="name"
                    formControlName="locationsNameList" defaultLabel="Select Location(s)" class="multiselect-custom">
                    <ng-template let-location pTemplate="item">
                        <div class="location-item">
                            <div>{{ location.name }} - {{ location.description }}</div>
                        </div>
                    </ng-template>
                </p-multiSelect>
                <app-control-messages [control]="newUserForm.get('locationsNameList')"></app-control-messages>
            </div>
            <div class="field m-5">
                <span class="p-float-label">
                    <input type="text" id="inputemail" pInputText formControlName="email" required />
                    <label for="inputemail">Email</label>
                    <app-control-messages [control]="newUserForm.get('email')"></app-control-messages>
                </span>
            </div>
            <div class="field m-5">
                <span class="p-float-label">
                    <input type="text" id="inputusername" pInputText formControlName="username" required />
                    <label for="inputusername">Username</label>
                    <app-control-messages [control]="newUserForm.get('username')"></app-control-messages>
                </span>
            </div>
            <div class="field m-5">
                <span class="p-float-label">
                    <p-password [inputId]="inputpassword" formControlName="password" required [toggleMask]="true"
                        [strongRegex]="this.pattern"></p-password>
                    <label for="inputpassword">Password</label>
                    <app-control-messages [control]="newUserForm.get('password')"></app-control-messages>
                </span>
            </div>
            <div class="field m-5">
                <span class="p-float-label">
                    <p-password [inputId]="confirmpassword" formControlName="confirmPassword" required
                        [toggleMask]="true" [feedback]="false"></p-password>
                    <label for="confirmpassword">Confirm Password</label>
                    <app-control-messages [control]="newUserForm.get('confirmPassword')"></app-control-messages>
                </span>
                <!--Second control messages for passwords must match error on whole group-->
                <app-control-messages [control]="newUserForm"></app-control-messages>
            </div>
        </form>
    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
            (click)="hideNewUser()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
            (click)="submitNewUser()"></button>
    </ng-template>
</p-dialog>

<!--Password reset dialog-->
<p-dialog [(visible)]="passwordDialog" [style]="{ width: '450px' }" header="Reset User Password" [modal]="true"
    styleClass="p-fluid">
    <ng-template pTemplate="content">
        <p-messages [(value)]="errorMsgs"></p-messages>
        <form [formGroup]="passwordResetForm">
            <div class="field m-5">
                <span class="p-float-label">
                    <p-password [inputId]="resetpassword" formControlName="password" required [toggleMask]="true"
                        [strongRegex]="this.pattern"></p-password>
                    <label for="resetpassword">Password</label>
                    <app-control-messages [control]="passwordResetForm.get('password')"></app-control-messages>
                </span>
            </div>
            <div class="field m-5">
                <span class="p-float-label">
                    <p-password [inputId]="confirmpassword" formControlName="confirmPassword" required
                        [toggleMask]="true" [feedback]="false"></p-password>
                    <label for="confirmpassword">Confirm Password</label>
                    <app-control-messages [control]="passwordResetForm.get('confirmPassword')"></app-control-messages>
                </span>
                <!--Second control messages for passwords must match error on whole group-->
                <app-control-messages [control]="passwordResetForm"></app-control-messages>
            </div>
        </form>
        <p-message *ngIf="selectedUser.authorizationSource === 'attendant-portal'" styleClass="m-5" severity="warn"
            closable="false"
            text="Attendant Portal users are not allowed to change their password through the Vendor Portal.">
        </p-message>
    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
            (click)="hidePasswordReset()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
            [disabled]="selectedUser.authorizationSource === 'attendant-portal'"
            (click)="submitPasswordReset()"></button>
    </ng-template>
</p-dialog>

<!--Change Name dialog-->
<p-dialog [(visible)]="nameDialog" [style]="{ width: '450px' }" header="Change Name" [modal]="true"
    styleClass="p-fluid">
    <ng-template pTemplate="content">
        <p-messages [(value)]="errorMsgs"></p-messages>
        <form [formGroup]="changeNameForm">
            <div class="field m-5">
                <span class="p-float-label">
                    <input id="firstName" type="text" pInputText formControlName="firstName" />
                    <label for="firstName">First Name</label>
                </span>
                <app-control-messages [control]="changeNameForm.get('firstName')"></app-control-messages>
            </div>
            <div class="field m-5">
                <span class="p-float-label">
                    <input id="lastName" type="text" pInputText formControlName="lastName" />
                    <label for="lastName">Last Name</label>
                </span>
                <app-control-messages [control]="changeNameForm.get('lastName')"></app-control-messages>
            </div>
        </form>
    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
            (click)="hideChangeName()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
            (click)="submitChangeName()"></button>
    </ng-template>
</p-dialog>

<!-- Assign locations dialog -->
<p-dialog [(visible)]="locationDialog" [style]="{ width: '30vw' }" header="Assign User Locations" [modal]="true"
    styleClass="p-fluid">
    <ng-template pTemplate="content">
        <p-messages [(value)]="errorMsgs"></p-messages>
        <form [formGroup]="assignLocationForm">
            <div class="field m-5">
                <p-multiSelect [options]="locations" optionValue="name" optionLabel="name"
                    formControlName="locationsNameList" defaultLabel="Select Location(s)" class="multiselect-custom"
                    appendTo="body">
                    <ng-template let-location pTemplate="item">
                        <div class="location-item">
                            <div>{{ location.name }} - {{ location.description }}</div>
                        </div>
                    </ng-template>
                </p-multiSelect>
                <app-control-messages [control]="assignLocationForm.get('locationsNameList')"></app-control-messages>
            </div>
        </form>
        <p-message *ngIf="selectedUser.authorizationSource === 'attendant-portal'" styleClass="m-5" severity="warn"
            closable="false" text="Attendant Portal users are not allowed to change their locations.">
        </p-message>
    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
            (click)="hideAssignLocations()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
            [disabled]="selectedUser.authorizationSource === 'attendant-portal'"
            (click)="submitAssignLocations()"></button>
    </ng-template>
</p-dialog>

<!-- Assign roles dialog -->
<p-dialog [(visible)]="roleDialog" [style]="{ width: '30vw' }" header="Assign User Role" [modal]="true"
    styleClass="p-fluid">
    <ng-template pTemplate="content">
        <p-messages [(value)]="errorMsgs"></p-messages>
        <form [formGroup]="assignRoleForm">
            <div class="field m-5">
                <p-multiSelect [options]="roles" optionValue="code" optionLabel="name" formControlName="rolesNameList"
                    defaultLabel="Select Role(s)" class="multiselect-custom" appendTo="body">
                    <ng-template let-role pTemplate="item">
                        <div class="location-item">
                            <div>{{ role.name }}</div>
                        </div>
                    </ng-template>
                </p-multiSelect>
                <!-- <p-dropdown
                    [options]="roles"
                    [showClear]="true"
                    optionValue="code"
                    optionLabel="name"
                    formControlName="role"
                    placeholder="Select Role"
                    appendTo="body">
                    <ng-template let-role pTemplate="item">
                        <div>
                            <div>{{ role.name }}</div>
                        </div>
                    </ng-template>
                </p-dropdown> -->
                <app-control-messages [control]="assignRoleForm.get('rolesNameList')"></app-control-messages>
            </div>
        </form>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
            (click)="hideAssignRoles()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
            (click)="submitAssignRoles()"></button>
    </ng-template>
</p-dialog>

<!-- Assign vendors dialog -->
<p-dialog [(visible)]="vendorDialog" [style]="{ width: '30vw' }" header="Assign Vendors" [modal]="true"
    styleClass="p-fluid">
    <ng-template pTemplate="content">
        <p-messages [(value)]="errorMsgs"></p-messages>
        <form [formGroup]="assignVendorForm">
            <div class="field m-5">
                <p-multiSelect [options]="vendors" optionValue="vendorID" optionLabel="name"
                    formControlName="vendorsList" defaultLabel="Select Vendor(s)" class="multiselect-custom"
                    appendTo="body">
                    <ng-template let-vendor pTemplate="item">
                        <div class="location-item">
                            <div>{{ vendor.name }}</div>
                        </div>
                    </ng-template>
                </p-multiSelect>
                <app-control-messages [control]="assignVendorForm.get('vendorsList')"></app-control-messages>
            </div>
        </form>
    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
            (click)="hideAssignVendors()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
            (click)="submitAssignVendors()"></button>
    </ng-template>
</p-dialog>

<!-- User Settings Dialog -->
<p-dialog [(visible)]="userSettingDialog" [style]="{ width: '30vw' }" header="User Settings" [modal]="true"
    styleClass="p-fluid">
    <ng-template pTemplate="content">
        <p-messages [(value)]="errorMsgs"></p-messages>
        <form [formGroup]="userSettingsForm">
            <div class="m-5 p-field-checkbox">
                <p-checkbox formControlName="disableEmailNotifs" binary="true"
                    inputId="disable-email-notifs"></p-checkbox>
                <label for="disable-email-notifs">Disable Email Notifications</label>
                <app-control-messages [control]="userSettingsForm.get('disableEmailNotifs')"></app-control-messages>
            </div>
        </form>
    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
            (click)="hideUserSettings()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
            (click)="submitUserSettings()"></button>
    </ng-template>
</p-dialog>

<!-- Confirm user deletion dialog -->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"><p-messages
        [(value)]="errorMsgs"></p-messages></p-confirmDialog>