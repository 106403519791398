import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services/authentication.service';
import { environment } from '../environments/environment';
import { SessionService } from '../session.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        public session: SessionService,
    ) { }

    anonymousRouteAllowed(route: ActivatedRouteSnapshot) {

        // TODO This is a temporary work-around to allow anonymous routes

        if (route.routeConfig.path === 'vendor/invite/accept') {
            return true;
        } else {
            return false;
        }

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (this.anonymousRouteAllowed(route)) {
            this.session.isAuthenticated = true;
            return true;
        }


        // if (environment.devModeActive) {
        //     this.session.isAuthenticated = true;
        // }

        if (this.session.checkSession()) {
            // logged in so return true
            return true;
        } else {
            // not logged in so redirect to login page with the return url
            // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            // Do not use the return URL - always go to the account summary screen so their path through the app remains consistent.
            this.router.navigate(['/login']);
            return false;
        }
    }

    /**
     *
     * @param route
     * @param state
     * @returns true/false for whether authenticated
     *
     * Used by app main component in router module
     * All components are children of main, so checks whether user is logged in and can be redirected to default child component
     *
     * If not logged in, redirect to login page
     */
    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (this.anonymousRouteAllowed(route)) {
            this.session.isAuthenticated = true;
            return true;
        }

        // if (environment.devModeActive) {
        //     this.session.isAuthenticated = true;
        // }

        if (this.session.checkSession()) {
            return true;
        } else {
            // not logged in so go to login page
            //this.router.navigate(['']);
            return false;
        }
    }
}
