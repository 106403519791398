import {
    Component,
    OnInit,
    ViewChild,
    OnDestroy,
    AfterViewInit,
    AfterContentChecked,
    ChangeDetectorRef,
} from '@angular/core';
import { Invoice } from '../api/models/invoice';
import { MPIAppService } from '../../services/mpiapp.service';
import { DateTime } from 'luxon';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { finalize, map, switchMap, takeUntil } from 'rxjs/operators';
import { LazyLoadEvent, MessageService, PrimeIcons, SortEvent } from 'primeng/api';
import { FilePartial } from '../api/models/file-partial';
import { SessionService } from 'src/session.service';
import { LoginModel } from '../models/login.model';
import { environment } from '../../environments/environment';
import { LocationsPartial } from '../api/models/locations-partial';
import { ValidationService } from '../validation.service';
import { Observable, Subject, Subscription, forkJoin } from 'rxjs';
import { LocationField } from './location_field';
import { SignaturePadComponent } from '../signature-pad/signature-pad.component';
import { SignatureService } from '../signature.service';
import { Vendor } from '../api/models/vendor';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { GLCodeGenerator } from '../../shared/gl-code-generation';
import { ActivatedRoute, Router } from '@angular/router';
import { Settings } from '../api/models/settings';
import { InvoiceLocationAndGLDistribution } from '../api/models/mpi-custom-models';
import { IsLoadingService } from '@service-work/is-loading';
import { ViewInvoice } from '../api/models';
import { httpErrorToast } from 'src/helpers/http-toasts';
import { HttpErrorResponse } from '@angular/common/http';
import { escapeRegExp } from 'src/helpers/escape-regexp';

export interface InvoiceWithDateStrings extends Invoice {
    dateCreated?: string;
    datePaid?: string;
    checkNum?: string;
}

export interface ViewInvoiceWithDateStrings extends ViewInvoice {
    dateCreated?: string;
    datePaid?: string;
    checkNum?: string;
}

export interface InvoiceFields {
    locationDistribution?: any;
    importMatch?: {
        invoiceID: string;
        vendorID: string;
        checkNum: string;
    };
}

@Component({
    selector: 'app-view-invoices',
    templateUrl: './view-invoices.component.html',
    styleUrls: ['./view-invoices.component.scss'],
    providers: [MessageService],
})
export class ViewInvoicesComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentChecked {
    private destroy$: Subject<void> = new Subject<void>();
    invoiceParam: string;
    // if manager role, then prompt for which location to view invoices
    promptLocations = false;
    locations: LocationsPartial[];
    locationSearchResults: LocationsPartial[];
    chosenLocation: LocationsPartial;
    amountChangesSubscription: Subscription;
    enteredAmountSum: number = 0;

    // json obj from database for location amounts
    // don't need a whole form for corporate office since they input nothing
    // just access values from here
    location_amounts_obj: Object;

    // header to be shown, changes depending on role
    statusHeader = 'Incoming Requests for Payment';
    statusHeaderRefund = 'Incoming Requests for Refund';

    user_profile: LoginModel;
    user_role: string;

    totalInvoices: number = 0;
    invoices: InvoiceWithDateStrings[] = [];
    displayedInvoices: ViewInvoiceWithDateStrings[] = [];

    defaultLazyLoadEvent: LazyLoadEvent = {
        rows: 10,
        first: 0,
        sortField: 'submittedDate',
        sortOrder: -1,
    };

    invoiceDetailsShown = false;

    // so far only accounting can toggle for denied invoices
    showDeniedInvoices = false;
    showApprovedInvoices = false;
    showPaidInvoices = false;
    showUnpaidInvoices = false;
    // by default show pending invoices
    showPendingInvoices = true;

    // Indicates if the file sections should be shown
    POFileDetailsShown = false;
    invoiceFileDetailsShown = false;

    // TODO This isn't aware of the vendorRecord property which can exist. We need to have another model that includes
    // the additional property.

    // Model of the invoice in the grid that was selected for display.
    selectedInvoice: Invoice;
    selectedViewInvoice: ViewInvoiceWithDateStrings;
    managerApprovedDateTime: Date;
    managerApprovedDateString: string;
    corporateOfficeApprovedDateTime: Date;
    corporateOfficeApprovedDateString: string;

    // Model of the invoice currently displayed for approval. Assigned from selectedInvoice
    invoiceToShow: InvoiceWithDateStrings;
    invoiceToShowVendor: Vendor; // Vendor of the shown invoice.
    poidForSelectedInvoice: string;

    poFiles: FilePartial[];
    invoiceFiles: FilePartial[];

    env = environment;

    loadingInvoices$: Observable<boolean>;
    requestInProgress = false;
    printRequestInProgress = false;

    // Form state variables
    canApprove: boolean = false;
    canDeny: boolean = false;

    uploadedFiles: any[] = [];

    /**
     * Get a reference to our signature pad component once it becomes available
     * Taken from: https://stackoverflow.com/a/41095677/14560781
     */
    signatureRequired: boolean = false;
    public signaturePad: SignaturePadComponent;
    @ViewChild(SignaturePadComponent, { static: false }) set component(component: SignaturePadComponent) {
        if (component) {
            this.signaturePad = component;
        }
    }

    selectedLocations: UntypedFormGroup[];

    glForm = this.formBuilder.group({
        locations: this.formBuilder.array([]),
    });

    // locationAmountForm = this.formBuilder.group({
    //     locations: this.formBuilder.array([]),
    // });

    // disabled and readonly options should be set here to avoid
    // console warnings regarding reactive forms.
    invoiceDetails = this.formBuilder.group({
        invoiceID: ['', Validators.required],
        poid: [{ value: '', disabled: true, readonly: true }],
        vendorName: [{ value: '', disabled: true, readonly: true }],
        locations: [{ value: '', disabled: true, readonly: true }],
        amount: [{ value: '', disabled: true, readonly: true }],
        submittedDate: [{ value: '', disabled: true, readonly: true }],
        checkNum: [{ value: '', disabled: true, readonly: true }],
    });

    signatureForm = this.formBuilder.group({
        signature: ['', Validators.required],
    });

    paidInvoiceControlForm = this.formBuilder.group({
        paidDate: ['', Validators.required],
        checkNo: [0, Validators.required],
        amount: [0, Validators.required],
    });

    constructor(
        private mpiApp: MPIAppService,
        private messageService: MessageService,
        private formBuilder: UntypedFormBuilder,
        private session: SessionService,
        private validationService: ValidationService,
        private signatureService: SignatureService,
        private loadingService: IsLoadingService,
        private route: ActivatedRoute,
        private changeDetector: ChangeDetectorRef,
        private router: Router
    ) { }

    ngAfterViewInit(): void { }

    // This is what removes the ExpressionChangedAfterItHasBeenCheckedError
    // after changing the form value updates to happen according to reactive
    // form rules.
    ngAfterContentChecked(): void {
        this.changeDetector.detectChanges();
    }

    ngOnInit(): void {
        this.loadingInvoices$ = this.loadingService.isLoading$({ key: 'load-invoices' });
        // Initialize the GL Code list in case it's undefined
        this.mpiApp
            .initGLCodeList()
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                error: (err) => {
                    console.error(err);

                    this.messageService.add({
                        severity: 'error',
                        summary: 'Could not get the gl code list.',
                    });
                },
            });
        // Check to see if an invoice ID is specified in the URL
        this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
            if (params.invoice) {
                // There will be an invoice to show the user after the table loads
                this.invoiceParam = params.invoice;
            }
        });

        this.locationSearchResults = [];
        /**
         * Set up what invoices are shown based on role
         *
         * Managers are shown invoices in first stage, but must select which location's invoices to see
         *
         * Corporate office sees invoices approved by managers
         *
         * Accounting sees invoices approved by corporate office
         */
        this.user_profile = this.session.getUserProfile();
        if (this.user_profile?.activeRole) {
            // Show the component based on the users activeRole
            this.user_role = this.user_profile.activeRole;
            if (this.invoiceParam && this.user_role === 'admin') {
                this.user_role = 'manager';
            }
        }

        if (this.user_role) {
            if (this.user_role === 'manager') {
                /**
                 * Manager needs to choose location to see invoices for
                 * Fetch locations, set the location prompt, and set the signature pad to be open
                 *
                 * Note: Only calling get invoices for manager once they select a location to view for
                 */
                this.mpiApp
                    .getLocations()
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((data) => {
                        this.locations = data;
                    });

                this.promptLocations = true;
                this.signatureRequired = true;

                //
                // Managers no longer need to select a location so show the invoices on load
                //
                //this.getInvoices();

                /**
                 * Subscribe to the updates to the form array for amounts entered by manager
                 * update the total entered on every update to display in the template
                 */
                this.amountChangesSubscription = this.sumLocationAmounts();
            } else if (this.user_role === 'corporate-office') {
                this.mpiApp
                    .getLocations()
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((data) => {
                        this.locations = data;
                    });

                this.statusHeader = 'Manager Approved Invoices';
                this.statusHeaderRefund = 'Manager Approved Refunds';
                this.signatureRequired = true;

                //this.getInvoices();

                /**
                 * Subscribe to the updates to the form array for amounts entered by corporate
                 * update the total entered on every update to display in the template
                 */
                this.amountChangesSubscription = this.sumLocationAmounts();
            } else if (this.user_role === 'accounts-payable') {
                /* Corporate office does nothing other than view invoice data from manager and sign off*/
                //this.getInvoices();
                this.statusHeader = 'Invoices Approved for Payment';
                this.statusHeaderRefund = 'Refunds Approved for Payment';
                this.signatureRequired = false;
            } else if (this.user_role === 'accounting') {
                // Accounting needs locations to check validity of location names
                this.mpiApp
                    .getLocations()
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((data) => {
                        this.locations = data;
                    });

                //this.getInvoices();
                this.statusHeader = 'Corporate Office Approved Invoices';
                this.statusHeaderRefund = 'Corporate Office Approved Refunds';

                /**
                 * Subscribe to the updates to the form array for amounts updated by accounting
                 * update the total entered on every update to display in the template
                 */
                this.amountChangesSubscription = this.sumLocationAmounts();
            } else {
                // NOTE: For future in case of vendors viewing invoices
                //this.getInvoices();
            }
        } else {
            /**
             * NOTE: TBD, don't know what to do if profile doesn't have a role
             */
        }

        if (location.hash === '#/accounting/invoices') {
            this.showPaidInvoices = true;
            this.statusHeader = 'Invoices';
            this.statusHeaderRefund = 'Refunds';
        }

        // Calling this function in ngOnInit prevents the "Expression has changed after it was checked" error
        this.loadInvoices(null);
    }

    ngOnDestroy() {
        this.destroy$.next();
        // if manager or accounting, we subscribed to updates to form array
        // unsubscribe to avoid memory leak
        if (this.amountChangesSubscription) {
            this.amountChangesSubscription.unsubscribe();
        }
    }

    /**
     * Returns a subscription that sums up the location amounts on the form
     * @private
     */
    private sumLocationAmounts() {
        return this.glformLocationArray.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((locationArray) => {
            let amount = 0;
            for (const location of locationArray) {
                if (location.amount) {
                    const locAmount = Number(location.amount.toFixed(2));
                    // console.log(`Adding ${locAmount} to ${amount} is...`);
                    // Ensure that the added amounts are always two decimal point numbers
                    amount = Number((amount + locAmount).toFixed(2));
                    // console.log(amount);
                }
            }
            this.enteredAmountSum = amount;
        });
    }

    /**
     * Create a formgroup with a location and amount
     * Meant for each location an invoice applies to
     *
     * Used only by addLocationFormGroup()
     * @returns new formgroup for locations amount form
     */
    private createLocationFormGroup(): UntypedFormGroup {
        return new UntypedFormGroup({
            location: new UntypedFormControl('', [Validators.required]),
            amount: new UntypedFormControl(null, [Validators.required]),
        });
    }

    /**
     * Create new formgroup for GL form
     * Used for each location in the invoice
     *
     * @returns new formgroup for GL form
     */
    private createGlFormGroup(): UntypedFormGroup {
        // Create the form group and set the validators that apply to all roles
        const group = new UntypedFormGroup({
            location: new UntypedFormControl('', [Validators.required]),
            glCode: new UntypedFormControl(''),
            comment: new UntypedFormControl(''),
            amount: new UntypedFormControl(null, [Validators.required]),
        });

        //
        // Set role specific validators
        //
        if (this.user_role === 'corporate-office') {
            // Add special validators for corporate office.
            // Don't require glCode validation because they can't see this field
            group.get('glCode').clearValidators();
        } else {
            // Set any other validators
            group.get('glCode').setValidators([Validators.required]);
        }

        return group;
    }

    public generateGLCode(locationName: string, index: number) {
        let updateValue = this.mpiApp.glCodeGenerator.generateGLCode(
            locationName,
            this.invoiceToShowVendor.defaultGLCode
        );

        if (updateValue === '') {
            updateValue = this.invoiceToShowVendor.defaultGLCode;
        }

        this.glformLocationArray.controls[index].get('glCode').setValue(updateValue);
    }

    /**
     * Add a new location form control to the locations form array
     * Set its inital value of the location to the string provided
     *
     * Adapted from: https://stackoverflow.com/a/59118097/14560781
     *
     * @param formType which form to add location to
     * @param location location name string
     * @param amount previously entered amount, if available
     * @param glCode previously entered glCode, if available
     */
    addLocationFormGroup(formType: string, location: string, amount?: number, glCode?: string, comment?: string) {
        if (formType === 'amountForm') {
            // main form array for all the locations in this invoice
            const locations = this.glformLocationArray;

            let new_location = this.createLocationFormGroup();
            new_location.controls['location'].setValue(location);
            if (amount) {
                new_location.controls['amount'].setValue(amount);
            }

            locations.push(new_location);
        } else if (formType === 'glForm') {
            const locations = this.glformLocationArray;

            let new_location = this.createGlFormGroup();
            new_location.controls['location'].setValue(location);
            new_location.controls['amount'].setValue(amount);
            if (glCode) {
                new_location.controls['glCode'].setValue(glCode);
            }
            if (comment) {
                new_location.controls['comment'].setValue(comment);
            }
            locations.push(new_location);
        }
    }

    /**
     * Simplify getting the gl form locations array for the template
     */
    get glformLocationArray(): UntypedFormArray {
        return this.glForm.get('locations') as UntypedFormArray;
    }

    get glFormLocationNames(): string[] {
        const locations: any[] = this.glForm.get('locations').value;
        let locationNames: string[] = [];
        locations.forEach((loc) => {
            locationNames.push(loc.location);
        });
        return locationNames;
    }

    /**
     * When new location is selected by manager, update the array of invoices that match the location
     * Only used when role is manager, so pulls invoices with status 'submitted' as invoices in first stage
     *
     * @param event new chosen location name event
     */
    changeLocation(event) {
        /**
         * If manager just cleared their selected location so that it's now null, set the invoices to show to empty
         * so that they don't continue to see invoices for the previous chosen location
         *
         * OR
         *
         * If there was an invoice being displayed and the manager chose a new location, close the invoice details view
         */
        if (!this.chosenLocation || this.selectedInvoice) {
            if (this.selectedInvoice) {
                this.deselectInvoice();
                this.selectedInvoice = null;
            }
            this.invoices = [];
        } else {
            //this.getInvoices();
        }
    }

    searchLocations(query: string) {
        const pattern = new RegExp('.*' + query + '.*', 'i');
        const filter = `{"where": {"or": [{"name": {"regexp": "${pattern}"}},{"description": {"regexp": "${pattern}"}}]},"order":["name ASC"]}`;

        this.mpiApp
            .getLocations(filter)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (data) => {
                    this.locationSearchResults = data;
                },
                error: (err) => {
                    console.error(err);
                },
                complete: () => { },
            });
    }

    selectLocation(event: LocationsPartial | string, index: number) {
        // Check if the user used the dropdown to select a location, or just typed it in
        if (typeof event !== 'string') {
            if (event.name) {
                // Make sure only the location name is assigned to the form control after selection
                this.glformLocationArray.at(index).get('location').setValue(event.name);
            }

            this.generateGLCode(event.name, index);
        } else {
            this.generateGLCode(event, index);
        }

        // Generate the GL Code for the associated location name
    }

    /**
     *
     * @param event PrimeNG Table LazyLoadEvent that contains the properties needed to filter the results.
     * * event.first = First row offset.
     * * event.rows = Number of rows per page.
     * * event.sortField = Field name to sort with.
     * * event.sortOrder = Sort order as number, 1 for asc and -1 for dec.
     * * filters: FilterMetadata object having field as key and filter value, filter matchMode as value.
     */
    loadInvoices(event: LazyLoadEvent) {
        //console.log('onLazyLoad', event);
        this.getInvoices(event);
        // if (this.invoices) {
        //     this.displayedInvoices = this.invoices.slice(event.first, event.first + event.rows);
        // }
    }

    addLazyEventToFilter(event: LazyLoadEvent, filterObj: any): any {
        filterObj['limit'] = event.rows;
        filterObj['skip'] = event.first;

        if (event.sortField) {
            const sortOrder = event.sortOrder > 0 ? 'ASC' : 'DESC';
            filterObj['order'] = [`${event.sortField} ${sortOrder}`];
        }

        if (event.globalFilter) {
            let existingFilter: any = {};
            const filterFields: any[] = [];
            if (filterObj['where']) {
                // TODO: Add existing clause to the new list
                existingFilter = filterObj['where'];
            }

            const sanitizedFilter: string = escapeRegExp(event.globalFilter as string);

            // TODO: Add list of properties with the global filter
            const regexp = new RegExp('^.*' + sanitizedFilter + '.*$', 'gi');
            const locNameRegex = new RegExp('.*"locationName":".*' + sanitizedFilter + '.*".*');
            filterFields.push({ invoiceId: { regexp: `${regexp}` } });
            filterFields.push({ dateCreated: { regexp: `${regexp}` } });
            filterFields.push({ status: { regexp: `${regexp}` } });
            filterFields.push({ datePaid: { regexp: `${regexp}` } });
            filterFields.push({ amount: { regexp: `${regexp}` } });
            filterFields.push({ fields: { regexp: `${locNameRegex}` } });
            filterFields.push({ vendorName: { regexp: `${regexp}` } });
            filterFields.push({ locationNames: { regexp: `${regexp}` } });

            // Put the new list of where clauses inside an or operator
            // Update the filter's where clause with an and operator between the existing filters and global filters
            filterObj['where'] = { and: [existingFilter, { or: filterFields }] };
            //filterObj['where'] = { and: [existingFilter, { invoiceId: { regexp: `${regexp}` } }] };
        }

        return filterObj;
    }

    /**
     * Load the invoices the current user's role should see
     * Manager sees newly submitted invoices
     *
     * Corporate office sees invoices approved by managers
     *
     * Accounting sees invoices approved by corporate office
     */
    getInvoices(lazyEvent?: LazyLoadEvent): void {
        this.requestInProgress = true;
        this.invoiceDetailsShown = false;
        let filterObj: any = {};

        if (location.hash === '#/accounting/invoices') {
            if (this.showUnpaidInvoices) {
                if (this.showDeniedInvoices) {
                    // Unpaid and denied are selected
                    filterObj = { where: { status: { neq: 'paid' } }, order: ['submittedDate DESC'] };
                } else {
                    // Only unpaid selected
                    filterObj = {
                        where: { and: [{ status: { neq: 'paid' } }, { status: { neq: 'denied' } }] },
                        order: ['submittedDate DESC'],
                    };
                }
            } else if (this.showDeniedInvoices) {
                // Only denied selected
                filterObj = { where: { status: 'denied' }, order: ['submittedDate DESC'] };
            } else {
                // Neither selected
                filterObj = { where: { status: 'paid' }, order: ['submittedDate DESC'] };
            }
        } else {
            if (this.user_role === 'manager') {
                const hasLocationName = (element) => element == this.chosenLocation.name;

                /**
                 * NOTE: Manager only sees invoices marked as submitted
                 * possibly change to see fully approved so they can deny, or see denied to change to approved
                 *
                 * Filter invoices which contain the location the manager has selected to view invoices for
                 */
                filterObj = { where: { status: 'submitted' }, order: ['submittedDate DESC'] };
            } else if (this.user_role === 'corporate-office') {
                // get all invoices that managers have approved, now pending corporate office approval
                filterObj = { where: { status: 'manager-approved' }, order: ['submittedDate DESC'] };
            } else if (this.user_role === 'accounts-payable') {
                // get all invoices that managers have approved, now pending corporate office approval
                filterObj = {
                    where: { or: [{ status: 'approved' }, { status: 'reopened' }] },
                    order: ['submittedDate DESC'],
                };
            } else if (this.user_role === 'accounting') {
                const deniedAndApproved: boolean = this.showDeniedInvoices && this.showApprovedInvoices;
                const deniedAndPending: boolean = this.showDeniedInvoices && this.showPendingInvoices;
                const approvedAndPending: boolean = this.showApprovedInvoices && this.showPendingInvoices;
                const showAll: boolean = this.showDeniedInvoices && this.showApprovedInvoices && this.showPendingInvoices;

                if (showAll) {
                    filterObj = {
                        where: {
                            or: [
                                { status: 'denied' },
                                { status: 'approved' },
                                { status: 'corporate-office-approved' },
                                { status: 'reopened' },
                            ],
                        },
                        order: ['submittedDate DESC'],
                    };

                } else if (deniedAndPending) {
                    filterObj = {
                        where: {
                            or: [{ status: 'corporate-office-approved' }, { status: 'reopened' }, { status: 'denied' }],
                        },
                        order: ['submittedDate DESC'],
                    };
                } else if (approvedAndPending) {
                    filterObj = {
                        where: {
                            or: [{ status: 'approved' }, { status: 'corporate-office-approved' }, { status: 'reopened' }],
                        },
                        order: ['submittedDate DESC'],
                    };
                } else if (deniedAndApproved) {
                    filterObj = {
                        where: { or: [{ status: 'approved' }, { status: 'denied' }] },
                        order: ['submittedDate DESC'],
                    };
                } else if (this.showDeniedInvoices) {
                    filterObj = {
                        where: { status: 'denied' },
                        order: ['submittedDate DESC'],
                    };
                } else if (this.showApprovedInvoices) {
                    filterObj = {
                        where: { status: 'approved' },
                        order: ['submittedDate DESC'],
                    };
                } else if (this.showPendingInvoices) {
                    filterObj = {
                        where: { or: [{ status: 'corporate-office-approved' }, { status: 'reopened' }] },
                        order: ['submittedDate DESC'],
                    };
                } else {
                    this.invoices = [];
                    return;
                }
            } else {
                // for future any other role sees all invoices
            }
        }

        if (lazyEvent) {
            filterObj = this.addLazyEventToFilter(lazyEvent, filterObj);
        } else {
            // No lazy load event was provided, so use the default so the entire invoice list is not returned
            filterObj['limit'] = this.defaultLazyLoadEvent.rows;
            filterObj['skip'] = this.defaultLazyLoadEvent.first;
            const sortOrder = this.defaultLazyLoadEvent.sortOrder > 0 ? 'ASC' : 'DESC';
            filterObj['order'] = [`${this.defaultLazyLoadEvent.sortField} ${sortOrder}`];
        }

        const filter = JSON.stringify(filterObj);
        // Only send the where clause part of the filter to get the total number of records
        // that work
        const where = JSON.stringify(filterObj.where);

        const gettingInvoiceCount$ = this.mpiApp.getInvoicesCount(where).pipe(takeUntil(this.destroy$));
        const gettingInvoices$ = this.mpiApp.getDisplayInvoices(filter).pipe(takeUntil(this.destroy$));

        this.loadingService.add(
            forkJoin([gettingInvoiceCount$, gettingInvoices$]).subscribe({
                next: (results) => {
                    this.totalInvoices = results[0] as number;
                    this.displayedInvoices = results[1] as ViewInvoice[];

                    this.displayedInvoices.forEach((invoice: ViewInvoiceWithDateStrings) => {
                        const dateCreated = new Date(invoice.submittedDate);
                        invoice.dateCreated = dateCreated.toLocaleString();

                        if (invoice.paidDate) {
                            const datePaid = new Date(invoice.paidDate);
                            invoice.datePaid = datePaid.toLocaleString();
                        }

                        if (invoice.fields) {
                            const invoice_fields = JSON.parse(invoice.fields);
                            if (invoice_fields['importMatch']) {
                                invoice.checkNum = invoice_fields['importMatch'].checkNum;
                            }
                        }

                        if (this.invoiceParam) {
                            this.selectedInvoice = this.invoices.find((invoice) => invoice.invoiceID === this.invoiceParam);
                            this.selectInvoice({});
                        }
                    });
                    this.requestInProgress = false;
                }, error: (error: HttpErrorResponse) => {
                    const errMsg: string = error.error.error.message ? error.error.error.message : 'No Message';
                    console.error('getInvoices(): ', error);
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Get Invoices Failed',
                        detail: 'Error ' + error.status + ': ' + errMsg,
                    });
                }
            }),
            { key: 'load-invoices' }
        );
    }

    /**
     * Fill out invoice details area when new invoice row is selected
     *
     * Sets up the different forms for manager, corporate office, and accounting roles
     * @param event
     */
    async selectInvoice(event) {
        await this.mpiApp
            .getInvoice(this.selectedViewInvoice.id.toString())
            .toPromise()
            .catch((reason) => {
                console.error(reason);
            })
            .then((data: Invoice) => {
                this.selectedInvoice = data;
            });

        // obj with previously entered location amounts from database invoice field
        // stored under namespace locationDistribution
        const invoice_fields: InvoiceFields = JSON.parse(this.selectedInvoice.fields);

        /* if manager, create form for all locations for the invoice selected */
        if (this.user_role === 'manager') {
            // clear out form data first
            this.glformLocationArray.clear();
            this.glformLocationArray.clearValidators();
            this.glForm.reset();

            this.populateLocationFormGroup(invoice_fields);

            // set a validator to check if the entered amounts sum to the total amount of the invoice
            this.glformLocationArray.setValidators([this.validationService.amountSum(this.selectedInvoice.amount)]);

            this.canApprove = !(this.selectedInvoice.status === 'manager-approved');
            this.canDeny = !(this.selectedInvoice.status === 'denied');
            if (this.showPaidInvoices) {
                this.glformLocationArray.disable();
                this.glForm.disable();
            }
        } else if (this.user_role === 'corporate-office') {
            // clear out form data first
            this.glformLocationArray.clear();
            this.glformLocationArray.clearValidators();
            this.glForm.reset();

            this.populateLocationFormGroup(invoice_fields);

            // set a validator to check if the entered amounts sum to the total amount of the invoice
            this.glformLocationArray.setValidators([this.validationService.amountSum(this.selectedInvoice.amount)]);

            this.canDeny = !(this.selectedInvoice.status === 'denied');
            this.canApprove = !(this.selectedInvoice.status === 'corporate-office-approved');
            if (this.showPaidInvoices) {
                this.glformLocationArray.disable();
                this.glForm.disable();
            }
        } else if (this.user_role === 'accounting' || this.user_role === 'accounts-payable') {
            // clear out form data first
            this.glformLocationArray.clear();
            this.glformLocationArray.clearValidators();
            this.glForm.reset();

            // object of objects for each location's amount and glcode
            // guaranteed to have at least amount if in accounting role, might have glcode if viewing invoice already approved
            //const invoice_fields = JSON.parse(this.selectedInvoice.fields);

            this.managerApprovedDateTime = new Date(this.selectedInvoice.managerApproved);
            this.corporateOfficeApprovedDateTime = new Date(this.selectedInvoice.corporateOfficeApproved);

            this.populateLocationFormGroup(invoice_fields);

            this.glformLocationArray.setValidators([this.validationService.amountSum(this.selectedInvoice.amount)]);

            if (this.user_role === 'accounts-payable') {
                this.canDeny = false;
                this.canApprove = false;
            }

            if (this.user_role === 'accounting') {
                this.canDeny = !(this.selectedInvoice.status === 'denied');
                this.canApprove = !(this.selectedInvoice.status === 'approved');
            }

            if (this.showPaidInvoices) {
                this.glformLocationArray.disable();
                this.glForm.disable();
            }
        }

        this.selectedInvoice.vendorRecord = await this.mpiApp.getVendor(this.selectedInvoice.vendorID).toPromise();

        this.poidForSelectedInvoice = (
            await this.mpiApp
                .getPurchaseOrders(`{"where":{"purchaseOrderID":"${this.selectedInvoice.purchaseOrderID}"}}`)
                .toPromise()
        )[0].poID;

        this.invoiceToShow = this.selectedInvoice;

        // Patch all the new invoice information into the invoice details form.
        // This helps in getting around the ExpressionChangedAfterItHasBeenCheckedError.
        this.invoiceDetails.patchValue({
            invoiceID: this.invoiceToShow.invoiceID,
            poid: this.poidForSelectedInvoice,
            vendorName: (this.invoiceToShow.vendorRecord as Vendor).name,
            locations: this.invoiceToShow.locationsNameList.join(', '),
            amount: this.invoiceToShow.amount.toFixed(2),
            submittedDate: DateTime.fromISO(this.invoiceToShow.submittedDate).toLocaleString(DateTime.DATETIME_SHORT),
            checkNum: invoice_fields?.importMatch?.checkNum ? invoice_fields.importMatch.checkNum : '',
        });

        this.invoiceDetails.controls.invoiceID.clearValidators();
        this.invoiceDetails.controls.invoiceID.setValidators(Validators.required);
        this.invoiceDetails.controls.invoiceID.setValue(this.invoiceToShow.invoiceID, {
            disabled:
                this.user_role !== 'accounting' &&
                this.user_role !== 'manager' &&
                this.user_role !== 'corporate-office',
            readonly:
                this.user_role !== 'accounting' &&
                this.user_role !== 'manager' &&
                this.user_role !== 'corporate-office',
        });
        this.invoiceDetails.updateValueAndValidity();
        // console.log(this.invoiceToShow);

        // Get the vendor of the shown invoice.
        this.mpiApp
            .getVendor(this.invoiceToShow.vendorID)
            .pipe(takeUntil(this.destroy$))
            .subscribe((vendor: Vendor) => {
                this.invoiceToShowVendor = vendor;
                // console.log(vendor.defaultGLCode);
                this.setDefaultGLCode();
            });

        this.loadFilesForInvoice();

        this.invoiceDetailsShown = true;
    }

    /**
     * Called when invoice row in unselected
     *
     * Close the invoice details section and set our selected invoice to null
     */
    deselectInvoice() {
        //console.log('deselect');
        this.invoiceDetailsShown = false;
        this.invoiceToShow = null;
    }

    /**
     * Given a list of invoice fields load the location distribution form with the data. If the invoice fields do not
     * exist or are empty then use the invoice locationsNameList as the initial list of invoice location distributions.
     *
     * @param invoiceFields - Serialized invoice.fields object or null
     */
    populateLocationFormGroup(invoiceFields: any | null) {
        // Protect empty invoice fields from throwing errors
        if (!invoiceFields) {
            invoiceFields = {};
        }

        if (!invoiceFields.hasOwnProperty('locationDistribution')) {
            // There are no invoice fields so use the invoice locationsNameList as the starting list

            for (const location of this.selectedInvoice.locationsNameList) {
                // Add only the location to the form, the user will supply the additional information.
                this.addLocationFormGroup('glForm', location);
            }
        } else {
            // We have an existing location distribution object to use

            // The keys to the location distribution are arbitrary
            const locationKeys = Object.keys(invoiceFields['locationDistribution']);

            // Loop over all the location distribution object keys
            for (const locationKey of locationKeys) {
                // Pull one of the locations from the location distributions fields
                const locationObj = invoiceFields.locationDistribution[locationKey];

                const location = locationObj.locationName;
                let locAmount: number = null;
                let locGLCode: string = null;
                let locComment: string = null;

                if (locationObj?.amount !== null && locationObj?.amount !== undefined) {
                    // We have distribution amount so include it

                    if (locationObj.glCode !== null && locationObj.glCode !== undefined) {
                        // We also have a glcode so include it
                        this.addLocationFormGroup('glForm', location, locationObj.amount, locationObj.glCode, locationObj.comment);
                    } else {
                        this.addLocationFormGroup('glForm', location, locationObj.amount);
                    }
                } else {
                    // No distribution amount so default to none

                    if (locationObj.glCode !== null && locationObj.glCode !== undefined) {
                        // We have a glcode so include it
                        this.addLocationFormGroup('glForm', location, 0, locationObj.glCode, locationObj.comment);
                    } else {
                        this.addLocationFormGroup('glForm', location);
                    }
                }
            }
        }
    }

    addLocation() {
        this.addLocationFormGroup(
            'glForm',
            '',
            0,
            this.mpiApp.glCodeGenerator.generateGLCode('', this.invoiceToShowVendor.defaultGLCode)
        );
    }

    deleteLocation(index: number) {
        // console.log(index);
        this.glformLocationArray.removeAt(index);
    }

    saveLocations() {
        // Save the changes by patching the updated values back to the server.
        let update = {};
        const location_payload = this.mpiApp.normalizeLocationDistribution(this.glformLocationArray, this.locations);

        let newLocationList: string[] = [];
        // Get the new list of location names for display
        for (const locControl of this.glformLocationArray.controls) {
            newLocationList.push(locControl.get('location').value);
        }

        let invoice_fields = JSON.parse(this.selectedInvoice.fields) || {};
        invoice_fields['locationDistribution'] = location_payload;

        // Find the index of the selected invoice in the list of invoices
        const toUpdateIndex = this.invoices.findIndex((invoice) => {
            return invoice.invoiceID === this.selectedInvoice.invoiceID;
        });

        // If the invoice is found, update the location name list and locationDistribution to the new value
        if (toUpdateIndex !== -1) {
            this.selectedInvoice.locationsNameList = newLocationList;
            this.invoices[toUpdateIndex].locationsNameList = newLocationList;
            this.selectedInvoice.fields = JSON.stringify(invoice_fields);
            this.invoices[toUpdateIndex].fields = JSON.stringify(invoice_fields);
        }

        update['fields'] = JSON.stringify(invoice_fields);

        // When updating the GL codes in this fashion we don't want to reload the invoices after patch
        this.patchUpdatedInvoice(update, true, false);
    }

    /**
     * Set a new status for an invoice
     * Called after clicking approve or deny button
     *
     * @param newStatus approve or deny event string
     * @param printDocs Default is false. Set to true if you want generated document printed after approval
     */
    async setStatus(newStatus: string, printDocs = false) {
        /**
         * Use different approval and denied status messages so we can filter which invoices to display based on user roles
         */
        const role_updates = {
            manager: { approved: 'manager-approved', denied: 'denied' },
            'corporate-office': { approved: 'corporate-office-approved', denied: 'denied' },
            accounting: { approved: 'approved', denied: 'denied', reopened: 'reopened' },
        };
        const profile_status = role_updates[this.user_role];

        const now = DateTime.now();
        let update = {
            status: profile_status[newStatus],
            statusLastSet: now.toString(),
        };

        /* if manager, put the amount entered for each location in the update */
        if (this.user_role === 'manager') {
            // validate the entered amounts and user signature
            // if updating to denied, don't force amounts to be entered
            if ((!this.glForm.valid && newStatus !== 'denied') || !this.signatureForm.valid) {
                this.validationService.validateAllFormFields(this.glForm);
                this.validationService.validateAllFormFields(this.signatureForm);
                return;
            }

            // create a payload of nested objects where for each location key, there is a locationfield object as value
            const location_payload = this.mpiApp.normalizeLocationDistribution(
                this.glformLocationArray,
                this.locations
            );

            // If we can't parse fields - because it doesn't exist, then use an empty object.
            let invoice_fields = JSON.parse(this.selectedInvoice.fields) || {};
            invoice_fields['locationDistribution'] = location_payload;
            update['fields'] = JSON.stringify(invoice_fields);

            update['managerSignature'] = this.signatureForm.get('signature').value;
        } else if (this.user_role === 'corporate-office') {
            // validate the entered amounts and user signature
            // if updating to denied, don't force amounts to be entered
            if ((!this.glForm.valid && newStatus !== 'denied') || !this.signatureForm.valid) {
                this.validationService.validateAllFormFields(this.glForm);
                this.validationService.validateAllFormFields(this.signatureForm);
                return;
            }

            // set payload with gl codes
            const location_payload = this.mpiApp.normalizeLocationDistribution(
                this.glformLocationArray,
                this.locations
            );
            let invoice_fields = JSON.parse(this.selectedInvoice.fields) || {};
            invoice_fields['locationDistribution'] = location_payload;
            update['fields'] = JSON.stringify(invoice_fields);

            // validate user signed off on invoice status
            if (!this.signatureForm.valid && newStatus !== 'denied') {
                this.validationService.validateAllFormFields(this.signatureForm);
                return;
            }

            update['corporateOfficeSignature'] = this.signatureForm.get('signature').value;
        } else if (this.user_role === 'accounting') {
            // validate gl codes are entered
            if (!this.glForm.valid && newStatus !== 'denied' && newStatus !== 'reopened') {
                this.validationService.validateAllFormFields(this.glForm);
                return;
            }

            // set payload with gl codes
            const location_payload = this.mpiApp.normalizeLocationDistribution(
                this.glformLocationArray,
                this.locations
            );

            // to preserve data other than location fields in invoice fields attribute
            // read the whole object, overwrite locationDistribution, and write back whole object
            let invoice_fields = JSON.parse(this.selectedInvoice.fields) || {};
            invoice_fields['locationDistribution'] = location_payload;
            update['fields'] = JSON.stringify(invoice_fields);
        }

        this.invoiceToShow.status = profile_status[newStatus];

        await this.patchUpdatedInvoice(update);

        if (printDocs) {
            this.printAllDocuments(false);
        }
    }

    /**
     * Send updated PATCH request to server. By default we hide invoices by deselecting what we just patched and reload
     * the list of invoices after patch.
     *
     * @param update
     * @param dontHideDetails
     * @param reloadInvoicesAfter
     */
    async patchUpdatedInvoice(update: any, dontHideDetails = false, reloadInvoicesAfter = true) {
        this.requestInProgress = true;
        return this.mpiApp
            .updateInvoice(this.invoiceToShow.id, update)
            .toPromise()
            .catch((error) => {
                const errMsg: string = error.error.error.message ? error.error.error.message : 'No Message';
                console.error(error);
                this.messageService.add({
                    severity: 'error',
                    summary: 'Invoice update failed',
                    detail: 'Error ' + error.status + ': ' + errMsg,
                });
            })
            .then((data) => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Invoice update successful',
                    detail: 'Invoice status updated',
                });
                this.glForm.markAsPristine();
            })
            .finally(() => {
                this.requestInProgress = false;
                if (!dontHideDetails) {
                    // deselect invoice to remove updated invoice from grid
                    this.deselectInvoice();
                }

                if (reloadInvoicesAfter) {
                    // reload invoices
                    this.getInvoices();
                }
            });
    }

    loadFilesForInvoice() {
        this.poFiles = [];
        this.invoiceFiles = [];

        this.POFileDetailsShown = false;
        this.invoiceFileDetailsShown = false;

        let docType = 'purchaseOrder';
        let docTypeID = String(this.invoiceToShow?.purchaseOrderID);

        this.mpiApp
            .getFiles(docType, docTypeID)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (data: FilePartial[]) => {
                    this.poFiles = data;
                    this.POFileDetailsShown = true;
                },
                (error) => {
                    console.error(error);
                }
            );

        docType = 'invoice';
        // docTypeID = String(this.invoiceToShow?.invoiceID);
        docTypeID = String(this.invoiceToShow?.id);

        this.mpiApp
            .getFiles(docType, docTypeID)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (data: FilePartial[]) => {
                    this.invoiceFiles = data;
                    this.invoiceFileDetailsShown = true;
                },
                (error) => {
                    console.error(error);
                }
            );
    }

    markInvoicePaid() {
        this.requestInProgress = true;
        let invoiceFields = JSON.parse(this.selectedInvoice.fields) || {};
        invoiceFields.importMatch = {
            invoiceID: this.selectedInvoice.invoiceID,
            vendorID: this.selectedInvoice.vendorID,
            checkNum: this.paidInvoiceControlForm.controls.checkNo.value,
        };
        const update = {
            fields: JSON.stringify(invoiceFields),
            paidDate: new Date(this.paidInvoiceControlForm.controls.paidDate.value).toISOString(),
            paymentBatchID: 'Manually Marked As Paid',
        };

        // Update the invoice
        this.mpiApp
            .updateInvoice(this.selectedInvoice.id, update)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (data) => {
                    // console.log(data);
                },
                error: (err) => {
                    console.error(err);
                    this.requestInProgress = false;
                },
                complete: () => {
                    this.requestInProgress = false;
                    this.getInvoices();
                },
            });
    }

    /**
     * Reset signature pad canvas
     */
    clearCanvas() {
        this.signaturePad.clearCanvas();
        this.signatureForm.reset();
    }

    /**
     * Save the latest version of the user string
     *
     * Take what's currently in our signature service and fill our signature form with it
     */
    saveSignature() {
        const signature: string = this.signatureService.getSignature();
        this.signatureForm.controls['signature'].setValue(signature);
    }

    isSignatureValid() {
        return this.signatureForm.valid;
    }

    /**
     * For accounting role, called when show denied invoices toggle is changed
     *
     * Update status of toggle and refresh invoices
     * @param event button checked event
     */
    setInvoiceFilter(event, type: string) {
        if (type === 'approved') {
            this.showApprovedInvoices = event.checked;
        } else if (type === 'denied') {
            this.showDeniedInvoices = event.checked;
        } else if (type === 'unpaid') {
            this.showUnpaidInvoices = event.checked;
        } else if (type === 'pending') {
            this.showPendingInvoices = event.checked;
        }

        if (this.selectedInvoice) {
            this.deselectInvoice();
            this.selectedInvoice = null;
        }
        this.getInvoices();
    }

    downloadFile(fileID: string) {
        this.mpiApp.showFile(fileID);
    }

    // Set the default GL code for all locations that don't have an existing GL code.
    setDefaultGLCode() {
        let isUpdatedGlCode = false;
        // Get the GL form locations
        const locations = this.glForm.get('locations') as UntypedFormArray;
        for (let i = 0; i < locations.value.length; i++) {
            if (locations.value[i].glCode === '') {
                // If the glCode for the location isn't set then set the default

                // This sets the model but doesn't update the control
                // locations.value[i].glCode = this.invoiceToShowVendor.defaultGLCode;

                // Get the location form control
                const locationControl = locations.controls[i];
                // Get the glCode form control inside the location
                const glCodeControl = locationControl.get('glCode');

                let updateValue = this.mpiApp.glCodeGenerator.generateGLCode(
                    locations.value[i].location,
                    this.invoiceToShowVendor.defaultGLCode
                );

                // If there is no mapping, just use the vendor's GL Code
                if (updateValue === '') {
                    updateValue = this.invoiceToShowVendor.defaultGLCode;
                }

                // Update the value on the form
                glCodeControl.setValue(updateValue);
                isUpdatedGlCode = true;
            }
        }

        if (isUpdatedGlCode) {
            this.saveLocations();
        }
    }

    printAllDocuments(previewOnly: boolean) {
        this.printRequestInProgress = true;
        this.requestInProgress = true;
        this.mpiApp
            .printAllDocuments(this.selectedInvoice.id)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (mergedPDF: any) => {
                    let blob: any = new Blob([mergedPDF], { type: 'application/pdf; charset=utf-8' });
                    // Create file URL
                    const url = window.URL.createObjectURL(blob);

                    if (!previewOnly) {
                        // Create filename-safe versions of identifiers
                        const safeInvoiceID = this.selectedInvoice.invoiceID.replace(/[^a-z0-9]/gi, '_');
                        const safeCompanyName = (this.selectedInvoice.vendorRecord as Vendor).name.replace(
                            /[^a-z0-9]/gi,
                            '_'
                        );
                        let safeLocations = '';
                        if (this.selectedInvoice.locationsNameList?.length === 1) {
                            safeLocations = 'Loc-' + this.selectedInvoice.locationsNameList[0];
                        } else if (this.selectedInvoice.locationsNameList?.length > 1) {
                            safeLocations = 'Loc-multiple';
                        } else {
                            safeLocations = 'Loc-unknown';
                        }

                        // Build the filename
                        const filename =
                            safeCompanyName +
                            '_' +
                            safeInvoiceID +
                            '_' +
                            safeLocations +
                            '_' +
                            DateTime.now().toFormat('MM-dd-yyyy');

                        // Create a hidden anchor to download the PDF with the custom filename
                        const downloadLink = document.createElement('a');
                        downloadLink.href = url;
                        downloadLink.download = filename;
                        document.body.appendChild(downloadLink);

                        // Open PDF view in new window for viewing
                        window.open(url, '_blank');
                        // Activate the hidden anchor to download PDF with custom filename
                        downloadLink.click();
                    } else {
                        // Open PDF view in new window for viewing only
                        window.open(url, '_blank');
                    }
                },
                error: (err) => {
                    this.printRequestInProgress = false;
                    this.requestInProgress = false;
                    console.error(err);
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Document Printing Failed',
                        detail:
                            'Error ' + err.status + ': ' + err.statusText !== 'unknown' ? err.statusText : err.message,
                    });
                },
                complete: () => {
                    this.printRequestInProgress = false;
                    this.requestInProgress = false;
                },
            });
    }

    changeInvoiceID() {
        const invoiceID = this.invoiceToShow.invoiceID;
        const newInvoiceID = this.invoiceDetails.get('invoiceID').value;

        // console.log(`Changed ${invoiceID} to ${newInvoiceID}`);

        if (invoiceID !== newInvoiceID) {
            // Change

            const payload = {
                invoiceID: newInvoiceID,
            };

            this.mpiApp
                .updateInvoice(this.invoiceToShow.id, payload)
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                    next: (response) => {
                        this.invoiceToShow.invoiceID = newInvoiceID;

                        this.messageService.add({
                            severity: 'info',
                            summary: 'Invoice number updated',
                        });
                    },
                    error: (error) => {
                        const errMsg: string = error.error.error.message ? error.error.error.message : 'No Message';
                        this.messageService.add({
                            severity: 'error',
                            summary: error.error.error.name + ': ' + errMsg,
                        });
                        this.invoiceDetails.get('invoiceID').setValue(invoiceID);
                    }
                }
                );
        } else {
            // Nothing changed
        }
    }

    customSort(event: SortEvent) {
        event.data.sort((data1, data2) => {
            let value1 = null;
            let value2 = null;
            let result = null;

            // Check if field is a subproperty
            if (event.field.split('.').length > 1) {
                const prop1 = event.field.split('.')[0];
                const prop2 = event.field.split('.')[1];
                value1 = data1[prop1][prop2];
                value2 = data2[prop1][prop2];
            } else {
                value1 = data1[event.field];
                value2 = data2[event.field];
            }

            if (value1 === null && value2 !== null) {
                result = -1;
            } else if (value1 !== null && value2 === null) {
                result = 1;
            } else if (value1 === null && value2 === null) {
                result = 0;
            } else if (event.field === 'invoiceId') {
                // Do special sort for invoiceID since ID can be number or alphanumeric
                let num1 = parseInt(value1);
                let num2 = parseInt(value2);

                if (isNaN(num1) && !isNaN(num2)) {
                    result = -1;
                } else if (!isNaN(num1) && isNaN(num2)) {
                    result = 1;
                } else if (isNaN(num1) && isNaN(num2)) {
                    result = value1.localeCompare(value2);
                } else {
                    result = num1 < num2 ? -1 : num1 > num2 ? 1 : 0;
                }
            } else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            } else {
                result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
            }

            return event.order * result;
        });
    }
}
