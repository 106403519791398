import { Component, OnDestroy, OnInit } from '@angular/core';
import * as xlsx from 'xlsx';
import { MPIAppService } from '../../services/mpiapp.service';
import { DateTime } from 'luxon';
import { Invoice } from '../api/models/invoice';
import { BehaviorSubject, Observable, Subject, Subscription, of } from 'rxjs';
import { finalize, startWith, takeUntil, takeWhile } from 'rxjs/operators';
import { ImportMatch } from './importMatch';
import { SagePaymentFileRow } from './SagePaymentFile';
import { IsLoadingService } from '@service-work/is-loading';

@Component({
    selector: 'app-import-sage-payments',
    templateUrl: './import-sage-payments.component.html',
    styleUrls: ['./import-sage-payments.component.scss'],
})
export class ImportSagePaymentsComponent implements OnInit, OnDestroy {
    private subs: Subscription[] = [];
    activeSubs$: Subject<number> = new Subject<number>();
    private destroy$: Subject<void> = new Subject<void>();

    totalHTTPCalls: number = null;
    invoicesRemaining: number = 0;

    fileName: string;
    excelFileRows: SagePaymentFileRow[];

    selectableInvoices: Invoice[];
    selectableInvoicesLoading$: Observable<boolean>;

    // save the invoice a payment was automatically matched to for display to user
    matchedInvoice: Invoice[] = [];
    // save paymentBatchID split into 2 strings, so we can display prev match info to user
    prevMatchInfo: string[][] = [];

    // current invoice the user wants to manually match
    selectedInvoice: Invoice;
    // current payment row the user wants to manually match
    currentFileRow: SagePaymentFileRow;

    viewInvoiceDisplay = false;
    selectInvoiceDisplay = false;
    previousMatchDisplay = false;

    constructor(
        private mpiApp: MPIAppService,
        public isLoadingService: IsLoadingService,
    ) { }

    ngOnInit(): void {
        this.selectableInvoicesLoading$ = this.isLoadingService.isLoading$({ key: 'invoices-unpaid' });

        this.activeSubs$
            .pipe(takeUntil(this.destroy$), startWith(this.subs.length))
            .subscribe((length) => {
                if (length === 0 && this.totalHTTPCalls !== null) {
                    this.totalHTTPCalls = 0;
                    this.invoicesRemaining = 0;
                    this.loadInvoices();
                }
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.subs.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    loadInvoices() {
        /**
         * Assume paymentBatchID is only set when matched, thus a non-null value means a prev match
         * Set selectable invoices to any invoice NOT already matched
         */
        const unpaidInvoicesFilter = { where: { and: [{ paymentBatchID: null }, { status: 'approved' }] } };
        this.isLoadingService.add(
            this.mpiApp.getInvoices(JSON.stringify(unpaidInvoicesFilter)).subscribe((filteredInvoices) => (this.selectableInvoices = filteredInvoices)),
            { key: 'invoices-unpaid' }
        );

    }

    uploadExcel(e: { files: File[] }) {
        this.invoicesRemaining = 0;
        // select files[0] because select button restricts to one file at a time
        //
        // Example for using fileReader from:
        // https://www.npmjs.com/package/xlsx/v/0.17.3#:~:text=change%27%2C%20handleFileAsync%2C%20false)%3B-,Using%20FileReader,-%3A
        // https://stackoverflow.com/a/37083658/14560781
        const file = e.files[0];
        this.fileName = file.name;

        const reader = new FileReader();
        reader.onload = (event) => {
            const workBook: xlsx.WorkBook = xlsx.read(event.target.result, { type: 'binary' });
            const jsonData: object = workBook.SheetNames.reduce((initial, name) => {
                const sheet = workBook.Sheets[name];
                initial[name] = xlsx.utils.sheet_to_json(sheet);
                return initial;
            }, {});
            this.excelFileRows = Object.values(jsonData)[0];

            this.excelFileRows.forEach((element, i, arr) => {
                // Format the check date column
                //
                // check date is already a number, and we convert it to a string
                // thus unary operator won't do anything besides satisfy type check
                arr[i]['check date'] = this.convertExcelDate(+element['check date']);

                if (element['check amount'].endsWith('-')) {
                    // Minus sign is on the wrong side of the number.
                    // Move it to the front.
                    element['check amount'] = '-' + element['check amount'].replace('-', '');
                }

                // make sure all values are strings since the Excel reader is loose with types
                arr[i]['check amount'] = element['check amount'].toString();
                arr[i]['check number'] = element['check number'].toString();
                arr[i]['invoice number'] = element['invoice number'].toString();

                // Store a behavior subject with value of ImportMatch status, so that we can async access and update the match status
                // once the HTTP call completes
                arr[i].matchedInvoice = this.matchInvoice(element);
            });
        };

        reader.onerror = (error) => {
            console.error(error);
        };

        reader.readAsBinaryString(file);
    }

    uploadCSV(e: { files: File[] }) {
        this.invoicesRemaining = 0;
        const CSVPositionMapping = {
            'check date': 1,
            'vendor number': 2,
            'vendor name': 3,
            'check amount': 4,
            'check type': 5,
            'check number': 0,
            'invoice number': 14,
            'invoice amount': 17
        };

        // select files[0] because select button restricts to one file at a time
        //
        // Example for using fileReader from:
        // https://www.npmjs.com/package/xlsx/v/0.17.3#:~:text=change%27%2C%20handleFileAsync%2C%20false)%3B-,Using%20FileReader,-%3A
        // https://stackoverflow.com/a/37083658/14560781
        const file = e.files[0];
        this.fileName = file.name;

        const reader = new FileReader();
        reader.onload = (event) => {
            const workBook: xlsx.WorkBook = xlsx.read(event.target.result, { type: 'binary', raw: true, cellText: true, WTF: true });
            const jsonData = xlsx.utils.sheet_to_json(workBook.Sheets.Sheet1, { header: 1, dateNF: 'YYYY-MM-DD' });

            // console.log(jsonData);

            this.excelFileRows = [];

            for (const element of jsonData) {

                const checkDateStr = element[CSVPositionMapping['check date']];
                // Return the currency as a raw number so the currency pipe can format it.
                let checkAmountStr: string = element[CSVPositionMapping['check amount']].toString();
                if (checkAmountStr.endsWith('-')) {
                    checkAmountStr = '-' + checkAmountStr.replace('-', '');
                }
                const checkAmount = this.mpiApp.stripCurrency(checkAmountStr);

                let invAmountStr: string = element[CSVPositionMapping['invoice amount']].toString();
                if (invAmountStr.endsWith('-')) {
                    invAmountStr = '-' + invAmountStr.replace('-', '');
                }

                const invoiceNumber = element[CSVPositionMapping['invoice number']].toString();

                // console.log(`Imported ${invoiceNumber} for ${checkAmount}`);

                const sagePaymentFileRow: SagePaymentFileRow = {
                    'check number': element[CSVPositionMapping['check number']].toString(),
                    'check date': checkDateStr,
                    'check amount': checkAmount,
                    'invoice amount': this.mpiApp.stripCurrency(invAmountStr).toString(),
                    'invoice number': invoiceNumber,
                    'check type': element[CSVPositionMapping['check type']].toString(),
                    'vendor name': element[CSVPositionMapping['vendor name']].toString(),
                    'vendor number': element[CSVPositionMapping['vendor number']].toString(),
                    // Because matchedInvoice needs the result of the this.matchInvoice function needs
                    // the SagePaymentFileRow we stub out a placeholder value so we can build the entry
                    // and then feed the SadePaymentFileRow back into matchInvoice
                    matchedInvoice: new BehaviorSubject<ImportMatch>({ prevMatch: false })
                };

                // Now that we have the structure of the row see if we can match it
                sagePaymentFileRow.matchedInvoice = this.matchInvoice(sagePaymentFileRow);

                // console.log(sagePaymentFileRow);

                this.excelFileRows.push(sagePaymentFileRow);
            }
        };

        reader.onerror = (error) => {
            console.error(error);
        };

        reader.readAsBinaryString(file);
    }

    convertExcelDate(date1900: number) {
        const excelEpoch = DateTime.fromSQL('1900-01-01', { zone: 'utc' }).plus({ days: date1900 });
        return this.mpiApp.convertDateToDisplayDate(excelEpoch);
    }

    /**
     * Return an object describing if the element is found to have been previously matched or not
     *
     * If so, also return its payment batch id
     *
     * If not, return whether an automatic invoice match was found
     *
     * Automatic invoice match if invoiceID matches the Excel invoice number, vendor IDs match,  and the invoice is fully approved
     *
     * @param excelRow excel sheet excelRow line
     * @returns behavior subject with import match object to determine if the invoice has been matched
     */
    matchInvoice(excelRow: SagePaymentFileRow): BehaviorSubject<ImportMatch> {
        /**
         * Return a behavior subject so that if there's no previous automatic match
         * we can update the value of the match status once our http request returns
         *
         * Behavior subject has value of an ImportMatch object, so that we can get the current status of the match by getting the value
         * of the behavior subject
         *
         * Set to NULL initially so that buttons display a loading symbol
         */
        const matchStatusSubject = new BehaviorSubject<ImportMatch>(null);

        const paidKey = { invoiceID: excelRow['invoice number'], vendorID: excelRow['vendor number'], checkNum: excelRow['check number'] };

        const subscription =
            this.mpiApp.matchInvoice(JSON.stringify(paidKey), this.fileName, excelRow['check date'])
                .pipe(
                    takeWhile((data) => {
                        if (data) {
                            matchStatusSubject.next(data);
                            return false;
                        } else {
                            return true;
                        }
                    }),
                    finalize(() => {
                        const index = this.subs.indexOf(subscription);
                        if (index !== -1) {
                            this.subs.splice(index, 1);
                            this.activeSubs$.next(this.subs.length);
                            this.invoicesRemaining = Math.floor((1 - (this.subs.length / this.totalHTTPCalls)) * 100);
                        }
                    }))
                .subscribe({
                    error: (err) => {
                        console.error('matchInvoice: ', err);
                        matchStatusSubject.next({ prevMatch: false, invoice: undefined });
                    },
                });
        this.subs.push(subscription);
        this.activeSubs$.next(this.subs.length);
        ++this.totalHTTPCalls;

        return matchStatusSubject;
    }

    /**
     * Open dialog to show matched invoice paymentBatch info
     */
    showViewInvoiceDialog() {
        this.viewInvoiceDisplay = true;
    }

    /**
     * Save the invoice we matched to in a public state variable, so we can display its info when clicking 'previous match'
     * Then open matched invoice info dialog
     *
     * Used to display info to user for what invoice was automatically matched
     * @param fileRow an entry in excelFileRows
     */
    setMatchedInvoice(fileRow: SagePaymentFileRow) {
        const matchStatus: ImportMatch = fileRow.matchedInvoice.value;

        this.matchedInvoice = [matchStatus.invoice];

        this.showViewInvoiceDialog();
    }

    /**
     * Save the Excel file row the user selected to make a manual match for
     * Then open dialog to select a manual match
     * @param fileRow an entry in excelFileRows
     */
    showSelectInvoiceDialog(fileRow) {
        this.currentFileRow = fileRow;
        this.selectInvoiceDisplay = true;
    }

    closeSelectInvoiceDialog() {
        this.selectInvoiceDisplay = false;
    }

    /**
     * Save the user's invoice match selection and close selection dialog
     */
    manuallyMatchInvoice() {
        this.linkInvoice();

        this.closeSelectInvoiceDialog();
    }

    /**
     * Save the payment batch id for a previous payment match
     * Used when displaying to user the invoice's previous match details
     * @param fileRow an entry in excelFileRows
     */
    showPreviousMatchDialog(fileRow: SagePaymentFileRow) {
        /* save payment batch id into array, so we can display it in the ptable */
        const matchStatus: ImportMatch = fileRow.matchedInvoice.value;
        const prevInfo = matchStatus.paymentBatchID.split('|');
        this.prevMatchInfo = [prevInfo];

        this.previousMatchDisplay = true;
    }

    /**
     * Update invoice with invoiceID to have match with incoming Excel file row
     *
     * Will either be called from matchInvoice during automatic matching on file read
     * or
     * If no automatic match, will be called when user manually selects an invoice to match with
     * in that case will use current state variables instead of passed parameters
     *
     * @param fileRow Object with a payment from a row in the imported Excel file
     * @param invoiceField Parsed invoice fields attribute
     * @param invoiceID ID of invoice to update
     */
    linkInvoice(fileRow?: SagePaymentFileRow, invoiceField?: any, invoiceID?: string) {
        let excelRow: SagePaymentFileRow = null;
        if (!fileRow) {
            // current payment row the user wants to match is saved in currentFileRow when select dialog is opened
            excelRow = this.currentFileRow;
        } else {
            excelRow = fileRow;
        }

        let invoiceFields: any = null;
        if (!invoiceField) {
            // User's selected invoice to match to is saved to selectedInvoice by ptable
            invoiceFields = JSON.parse(this.selectedInvoice.fields) || {};
        } else {
            invoiceFields = invoiceField;
        }

        let id: string = null;
        if (!invoiceID) {
            // get id of selected invoice if making manual match
            id = this.selectedInvoice.id.toString();
        } else {
            id = invoiceID;
        }

        // JSON object querying is picky, won't implicitly cast numbers to string in queries
        // ensure all fields are strings in the object so queries work
        invoiceFields.importMatch = {
            invoiceID: excelRow['invoice number'].toString(),
            vendorID: excelRow['vendor number'].toString(),
            checkNum: excelRow['check number'].toString(),
        };
        // UTC offset, may not match user's local time
        const matchBatchID = this.fileName + ' | ' + new Date().toISOString().slice(0, 10);
        const update = {
            fields: JSON.stringify(invoiceFields),
            paidDate: new Date(excelRow['check date']).toISOString(),
            paymentBatchID: matchBatchID,
        };

        this.subs.push(
            this.mpiApp.updateInvoice(id, update)
                .subscribe({
                    next: (data) => {
                        // update this file row's matched invoice status so its button changes
                        this.currentFileRow.matchedInvoice.next({ prevMatch: false, invoice: this.selectedInvoice });

                        this.selectedInvoice = null;
                        this.loadInvoices();
                    },
                    error: (err) => { console.error(err) },
                    complete: () => { }
                })
        );
    }

    /**
     * Clear the currently uploaded file to allow user to import new file
     *
     * @param fileUpload fileUpload reference variable from template
     */
    clearFile(fileUpload: any) {
        fileUpload.clear();

        this.fileName = null;
        this.excelFileRows = [];
        this.currentFileRow = null;
        this.selectedInvoice = null;
        this.selectableInvoices = [];
        this.viewInvoiceDisplay = false;
        this.selectInvoiceDisplay = false;
        this.previousMatchDisplay = false;
    }

    // // data: AOA = [ [1, 2], [3, 4] ];
    // data;
    // // wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
    // // fileName: string = 'SheetJS.xlsx';
    // ver: string = XLSX.version;
    //
    // onFileChange(evt: any) {
    //     /* wire up file reader */
    //     const target: DataTransfer = <DataTransfer>(evt.target);
    //     if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    //     const reader: FileReader = new FileReader();
    //     reader.onload = (e: any) => {
    //         /* read workbook */
    //         const ab: ArrayBuffer = e.target.result;
    //         const wb: XLSX.WorkBook = XLSX.read(ab);
    //
    //         /* grab first sheet */
    //         const wsname: string = wb.SheetNames[0];
    //         const ws: XLSX.WorkSheet = wb.Sheets[wsname];
    //
    //         /* save data */
    //         this.data = <AOA>(XLSX.utils.sheet_to_json(ws, {header: 1}));
    //     };
    //     reader.readAsArrayBuffer(target.files[0]);
    // }

    // export(): void {
    //     /* generate worksheet */
    //     const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);
    //
    //     /* generate workbook and add the worksheet */
    //     const wb: XLSX.WorkBook = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    //
    //     /* save to file */
    //     XLSX.writeFile(wb, this.fileName);
    // }
}
