import { Component, OnInit } from '@angular/core';
import { Message, MessageService } from 'primeng/api';
import { Vendor } from 'src/app/api/models/vendor';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MPIAppService } from '../../services/mpiapp.service';
import { finalize } from 'rxjs/operators';
import { ValidationService } from '../validation.service';

@Component({
    selector: 'app-invite-vendor-form',
    templateUrl: './invite-vendor-form.component.html',
    styleUrls: ['./invite-vendor-form.component.scss'],
    providers: [MessageService],
})
export class InviteVendorFormComponent implements OnInit {
    errorMsgs: Message[] = [];
    vendor: Vendor;
    locations: any[];
    requestInProgress: boolean = false;

    vendorInvite = this.formBuilder.group(
        {
            name: ['', Validators.required],
            accountContactFirstName: ['', Validators.required],
            accountContactLastName: ['', Validators.required],
            accountContactEmail: ['', [Validators.required, Validators.email]],
            selectedLocations: ['', Validators.required],
        },
        {
            updateOn: 'blur',
        }
    );

    constructor(
        private messageService: MessageService,
        private formBuilder: UntypedFormBuilder,
        public mpiApp: MPIAppService,
        private validationService: ValidationService
    ) {}

    ngOnInit(): void {
        this.mpiApp.getLocations().subscribe((locations) => (this.locations = locations));
    }

    submitForm(event) {
        /* Validate all fields first */
        if (!this.vendorInvite.valid) {
            this.validationService.validateAllFormFields(this.vendorInvite);
            this.errorMsgs = [
                {
                    severity: 'error',
                    summary: 'Invalid Form',
                    detail: 'Please correct all errors and resubmit',
                },
            ];
            return;
        }

        const vendor = {} as Vendor;
        vendor['name'] = this.vendorInvite.get('name').value;
        vendor['accountContactFirstName'] = this.vendorInvite.get('accountContactFirstName').value;
        vendor['accountContactLastName'] = this.vendorInvite.get('accountContactLastName').value;
        vendor['accountContactEmail'] = this.vendorInvite.get('accountContactEmail').value;

        // Change the list of applicable locations to an array of location Names
        let applicableLocationsObj = this.vendorInvite.get('selectedLocations').value;

        const locationList: string[] = [];
        applicableLocationsObj.forEach((item) => {
            locationList.push(item.name);
        });

        vendor['locationsNameList'] = locationList;

        applicableLocationsObj = null; // No longer needed

        this.requestInProgress = true;

        this.mpiApp
            .inviteVendor(vendor)
            .pipe(
                finalize(() => {
                    this.requestInProgress = false;
                })
            )
            .subscribe({
                next: (data) => {
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Invite submit successfull',
                        detail: 'Vendor invite successfully submitted',
                    });
                    this.vendorInvite.reset();
                },
                error: (error) => {
                    console.log(error);
                    const loopbackError = error.error.error;
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Invite submit failed',
                        detail: 'Error ' + loopbackError.statusCode + ': ' + loopbackError.message,
                    });
                },
            });
    }
}
