<div *ngIf="verificationStatus !== 'checking'">
    <p-splitButton label="Actions" icon="pi pi-cog" [model]="fileActionItems"></p-splitButton>
</div>

<div *ngIf="verificationStatus === 'checking'">Checking...</div>

<div *ngIf="verificationStatus === 'pending'">
    <button
        *ngIf="!isDenyClicked"
        pButton
        type="button"
        label="Deny"
        class="p-button-danger my-2"
        (click)="changeDenyState(true)"></button>
    <p-inputGroup *ngIf="isDenyClicked" class="my-2">
        <button
            type="button"
            pButton
            pRipple
            icon="pi pi-times"
            class="p-button-danger"
            (click)="changeDenyState(false)"></button>
        <input type="text" pInputText placeholder="Reason for Denial" [(ngModel)]="reasonForDenial" />
        <button
            type="button"
            pButton
            pRipple
            icon="pi pi-check"
            class="p-button-success"
            (click)="updateVerificationStatus('denied', reasonForDenial)"></button>
    </p-inputGroup>
    <button
        pButton
        type="button"
        label="Approve"
        class="p-button-success mb-2"
        (click)="updateVerificationStatus('approved')"></button>
</div>

<div *ngIf="verificationStatus === 'approved'">
    <button
        *ngIf="!isDenyClicked"
        pButton
        type="button"
        label="Deny"
        class="p-button-danger my-2"
        (click)="changeDenyState(true)"></button>
    <p-inputGroup *ngIf="isDenyClicked" class="my-2">
        <button
            type="button"
            pButton
            pRipple
            icon="pi pi-times"
            class="p-button-danger"
            (click)="changeDenyState(false)"></button>
        <input type="text" pInputText placeholder="Reason for Denial" [(ngModel)]="reasonForDenial" />
        <button
            type="button"
            pButton
            pRipple
            icon="pi pi-check"
            class="p-button-success"
            (click)="updateVerificationStatus('denied', reasonForDenial)"></button>
    </p-inputGroup>
</div>

<div *ngIf="verificationStatus == 'denied'">
    <button
        pButton
        type="button"
        label="Approve"
        class="p-button-success my-2"
        (click)="updateVerificationStatus('approved')"></button>
</div>

<div *ngIf="verificationStatus == ''">Unknown file state</div>
