<p-toast></p-toast>
<p-table
    #expiringTable
    [columns]="columns"
    [value]="vendors"
    styleClass="p-datatable-gridlines"
    responsiveLayout="scroll">
    <ng-template pTemplate="caption">
        <div class="p-d-flex">
            <button
                type="button"
                pButton
                pRipple
                icon="pi pi-file-pdf"
                label="Print"
                (click)="exportPDF()"
                class="p-button-warning p-mr-2"
                pTooltip="Print to PDF"
                tooltipPosition="bottom"></button>
            <p-progressSpinner
                *ngIf="requestInProgress"
                [style]="{ width: '30px', height: '30px' }"
                styleClass="custom-spinner"
                strokeWidth="8"
                fill="#EEEEEE"
                animationDuration=".5s"></p-progressSpinner>
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <!-- <th *ngFor="let col of columns" pSortableColumn="col.field">{{ col.header }}</th> -->
            <th pSortableColumn="accountContactLastName">
                Name <p-sortIcon field="accountContactLastName"></p-sortIcon>
            </th>
            <th pSortableColumn="name">Company <p-sortIcon field="name"></p-sortIcon></th>
            <th pSortableColumn="originalFileName">File Name <p-sortIcon field="originalFileName"></p-sortIcon></th>
            <th pSortableColumn="expirationDate">Expiration Date <p-sortIcon field="expirationDate"></p-sortIcon></th>
            <th pSortableColumn="verificationStatus">Status <p-sortIcon field="verificationStatus"></p-sortIcon></th>
            <th pSortableColumn="note">Note <p-sortIcon field="note"></p-sortIcon></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-vendor let-columns="columns">
        <tr>
            <!-- <td *ngFor="let col of columns">
                {{ vendor[col.field] }}
            </td> -->
            <td>{{ vendor.accountContactFirstName }} {{ vendor.accountContactLastName }}</td>
            <td>{{ vendor.name }}</td>
            <td>{{ vendor.originalFileName }}</td>
            <td>{{ vendor.expirationDate }}</td>
            <td>
                {{ vendor.verificationStatus }}
                <p-tag *ngIf="vendor.notifiedExpirationTimestamp" severity="success" value="Notified"></p-tag>
                <p-tag *ngIf="vendor.notifiedExpirationTimestamp === null" severity="danger" value="Not Notified"></p-tag>
            </td>
            <td>{{ vendor.note }}</td>
        </tr>
    </ng-template>
</p-table>
