import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { SessionService } from 'src/session.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    inputStyle = 'outlined';

    ripple: boolean;

    constructor(
        private primengConfig: PrimeNGConfig, 
        private router: Router, 
        public session: SessionService
    ) {
    }

    ngOnInit() {
        this.primengConfig.ripple = true;
        this.ripple = true;
    }

    showSelectionCard(): boolean {
        return this.session.isPortalSelection && this.router.url === '/';
    }

    showVendorLogin() {
        this.session.isPortalSelection = false;
        this.router.navigate(['/login']);
    }

    showDocPortalLogin() {
        window.location.href = '/doc-app/';
    }
}
