import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/session.service';
import { LoginModel } from '../models/login.model';

@Component({
    selector: 'app-redirect',
    templateUrl: './redirect.component.html',
    styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent implements OnInit {
    constructor(private router: Router, public session: SessionService) {}

    ngOnInit(): void {
        /* Fetch user profile from localstorage */
        const profile: LoginModel = this.session.getUserProfile();

        if (profile?.role) {
            switch (profile?.role) {
                case 'accounting': {
                    this.router.navigate(['/accounting/vendor/approval']);
                    break;
                }
                case 'accounts-payable': {
                    this.router.navigate(['/accounts-payable/mark-paid']);
                    break;
                }
                case 'corporate-office': {
                    this.router.navigate(['/purchasing/approval']);
                    break;
                }
                case 'manager': {
                    this.router.navigate(['manager/dashboard']);
                    break;
                }
                case 'vendor': {
                    this.router.navigate(['vendor/info']);
                    break;
                }
            }
        }
    }
}
