<router-outlet *ngIf="!showSelectionCard(); else portalSelection"></router-outlet>

<ng-template #portalSelection>
    <div class="flex justify-content-center">
        <div class="flex justify-content-center mt-5 w-full md:w-5">
            <p-card header="Select Portal Login">
                <ng-template pTemplate="content">
                    <div class="flex justify-content-center">
                        <div class="grid">
                            <div class="col-12 sm:col-6">
                                <div class="flex flex-column text-center">
                                    <div>
                                        <i class="fa-solid fa-users fa-4x fa-fw"></i>
                                    </div>
                                    <h4>
                                        Vendor Portal
                                    </h4>
                                    <p>
                                        If you are a vendor for Modern Parking, Inc and need to register for an account,
                                        submit an invoice, or check on payment
                                        status, please visit our vendor portal.
                                    </p>
                                    <p-button label="Login to Vendor Portal" icon="pi pi-arrow-right" iconPos="right"
                                        styleClass="w-full" (onClick)="showVendorLogin()"></p-button>
                                </div>
                            </div>
                            <div class="col-12 sm:col-6">
                                <div class="flex flex-column text-center">
                                    <div>
                                        <i class="fa-light fa-file-invoice fa-4x fa-fw"></i>
                                    </div>
                                    <h4>
                                        Document Portal
                                    </h4>
                                    <p>
                                        If you are a customer of Modern Parking, Inc and need to receive or view
                                        documents
                                        that have been sent to you please
                                        visit the document portal.
                                    </p>
                                    <p-button label="Login to Document Portal" icon="pi pi-arrow-right" iconPos="right"
                                        styleClass="w-full" (onClick)="showDocPortalLogin()"></p-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-card>
        </div>
    </div>
</ng-template>