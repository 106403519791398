<p-toast></p-toast>

<!--<div *ngIf="promptLocations" class="dashboard grid p-fluid">-->
<!--    <div class="col-12 md:col-12">-->
<!--        <div class="card">-->
<!--            <h1 style="font-size:16px">Location to show invoices for:</h1>-->
<!--            <div class="col-3 md:col-3">-->
<!--                <p-dropdown [options]="locations" [(ngModel)]="chosenLocation" placeholder="Select a Location"-->
<!--                    optionLabel="name" [showClear]="true" (onChange)="changeLocation($event)"></p-dropdown>-->
<!--            </div>-->

<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<div class="dashboard grid p-fluid">
    <div class="col-12 md:col-12 no-print">
        <div class="card">
            <h1 style="font-size: 16px">{{ statusHeader }}</h1>

            <div *ngIf="user_role === 'accounting' && !showPaidInvoices" class="col-3 mb-3">
                <p-toggleButton [onLabel]="'Denied invoices'" [offLabel]="'Denied invoices'" [onIcon]="'pi pi-check'"
                    [offIcon]="'pi pi-times'" [(ngModel)]="showDeniedInvoices"
                    (onChange)="setInvoiceFilter($event, 'denied')"></p-toggleButton>
                <p-toggleButton [onLabel]="'Approved invoices'" [offLabel]="'Approved invoices'"
                    [onIcon]="'pi pi-check'" [offIcon]="'pi pi-times'" [(ngModel)]="showApprovedInvoices"
                    (onChange)="setInvoiceFilter($event, 'approved')"></p-toggleButton>
                <p-toggleButton [onLabel]="'Pending Invoices'" [offLabel]="'Pending Invoices'" [onIcon]="'pi pi-check'"
                    [offIcon]="'pi pi-times'" [(ngModel)]="showPendingInvoices"
                    (onChange)="setInvoiceFilter($event, 'pending')"></p-toggleButton>
            </div>
            <div *ngIf="showPaidInvoices" class="col-3 mb-3">
                <p-toggleButton [onLabel]="'Unpaid invoices'" [offLabel]="'Unpaid invoices'" [onIcon]="'pi pi-check'"
                    [offIcon]="'pi pi-times'" [(ngModel)]="showUnpaidInvoices"
                    (onChange)="setInvoiceFilter($event, 'unpaid')"></p-toggleButton>
                <p-toggleButton [onLabel]="'Denied invoices'" [offLabel]="'Denied invoices'" [onIcon]="'pi pi-check'"
                    [offIcon]="'pi pi-times'" [(ngModel)]="showDeniedInvoices"
                    (onChange)="setInvoiceFilter($event, 'denied')"></p-toggleButton>
            </div>

            <!-- <div *ngIf="requestInProgress" class="col-3 mb-3">
                <p-progressSpinner></p-progressSpinner>
            </div> -->

            <p-table #invoiceTable [value]="displayedInvoices" [globalFilterFields]="[
                    'invoiceId',
                    'dateCreated',
                    'status',
                    'datePaid',
                    'amount',
                    'vendorName',
                    'locationNames'
                ]" selectionMode="single" [(selection)]="selectedViewInvoice" [paginator]="true"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} invoices" [rows]="10"
                [customSort]="true" [lazy]="true" [lazyLoadOnInit]="false" [loading]="loadingInvoices$ | async"
                [totalRecords]="totalInvoices" (onLazyLoad)="loadInvoices($event)" (sortFunction)="customSort($event)"
                (onRowSelect)="selectInvoice($event)" (onRowUnselect)="deselectInvoice()">
                <ng-template pTemplate="caption">
                    <div class="flex align-items-center justify-content-end">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" id="invoice-table-search"
                                    (input)="invoiceTable.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="invoiceId">
                            Invoice #
                            <p-sortIcon field="invoiceId"></p-sortIcon>
                        </th>
                        <th pSortableColumn="vendorName">Company <p-sortIcon field="vendorName"></p-sortIcon></th>
                        <th pSortableColumn="locationNames">
                            Locations <p-sortIcon field="locationNames"></p-sortIcon>
                        </th>
                        <th pSortableColumn="submittedDate">
                            Date Created
                            <p-sortIcon field="submittedDate"></p-sortIcon>
                        </th>
                        <th pSortableColumn="status">
                            Status
                            <p-sortIcon field="status"></p-sortIcon>
                        </th>
                        <th *ngIf="showPaidInvoices">Check #</th>
                        <th *ngIf="showPaidInvoices" pSortableColumn="paidDate">
                            Paid Date<p-sortIcon field="paidDate"></p-sortIcon>
                        </th>
                        <th pSortableColumn="amount">
                            Amount
                            <p-sortIcon field="amount"></p-sortIcon>
                        </th>
                        <th pSortableColumn="dateCreated" style="display: none">Date Created</th>
                        <th pSortableColumn="datePaid" style="display: none">Date Paid</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-invoice>
                    <tr [pSelectableRow]="invoice">
                        <td>{{ invoice.invoiceId }}</td>
                        <td>{{ invoice.vendorName }}</td>
                        <td class="text-truncate">
                            {{ invoice.locationNames }}
                        </td>
                        <td>
                            {{ invoice.submittedDate | date : 'M/d/yyyy hh:mm a' }}
                        </td>
                        <td>{{ invoice.status }}</td>
                        <td *ngIf="showPaidInvoices">{{ invoice.checkNum }}</td>
                        <td *ngIf="showPaidInvoices">{{ invoice.paidDate | date : 'short' }}</td>
                        <td>{{ invoice.amount | currency : 'USD' }}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>

    <!-- Invoice details box -->
    <div class="col-12 print-content print-container">
        <div class="grid" *ngIf="invoiceDetailsShown">
            <div class="col-12">
                <div class="unified-row grid p-fluid">
                    <div class="col-12 md:col-3">
                        <div class="p-fluid min-height-card">
                            <h5>Invoice Details</h5>
                            <form [formGroup]="invoiceDetails">
                                <div class="invoice-details-grid">
                                    <!-- Debug invoice ID -->
                                    <!-- <div>Invoice: {{invoiceToShow.id}}</div> -->
                                    <div>Invoice:</div>
                                    <div>
                                        <input type="text" pInputText id="invoice-invoice-id"
                                            formControlName="invoiceID" (change)="changeInvoiceID()" />
                                    </div>
                                    <div>Purchase Order:</div>
                                    <div>
                                        <input type="text" pInputText id="invoice-po-id" formControlName="poid" />
                                    </div>
                                    <div>Company:</div>
                                    <div>
                                        <input type="text" pInputText id="invoice-vendor-name"
                                            formControlName="vendorName" />
                                    </div>

                                    <div>Locations:</div>
                                    <div>
                                        <input type="text" pInputText id="invoice-location-list"
                                            formControlName="locations" />
                                    </div>
                                    <div>Amount:</div>
                                    <div>
                                        <input type="text" pInputText id="invoice-amount" formControlName="amount" />
                                    </div>
                                    <div>Submitted:</div>
                                    <div>
                                        <input type="text" pInputText id="invoice-submit-date"
                                            formControlName="submittedDate" />
                                    </div>
                                    <div *ngIf="showPaidInvoices">Check Number:</div>
                                    <div *ngIf="showPaidInvoices">
                                        <input type="text" pInputText id="invoice-check-num"
                                            formControlName="checkNum" />
                                    </div>
                                </div>
                            </form>
                            <!-- invoiceDetails -->
                        </div>
                    </div>

                    <!-- If manager, corporate, or accounting, show table of locations to appropriate the amount between-->
                    <div *ngIf="user_role === 'manager' || user_role === 'corporate-office' || user_role === 'accounting'"
                        class="col-12 md:col-9">
                        <h1 *ngIf="user_role === 'manager'" style="font-size: 16px">Enter Invoice Location Amounts</h1>
                        <h1 *ngIf="user_role === 'corporate-office'" style="font-size: 16px">
                            Entered Invoice Location Amounts
                        </h1>
                        <h1 *ngIf="user_role === 'accounting'" style="font-size: 16px">Entered Invoice Location Amounts
                        </h1>
                        <form [formGroup]="glForm">
                            <div formArrayName="locations">
                                <p-table [value]="glformLocationArray.controls" responsiveLayout="scroll"
                                    styleClass="p-datatable-sm p-datatable-gridlines">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th style="width: 3.5rem">
                                                <div class="flex align-items-center justify-content-start">
                                                    <button pButton pRipple type="button" icon="pi pi-plus"
                                                        class="p-button-rounded p-button-success p-button-text"
                                                        (click)="addLocation()" *ngIf="!showPaidInvoices"></button>
                                                </div>
                                            </th>
                                            <th>Location Name</th>
                                            <!-- GL Code-->
                                            <th [ngClass]="{ 'hide-gl-codes': user_role !== 'accounting' }">
                                                <div class="flex justify-content-between">
                                                    <div class="flex align-self-center justify-content-start">GL Code
                                                    </div>
                                                    <div *ngIf="invoiceToShowVendor?.defaultGLCode === undefined"
                                                        class="flex align-self-center justify-content-end">
                                                        <p-tag severity="warning" value="No GL Code Set"></p-tag>
                                                    </div>
                                                    <div class="flex justify-content-end">
                                                        <button pButton pRipple
                                                            pTooltip="Click to set the default GL code for this vendor."
                                                            tooltipPosition="top" type="button" icon="pi pi-bookmark"
                                                            class="p-button-rounded p-button-text p-button-plain"
                                                            (click)="setDefaultGLCode()"
                                                            *ngIf="!showPaidInvoices"></button>
                                                        <!-- <i
                                                        class="fa-solid fa-book-open"
                                                        (click)="setDefaultGLCode()"
                                                        pTooltip="Click to set the default GL code for this vendor."
                                                        tooltipPosition="top">
                                                    </i> -->
                                                    </div>
                                                </div>
                                            </th>
                                            <th>Amount</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-i="rowIndex">
                                        <tr [formGroupName]="i">
                                            <td>
                                                <div class="flex align-self-center justify-content-start">
                                                    <button pButton pRipple type="button" icon="pi pi-times"
                                                        class="p-button-rounded p-button-danger p-button-text p-button-sm"
                                                        (click)="deleteLocation(i)" *ngIf="!showPaidInvoices"></button>
                                                </div>
                                            </td>
                                            <td>
                                                <p-autoComplete inputId="location-name-{{i}}" formControlName="location"
                                                    [suggestions]="locationSearchResults"
                                                    (completeMethod)="searchLocations($event.query)"
                                                    (onSelect)="selectLocation($event.value, i)"
                                                    (onBlur)="selectLocation($event.target.value, i)" appendTo="body">
                                                    <ng-template let-loc pTemplate="item">
                                                        {{ loc.name }} - {{ loc.description }}
                                                    </ng-template>
                                                </p-autoComplete>
                                                <!-- <input
                                                    type="text"
                                                    pInputText
                                                    formControlName="location"
                                                    required
                                                    (onchange)="generateGLCode($event.target.value, i)"
                                                    (blur)="generateGLCode($event.target.value, i)" /> -->
                                                <app-control-messages
                                                    [control]="glformLocationArray.controls[i].get('location')">
                                                </app-control-messages>
                                            </td>
                                            <td [ngClass]="{ 'hide-gl-codes': user_role !== 'accounting' }">
                                                <input type="text" pInputText id="gl-code-{{i}}"
                                                    formControlName="glCode" />
                                                <app-control-messages
                                                    [control]="glformLocationArray.controls[i].get('glCode')">
                                                </app-control-messages>
                                                <br />
                                                <!-- <label for="comment-{{i}}">Comment</label> -->
                                                <div class="pt-2">
                                                    <input type="text" pInputText id="comment-{{i}}"
                                                        placeholder="Comment" formControlName="comment"
                                                        class="p-inputtext-sm" />
                                                </div>
                                            </td>
                                            <td>
                                                <!--{{glformLocationArray.value[i].amount | currency: 'USD'}}-->
                                                <p-inputGroup>
                                                    <p-inputGroupAddon>$</p-inputGroupAddon>
                                                    <p-inputNumber mode="decimal" locale="en-US" [minFractionDigits]="2"
                                                        [maxFractionDigits]="2" [min]="0" formControlName="amount"
                                                        required inputId="amount-{{i}}">
                                                    </p-inputNumber>
                                                </p-inputGroup>
                                                <app-control-messages
                                                    [control]="glformLocationArray.controls[i].get('amount')">
                                                </app-control-messages>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                            <div class="m-2">
                                <div>
                                    Total Amount Entered: {{ enteredAmountSum | currency : 'USD' }}
                                    <p-button label="Save Locations" [loading]="requestInProgress"
                                        [disabled]="enteredAmountSum !== invoiceToShow.amount"
                                        (onClick)="saveLocations()" *ngIf="!showPaidInvoices"></p-button>
                                    <div *ngIf="enteredAmountSum !== invoiceToShow.amount">
                                        There is a
                                        {{ enteredAmountSum - invoiceToShow.amount | currency : 'USD' }}
                                        difference between the invoice and location amount.
                                    </div>
                                </div>
                                <app-control-messages [control]="glformLocationArray"></app-control-messages>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-12" *ngIf="
                invoiceDetailsShown &&
                selectedInvoice.status !== 'paid' &&
                (user_role == 'accounting' || user_role == 'accounts-payable')
            ">
                <div class="card">
                    <h5>Manually Mark Invoice As Paid</h5>
                    <form [formGroup]="paidInvoiceControlForm">
                        <div class="grid p-fluid mt-2">
                            <div class="m-2">
                                <label for="paid-date">Paid Date</label>
                                <p-calendar inputId="paid-date" formControlName="paidDate"
                                    dateFormat="dd/mm/yy"></p-calendar>
                            </div>
                            <div class="m-2">
                                <label for="check-num">Check Num</label>
                                <p-inputNumber inputId="check-num" formControlName="checkNo" [useGrouping]="false"
                                    allowEmpty="true"></p-inputNumber>
                            </div>
                            <div class="m-2">
                                <label for="amount-paid">Amount</label>
                                <p-inputNumber inputId="amount-paid" formControlName="amount" mode="currency"
                                    currency="USD" locale="en-US" allowEmpty="true"></p-inputNumber>
                            </div>
                            <div class="p-d-flex p-ai-end mb-2">
                                <button pButton type="submit" label="Submit" [disabled]="
                                    paidInvoiceControlForm.invalid ||
                                    paidInvoiceControlForm.controls.amount.value !== selectedInvoice.amount
                                " [loading]="requestInProgress" (click)="markInvoicePaid()"></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <!-- Show manager and corporate office signatures if accounting -->
            <div class="col-12" *ngIf="
                invoiceDetailsShown &&
                (showPaidInvoices || user_role == 'accounting' || user_role == 'accounts-payable')
            ">
                <div class="card">
                    <div class="grid p-fluid">
                        <div class="m-3">
                            <div class="border-bottom-1 surface-border mb-3 flex justify-content-center">
                                <h5>Manager Signature</h5>
                            </div>
                            <div class="card shadow-1" style="position: relative">
                                <img class="signature" [src]="selectedInvoice.managerSignature" />
                            </div>

                            <div class="flex justify-content-between">
                                <div class="align-self-center">
                                    <div *ngIf="selectedInvoice?.managerApprovedUserName; else notAppUsername">
                                        <div>{{ selectedInvoice?.managerApprovedUserName }}</div>
                                    </div>
                                    <ng-template #notAppUsername>
                                        <div>Unknown user</div>
                                    </ng-template>
                                </div>

                                <div class="align-self-center">
                                    {{ managerApprovedDateTime | date : 'short' }}
                                </div>

                                <div class="align-self-center">
                                    <div *ngIf="
                                        selectedInvoice.managerApprovedFirstName !== null ||
                                            selectedInvoice.managerApprovedLastName !== null;
                                        else noManagerName
                                    ">
                                        <span *ngIf="selectedInvoice.managerApprovedFirstName; else noManagerFirstName">
                                            {{ selectedInvoice.managerApprovedFirstName }}
                                        </span>
                                        <ng-template #noManagerFirstName> null</ng-template>
                                        <span *ngIf="selectedInvoice.managerApprovedLastName; else noManagerLastName">
                                            {{ selectedInvoice.managerApprovedLastName }}
                                        </span>
                                        <ng-template #noManagerLastName> null</ng-template>
                                    </div>
                                    <ng-template #noManagerName> No Name Found</ng-template>
                                </div>
                            </div>
                        </div>
                        <div class="m-3">
                            <div class="border-bottom-1 surface-border mb-3 flex justify-content-center">
                                <h5>Corporate Office Signature</h5>
                            </div>
                            <div class="card shadow-1" style="position: relative">
                                <img class="signature" [src]="selectedInvoice.corporateOfficeSignature" />
                            </div>

                            <div class="flex justify-content-between">
                                <div class="align-self-center">
                                    <div
                                        *ngIf="selectedInvoice?.corporateOfficeApprovedUserName; else notAppCorpUsername">
                                        <div>{{ selectedInvoice?.corporateOfficeApprovedUserName }}</div>
                                    </div>

                                    <ng-template #notAppCorpUsername>
                                        <div>Unknown user</div>
                                    </ng-template>
                                </div>

                                <div class="align-self-center">
                                    {{ corporateOfficeApprovedDateTime | date : 'short' }}
                                </div>

                                <div class="align-self-center">
                                    <div *ngIf="
                                        selectedInvoice.corporateOfficeApprovedFirstName !== null ||
                                            selectedInvoice.corporateOfficeApprovedLastName !== null;
                                        else noCorpName
                                    ">
                                        <span
                                            *ngIf="selectedInvoice.corporateOfficeApprovedFirstName; else noCorpFirstName">
                                            {{ selectedInvoice.corporateOfficeApprovedFirstName }}
                                        </span>
                                        <ng-template #noCorpFirstName> null</ng-template>
                                        <span
                                            *ngIf="selectedInvoice.corporateOfficeApprovedLastName; else noCorpLastName">
                                            {{ selectedInvoice.corporateOfficeApprovedLastName }}
                                        </span>
                                        <ng-template #noCorpLastName> null</ng-template>
                                    </div>
                                    <ng-template #noCorpName> No Name Found</ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12" *ngIf="invoiceDetailsShown && POFileDetailsShown && invoiceFileDetailsShown">
                <div class="card">
                    <h5>Attached Files</h5>
                    <div class="p-fluid formgrid grid">
                        <div class="p-field col-12">
                            <p-table [value]="invoiceFiles">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Invoice File(s)</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-file>
                                    <tr>
                                        <td>
                                            <button class="file-download-link" (click)="downloadFile(file.id)">
                                                {{ file.originalFileName }}
                                            </button>
                                            <!-- Does not work as a link -->
                                            <!--<input type="button" (click)="downloadFile('101')" value="Download Inline">-->
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>

                        <div class="p-field col-12">
                            <p-table [value]="poFiles">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Purchase Order File(s)</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-file>
                                    <tr>
                                        <td>
                                            <button class="file-download-link" (click)="downloadFile(file.id)">
                                                {{ file.originalFileName }}
                                            </button>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Signature Pad -->
            <div class="col-12" *ngIf="invoiceDetailsShown && signatureRequired && !showPaidInvoices">
                <div class="card shadow-1">
                    <div class="grid">
                        <div class="col-12 md:col-6 lg:col-4 flex align-self-center">
                            <div>
                                <h5>Sign off on invoice status</h5>
                                <app-signature-pad></app-signature-pad>

                                <div class="flex justify-content-between">
                                    <div class="flex justify-content-start">
                                        <button pButton pRipple type="button" label="Save Signature" icon="pi pi-check"
                                            class="p-button-success" (click)="saveSignature()"
                                            [disabled]="isSignatureValid()"></button>
                                    </div>

                                    <div class="flex justify-content-end">
                                        <button pButton pRipple type="button" label="Clear"
                                            class="p-button-danger p-button-text" (click)="clearCanvas()"></button>
                                    </div>
                                </div>
                                <div class="m-2">
                                    <app-control-messages
                                        [control]="signatureForm.get('signature')"></app-control-messages>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 md:col-6 lg:col-4" *ngIf="user_role === 'corporate-office'">
                            <div class="m-3">
                                <div class="border-bottom-1 surface-border mb-3 flex justify-content-center">
                                    <h5>Manager Signature</h5>
                                </div>
                                <div class="card shadow-1" style="position: relative">
                                    <img class="signature" [src]="selectedInvoice.managerSignature" />
                                </div>

                                <div class="flex justify-content-between">
                                    <div class="align-self-center">
                                        <div *ngIf="selectedInvoice?.managerApprovedUserName; else notAppUsername">
                                            <div>{{ selectedInvoice?.managerApprovedUserName }}</div>
                                        </div>
                                        <ng-template #notAppUsername>
                                            <div>Unknown user</div>
                                        </ng-template>
                                    </div>

                                    <div class="align-self-center">
                                        {{ managerApprovedDateTime | date : 'short' }}
                                    </div>

                                    <div class="align-self-center">
                                        <div *ngIf="
                                            selectedInvoice.managerApprovedFirstName !== null ||
                                                selectedInvoice.managerApprovedLastName !== null;
                                            else noManagerName
                                        ">
                                            <span
                                                *ngIf="selectedInvoice.managerApprovedFirstName; else noManagerFirstName">
                                                {{ selectedInvoice.managerApprovedFirstName }}
                                            </span>
                                            <ng-template #noManagerFirstName> null</ng-template>
                                            <span
                                                *ngIf="selectedInvoice.managerApprovedLastName; else noManagerLastName">
                                                {{ selectedInvoice.managerApprovedLastName }}
                                            </span>
                                            <ng-template #noManagerLastName> null</ng-template>
                                        </div>
                                        <ng-template #noManagerName> No Name Found</ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="m-2">
                    <app-control-messages [control]="signatureForm.get('signature')"></app-control-messages>
                </div>
            </div>
        </div>
    </div>

    <!-- Invoice approve/deny buttons -->
    <div class="col-12 md:col-12" *ngIf="invoiceDetailsShown && !showPaidInvoices">
        <div class="unified-row grid justify-content-between p-fluid">
            <div class="col-12 sm:col-12 md:col-6 lg:col-3 xl:col-2">
                <button *ngIf="canDeny" pButton type="button" label="Deny Request" class="p-button-danger mr-2 mb-2"
                    [disabled]="requestInProgress" (click)="setStatus('denied')"></button>
            </div>

            <div class="col-12 sm:col-12 md:col-6 lg:col-3 xl:col-2">
                <button *ngIf="user_role === 'accounting' || user_role === 'accounts-payable'" pButton type="button"
                    label="Reopen Invoice" class="p-button-warning mr-2 mb-2" [disabled]="requestInProgress"
                    (click)="setStatus('reopened')"></button>
            </div>

            <div class="col-12 sm:col-12 md:col-6 lg:col-3 xl:col-2"
                *ngIf="user_role === 'accounting' || user_role === 'accounts-payable'">
                <button pButton type="button" label="Preview Documents" class="mr-2 mb-2"
                    [loading]="printRequestInProgress" [disabled]="glForm.dirty"
                    (click)="printAllDocuments(true)"></button>
            </div>

            <div class="col-12 sm:col-12 md:col-6 lg:col-3 xl:col-2" *ngIf="
                    (user_role === 'accounting' || user_role === 'accounts-payable') &&
                    selectedInvoice.status !== 'approved'
                ">
                <button pButton type="button" label="Print Docs and Approve Payment" class="mr-2 mb-2 p-button-success"
                    [loading]="printRequestInProgress" [disabled]="glForm.dirty"
                    (click)="setStatus('approved', true)"></button>
            </div>

            <div class="col-12 sm:col-12 md:col-6 lg:col-3 xl:col-2" *ngIf="
                    (user_role === 'accounting' || user_role === 'accounts-payable') &&
                    selectedInvoice.status === 'approved'
                ">
                <button pButton type="button" label="Print Documents" class="mr-2 mb-2 p-button-info"
                    [loading]="printRequestInProgress" [disabled]="glForm.dirty"
                    (click)="printAllDocuments(false)"></button>
            </div>

            <div class="col-12 sm:col-12 md:col-6 lg:col-3 xl:col-2" *ngIf="canApprove">
                <button pButton type="button" label="Approve Payment" class="mr-2 mb-2 p-button-success"
                    [loading]="requestInProgress" (click)="setStatus('approved')"></button>
            </div>
        </div>
    </div>
    <div class="col-12 md:col-12" *ngIf="invoiceDetailsShown && showPaidInvoices">
        <div class="unified-row grid p-fluid">
            <div class="col-2 col-offset-5"
                *ngIf="user_role == 'accounting' || user_role == 'accounts-payable' || user_role == 'corporate-office'">
                <button pButton type="button" label="Print all Documents" [loading]="printRequestInProgress"
                    [disabled]="glForm.dirty" (click)="printAllDocuments()"></button>
            </div>
        </div>
    </div>

    <!-- Refund Requests List -->
    <div *ngIf="user_role !== 'manager' || showPaidInvoices === true" class="col-12 md:col-12">
        <div class="card">
            <h1 style="font-size: 16px">{{ statusHeaderRefund }}</h1>
            <app-view-refund-requests></app-view-refund-requests>
        </div>
    </div>
</div>