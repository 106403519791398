<p-toast></p-toast>
<div class="dashboard grid p-fluid">
    <div class="col-12 md:col-12">
        <div class="card">
            <h1 style="font-size: 16px">Incoming Requests for New Vendor Accounts</h1>
            <p-table
                #vendorApprovalTable
                [value]="requests"
                [globalFilterFields]="[
                    'vendorID',
                    'name',
                    'locationsNameListString',
                    'status'
                ]"
                selectionMode="single"
                [(selection)]="selectedRequest"
                dataKey="vendorID"
                [paginator]="true"
                [rows]="15"
                (onRowSelect)="onRowSelect($event)"
                (onRowUnselect)="onRowUnselect($event)">
                <ng-template pTemplate="caption">
                    <div class="flex align-items-center justify-content-end">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input
                                    pInputText
                                    type="text"
                                    (input)="vendorApprovalTable.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="vendorID">
                            ID
                            <p-sortIcon field="vendorID"></p-sortIcon>
                        </th>
                        <th pSortableColumn="name">
                            Company
                            <p-sortIcon field="name"></p-sortIcon>
                        </th>
                        <th pSortableColumn="locationsNameListString">
                            Locations
                            <p-sortIcon field="locationsNameListString"></p-sortIcon>
                        </th>
                        <th pSortableColumn="status">
                            Approval Status
                            <p-sortIcon field="stats"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-request>
                    <tr [pSelectableRow]="request">
                        <td>{{ request.vendorID }}</td>
                        <td>{{ request.name }}</td>
                        <td class="text-truncate">{{ request.locationsNameListString }}</td>
                        <td>{{ request.status }}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<p-dialog
    header="Vendor"
    [(visible)]="isSelected"
    [modal]="true"
    [breakpoints]="{ '960px': '100vw', '640px': '100vw' }"
    [style]="{ width: '80vw' }"
    (onHide)="onRowUnselect($event)">
    <app-view-vendor-info
        [(id)]="selectedRequest.vendorID"
        (filesApprovedEvent)="markFilesApproved($event)"
        [componentMode]="'accounting'">
    </app-view-vendor-info>

    <ng-template pTemplate="footer">
        <div class="mt-2">
            <p-button
                label="Approve"
                icon="pi pi-check"
                styleClass="p-button-success"
                (onClick)="handleApprove($event)"
                [disabled]="!filesApproved"></p-button>
            <p-button
                label="Deny"
                icon="pi pi-times"
                styleClass="p-button-danger"
                (onClick)="handleDeny($event)"></p-button>
            <p-button
                label="Cancel"
                icon="pi pi-times-circle"
                styleClass="p-button-outlined"
                (onClick)="onRowUnselect($event)"></p-button>
        </div>
    </ng-template>
</p-dialog>
