<!-- This component is designed to run as a child component which should have a toast -->
<!--<p-toast></p-toast>-->

<div id="dd-container">
    <div class="flex flex-column justify-content-center">
        <form [formGroup]="changePO">
            <div class="field col-12">
                <div class="dd-title">Request PO Change</div>
                <div>You can request a change in the amount of the purchase order. Doing so will resubmit the PO for approval before it can be used by the vendor.</div>
            </div>
            <div class="col-12">
                <span class="p-float-label">
<!--                    <input formControlName="reason" id="dd-reason" type="text" autocomplete="off" pInputText appControlErrorMessage>-->
                                    <textarea formControlName="reason" rows="5" cols="30" placeholder=""
                                              id="dd-reason" pInputTextarea></textarea>
                    <label for="dd-reason">Reason for change</label>
                </span>
                <app-control-messages [control]="changePO.get('reason')"></app-control-messages>
            </div>
            <div class="field col-12">
                <span class="p-float-label">
                    <input formControlName="newAmount" required id="dd-new-amount" autocomplete="off" pInputText appControlErrorMessage>
                    <label for="dd-new-amount">New PO Amount</label>
                </span>
                <app-control-messages [control]="changePO.get('newAmount')"></app-control-messages>
            </div>
            <div class="field col-12">
                <p-button type="button" label="Submit Request" icon="pi pi-user" (onClick)="requestChange('')">
                </p-button>
            </div>

            <div class="col-12">

                <div *ngIf="isRequestFinished">
                    <div *ngIf="isRequestSuccessful; else requestFailed">
                        <strong class="success-text">Change Submitted</strong>
                    </div>

                    <ng-template #requestFailed>
                        <small class="p-error">
                            {{ failureMessage }}
                        </small>
                    </ng-template>
                </div>

            </div>

        </form>
    </div>
</div>
