import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MPIAppService } from '../../services/mpiapp.service';
import { FilePartial } from '../api/models/file-partial';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'app-file-verification-control',
    templateUrl: './file-verification-control.component.html',
    styleUrls: ['./file-verification-control.component.scss'],
})
export class FileVerificationControlComponent implements OnInit {
    @Input() fileID: string;
    @Input() isInsuranceRequired: boolean;
    @Output() fileStatusEvent = new EventEmitter<any>();

    fileMetaData: FilePartial;
    verificationStatus: string;

    fileActionItems: MenuItem[] = [
        { label: 'Archive', icon: 'pi pi-send', command: () => this.alterFile('archived') },
        { label: 'Delete', icon: 'pi pi-send', command: () => this.alterFile('deleted') },
    ];

    isDenyClicked: boolean = false;
    reasonForDenial: string = '';

    constructor(public mpiApp: MPIAppService) {}

    ngOnInit(): void {
        this.fileMetaData = {};
        // Indicate that we're checking the status of this file
        this.verificationStatus = 'checking';

        this.mpiApp.getFileMetaData(this.fileID).subscribe(
            (data: FilePartial) => {
                this.fileMetaData = data;

                // Resolve the state of this component based on the file verification status
                this.verificationStatus = this.determineVerificationStatus(data.verificationStatus);
            },
            (error) => {
                // TODO Raise Error
            }
        );
    }

    changeDenyState(value: boolean) {
        this.isDenyClicked = value;
    }

    /*
  Based on the file status determine how the control show behave
   */
    determineVerificationStatus(status: string) {
        if (status !== '') {
            // At the moment just allow the status to flow up to the UI
            return status;
        } else {
            // We don't have a status so show an intedeterminate status
            return 'unknown';
        }
    }

    updateVerificationStatus(newStatus: string, reason?: string) {
        this.verificationStatus = 'checking';

        this.mpiApp.setFileMetaData(this.fileID, 'verificationStatus', newStatus, reason).subscribe((data: any) => {
            // After the verification is set show the new status
            this.verificationStatus = newStatus;
            this.isDenyClicked = false;
            this.reasonForDenial = '';
        });

        const response = { newStatus: newStatus, note: reason };
        // return new status to view vendor info component
        this.fileStatusEvent.emit(response);
    }

    alterFile(action: string) {
        this.verificationStatus = action;

        this.mpiApp.setFileMetaData(this.fileID, 'verificationStatus', action).subscribe((data: any) => {
            // After the verification is set show the new status
            this.verificationStatus = action;
        });

        // return new status to view vendor info component
        this.fileStatusEvent.emit(action);
    }
}
