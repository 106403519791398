import { Component, OnDestroy, OnInit } from '@angular/core';
import { Vendor } from '../api/models/vendor';
import { MPIAppService } from '../../services/mpiapp.service';
import { MessageService } from 'primeng/api';
// import { jsPDF } from 'jspdf';
// import 'jspdf-autotable';

export interface VendorWithInsurance extends Vendor {
    content?: any;
    docType: string;
    docTypeID: number;
    expirationDate: string;
    id?: number;
    mimeType: string;
    note?: string;
    notifiedExpirationTimestamp?: string;
    originalFileName: string;
    verificationStatus: string;
}

@Component({
    selector: 'app-vendor-expiring-insurance',
    templateUrl: './vendor-expiring-insurance.component.html',
    styleUrls: ['./vendor-expiring-insurance.component.scss'],
    providers: [MessageService],
})
export class VendorExpiringInsuranceComponent implements OnInit, OnDestroy {
    vendors: VendorWithInsurance[];
    columns: any[];
    exportColumns: any[];
    requestInProgress = false;

    constructor(private mpi: MPIAppService, private messageService: MessageService) {}

    ngOnInit(): void {
        this.requestInProgress = true;
        this.mpi.getVendorsWithExpiringDocuments().subscribe({
            next: (data: VendorWithInsurance[]) => {
                this.vendors = data;
            },
            error: (err) => {
                this.requestInProgress = false;
                this.messageService.add({
                    severity: 'error',
                    summary: 'Retrieving Expiring Documents Failed',
                    detail: 'Error ' + err.status + ': ' + err.error.error.message,
                });
                console.error(err);
            },
            complete: () => {
                this.requestInProgress = false;
                if(this.vendors.length === 0) {
                    this.messageService.add({
                        severity: 'warn',
                        summary: 'No Expiring Documents Found',
                    });
                }
            },
        });

        this.columns = [
            { field: 'accountContactFirstName', header: 'Name' },
            { field: 'name', header: 'Company' },
            { field: 'originalFileName', header: 'File Name' },
            { field: 'expirationDate', header: 'Expiration Date' },
            { field: 'verificationStatus', header: 'Status' },
            { field: 'note', header: 'Note' },
        ];

        this.exportColumns = this.columns.map((col) => ({ title: col.header, dataKey: col.field }));
    }

    ngOnDestroy(): void {}

    exportPDF() {
        /* tslint:disable */
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then((x) => {
                const doc = new jsPDF.default('l');
                (doc as any).autoTable(this.exportColumns, this.vendors);
                doc.save('products.pdf');
            });
        });
        /* tslint:enable */
    }
}
