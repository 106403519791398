<p-toast></p-toast>

<p-card header="Edit Locations and GL Codes">
    <button pButton type="button" label="Save to Database" (click)="saveToDatabase()" class="mb-3"></button>
    <p-table
        [value]="locations"
        [rows]="10"
        [rowsPerPageOptions]="[10, 25, 50, locations?.length]"
        [showCurrentPageReport]="true"
        [paginator]="true"
        [globalFilterFields]="['location', 'glCode']"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        dataKey="id"
        responsiveLayout="scroll">
        <ng-template pTemplate="header">
            <tr>
                <th>Location</th>
                <th>GL Code</th>
                <!-- <th>GL Code</th> -->
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-location>
            <tr>
                <td pEditableColumn>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input pInputText type="text" [(ngModel)]="location.location" />
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ location.location }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td pEditableColumn>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input pInputText type="text" [(ngModel)]="location.glCode" />
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ location.glCode }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <!-- <td>{{ location.glCodeLong }}</td> -->
            </tr>
        </ng-template>
    </p-table>
</p-card>

<p-card header="Edit Document Retention Length" [style]="{ 'margin-top': '2em' }">
    <div class="p-grid p-fluid p-d-flex">
        <div class="p-field p-col-3 p-as-end">
            <label for="numYears"># of Years to Save Expired Documents</label>
            <p-inputNumber
                inputId="numYears"
                [(ngModel)]="numYearsExpiredRetention"
                (onBlur)="saveNumYearsSetting()"></p-inputNumber>
        </div>
    </div>
</p-card>
