import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-manager-invoice-approval',
    templateUrl: './manager-invoice-approval.component.html',
    styleUrls: ['./manager-invoice-approval.component.scss'],
})
export class ManagerInvoiceApprovalComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
