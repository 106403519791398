import { Component, OnInit } from '@angular/core';
import { PaymentRequest } from '../domain/payment-request';
import { Invoice } from '../api/models/invoice';
import { MPIAppService } from '../../services/mpiapp.service';

import { LazyLoadEvent, MessageService, PrimeIcons } from 'primeng/api';
import { ViewInvoice } from '../api/models';
import { IsLoadingService } from '@service-work/is-loading';
import { Observable, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { escapeRegExp } from 'src/helpers/escape-regexp';
import { SessionService } from 'src/session.service';

@Component({
    selector: 'app-vendor-payments',
    templateUrl: './vendor-payments.component.html',
    styleUrls: ['./vendor-payments.component.scss'],
    providers: [MessageService],
})
export class VendorPaymentsComponent implements OnInit {

    invoices: ViewInvoice[] = [];
    totalInvoices: number = 0;
    selectedInvoice: ViewInvoice;

    defaultLazyLoadEvent: LazyLoadEvent = {
        rows: 10,
        first: 0,
        sortField: 'submittedDate',
        sortOrder: -1,
    };

    loadingInvoices$: Observable<boolean>;

    /* default event array so timeline displays blank when page is first loaded */
    eventTimeline: any[] = [];
    // customEvents: any[];

    constructor(
        private mpiApp: MPIAppService,
        private messageService: MessageService,
        private loadingService: IsLoadingService,
        private sessionService: SessionService
    ) { }

    ngOnInit() {
        this.loadingInvoices$ = this.loadingService.isLoading$({ key: 'load-invoices' });

        this.getInvoices();
        /*
        this.customEvents = [
            {
                status: 'Purchase Order Approved',
                date: '15/10/2020',
                icon: PrimeIcons.SHOPPING_CART,
                color: '#9C27B0'
            },
            {status: 'Invoice Submitted', date: '15/10/2020', icon: PrimeIcons.COG, color: '#673AB7'},
            {status: 'Invoice Approved', date: '15/10/2020', icon: PrimeIcons.ENVELOPE, color: '#FF9800'},
            {status: 'Payment Sent', date: '16/10/2020', icon: PrimeIcons.CHECK, color: '#607D8B'}
        ];
        */
    }

    getInvoices(lazyEvent?: LazyLoadEvent): void {
        const sortOrder = this.defaultLazyLoadEvent.sortOrder > 0 ? 'ASC' : 'DESC';

        let filterObj: any = {
            limit: this.defaultLazyLoadEvent.rows,
            skip: this.defaultLazyLoadEvent.first,
            order: [`${this.defaultLazyLoadEvent.sortField} ${sortOrder}`]
        };

        // If the session stored roleID is 0, then the user is not a standard vendor,
        // so just show all invoices. Otherwise, filter the invoices down by roleID (vendorID).
        if (this.sessionService.getUserProfile().roleID !== 0 && 
            this.sessionService.getUserProfile().roleID !== null) {
            filterObj['where'] = { vendorId: this.sessionService.getUserProfile().roleID };
        }

        if (lazyEvent) {
            filterObj = this.addLazyEventToFilter(lazyEvent, filterObj);
        }

        const filter = JSON.stringify(filterObj);
        let where: null | string = null;

        // Only filter results if the user is a normal vendor (aka roleID is not 0)
        if (this.sessionService.getUserProfile().roleID !== 0) {
            where = JSON.stringify(filterObj.where);
        }

        const gettingInvoiceCount$ = this.mpiApp.getInvoicesCount(where);
        const gettingInvoices$ = this.mpiApp.getDisplayInvoices(filter);

        this.loadingService.add(forkJoin([gettingInvoiceCount$, gettingInvoices$]).subscribe({
            next: (results) => {
                this.totalInvoices = results[0];
                this.invoices = results[1];
            },
            error: (err) => {
                console.log(err);
                this.messageService.add({
                    severity: 'error',
                    summary: 'Get Invoices Failed',
                    detail: 'Error: ' + err.error.error.name,
                });
            },
            complete: () => { }
        }), { key: 'load-invoices' });
    }

    /**
     * Display the payment process timeline for the clicked invoice
     *
     * NOTE: Some things in example customEvents aren't apart of current invoices in database
     * like purchase order approval date and invoice approval date
     *
     * Selection taken from: https://primefaces.org/primeng/showcase/#/table/selection
     */
    displayTimeline(invoice: ViewInvoice): void {

        this.eventTimeline = [];

        // console.log(invoice);

        this.mpiApp.getInvoiceEvents(String(invoice.id)).subscribe((events: any) => {

            if (!events.length) {
                this.eventTimeline = [{
                    status: 'No Events for Invoice ' + invoice.invoiceId,
                    date: '',
                    description: 'This invoice has no events available for display.',
                    icon: PrimeIcons.PAUSE,
                    color: 'red'
                }];
            } else {

                this.eventTimeline = [];

                for (const event of events) {
                    this.eventTimeline.push({
                        status: event.descriptionTitle,
                        date: event.datetime,
                        description: event.description,
                        icon: PrimeIcons.CHECK,
                        color: 'green'
                    });
                }

            }

        },
            (error) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Purchase Order Submit Failed',
                    detail: 'Error: ' + error.message,
                });
            }

        );

    }

    addLazyEventToFilter(event: LazyLoadEvent, filterObj: any): any {
        filterObj['limit'] = event.rows;
        filterObj['skip'] = event.first;

        if (event.sortField) {
            const sortOrder = event.sortOrder > 0 ? 'ASC' : 'DESC';
            filterObj['order'] = [`${event.sortField} ${sortOrder}`];
        } else {
            const sortOrder = this.defaultLazyLoadEvent.sortOrder > 0 ? 'ASC' : 'DESC';
            filterObj['order'] = [`${this.defaultLazyLoadEvent.sortField} ${sortOrder}`];
        }

        if (event.globalFilter) {
            let existingFilter: any = {};
            const filterFields: any[] = [];
            if (filterObj['where']) {
                // TODO: Add existing clause to the new list
                existingFilter = filterObj['where'];
            }

            const sanitizedFilter: string = escapeRegExp(event.globalFilter as string);

            // TODO: Add list of properties with the global filter
            const regexp = new RegExp('^.*' + sanitizedFilter + '.*$', 'gi');
            const locNameRegex = new RegExp('.*"locationName":".*' + sanitizedFilter + '.*".*');
            filterFields.push({ invoiceId: { regexp: `${regexp}` } });
            filterFields.push({ dateCreated: { regexp: `${regexp}` } });
            filterFields.push({ status: { regexp: `${regexp}` } });
            filterFields.push({ datePaid: { regexp: `${regexp}` } });
            filterFields.push({ amount: { regexp: `${regexp}` } });
            filterFields.push({ fields: { regexp: `${locNameRegex}` } });
            filterFields.push({ vendorName: { regexp: `${regexp}` } });
            filterFields.push({ locationNames: { regexp: `${regexp}` } });

            // Put the new list of where clauses inside an or operator
            // Update the filter's where clause with an and operator between the existing filters and global filters
            filterObj['where'] = { and: [existingFilter, { or: filterFields }] };
            //filterObj['where'] = { and: [existingFilter, { invoiceId: { regexp: `${regexp}` } }] };
        }

        return filterObj;
    }
}
