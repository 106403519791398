import { Component, OnDestroy, OnInit } from '@angular/core';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { PurchaseOrder } from '../api/models/purchase-order';
import { MPIAppService, PurchaseOrdersAndVendors } from '../../services/mpiapp.service';
import { Vendor } from '../api/models/vendor';
import { FilePartial } from '../api/models/file-partial';
import { environment } from '../../environments/environment';
import { DateTime } from 'luxon';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmationService } from 'primeng/api';
import { forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { IsLoadingService } from '@service-work/is-loading';
import { ChangePurchaseOrderComponent } from '../change-purchase-order/change-purchase-order.component';
import { MPIRecurringService } from '../../services/mpirecurring.service';
import { Invoice, ViewPurchaseOrder } from '../api/models';

export interface PurchaseOrderWithVendor extends PurchaseOrder {
    managerName?: string;
    vendorName?: string;
    dateRequested?: string;
}
@Component({
    selector: 'app-purchase-order-list',
    templateUrl: './purchase-order-list.component.html',
    styleUrls: ['./purchase-order-list.component.scss'],
    providers: [ConfirmationService, MessageService, DialogService],
})
export class PurchaseOrderListComponent implements OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject<void>();
    purchaseOrders: PurchaseOrderWithVendor[];
    displayedPurchaseOrders: ViewPurchaseOrder[];
    vendors: Vendor[];
    purchaseOrderVendor: Vendor;
    selectedViewPO: ViewPurchaseOrder;
    selectedPO: PurchaseOrder;
    selectedPOInvoices: Invoice[];
    files: FilePartial[];
    vendorNames = {};
    totalPOs: number = 0;

    env = environment;

    // If this is a recurring PO store the dates it occurs.
    recurringDates: DateTime[] = [];
    recurringDisplayMessage = '';

    // Resolved when the PO is selected for display to determine if the recurring fields
    // should be shown or not.
    showRecurring = false;

    isListLoading$: Observable<boolean>;

    private defaultLazyLoadEvent: LazyLoadEvent = {
        rows: 10,
        first: 0,
        sortField: 'poID',
        sortOrder: -1,
    };

    constructor(
        private messageService: MessageService,
        private mpiApp: MPIAppService,
        private confirmationService: ConfirmationService,
        public isLoadingService: IsLoadingService,
        public dialogService: DialogService,
        public mpiRecurring: MPIRecurringService
    ) { }

    ngOnInit(): void {
        this.purchaseOrders = [];
        this.displayedPurchaseOrders = [];
        this.selectedPOInvoices = [];

        this.isListLoading$ = this.isLoadingService.isLoading$({ key: 'list' });

        this.mpiApp
            .getVendors()
            .toPromise()
            .catch((reason) => {
                console.error(reason);
                this.vendors = [];
            })
            .then((data: Vendor[]) => {
                this.vendors = data;
                // Setup a vendor name to vendor ID map
                for (const vendor of this.vendors) {
                    this.vendorNames[vendor.vendorID] = vendor.name;
                }
            });

        // Calling this function in ngOnInit prevents the "Expression has changed after it was checked" error
        this.loadPOs(null);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    loadPOs(lazyEvent: LazyLoadEvent) {
        const defaultPOFilter: any = {
            where: {
                and: [{ status: { neq: 'scheduled' } }],
            },
            order: ['poID DESC'],
        };
        let poFilter = defaultPOFilter;

        if (lazyEvent) {
            poFilter = this.addLazyEventToFilter(lazyEvent, poFilter);
        } else {
            // No lazy load event was provided, so use the default so the entire invoice list is not returned
            poFilter['limit'] = this.defaultLazyLoadEvent.rows;
            poFilter['skip'] = this.defaultLazyLoadEvent.first;
            const sortOrder = this.defaultLazyLoadEvent.sortOrder > 0 ? 'ASC' : 'DESC';
            poFilter['order'] = [`${this.defaultLazyLoadEvent.sortField} ${sortOrder}`];
        }

        const filter = JSON.stringify(poFilter);
        // Only send the where clause part of the filter to get the total number of records
        // that work
        const where = JSON.stringify(poFilter.where);
        const gettingPOCount$ = this.mpiApp.getPurchaseOrderCount(where).pipe(takeUntil(this.destroy$));
        const gettingPOs = this.mpiApp.getDisplayPurchaseOrders(filter).pipe(takeUntil(this.destroy$));

        // Get a list of all vendors and pos to populate the grid.
        this.isLoadingService.add(
            forkJoin([gettingPOCount$, gettingPOs]).subscribe((results) => {
                this.totalPOs = results[0];
                this.displayedPurchaseOrders = results[1];

                this.displayedPurchaseOrders.forEach((e, i, a) => {
                    if (a[i].poID === '' || a[i].poID === 'PENDING') {
                        a[i].poID = this.mpiApp.idToHash(a[i].purchaseOrderID);
                    }
                });
            }),
            { key: 'list' }
        );
    }

    addLazyEventToFilter(event: LazyLoadEvent, filterObj: any): any {
        filterObj['limit'] = event.rows;
        filterObj['skip'] = event.first;

        if (event.sortField !== undefined) {
            const sortOrder = event.sortOrder > 0 ? 'ASC' : 'DESC';
            filterObj['order'] = [`${event.sortField} ${sortOrder}`];
        }

        if (event.globalFilter) {
            let existingFilter: any = {};
            const filterString: string = event.globalFilter;
            if (filterObj['where']) {
                // TODO: Add existing clause to the new list
                existingFilter = filterObj['where']['and'][0];
            }

            if (filterString.length >= 2) {
                const filterFields: any[] = [];

                // TODO: Add list of properties with the global filter
                const regexp = new RegExp('.*' + event.globalFilter + '.*', 'i');
                filterFields.push({ vendorName: { regexp: `${regexp}` } });
                filterFields.push({ locationNames: { regexp: `${regexp}` } });
                filterFields.push({ vendorID: { regexp: `${regexp}` } });
                filterFields.push({ estimatedCost: { regexp: `${regexp}` } });
                filterFields.push({ remainingBalance: { regexp: `${regexp}` } });
                filterFields.push({ status: { regexp: `${regexp}` } });
                filterFields.push({ managerRequested: { regexp: `${regexp}` } });
                filterFields.push({ poID: { regexp: `${regexp}` } });

                // Put the new list of where clauses inside an or operator
                // Update the filter's where clause with an and operator between the existing filters and global filters
                filterObj['where'] = { and: [existingFilter, { or: filterFields }] };
            }
        }

        return filterObj;
    }

    async purchaseOrderSelect(event) {
        // match the selected purchase to a vendor so we can display vendor name in template
        this.purchaseOrderVendor = this.vendors.find(
            (vendor: Vendor) => vendor.vendorID == this.selectedViewPO.vendorID
        );

        await this.mpiApp
            .getPurchaseOrder(this.selectedViewPO.purchaseOrderID.toString())
            .toPromise()
            .catch((reason) => {
                console.error(reason);
            })
            .then((data: PurchaseOrder) => {
                this.selectedPO = data;
            });

        this.isLoadingService.add(
            this.mpiApp
                .getInvoices(`{"where":{"purchaseOrderID":${this.selectedPO.purchaseOrderID}}}`)
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                    next: (data) => {
                        if (data.length >= 1) {
                            this.selectedPOInvoices = data;
                        } else {
                            this.selectedPOInvoices = [];
                        }
                    },
                    error: (err) => {
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Error Getting Invoices',
                            detail: 'Failed to get invoices for this purchase order.',
                        });
                    },
                }),
            { key: 'po-invoices' }
        );

        this.isLoadingService.add(
            this.mpiApp
                .getFiles('purchaseOrder', this.selectedPO.purchaseOrderID.toString())
                .pipe(takeUntil(this.destroy$))
                .subscribe((data: FilePartial[]) => {
                    this.files = data;
                }),
            { key: 'po-files' }
        );

        if (this.selectedPO.poType === 'recurring') {
            // Convert the attributes string back into an object
            const recurringAttributes = this.mpiRecurring.parseAttributeString(this.selectedPO.recurringAttributes);

            // Calculate the schedule of occurrences and message.
            this.recurringDates = this.mpiRecurring.calculateSchedule(recurringAttributes);
            this.recurringDisplayMessage = this.mpiRecurring.getScheduleAsDisplayString(
                recurringAttributes,
                this.recurringDates
            );

            this.showRecurring = true;
        } else {
            this.showRecurring = false;
        }
    }

    handleClose = () => {
        const poID = this.selectedPO.purchaseOrderID.toString();
        const payload = { status: 'closed', statusLastSet: DateTime.now().toISO() };

        this.mpiApp
            .updatePurchaseOrder(poID, payload)
            .pipe(
                finalize(() => {
                    this.selectedPO = null;
                })
            )
            .subscribe(
                (data) => {
                    this.messageService.add({
                        severity: 'info',
                        summary: 'Purchase Order closed',
                        detail: 'Purchase order successfully marked as closed',
                    });

                    const index = this.purchaseOrders.findIndex(
                        (obj) => obj.purchaseOrderID === this.selectedPO.purchaseOrderID
                    );

                    if (this.purchaseOrders[index]) this.purchaseOrders[index].status = 'closed';
                },
                (error) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error Closing Purchase Order',
                        detail: 'Purchase order status update failed',
                    });
                }
            );
    };

    changePO(event: Event) {
        const purchaseOrderIDString = this.selectedPO.purchaseOrderID.toString();

        // Has this PO been used?
        if (this.selectedPO.remainingBalance !== this.selectedPO.estimatedCost) {
            this.confirmationService.confirm({
                target: event.target,
                message: `You cannot change this PO since it has already had an invoice applied.`,
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'OK',
                rejectVisible: false,
            });

            return;
        } else {
            // This PO has not been used before and can be changed
        }

        // Show the dialog to change the PO
        const ref = this.dialogService.open(ChangePurchaseOrderComponent, {
            data: {
                poID: purchaseOrderIDString,
            },
        });

        // When the dialog closes this triggers
        ref.onClose.subscribe(() => {
            // The user has closed the change request

            // Find the updated PO
            const filter = {
                where: {
                    purchaseOrderID: purchaseOrderIDString,
                },
            };

            this.mpiApp.getPurchaseOrders(JSON.stringify(filter)).subscribe((data: PurchaseOrder[]) => {
                // Find the old PO in the browser list of POs
                const updatedPO = data[0];
                this.selectedPO = updatedPO;

                // Loop over all the POs in the browser list and update it in the list
                this.purchaseOrders.forEach((value, index, array) => {
                    if (value.purchaseOrderID === this.selectedPO.purchaseOrderID) {
                        // Update the PO in the list
                        array[index].estimatedCost = updatedPO.estimatedCost;
                        value.estimatedCost = updatedPO.estimatedCost;

                        array[index].remainingBalance = updatedPO.estimatedCost;
                        value.remainingBalance = updatedPO.estimatedCost;
                    }
                });
            });
        });
    }

    confirmClose(event: Event) {
        this.confirmationService.confirm({
            target: event.target,
            message: `Closing purchase order means vendors won't have access anymore. Proceed?`,
            icon: 'pi pi-exclamation-triangle',
            accept: this.handleClose,
            reject: () => {
                this.messageService.add({ severity: 'error', summary: 'Purchase Order Closure Canceled' });
            },
        });
    }

    downloadFile(fileID: number | string) {
        this.mpiApp.showFile(String(fileID));
    }
}
