import { Component, OnInit } from '@angular/core';
import { ExportApprovedInvoicesComponent} from '../export-approved-invoices/export-approved-invoices.component';
import {VendorSetupRequest} from '../domain/vendor-setup-request';

@Component({
  selector: 'app-pay-approved-invoices',
  templateUrl: './pay-approved-invoices.component.html',
  styleUrls: ['./pay-approved-invoices.component.scss']
})
export class PayApprovedInvoicesComponent implements OnInit {

    requests: VendorSetupRequest[];

    constructor() { }

    ngOnInit(): void {

        this.requests = [];

        this.requests.push({
            id: 'abc123',
            company: 'Hello Company',
            locations: [123, 456, 789]
        });

        this.requests.push({
            id: 'def456',
            company: 'Cool Signs',
            locations: [123, 456, 789]
        });

        this.requests.push({
            id: 'abc123',
            company: 'Hello Company',
            locations: [123, 456, 789]
        });

        this.requests.push({
            id: 'def456',
            company: 'Cool Signs',
            locations: [123, 456, 789]
        });

    }

}
