import {Injectable} from '@angular/core';
import {RecurringAttributes} from '../app/api/models/mpi-custom-models';
import {DateTime} from 'luxon';
import {throwError} from 'rxjs';
import {MPIRecurringMPILib} from './mpirecurring.mpilib';

@Injectable({
    providedIn: 'root'
})
export class MPIRecurringService extends MPIRecurringMPILib {

}
