<p-toast></p-toast>
<div class="login-body-copy">
    <div id="login-wrapper">
        <p-messages [(value)]="msgs"></p-messages>
        <div id="login-container">

            <div class="flex flex-column justify-content-center">
                <span class="title">Reset Your Password</span>
                <small>Enter your account email address or username and we'll send you a link to reset your
                    password</small>

                <div class="p-fluid grid justify-content-center m-4">
                    <form [formGroup]="resetForm">
                        <div>
                            <span class="p-float-label">
                                <input formControlName="username" required id="reset_username" type="text"
                                    autocomplete="off" pInputText>
                                <label for="reset_username">Email/Username</label>

                            </span>
                        </div>
                        <app-control-messages [control]="resetForm.get('username')"></app-control-messages>
                    </form>
                </div>

                <p-button type="button" label="Continue" (onClick)="submit('password_reset')"
                    [loading]="isButtonLoading('password_reset')">
                </p-button>

                <div>
                    <a class="text-sm" routerLink="../">Return to sign in</a>
                </div>
            </div>

        </div>
    </div>
</div>