import { Component, OnInit } from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {finalize} from 'rxjs/operators';
import {MPIAppService} from '../../services/mpiapp.service';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-change-purchase-order',
  templateUrl: './change-purchase-order.component.html',
  styleUrls: ['./change-purchase-order.component.scss'],
    providers: [MessageService],
})
export class ChangePurchaseOrderComponent implements OnInit {

    isRequestFinished = false;
    isRequestSuccessful = true;
    failureMessage = '';

    // The poID being changed.
    poID = '';

    changePO = this.formBuilder.group({
        reason: ['', Validators.required],
        newAmount: ['', [Validators.required, Validators.pattern('^[0-9.]*$')]],
    });

  constructor(
      private formBuilder: UntypedFormBuilder,
      public mpiApp: MPIAppService,
      private messageService: MessageService,
      public ref: DynamicDialogRef,
      public config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {

      // Get the PO passed in to the dialog
      this.poID = this.config.data?.poID;

      // For testing, set a value on load
      // this.changePO.get('reason').setValue('abc');
      // this.changePO.get('newAmount').setValue('123');

  }

    requestChange(param: string) {

        this.isRequestFinished = false;
        this.isRequestSuccessful = false;

        if (!this.changePO.valid) {
            this.failureMessage = 'One or more fields is invalid.';
            this.isRequestFinished = true;
            this.isRequestSuccessful = false;
            return;
        }

        const reason = this.changePO.get('reason').value;
        const amount = this.changePO.get('newAmount').value;

        const payload = {
            status: 'pending',
            purchaseOrderID: Number(this.poID),
            estimatedCost: Number(amount),
            reason
        };

        this.mpiApp
            .resubmitPurchaseOrder(this.poID, payload)
            .pipe(
                finalize(() => {
                })
            )
            .subscribe(
                (data) => {
                    // this.messageService.add({
                    //     severity: 'info',
                    //     summary: 'Purchase Order Approved',
                    //     detail: 'Purchase order successfully marked as approved',
                    // });

                    this.isRequestFinished = true;
                    this.isRequestSuccessful = true;

                    this.changePO.reset();
                    this.changePO.markAsPristine();

                },
                (error) => {

                    // console.log(error);

                    this.isRequestFinished = true;
                    this.isRequestSuccessful = false;
                    this.failureMessage = error.error.error.message;

                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error Approving Purchase Order',
                        detail: 'Purchase order status update failed',
                    });
                }
            );




    }

}
