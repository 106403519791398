import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { InvoiceWithDateStrings } from '../view-invoices/view-invoices.component';
import { MPIAppService } from 'src/services/mpiapp.service';
import { MessageService, SortEvent } from 'primeng/api';
import { LoginModel } from '../models/login.model';
import { SessionService } from 'src/session.service';
import { httpErrorToast, httpSuccessToast } from 'src/helpers/http-toasts';
import { PurchaseOrder, User, Vendor } from '../api/models';
import { IsLoadingService } from '@service-work/is-loading';
import { PurchaseOrderWithVendor } from '../purchase-order-list/purchase-order-list.component';

export interface ManagerRequesterIDs {
    [key: string]: boolean;
}

@Component({
    selector: 'app-manager-dashboard',
    templateUrl: './manager-dashboard.component.html',
    styleUrls: ['./manager-dashboard.component.scss'],
    providers: [MessageService],
})
export class ManagerDashboardComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();

    openPOs: PurchaseOrderWithVendor[] = [];
    invoices: InvoiceWithDateStrings[] = [];
    selectedInvoice: InvoiceWithDateStrings;
    invoiceParam: string;

    vendors: Vendor[];
    vendorNames = {};
    managerUserIDs: ManagerRequesterIDs = {};
    users: Promise<User>[] = [];

    requestInProgress = false;
    openPOsLoading$: Observable<boolean>;
    invoicesLoading$: Observable<boolean>;

    user_profile: LoginModel;

    constructor(
        private mpiApp: MPIAppService,
        private messageService: MessageService,
        private loadingService: IsLoadingService,
        private router: Router,
        private session: SessionService
    ) {}

    ngOnInit(): void {
        this.openPOsLoading$ = this.loadingService.isLoading$({ key: 'open-pos' });
        this.invoicesLoading$ = this.loadingService.isLoading$({ key: 'invoices' });
        this.user_profile = this.session.getUserProfile();
        console.log(this.user_profile);
        this.getOpenPOs();
        this.getInvoices();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    getOpenPOs() {
        const filter = {
            where: {
                and: [{ status: { eq: 'approved' } }, { poType: { neq: 'recurring' } }],
            },
        };

        this.loadingService.add(
            this.mpiApp
                .getPurchaseOrdersAndVendors(JSON.stringify(filter), true)
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                    next: (POsAndVendors) => {
                        const poList = POsAndVendors.pos as PurchaseOrderWithVendor[];
                        poList.forEach((po, index, list) => {
                            // Go over the PO list to ensure every PO has a hashed poID
                            if (list[index].poID === '' || list[index].poID === 'PENDING') {
                                list[index].poID = this.mpiApp.idToHash(list[index].purchaseOrderID);
                            }

                            // If this is a new manager user, get their info
                            if (!this.managerUserIDs[po.managerRequesterUserID]) {
                                // Create a promise that will return the user object for the user who owns this PO
                                this.users[po.managerRequesterUserID] = new Promise((resolve) => {
                                    // Our promise is the result of a subscription to find the user
                                    this.mpiApp.getUser(po.managerRequesterUserID).subscribe((user) => {
                                        // Our information has been found, return it to the promise
                                        this.managerUserIDs[po.managerRequesterUserID] = true;
                                        list[index].managerName = `${user.firstName} ${user.lastName}`;
                                        resolve(user);
                                    });
                                });
                            }
                        });

                        // Assign all the POs and Vendors to locally scoped component variable
                        this.openPOs = poList;
                        this.vendors = POsAndVendors.vendors;

                        // Setup a vendor name to vendor ID map
                        for (const vendor of this.vendors) {
                            this.vendorNames[vendor.vendorID] = vendor.name;
                        }

                        // Assign the vendor name to each vendor ID in the list of POs
                        this.openPOs.forEach((po) => {
                            const date = new Date(po.managerRequested);
                            po.vendorName = this.vendorNames[po.vendorID];
                            po.dateRequested = date.toLocaleString();
                        });
                    },
                    error: (err: HttpErrorResponse) => {
                        httpErrorToast(this.messageService, err);
                    },
                }),
            { key: 'open-pos' }
        );
    }

    getInvoices() {
        this.requestInProgress = true;
        const filter = { where: { status: 'submitted' }, order: ['submittedDate DESC'] };
        this.loadingService.add(
            this.mpiApp
                .getInvoices(JSON.stringify(filter))
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                    next: (invoices: InvoiceWithDateStrings[]) => {
                        this.invoices = invoices;

                        this.invoices.forEach((invoice: InvoiceWithDateStrings) => {
                            const dateCreated = new Date(invoice.submittedDate);
                            invoice.dateCreated = dateCreated.toLocaleString();

                            if (invoice.paidDate) {
                                const datePaid = new Date(invoice.paidDate);
                                invoice.datePaid = datePaid.toLocaleString();
                            }

                            if (this.invoiceParam) {
                                this.selectedInvoice = this.invoices.find(
                                    (invoice) => invoice.invoiceID === this.invoiceParam
                                );
                                this.selectInvoice({});
                            }
                        });
                        this.requestInProgress = false;
                    },
                    error: (err: HttpErrorResponse) => {
                        console.error(err);
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Failed to get Invoices',
                            detail: 'Error ' + err.status + ': ' + err.error.error.message,
                        });
                        this.requestInProgress = false;
                    },
                }),
            { key: 'invoices' }
        );
    }

    selectInvoice(event: any) {
        this.router.navigate(['/manager/invoice/approval']);
    }

    customSort(event: SortEvent) {
        event.data.sort((data1, data2) => {
            let value1 = null;
            let value2 = null;
            let result = null;

            // Check if field is a subproperty
            if (event.field.split('.').length > 1) {
                const prop1 = event.field.split('.')[0];
                const prop2 = event.field.split('.')[1];
                value1 = data1[prop1][prop2];
                value2 = data2[prop1][prop2];
            } else {
                value1 = data1[event.field];
                value2 = data2[event.field];
            }

            if (value1 === null && value2 !== null) {
                result = -1;
            } else if (value1 !== null && value2 === null) {
                result = 1;
            } else if (value1 === null && value2 === null) {
                result = 0;
            } else if (event.field === 'invoiceID') {
                // Do special sort for invoiceID since ID can be number or alphanumeric
                let num1 = parseInt(value1);
                let num2 = parseInt(value2);

                if (isNaN(num1) && !isNaN(num2)) {
                    result = -1;
                } else if (!isNaN(num1) && isNaN(num2)) {
                    result = 1;
                } else if (isNaN(num1) && isNaN(num2)) {
                    result = value1.localeCompare(value2);
                } else {
                    result = num1 < num2 ? -1 : num1 > num2 ? 1 : 0;
                }
            } else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            } else {
                result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
            }

            return event.order * result;
        });
    }
}
