/**
 * Permanent error service
 *
 * Similar to the file-upload state service, use for state management for whether there's been
 * an error (i.e. failed to upload invoice) that needs to be displayed until user clears it
 */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PermanentErrorService {
    constructor() {}

    private _error = new BehaviorSubject<boolean>(false);

    /* Observable to subscribe to for bool value */
    error$ = this._error.asObservable();

    private set error(val: boolean) {
        this._error.next(val);
    }

    setError(isError: boolean) {
        this.error = isError;
    }

    getError() {
        return this._error.value;
    }
}
