import { IsLoadingService } from '@service-work/is-loading';
import { DateTime } from 'luxon';
import { Settings } from 'src/app/api/models/settings';
import { GLCodeGenerator } from './../../shared/gl-code-generation';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { LocationSetting, MPIAppService, VendorWithFiles } from 'src/services/mpiapp.service';
import { HttpErrorResponse } from '@angular/common/http';
import { File } from '../api/models';
import { SessionService } from 'src/session.service';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    providers: [MessageService],
})
export class SettingsComponent implements OnInit, OnDestroy {
    private subs: Subscription[];

    locations: LocationSetting[];
    glCodeList: GLCodeGenerator;

    numYearsExpiredRetention: number;
    numYearsOld: number;

    expiredDocuments: VendorWithFiles[];
    displayExpiredDocuments = false;

    requestInProgress = false;

    constructor(private mpiApp: MPIAppService, private messageService: MessageService, private session: SessionService) {
        this.subs = [];
        this.glCodeList = this.mpiApp.glCodeGenerator;

        if (this.glCodeList) {
            this.locations = this.glCodeList.glCodeList;
            this.locations = this.locations.sort((loc1, loc2) => parseInt(loc1.location) - parseInt(loc2.location));
        } else {
            this.subs.push(
                this.mpiApp.initGLCodeList().subscribe({
                    next: (data) => {
                        if (data !== null) {
                            this.locations = JSON.parse(data.value);
                            // Sort the list by location name
                            this.locations = this.locations.sort(
                                (loc1, loc2) => parseInt(loc1.location) - parseInt(loc2.location)
                            );
                            this.glCodeList = this.mpiApp.glCodeGenerator;
                        } else {
                            this.glCodeList = this.mpiApp.glCodeGenerator;
                            this.locations = this.glCodeList.glCodeList;
                            this.locations = this.locations.sort(
                                (loc1, loc2) => parseInt(loc1.location) - parseInt(loc2.location)
                            );
                        }
                    },
                })
            );
        }
    }

    ngOnInit(): void {
        if (this.subs === undefined) {
            this.subs = [];
        }

        this.subs.push(
            this.mpiApp.getSetting('numYearsExpiredRetention').subscribe({
                next: (data) => {
                    this.numYearsExpiredRetention = JSON.parse(data.value);
                    this.numYearsOld = JSON.parse(data.value);
                },
                error: (err: HttpErrorResponse) => {
                    console.error(err);
                    if (err.status !== 404) {
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Failed to get Years of Retention Setting',
                            detail: 'Error ' + err.status + ': ' + err.error.error.message,
                        });
                    }
                },
            })
        );
        // This API call shouldn't be necessary now that the list is stored in session storage
        // this.subs.push(
        //     this.mpiApp.getSetting('locations').subscribe({
        //         next: (data: Settings) => {
        //             console.log(JSON.parse(data.value));
        //             // Parse the location/glcode array
        //             this.locations = JSON.parse(data.value);
        //             // Go through the list of settings in the DB to update the GL Codes
        //             this.locations.forEach((loc) => {
        //                 loc.glCode = this.glCodeList.findGLCodeForLocation(loc.location);
        //             });
        //             this.messageService.add({
        //                 severity: 'success',
        //                 summary: 'Locations retrieved',
        //             });
        //         },
        //         error: (err) => {
        //             console.error(err);
        //             this.messageService.add({
        //                 severity: 'error',
        //                 summary: 'Failed to get Locations',
        //                 detail: 'Error ' + err.status + ': ' + err.error.error.message,
        //             });
        //         },
        //     })
        // );
    }

    ngOnDestroy(): void {
        this.subs.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    saveToDatabase() {
        // An array of objects that contain the location and gl code pair
        let settingsValue: LocationSetting[] = [];

        this.locations.forEach((loc) => {
            settingsValue.push({ id: loc.id, location: loc.location, glCode: loc.glCode });
        });

        let setting: Settings = {
            name: 'locations',
            value: JSON.stringify(settingsValue),
        };

        this.session.saveLocationGLCodes(JSON.stringify(settingsValue));

        this.subs.push(
            this.mpiApp.patchSetting(setting).subscribe({
                next: (data) => {
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Locations Setting Saved',
                    });
                },
                error: (err) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Failed to get Locations',
                        detail: 'Error ' + err.status + ': ' + err.error.error.message,
                    });
                },
                complete: () => {},
            })
        );
    }

    saveNumYearsSetting() {
        if (this.numYearsExpiredRetention !== this.numYearsOld) {
            let setting: Settings = {
                name: 'numYearsExpiredRetention',
                value: this.numYearsExpiredRetention.toString(),
            };

            this.mpiApp
                .patchSetting(setting)
                .toPromise()
                .catch((err: HttpErrorResponse) => {
                    if (err.status === 404) {
                        return this.mpiApp
                            .postSetting(setting)
                            .toPromise()
                            .finally(() => {
                                console.log('postSetting finished');
                            });
                    }
                })
                .then(
                    (success) => {
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Years of Retention Setting Saved',
                        });
                    },
                    (err) => {
                        console.error(err);
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Failed to get Years of Retention Setting',
                            detail: 'Error ' + err.status + ': ' + err.error.error.message,
                        });
                    }
                );
        }
    }
}
