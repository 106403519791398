<p-toast></p-toast>
<div class="no-print">
    <div *ngIf="user_role === 'accounting' && !showPaidRefunds" class="col-3 mb-3">
        <p-toggleButton
            [onLabel]="'Denied refunds'"
            [offLabel]="'Denied refunds'"
            [onIcon]="'pi pi-check'"
            [offIcon]="'pi pi-times'"
            [(ngModel)]="showDeniedRefunds"
            (onChange)="setRefundFilter($event, 'denied')"></p-toggleButton>
        <p-toggleButton
            [onLabel]="'Approved refunds'"
            [offLabel]="'Approved refunds'"
            [onIcon]="'pi pi-check'"
            [offIcon]="'pi pi-times'"
            [(ngModel)]="showApprovedRefunds"
            (onChange)="setRefundFilter($event, 'approved')"></p-toggleButton>
        <p-toggleButton
            [onLabel]="'Pending refunds'"
            [offLabel]="'Pending refunds'"
            [onIcon]="'pi pi-check'"
            [offIcon]="'pi pi-times'"
            [(ngModel)]="showPendingRefunds"
            (onChange)="setRefundFilter($event, 'pending')"></p-toggleButton>
    </div>
    <div *ngIf="showPaidRefunds" class="col-3 mb-3">
        <p-toggleButton
            [onLabel]="'Unpaid refunds'"
            [offLabel]="'Unpaid refunds'"
            [onIcon]="'pi pi-check'"
            [offIcon]="'pi pi-times'"
            [(ngModel)]="showUnpaidRefunds"
            (onChange)="setRefundFilter($event, 'unpaid')"></p-toggleButton>
        <p-toggleButton
            [onLabel]="'Denied refunds'"
            [offLabel]="'Denied refunds'"
            [onIcon]="'pi pi-check'"
            [offIcon]="'pi pi-times'"
            [(ngModel)]="showDeniedRefunds"
            (onChange)="setRefundFilter($event, 'denied')"></p-toggleButton>
    </div>

    <p-table
        #refundTable
        selectionMode="single"
        [value]="refundRequests"
        [globalFilterFields]="['ticketNumber', 'customerName', 'location', 'date', 'requestedAmount', 'status']"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} refund requests"
        [rows]="10"
        [paginator]="true"
        [loading]="loadingRefunds$ | async"
        [lazy]="true"
        [lazyLoadOnInit]="false"
        [totalRecords]="totalRefundRequests"
        [(selection)]="selectedRefund"
        (onLazyLoad)="getRefunds($event)"
        (onRowSelect)="selectRefund($event)"
        (onRowUnselect)="deselectRefund()"
        >
        <ng-template pTemplate="caption">
            <div class="flex align-items-center justify-content-end">
                <div>
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" id="refund-table-search"
                            (input)="refundTable.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
                    </span>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="ticketNumber">Ticket Number<p-sortIcon field="ticketNumber"></p-sortIcon></th>
                <th pSortableColumn="customerName">Customer Name<p-sortIcon field="customerName"></p-sortIcon></th>
                <th pSortableColumn="location">Location<p-sortIcon field="location"></p-sortIcon></th>
                <th pSortableColumn="date">Date<p-sortIcon field="date"></p-sortIcon></th>
                <th pSortableColumn="requestedAmount">
                    Requested Amount<p-sortIcon field="requestedAmount"></p-sortIcon>
                </th>
                <th pSortableColumn="status">Status<p-sortIcon field="status"></p-sortIcon></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-refund let-index>
            <tr [pSelectableRow]="refund">
                <td>{{ refund.ticketNumber }}</td>
                <td>{{ refund.customerName }}</td>
                <td>{{ refund.location }}</td>
                <td>{{ refund.date | date : 'shortDate' }}</td>
                <td>{{ refund.requestedAmount | currency : 'USD' }}</td>
                <td>{{ refund.status }}</td>
            </tr>
        </ng-template>
    </p-table>
</div>

<div *ngIf="refundDetailsShown" class="grid print-content print-container">
    <div class="col-12">
        <h5>Refund Details</h5>
        <form [formGroup]="refundDetailsForm" (ngSubmit)="updateInfo()">
            <div class="grid p-fluid">
                <div class="field col-12 sm:col-12 md:col-4">
                    <span class="p-float-label">
                        <p-autoComplete
                            inputId="cust-name"
                            formControlName="customerName"
                            showEmptyMessage="true"
                            emptyMessage="No customers found."
                            [suggestions]="customerResults"
                            (completeMethod)="searchCustomers($event.query)"
                            (onSelect)="selectCustomer($event.value)"
                            (onBlur)="selectCustomer($event.target.value, true)">
                            <ng-template let-cust pTemplate="item">
                                {{ cust.name }} - {{ cust.location }}
                            </ng-template></p-autoComplete
                        >
                        <label for="cust-name">Customer Name</label>
                    </span>
                    <div>
                        <app-control-messages [control]="refundDetailsForm.get('customerName')"></app-control-messages>
                    </div>
                </div>
                <div
                    *ngIf="user_role === 'accounting' || user_role === 'accounts-payable'"
                    class="field col-12 sm:col-6 md:col-4">
                    <span class="p-float-label">
                        <input
                            pInputText
                            formControlName="custID"
                            id="cust-id"
                            type="text"
                            [required]="user_role === 'accounting'" />
                        <label for="cust-id">Customer ID</label>
                    </span>
                    <div>
                        <app-control-messages [control]="refundDetailsForm.get('custID')"></app-control-messages>
                    </div>
                </div>
                <div class="field col-12 sm:col-6 md:col-4">
                    <span class="p-float-label">
                        <p-autoComplete
                            formControlName="location"
                            inputId="location"
                            [suggestions]="locationSearchResults"
                            (completeMethod)="searchLocations($event)"
                            (onSelect)="selectLocation($event)"
                            (onBlur)="selectLocation($event.target.value)">
                            <ng-template let-loc pTemplate="item"> {{ loc.name }} - {{ loc.description }} </ng-template>
                        </p-autoComplete>
                        <!-- <input pInputText formControlName="location" id="location" type="number" /> -->
                        <label for="location">Location</label>
                    </span>
                    <div>
                        <app-control-messages [control]="refundDetailsForm.get('location')"></app-control-messages>
                    </div>
                </div>
                <div
                    *ngIf="user_role === 'accounting' || user_role === 'accounts-payable'"
                    class="field col-12 sm:col-6 md:col-4">
                    <span class="p-float-label">
                        <input
                            pInputText
                            type="text"
                            id="gl-code"
                            formControlName="glCode"
                            [required]="user_role === 'accounting'" />
                        <label for="gl-code">GL Code</label>
                    </span>
                    <div>
                        <app-control-messages [control]="refundDetailsForm.get('glCode')"></app-control-messages>
                    </div>
                </div>
                <div class="field col-12 sm:col-6 md:col-4">
                    <span class="p-float-label">
                        <input
                            pInputText
                            type="text"
                            id="date"
                            [defaultValue]="refundDetailsForm.get('date').value | date : 'shortDate'"
                            [readonly]="true"
                            [disabled]="showPaidRefunds" />
                        <label for="date">Date</label>
                    </span>
                    <div>
                        <app-control-messages [control]="refundDetailsForm.get('date')"></app-control-messages>
                    </div>
                </div>
                <!-- <div *ngIf="!showPaidRefunds" class="field col-12 sm:col-6 md:col-4">
                    <span class="p-float-label">
                        <p-calendar formControlName="date" inputId="date"></p-calendar>
                        <label for="date">Date</label>
                    </span>
                    <div>
                        <app-control-messages [control]="refundDetailsForm.get('date')"></app-control-messages>
                    </div>
                </div> -->
                <div class="field col-12 sm:col-12 md:col-4">
                    <span class="p-float-label">
                        <textarea
                            pInputTextarea
                            formControlName="reason"
                            id="reason-for-refund"
                            rows="5"
                            cols="30"
                            autoResize="autoResize"></textarea>
                        <label for="reason-for-refund">Reason For Refund</label>
                    </span>
                    <div>
                        <app-control-messages [control]="refundDetailsForm.get('reason')"></app-control-messages>
                    </div>
                </div>
                <div class="field col-12 sm:col-6 md:col-4">
                    <span class="p-float-label">
                        <p-inputNumber
                            formControlName="transactionAmount"
                            inputId="trans-amount"
                            mode="currency"
                            currency="USD"
                            locale="en-US">
                        </p-inputNumber>
                        <label for="trans-amount">Transaction Amount</label>
                    </span>
                    <div>
                        <app-control-messages
                            [control]="refundDetailsForm.get('transactionAmount')"></app-control-messages>
                    </div>
                </div>
                <div class="field col-12 sm:col-6 md:col-4">
                    <span class="p-float-label">
                        <p-inputNumber
                            formControlName="last4CardNo"
                            mode="decimal"
                            inputId="last4"
                            [useGrouping]="false">
                        </p-inputNumber>
                        <label for="last4">Last 4 Digits of Credit Card</label>
                    </span>
                    <div>
                        <app-control-messages [control]="refundDetailsForm.get('last4CardNo')"></app-control-messages>
                    </div>
                </div>
                <div class="field col-12 sm:col-6 md:col-4">
                    <span class="p-float-label">
                        <input pInputText formControlName="ticketNumber" id="ticket-num" type="number" />
                        <label for="ticket-num">Ticket Number</label>
                    </span>
                    <div>
                        <app-control-messages [control]="refundDetailsForm.get('ticketNumber')"></app-control-messages>
                    </div>
                </div>
                <div class="field col-12 sm:col-6 md:col-4">
                    <span class="p-float-label">
                        <p-inputNumber
                            formControlName="requestedAmount"
                            inputId="refund-amount"
                            mode="currency"
                            currency="USD"
                            locale="en-US">
                        </p-inputNumber>
                        <label for="refund-amount">Refund Amount</label>
                    </span>
                    <div>
                        <app-control-messages
                            [control]="refundDetailsForm.get('requestedAmount')"></app-control-messages>
                    </div>
                </div>
                <div class="field col-12 sm:col-6 md:col-4">
                    <span class="p-float-label">
                        <input pInputText formControlName="payableTo" id="payable-to" type="text" />
                        <label for="payable-to">Make Check Payable To</label>
                    </span>
                    <div>
                        <app-control-messages [control]="refundDetailsForm.get('payableTo')"></app-control-messages>
                    </div>
                </div>
                <div class="field col-12 sm:col-12 md:col-4">
                    <span class="p-float-label">
                        <input pInputText formControlName="address" id="address-1" type="text" />
                        <label for="address-1">Address</label>
                    </span>
                    <input
                        pInputText
                        formControlName="address2"
                        placeholder="Suite/Apt #"
                        id="address-2"
                        type="text"
                        value="" />
                    <div>
                        <app-control-messages [control]="refundDetailsForm.get('address')"></app-control-messages>
                    </div>
                </div>
                <div class="field col-12 sm:col-12 md:col-4">
                    <span class="p-float-label">
                        <input pInputText formControlName="city" id="city" type="text" />
                        <label for="city">City</label>
                    </span>
                    <div>
                        <app-control-messages [control]="refundDetailsForm.get('city')"></app-control-messages>
                    </div>
                </div>
                <div class="field col-12 sm:col-12 md:col-4">
                    <span class="p-float-label">
                        <input pInputText formControlName="state" id="state" type="text" />
                        <label for="state">State</label>
                    </span>
                    <div>
                        <app-control-messages [control]="refundDetailsForm.get('state')"></app-control-messages>
                    </div>
                </div>
                <div class="field col-12 sm:col-12 md:col-4">
                    <span class="p-float-label">
                        <p-inputNumber formControlName="zipcode" mode="decimal" inputId="zipcode" [useGrouping]="false">
                        </p-inputNumber>
                        <label for="zipcode">Zipcode</label>
                    </span>
                    <div>
                        <app-control-messages [control]="refundDetailsForm.get('zipcode')"></app-control-messages>
                    </div>
                </div>
                <div class="field col-12 sm:col-12">
                    <button pButton type="submit" label="Update Info"></button>
                </div>

                <!-- Display Manager and Corporate Signatures if Accounting -->
                <div *ngIf="refundDetailsShown && user_role === 'accounting'" class="field col-12">
                    <div class="grid p-fluid">
                        <div class="m-3">
                            <div class="border-bottom-1 surface-border mb-3 flex justify-content-center">
                                <h5>Manager Signature</h5>
                            </div>
                            <div class="card shadow-1" style="position: relative">
                                <img class="signature" [src]="selectedRefund.managerSignature" />
                            </div>

                            <div class="flex justify-content-between">
                                <div class="align-self-center">
                                    <div *ngIf="selectedRefund?.managerApprovedUserName; else notAppUsername">
                                        <div>{{ selectedRefund?.managerApprovedUserName }}</div>
                                    </div>
                                    <ng-template #notAppUsername>
                                        <div>Unknown user</div>
                                    </ng-template>
                                </div>

                                <div class="align-self-center">
                                    {{ managerApprovedDateTime | date : 'short' }}
                                </div>

                                <div class="align-self-center">
                                    <div
                                        *ngIf="
                                            selectedRefund.managerApprovedFirstName !== null ||
                                                selectedRefund.managerApprovedLastName !== null;
                                            else noManagerName
                                        ">
                                        <span *ngIf="selectedRefund.managerApprovedFirstName; else noManagerFirstName">
                                            {{ selectedRefund.managerApprovedFirstName }}
                                        </span>
                                        <ng-template #noManagerFirstName> null</ng-template>
                                        <span *ngIf="selectedRefund.managerApprovedLastName; else noManagerLastName">
                                            {{ selectedRefund.managerApprovedLastName }}
                                        </span>
                                        <ng-template #noManagerLastName> null</ng-template>
                                    </div>
                                    <ng-template #noManagerName> No Name Found</ng-template>
                                </div>
                            </div>
                        </div>
                        <div class="m-3">
                            <div class="border-bottom-1 surface-border mb-3 flex justify-content-center">
                                <h5>Corporate Office Signature</h5>
                            </div>
                            <div class="card shadow-1" style="position: relative">
                                <img class="signature" [src]="selectedRefund.corporateOfficeSignature" />
                            </div>

                            <div class="flex justify-content-between">
                                <div class="align-self-center">
                                    <div
                                        *ngIf="
                                            selectedRefund?.corporateOfficeApprovedUserName;
                                            else notAppCorpUsername
                                        ">
                                        <div>{{ selectedRefund?.corporateOfficeApprovedUserName }}</div>
                                    </div>

                                    <ng-template #notAppCorpUsername>
                                        <div>Unknown user</div>
                                    </ng-template>
                                </div>

                                <div class="align-self-center">
                                    {{ corporateOfficeApprovedDateTime | date : 'short' }}
                                </div>

                                <div class="align-self-center">
                                    <div
                                        *ngIf="
                                            selectedRefund.corporateOfficeApprovedFirstName !== null ||
                                                selectedRefund.corporateOfficeApprovedLastName !== null;
                                            else noCorpName
                                        ">
                                        <span
                                            *ngIf="
                                                selectedRefund.corporateOfficeApprovedFirstName;
                                                else noCorpFirstName
                                            ">
                                            {{ selectedRefund.corporateOfficeApprovedFirstName }}
                                        </span>
                                        <ng-template #noCorpFirstName> null</ng-template>
                                        <span
                                            *ngIf="selectedRefund.corporateOfficeApprovedLastName; else noCorpLastName">
                                            {{ selectedRefund.corporateOfficeApprovedLastName }}
                                        </span>
                                        <ng-template #noCorpLastName> null</ng-template>
                                    </div>
                                    <ng-template #noCorpName> No Name Found</ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Display signature field and manager signature if Corporate Office -->
                <div class="col-12 md:col-12" *ngIf="refundDetailsShown && signatureRequired && !showPaidRefunds">
                    <div class="grid">
                        <div class="col-12 xl:col-5 flex align-self-center">
                            <div>
                                <h5>Sign off on invoice status</h5>
                                <app-signature-pad></app-signature-pad>

                                <div class="flex justify-content-between">
                                    <div>
                                        <button
                                            pButton
                                            pRipple
                                            type="button"
                                            label="Save Signature"
                                            icon="pi pi-check"
                                            class="p-button-success"
                                            (click)="saveSignature()"
                                            [disabled]="signatureForm.valid"></button>
                                    </div>

                                    <div>
                                        <button
                                            pButton
                                            pRipple
                                            type="button"
                                            label="Clear"
                                            class="p-button-danger p-button-text"
                                            (click)="clearCanvas()"></button>
                                    </div>
                                </div>
                                <div>
                                    <app-control-messages
                                        [control]="signatureForm.get('signature')"></app-control-messages>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 xl:col-5 flex align-self-center" *ngIf="user_role === 'corporate-office'">
                            <div class="m-3">
                                <div class="border-bottom-1 surface-border mb-3 flex justify-content-center">
                                    <h5>Manager Signature</h5>
                                </div>
                                <div class="card shadow-1" style="position: relative">
                                    <img class="signature" [src]="selectedRefund.customerSignature" />
                                </div>

                                <div class="flex justify-content-between">
                                    <div class="align-self-center">
                                        <div *ngIf="selectedRefund?.managerApprovedUserName; else notAppUsername">
                                            <div>{{ selectedRefund?.managerApprovedUserName }}</div>
                                        </div>
                                        <ng-template #notAppUsername>
                                            <div>Unknown user</div>
                                        </ng-template>
                                    </div>

                                    <div class="align-self-center">
                                        {{ managerApprovedDateTime | date : 'short' }}
                                    </div>

                                    <div class="align-self-center">
                                        <div
                                            *ngIf="
                                                selectedRefund.managerApprovedFirstName !== null ||
                                                    selectedRefund.managerApprovedLastName !== null;
                                                else noManagerName
                                            ">
                                            <span
                                                *ngIf="
                                                    selectedRefund.managerApprovedFirstName;
                                                    else noManagerFirstName
                                                ">
                                                {{ selectedRefund.managerApprovedFirstName }}
                                            </span>
                                            <ng-template #noManagerFirstName> null</ng-template>
                                            <span
                                                *ngIf="selectedRefund.managerApprovedLastName; else noManagerLastName">
                                                {{ selectedRefund.managerApprovedLastName }}
                                            </span>
                                            <ng-template #noManagerLastName> null</ng-template>
                                        </div>
                                        <ng-template #noManagerName> No Name Found</ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <app-control-messages [control]="signatureForm.get('signature')"></app-control-messages>
                    </div>
                </div>

                <div class="col-12 md:col-12">
                    <h5>Attached Files</h5>
                    <p-progressSpinner *ngIf="refundFilesLoading$ | async"></p-progressSpinner>
                    <div class="p-fluid formgrid grid" *ngIf="!(refundFilesLoading$ | async)">
                        <div class="p-field col-12">
                            <p-table [value]="refundFiles">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>File(s)</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-file>
                                    <tr>
                                        <td>
                                            <button class="file-download-link" (click)="downloadFile(file.id)">
                                                {{ file.originalFileName }}
                                            </button>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div *ngIf="!showPaidRefunds" class="col-12">
        <div class="unified-row grid p-jc-between p-fluid">
            <div class="col-12 sm:col-12 md:col-6 lg:col-3 xl:col-2">
                <button
                    *ngIf="canDeny"
                    pButton
                    type="button"
                    label="Deny Request"
                    class="p-button-danger mr-2 mb-2"
                    [disabled]="httpRequest$ | async"
                    (click)="setStatus('denied')"></button>
            </div>

            <div
                class="col-12 sm:col-12 md:col-6 lg:col-3 xl:col-2"
                *ngIf="user_role === 'accounting' || user_role === 'accounts-payable'">
                <button
                    pButton
                    type="button"
                    label="Preview Documents"
                    class="mr-2 mb-2"
                    [loading]="printRequest$ | async"
                    [disabled]="refundDetailsForm.invalid || (httpRequest$ | async) || (refundFilesLoading$ | async)"
                    (click)="printAllDocuments(true)"></button>
            </div>

            <div
                class="col-12 sm:col-12 md:col-6 lg:col-3 xl:col-2"
                *ngIf="
                    (user_role === 'accounting' || user_role === 'accounts-payable') &&
                    selectedRefund.status !== 'approved'
                ">
                <button
                    pButton
                    type="button"
                    label="Print Documents and Approve Payment"
                    class="mr-2 mb-2 p-button-success"
                    [loading]="printRequest$ | async"
                    [disabled]="refundDetailsForm.invalid || (httpRequest$ | async) || (refundFilesLoading$ | async)"
                    (click)="setStatus('approved', true)"></button>
            </div>

            <div
                class="col-12 sm:col-12 md:col-6 lg:col-3 xl:col-2"
                *ngIf="
                    (user_role === 'accounting' || user_role === 'accounts-payable') &&
                    selectedRefund.status === 'approved'
                ">
                <button
                    pButton
                    type="button"
                    label="Print Documents"
                    class="mr-2 mb-2 p-button-info"
                    [loading]="printRequest$ | async"
                    [disabled]="
                        refundDetailsForm.get('glCode').value === null ||
                        (httpRequest$ | async) ||
                        (refundFilesLoading$ | async)
                    "
                    (click)="printAllDocuments(false)"></button>
            </div>

            <div
                class="col-12 sm:col-12 md:col-6 lg:col-3 xl:col-2"
                *ngIf="(user_role === 'accounting' || user_role === 'accounts-payable') && canApprove">
                <button
                    pButton
                    type="button"
                    label="Approve Payment without Printing"
                    class="mr-2 mb-2 p-button-success"
                    [loading]="httpRequest$ | async"
                    [disabled]="refundDetailsForm.invalid || (printRequest$ | async) || (refundFilesLoading$ | async)"
                    (click)="setStatus('approved')"></button>
            </div>

            <div
                class="col-12 sm:col-12 md:col-6 lg:col-3 xl:col-2"
                *ngIf="!(user_role === 'accounting' || user_role === 'accounts-payable') && canApprove">
                <button
                    pButton
                    type="button"
                    label="Approve Payment"
                    class="mr-2 mb-2 p-button-success"
                    [loading]="httpRequest$ | async"
                    [disabled]="refundDetailsForm.invalid || (printRequest$ | async)"
                    (click)="setStatus('approved')"></button>
            </div>
        </div>
    </div>
    <div class="col-12 md:col-12" *ngIf="showPaidRefunds">
        <div class="unified-row grid p-fluid">
            <div
                class="col-2 col-offset-5"
                *ngIf="user_role == 'accounting' || user_role == 'accounts-payable' || user_role == 'corporate-office'">
                <button
                    pButton
                    type="button"
                    label="Print all Documents"
                    [loading]="printRequest$ | async"
                    [disabled]="refundFilesLoading$ | async"
                    (click)="printAllDocuments(false)"></button>
            </div>
        </div>
    </div>
</div>
