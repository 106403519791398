import { Component, OnInit, OnDestroy } from '@angular/core';
import { MPIAppService, VendorWithFiles } from 'src/services/mpiapp.service';
import { Vendor } from '../api/models/vendor';
import { Message, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { FilePartial } from '../api/models/file-partial';
import { User } from '../api/models/user';
import { HttpErrorResponse } from '@angular/common/http';
import { SessionService } from 'src/session.service';

export interface VendorAnalysis extends VendorWithFiles {
    setupStatus: {
        w9?: string;
        insurance?: string;
        userAccountVerified?: string;
    };
}

export interface VendorSortable extends VendorAnalysis {
    locationsNameListString: string;
}

@Component({
    selector: 'app-vendor-pending-setup',
    templateUrl: './vendor-pending-setup.component.html',
    styleUrls: ['./vendor-pending-setup.component.scss'],
    providers: [MessageService],
})
export class VendorPendingSetupComponent implements OnInit, OnDestroy {
    private subs: Subscription[];
    requests: Vendor[];
    requestsWithFiles: VendorAnalysis[];
    vendorUserAccountVerified: Promise<string>[] = [];
    selectedRequest: Vendor;
    isSelected: boolean = false;
    loadingFinished = false;

    filesApproved: boolean = true;

    devCache = false;

    constructor(private mpiApp: MPIAppService, private messageService: MessageService, private session: SessionService) { }

    ngOnInit(): void {
        this.subs = [];
        this.selectedRequest = {
            name: '',
        };

        // Get all the vendors who are not verified OR who have null status.
        const filter = {
            where: {
                or: [{ status: { neq: 'verified' } }, { status: { eq: null } }],
            },
        };

        // For testing use one specific vendor.
        // const filter = {
        //     where: {
        //             vendorID: 1
        //     }
        // };

        // this.subs.push(
        // this.requestsWithFiles = await

        if (this.devCache) {
            const data = this.session.getPendingCache();

            if (data) {
                this.requestsWithFiles = JSON.parse(data);
                this.anaylzeVendorState();
                return;
            }
            // No cache data so make the call
        }

        this.mpiApp.getVendorsWithFiles('', JSON.stringify(filter)).subscribe((data: any) => {
            this.requestsWithFiles = data;

            // For each of the vendors make an async request to check their invite status
            for (const vendor of this.requestsWithFiles) {
                // Filter to find vendor
                const vendorFilter = {
                    where: {
                        and: [{ roleID: vendor.vendorID }, { role: 'vendor' }],
                    },
                };

                // Look for the vendor's user account
                this.mpiApp.getUsers(JSON.stringify(vendorFilter)).subscribe((users: User[]) => {
                    // Store the result as a promise that will eventually be the user's status
                    this.vendorUserAccountVerified[vendor.vendorID] = new Promise((resolve) => {
                        // Did we have any matching users?
                        if (users.length > 0) {
                            // Yes, at least one - we're only expecting one. Resolve the state.
                            if (users[0].accountVerified === 1) {
                                resolve('accepted');
                            } else {
                                resolve('not accepted');
                            }
                        } else {
                            // No matching user
                            resolve('missing user');
                        }
                    });
                });
            }

            this.anaylzeVendorState();
            this.createStaticLocationNameList();

            if (this.devCache) {
                this.session.savePendingCache(JSON.stringify(data));
            }

            this.loadingFinished = true;

            return data;
        });
    }
    ngOnDestroy(): void {
        this.subs.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    anaylzeVendorState() {
        this.requestsWithFiles.forEach((vendor: VendorAnalysis) => {
            vendor.setupStatus = {};

            let w9Verified = false;
            let w9Pending = false;

            vendor.files.w9.forEach((f: FilePartial) => {
                if (f.verificationStatus === 'approved') {
                    w9Verified = true;
                }

                if (f.verificationStatus === 'pending') {
                    w9Pending = true;
                }
            });

            if (w9Verified) {
                vendor.setupStatus.w9 = 'ok';
            } else if (w9Pending) {
                vendor.setupStatus.w9 = 'pending approval';
            } else {
                vendor.setupStatus.w9 = 'missing';
            }

            let insuranceVerified = false;
            let insurancePending = false;
            vendor.files.insurance.forEach((f: FilePartial) => {
                if (f.verificationStatus === 'approved') {
                    insuranceVerified = true;
                }

                if (f.verificationStatus === 'pending') {
                    insurancePending = true;
                }
            });

            if (insuranceVerified) {
                vendor.setupStatus.insurance = 'ok';
            } else if (insurancePending) {
                vendor.setupStatus.insurance = 'pending approval';
            } else {
                vendor.setupStatus.insurance = 'missing';
            }
        });
    }

    createStaticLocationNameList() {
        this.requestsWithFiles.forEach((vendor: VendorSortable) => {
            console.log(vendor);
            vendor.locationsNameListString = '';
            vendor.locationsNameListString = vendor.locationsNameListVar.join(',');
        });
    }

    dumpFiles() {
        console.log(this.requestsWithFiles);
    }

    onRowSelect(event) {
        this.isSelected = true;
    }

    onRowUnselect(event) {
        this.isSelected = false;
        this.selectedRequest = {
            name: '',
        };
    }

    handleApprove(event) {
        const approve = { status: 'verified' };
        if (this.selectedRequest.vendorID) {
            this.subs.push(
                this.mpiApp.updateVendor(this.selectedRequest.vendorID.toString(), approve).subscribe(
                    (data) => {
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Verified',
                            detail: 'Vendor successfully approved',
                        });
                    },
                    (error) => {
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: 'Vendor approval failed',
                        });
                    }
                )
            );
        }
    }

    handleDeny(event) {
        const approve = { status: 'verification-denied' };
        if (this.selectedRequest.vendorID) {
            this.subs.push(
                this.mpiApp.updateVendor(this.selectedRequest.vendorID.toString(), approve).subscribe(
                    (data) => {
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Denied',
                            detail: 'Vendor successfully denied',
                        });
                    },
                    (error) => {
                        console.log(error);
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: 'Vendor denial failed',
                        });
                    }
                )
            );
        }
    }

    /**
     * Consume file approved event from child view-vendor-info component
     *
     * @param event boolean for whether files all files are approved or denied
     */
    markFilesApproved(event) {
        this.filesApproved = event;
    }
}
