<p-toast></p-toast>
<p-messages severity="warn" *ngIf="!fileVerificationMode && (!(allFilesApproved$ | async) || accountPending)">
    <ng-template pTemplate>
        <div class="ml-2">Warning: Vendor account is under review pending uploaded files approval</div>
    </ng-template>
</p-messages>
<p-messages [(value)]="errorMsgs" [enableService]="false"></p-messages>

<div class="grid">
    <form [formGroup]="form">
        <div class="col-12">
            <div class="card">
                <h5>Account Contact</h5>
                <div class="p-fluid formgrid grid">
                    <div class="field col-12">
                        <label for="account-contact-company-name">Company</label>
                        <input id="account-contact-company-name" type="text" pInputText formControlName="name" />
                        <app-control-messages [control]="form.get('name')"></app-control-messages>
                    </div>
                    <div class="field col-12 md:col-6">
                        <label for="account-contact-first-name">First Name</label>
                        <input
                            id="account-contact-first-name"
                            type="text"
                            pInputText
                            formControlName="accountContactFirstName" />
                        <app-control-messages [control]="form.get('accountContactFirstName')"></app-control-messages>
                    </div>

                    <div class="field col-12 md:col-6">
                        <label for="account-contact-last-name">Last Name</label>
                        <input
                            id="account-contact-last-name"
                            type="text"
                            pInputText
                            formControlName="accountContactLastName" />
                        <app-control-messages [control]="form.get('accountContactLastName')"></app-control-messages>
                    </div>

                    <!-- row end -->

                    <div class="field col-12 md:col-6">
                        <label for="account-contact-phone">Phone</label>
                        <input
                            id="account-contact-phone"
                            type="text"
                            pInputText
                            formControlName="accountContactPhone" />
                        <app-control-messages [control]="form.get('accountContactPhone')"></app-control-messages>
                    </div>

                    <div class="field col-12 md:col-6">
                        <label for="account-contact-email">Email</label>
                        <input
                            id="account-contact-email"
                            type="text"
                            pInputText
                            formControlName="accountContactEmail" />
                        <app-control-messages [control]="form.get('accountContactEmail')"></app-control-messages>
                    </div>

                    <div class="field col-12 md:col-6" *ngIf="componentMode === 'accounting'">
                        <p-checkbox
                            label="Insurance Info Required"
                            class="mb-3"
                            formControlName="insuranceDocumentRequired"
                            [binary]="true"></p-checkbox>
                    </div>

                    <!-- row end -->
                </div>
            </div>
        </div>
    </form>

    <div class="col-12">
        <div class="card">
            <h5>Insurance Information</h5>
            <form [formGroup]="insuranceForm">
                <div *ngIf="isInsuranceRequired">
                    <p-messages severity="warn">
                        <ng-template pTemplate>
                            <div class="ml-2">Uploading Insurance documents is required.</div>
                        </ng-template>
                    </p-messages>

                    <div class="p-fluid formgrid grid">
                        <div *ngIf="!fileVerificationMode">
                            <div class="field col-12 p-md-12">
                                <div>Files uploaded will require validation before they can be accepted.</div>
                            </div>
                            <div class="field col-12 p-md-12">
                                <label for="insurance-expiration-date">Expiration Date</label>
                                <input
                                    id="insurance-expiration-date"
                                    type="date"
                                    pInputText
                                    appControlErrorMessage
                                    formControlName="insuranceExpiration"
                                    (change)="changedExpirationDate()" />
                                <!-- <app-control-messages [control]="insuranceForm.get('insuranceExpiration')"></app-control-messages>-->
                            </div>
                            <div class="field col-12 p-md-12">
                                <div *ngIf="!(insuranceFilesExpirationValid | async)">
                                    Please <span class="warning-text">select an insurance expiration date</span> before
                                    uploading new insurance documents.
                                </div>
                            </div>
                            <div class="field col-12 insurance-expiration-upload-container" #insuranceUploadContainer>
                                <div class="insurance-expiration-upload-control">
                                    <p-fileUpload
                                        name="demo[]"
                                        url="{{ uploadURL }}"
                                        (onUpload)="insuranceUpload($event)"
                                        (onBeforeUpload)="modifyInsuranceUpload($event)"
                                        [disabled]="!(insuranceFilesExpirationValid | async)"
                                        multiple="multiple"
                                        accept="{{ mpiApp.UPLOAD_ALLOWED_TYPES }}"
                                        maxFileSize="{{ mpiApp.UPLOAD_MAX_BYTE_SIZE }}">
                                        <ng-template pTemplate="content">
                                            <ul *ngIf="uploadedInsuranceFiles.length">
                                                <li *ngFor="let file of uploadedInsuranceFiles">
                                                    <a class="file-download-link" (click)="downloadFile(file.id)">
                                                        {{ file.name }} - {{ file.size }} bytes
                                                    </a>
                                                </li>
                                            </ul>
                                        </ng-template>
                                    </p-fileUpload>
                                </div>
                                <!-- <div class="insurance-expiration-upload-control float-expiration-warning">-->
                                <!--    Must Select Date-->
                                <!-- </div>-->
                            </div>
                        </div>
                    </div>
                </div>

                <div class="p-fluid formgrid grid">
                    <div class="field col-12">
                        <h6>Previously Uploaded Insurance Files</h6>
                    </div>

                    <div class="field col-12">
                        <p-table [value]="insuranceFiles">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>File</th>
                                    <th>Expiration</th>
                                    <th>Verification Status</th>
                                    <th>Note</th>
                                    <th *ngIf="fileVerificationMode"></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-file>
                                <tr>
                                    <td>
                                        <a class="file-download-link" (click)="downloadFile(file.id)">
                                            {{ file.originalFileName }} - {{ file.size }} bytes
                                        </a>
                                    </td>
                                    <td *ngIf="user_profile.role === 'admin'">
                                        <p-calendar
                                            [(ngModel)]="file.expirationDate"
                                            [ngModelOptions]="{ standalone: true }"
                                            (onSelect)="updateInsuranceFileExpDate(file)"
                                            [appendTo]="'body'"
                                            ></p-calendar>
                                    </td>
                                    <td *ngIf="user_profile.role !== 'admin'">
                                        {{ file.expirationDate }}
                                    </td>
                                    <td>{{ file.verificationStatus }}</td>
                                    <td>{{ file.note }}</td>
                                    <td *ngIf="fileVerificationMode">
                                        <app-file-verification-control
                                            [fileID]="file.id"
                                            [isInsuranceRequired]="isInsuranceRequired"
                                            (fileStatusEvent)="fileStatusUpdate($event, file.id, 'insurance')">
                                        </app-file-verification-control>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <form [formGroup]="form">
        <div class="col-12">
            <div class="card">
                <h5>W-9 Form</h5>
                <div class="p-fluid formgrid grid">
                    <div class="field col-12 md:col-6"></div>
                    <div class="field col-12 md:col-6"></div>
                    <div class="field col-12" *ngIf="!fileVerificationMode">
                        <p-fileUpload
                            name="demo[]"
                            url="{{ uploadURL }}"
                            (onUpload)="W9Upload($event)"
                            (onBeforeUpload)="modifyW9Upload($event)"
                            multiple="multiple"
                            accept="{{ mpiApp.UPLOAD_ALLOWED_TYPES }}"
                            maxFileSize="{{ mpiApp.UPLOAD_MAX_BYTE_SIZE }}">
                            <ng-template pTemplate="content">
                                <ul *ngIf="uploadedW9Files.length">
                                    <li *ngFor="let file of uploadedW9Files">
                                        {{ file.name }} - {{ file.size }} bytes
                                    </li>
                                </ul>
                            </ng-template>
                        </p-fileUpload>
                    </div>

                    <div class="field col-12">
                        <h6>Previously Uploaded W-9 Files</h6>
                    </div>

                    <div class="field col-12 md:col-6"></div>

                    <div class="field col-12">
                        <p-table [value]="w9Files">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>File</th>
                                    <th>Verification Status</th>
                                    <th>Note</th>
                                    <th *ngIf="fileVerificationMode"></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-file>
                                <tr>
                                    <td>
                                        <a class="file-download-link" (click)="downloadFile(file.id)">
                                            {{ file.originalFileName }} - {{ file.size }} bytes
                                        </a>
                                    </td>
                                    <td>{{ file.verificationStatus }}</td>
                                    <td>{{ file.note }}</td>
                                    <td *ngIf="fileVerificationMode">
                                        <app-file-verification-control
                                            [fileID]="file.id"
                                            (fileStatusEvent)="fileStatusUpdate($event, file.id, 'w9')">
                                        </app-file-verification-control>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12">
            <div class="card">
                <h5>Billing Information</h5>
                <div class="p-fluid formgrid grid">
                    <div class="field col-12 md:col-6">
                        <label for="billing-contact-first-name">First Name</label>
                        <input
                            id="billing-contact-first-name"
                            type="text"
                            pInputText
                            formControlName="accountContactFirstName" />
                        <app-control-messages [control]="form.get('accountContactFirstName')"></app-control-messages>
                    </div>

                    <div class="field col-12 md:col-6">
                        <label for="billing-contact-last-name">Last Name</label>
                        <input
                            id="billing-contact-last-name"
                            type="text"
                            pInputText
                            formControlName="accountContactLastName" />
                        <app-control-messages [control]="form.get('accountContactLastName')"></app-control-messages>
                    </div>

                    <!-- row end -->

                    <div class="field col-12 md:col-6">
                        <label for="billing-contact-phone">Phone</label>
                        <input
                            id="billing-contact-phone"
                            type="text"
                            pInputText
                            formControlName="billingContactPhone" />
                        <app-control-messages [control]="form.get('billingContactPhone')"></app-control-messages>
                    </div>

                    <div class="field col-12 md:col-6">
                        <label for="billing-contact-email">Email</label>
                        <input
                            id="billing-contact-email"
                            type="text"
                            pInputText
                            formControlName="billingContactEmail" />
                        <app-control-messages [control]="form.get('billingContactEmail')"></app-control-messages>
                    </div>

                    <!-- row end -->

                    <div class="field col-12">
                        <label for="billing-address1">Address</label>
                        <input id="billing-address1" type="text" pInputText formControlName="billingAddress1" />
                        <app-control-messages [control]="form.get('billingAddress1')"></app-control-messages>
                    </div>
                    <div class="field col-12">
                        <input id="billing-address2" type="text" pInputText formControlName="billingAddress2" />
                        <app-control-messages [control]="form.get('billingAddress2')"></app-control-messages>
                    </div>

                    <div class="field col-12 md:col-6">
                        <label for="city">City</label>
                        <input id="city" type="text" pInputText formControlName="billingCity" />
                        <app-control-messages [control]="form.get('billingCity')"></app-control-messages>
                    </div>
                    <div class="field col-12 md-3">
                        <label for="state">State</label>
                        <p-dropdown
                            id="state"
                            inputId="state"
                            [options]="states"
                            placeholder="Select"
                            optionLabel="name"
                            dataKey="code"
                            formControlName="billingState"
                            ngDefaultControl
                            name="billingState"
                            required></p-dropdown>
                        <app-control-messages [control]="form.get('billingState')"></app-control-messages>
                    </div>
                    <div class="field col-12 md-3">
                        <label for="zip">Zip</label>
                        <input id="zip" type="text" pInputText formControlName="billingZip" />
                        <app-control-messages [control]="form.get('billingZip')"></app-control-messages>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12" *ngIf="componentMode === 'accounting'">
            <div class="card">
                <h5>Internal Accounting System Information</h5>
                <div class="p-fluid formgrid grid">
                    <div class="field col-12 md:col-6">
                        <label for="internal-accounting-glcode">GL Code</label>
                        <input id="internal-accounting-glcode" type="text" pInputText formControlName="defaultGLCode" />
                        <app-control-messages [control]="form.get('defaultGLCode')"></app-control-messages>
                    </div>

                    <div class="field col-12 md:col-6">
                        <label for="internal-accounting-vendorid">Vendor ID</label>
                        <input
                            id="internal-accounting-vendorid"
                            type="text"
                            pInputText
                            formControlName="accountingSystemVendorID" />
                        <app-control-messages [control]="form.get('accountingSystemVendorID')"></app-control-messages>
                    </div>

                    <!-- row end -->
                </div>
            </div>
        </div>

        <div>
            <p-button label="Save" class="mr-2 mb-2" (onClick)="completeInvite()" [disabled]="requestInProgress">
            </p-button>
        </div>
    </form>
</div>
