import { Injectable } from '@angular/core';
import { UntypedFormGroup, FormControl, ValidatorFn, AbstractControl, ValidationErrors, UntypedFormArray } from '@angular/forms';
import { DateTime } from 'luxon';

@Injectable({
    providedIn: 'root',
})
export class ValidationService {
    constructor() {}

    getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
        const config = {
            required: 'Required',
            placeholderState: 'Required',
            invalidDate: 'Invalid date',
            email: 'Invalid email address',
            mismatchedPasswords: 'Passwords must match',
            pattern: 'Invalid',
            minlength: 'Must be at least ' + validatorValue.requiredLength + ' characters long',
            maxlength: 'Must be no more than ' + validatorValue.requiredLength + ' characters long',
            weakPassword: 'Must be 8 characters long and contain at least 1 uppercase letter, 1 number, and 1 symbol',
            amountNotEqual: 'Error: Sum of amounts entered must equal total invoice amount',
            error: validatorValue,
        };

        return config[validatorName];
    }

    validateAllFormFields(formGroup: UntypedFormGroup) {
        // mark all fields as touched so they'll be validated
        formGroup.markAllAsTouched();
    }

    passwordStrengthValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            /**
             * Requires 8 digits, 1 uppercase letter, 1 number, and 1 symbol
             * Symbols taken from backend validator.isStrongPassword.js
             *
             * Adapted from: https://stackoverflow.com/a/21456918/14560781
             */
            const pattern =
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-#!$@%^&*()_+|~=`{}\[\]:";'<>?,.\/ ])[A-Za-z\d-#!$@%^&*()_+|~=`{}\[\]:";'<>?,.\/ ]{8,}$/gm;
            const strong = pattern.test(control.value);
            return strong ? null : { weakPassword: { value: control.value } };
        };
    }

    /**
     * Check if two form group fields match values
     * Used for checking if the entered password and confirm password fields match
     *
     * @param passwordKey string for password field
     * @param confirmPasswordKey string for confirm password field
     * @returns
     */
    matchingPasswords(passwordKey: string, confirmPasswordKey: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const password = control.get(passwordKey);
            const confirmPassword = control.get(confirmPasswordKey);

            return password.value === confirmPassword.value ? null : { mismatchedPasswords: true };
        };
    }

    stateValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            // if placeholder 'select' is state selected, its state code will be null
            // check if the state's code is anything else
            return control.value.code === null ? { placeholderState: true } : null;
        };
    }

    expirationDateValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const dt: DateTime = DateTime.fromISO(control.value);

            const dtNow = DateTime.now();

            if (dt < dtNow) {
                return { error: 'The date cannot be prior or equal to the current date' };
            }

            return dt.isValid ? null : { invalidDate: true };
        };
    }

    /**
     * Validator for amounts a manager allocates to each invoice location
     * Return whether all input amounts equal the invoice total amount or not
     *
     * @param totalAmount total amount all input amounts should equal
     * @returns
     */
    amountSum(totalAmount: number): ValidatorFn {
        return (locationArray: UntypedFormArray): ValidationErrors | null => {
            let amount = 0;
            for (const locationControl of locationArray.controls) {
                amount += locationControl.get('amount').value;
            }

            // Round the result to the required number of decimals.
            amount = Number(amount.toFixed(2));

            return totalAmount === amount ? null : { amountNotEqual: true };
        };
    }
}
