<p-toast></p-toast>
<!-- <p-messages [(value)]="errorMsgs"></p-messages> -->
<div class="grid">
    <div class="col-12 md:col-12">
        <p-card header="Approved Payments for Export">
            <p-table
                #exportInvoiceTable
                [value]="invoices"
                [globalFilterFields]="['dateCreated', 'vendorRecord.name', 'locationsNameList', 'status', 'amount']"
                selectionMode="single"
                [(selection)]="selectedInvoice">
                <ng-template pTemplate="caption">
                    <div class="flex align-items-center justify-content-end">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input
                                    pInputText
                                    type="text"
                                    (input)="exportInvoiceTable.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="statusLastSet">Accounting Approved Date</th>
                        <th pSortableColumn="vendorRecord.name">
                            Company
                            <p-sortIcon field="vendorRecord.name"></p-sortIcon>
                        </th>
                        <th pSortableColumn="locationsNameList">
                            Locations
                            <p-sortIcon field="locationsNameList"></p-sortIcon>
                        </th>

                        <th pSortableColumn="status">
                            Status
                            <p-sortIcon field="status"></p-sortIcon>
                        </th>
                        <th pSortableColumn="amount">
                            Amount
                            <p-sortIcon field="amount"></p-sortIcon>
                        </th>
                        <th pSortableColumn="dateCreated" style="display: none">Accounting Approved Date String</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-invoice>
                    <tr [pSelectableRow]="invoice">
                        <td>
                            {{ invoice.statusLastSet | date : 'M/d/yyyy hh:mm a' }}
                        </td>
                        <td>{{ invoice.vendorRecord.name }}</td>
                        <td class="text-truncate">
                            {{ invoice.locationsNameList }}
                        </td>
                        <td>{{ invoice.status }}</td>
                        <td>{{ invoice.amount | currency : 'USD' }}</td>
                        <td style="display: none">{{ invoice.dateCreated }}</td>
                    </tr>
                </ng-template>
            </p-table>
        </p-card>
    </div>
    <div class="col-12 md:col-12">
        <div class="unified-row grid p-fluid">
            <div class="col-2"></div>

            <div class="col-8"></div>

            <div class="col-2">
                <button
                    pButton
                    type="button"
                    label="Export Invoices"
                    class="p-mr-2 p-mb-2"
                    (click)="exportApprovedInvoices()"></button>
            </div>
        </div>
    </div>

    <div class="col-12 md:col-12">
        <p-card header="Approved Refunds for Export">
            <p-table
                #exportRefundTable
                [value]="refunds"
                [globalFilterFields]="['customerName', 'location', 'status', 'transactionAmount']"
                selectionMode="single"
                [(selection)]="selectedRefund">
                <ng-template pTemplate="caption">
                    <div class="flex align-items-center justify-content-end">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input
                                    pInputText
                                    type="text"
                                    (input)="exportRefundTable.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <!-- TODO: Add statusLastSet <th pSortableColumn="statusLastSet">Accounting Approved Date</th> -->
                        <th pSortableColumn="customerName">
                            Customer Name
                            <p-sortIcon field="customerName"></p-sortIcon>
                        </th>
                        <th pSortableColumn="location">
                            Location
                            <p-sortIcon field="location"></p-sortIcon>
                        </th>

                        <th pSortableColumn="status">
                            Status
                            <p-sortIcon field="status"></p-sortIcon>
                        </th>
                        <th pSortableColumn="transactionAmount">
                            Transaction Amount
                            <p-sortIcon field="transactionAmount"></p-sortIcon>
                        </th>
                        <!-- <th pSortableColumn="dateCreated" style="display: none">Accounting Approved Date String</th> -->
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-refund>
                    <tr [pSelectableRow]="refund">
                        <!-- <td>
                            {{ refund.statusLastSet | date: 'M/d/yyyy hh:mm a' }}
                        </td> -->
                        <td>{{ refund.customerName }}</td>
                        <td>
                            {{ refund.location }}
                        </td>
                        <td>{{ refund.status }}</td>
                        <td>{{ refund.transactionAmount | currency : 'USD' }}</td>
                        <!-- <td style="display: none">{{ refund.dateCreated }}</td> -->
                    </tr>
                </ng-template>
            </p-table>
        </p-card>
    </div>
    <div class="col-12 md:col-12">
        <div class="unified-row grid p-fluid">
            <div class="col-2"></div>

            <div class="col-8"></div>

            <div class="col-2">
                <button
                    pButton
                    type="button"
                    label="Export Refunds"
                    class="p-mr-2 p-mb-2"
                    (click)="exportApprovedRefunds()"></button>
            </div>
        </div>
    </div>
</div>
