<p-table 
    #emailLogsTable
    [value]="emailLogs" 
    [lazy]="true" 
    [lazyLoadOnInit]="false" 
    [loading]="loadingEmailLogs$ | async" 
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first} to {last} of {{totalEmailLogs}} emails"
    [rows]="10"
    [totalRecords]="totalEmailLogs"
    (onLazyLoad)="loadEmailLogs($event)"
    [paginator]="true"
    dataKey="id">
    <ng-template pTemplate="header">
        <tr>
            <th pSortableColumn="emailDate">
                Email Date <p-sortIcon field="emailDate"></p-sortIcon>
            </th>
            <th pSortableColumn="emailTo">
                Email To <p-sortIcon field="emailTo"></p-sortIcon>
            </th>
            <th pSortableColumn="status">
                Status<p-sortIcon field="status"></p-sortIcon>
            </th>
            <th pSortableColumn="subject">
                Subject <p-sortIcon field="subject"></p-sortIcon>
            </th>
            <th pSortableColumn="type">
                Type <p-sortIcon field="type"></p-sortIcon>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-emailLog>
        <tr>
            <td>{{ emailLog.emailDate | date: 'medium' }}</td>
            <td>{{ emailLog.emailTo }}</td>
            <td>{{ emailLog.status }}</td>
            <td>{{ emailLog.subject }}</td>
            <td>{{ emailLog.type }}</td>
        </tr>
    </ng-template>
</p-table>