<div class="login-body-copy">
    <div id="login-wrapper">
        <!--        <img src="assets/layout/images/login-logo.png" alt="omega-layout" />-->

        <div id="login-container">
            <div *ngIf="isVendorSelection">
                <span class="title">Choose An Account To Login As</span>
                <div class="p-d-flex p-flex-column">
                    <button pButton pRipple type="button" label="Current Account" (click)="navigatePostLogin(userLogin)"></button>
                    <p-listbox
                        [options]="vendorDisplayList"
                        [listStyle]="{'max-height':'150px'}"
                        [(ngModel)]="selectedVendor"
                        (onClick)="selectVendor($event)"
                        optionLabel="name"
                        optionValue="id"></p-listbox>
                </div>
            </div>
            <div *ngIf="!isVendorSelection">
                <div class="flex flex-column justify-content-center">
                    <span class="title">{{ siteName }}</span>

                    <div class="grid p-fluid justify-content-center m-1">
                        <form [formGroup]="loginData">
                            <div class="field col-12">
                                <span class="p-float-label">
                                    <input
                                        formControlName="username"
                                        id="username-input"
                                        type="text"
                                        autocomplete="off"
                                        pInputText />
                                    <label for="username-input">Username</label>
                                </span>
                                <app-control-messages [control]="loginData.get('username')"></app-control-messages>
                            </div>
                            <div class="field col-12">
                                <span class="p-float-label">
                                    <p-password
                                        name="password"
                                        ngDefaultControl
                                        formControlName="password"
                                        required
                                        id="password-input"
                                        [inputId]="'password-input'"
                                        [toggleMask]="true"
                                        [feedback]="false"></p-password>
                                    <label for="password-input">Password</label>
                                </span>
                                <app-control-messages [control]="loginData.get('password')"></app-control-messages>
                            </div>
                        </form>
                    </div>

                    <!-- TODO Implement reset password -->
                    <!--
                <div class="col-12 md:col-6">
                    <span class="remember-me">Remember Me</span>
                    <input type="checkbox">
                </div>
                -->

                    <div>
                        <a class="text-sm" routerLink="./password_reset">Forgot Password?</a>
                    </div>

                    <div class="col-12">
                        <p-button
                            type="button"
                            label="Sign In"
                            icon="pi pi-user"
                            (onClick)="login('login')"
                            [loading]="isButtonLoading('login')">
                        </p-button>
                    </div>

                    <div class="col-12">
                        <div *ngIf="isRequestFinished">
                            <div *ngIf="isRequestSuccessful; else requestFailed">
                                <strong class="success-text">Login accepted.</strong>
                            </div>

                            <ng-template #requestFailed>
                                <strong class="red-text">
                                    {{ failureMessage }}
                                </strong>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="login-footer">MPI Vendor Portal</div>
    </div>
</div>

<!--<h1>Debug</h1>-->
<!--<pre>-->
<!--    {{debug | json}}-->
<!--</pre>-->

<!--<br>-->
<!--{{currentRole}}-->
