import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { EmailLogs } from '../api/models';
import { EmailLogService } from 'src/services/email-log.service';
import { LazyLoadEvent } from 'primeng/api';
import { forkJoin, Observable, firstValueFrom } from 'rxjs';
import { IsLoadingService } from '@service-work/is-loading';
import { TableLazyLoadEvent } from 'primeng/table';

@Component({
  selector: 'app-email-logs',
  standalone: false,
  templateUrl: './email-logs.component.html',
  styleUrl: './email-logs.component.scss'
})
export class EmailLogsComponent implements OnInit, OnDestroy {
  private emailLogService = inject(EmailLogService);
  private loadingService = inject(IsLoadingService);

  emailLogs: EmailLogs[] = [];
  totalEmailLogs: number = 0;

  defaultLazyLoadEvent: LazyLoadEvent = {
    rows: 10,
    first: 0,
    sortField: 'emailDate',
    sortOrder: -1,
  };

  loadingEmailLogs$: Observable<boolean>;

  constructor() { }

  ngOnInit(): void {
    this.loadingEmailLogs$ = this.loadingService.isLoading$({ key: 'load-logs' });

    this.loadEmailLogs(this.defaultLazyLoadEvent);
  }

  loadEmailLogs(event?: LazyLoadEvent) {
    let where: any = {};
    let filter: any = this.createFilter(event, where);

    let APIsource = forkJoin([
      this.emailLogService.getEmailLogs(JSON.stringify(filter)),
      this.emailLogService.countEmailLogs(JSON.stringify(filter.where))
    ]);

    this.loadingService.add(
      firstValueFrom(APIsource), {key: 'load-logs'})
    .then((response: any) => {
      this.emailLogs = response[0];
      this.totalEmailLogs = response[1].count;
    }, 
    (error) => {
      console.error('loadEmailLogs Error: ', error);
      this.totalEmailLogs = 0;
      this.emailLogs = [];
    }).catch(() => {
      this.totalEmailLogs = 0;
      this.emailLogs = [];
    });
  }

  createFilter(event: TableLazyLoadEvent, where: any): any {
    const sortField = event.sortField ? event.sortField : this.defaultLazyLoadEvent.sortField;
    // If sortField is undefined, the user has not specified any sorting, so default to emailDate DESC
    const sortOrder = event.sortField && event.sortOrder && event.sortOrder > 0 ? 'ASC' : 'DESC';
    const defaultRows = this.defaultLazyLoadEvent.rows ? this.defaultLazyLoadEvent.rows : 10;

    const filterObj: any = {
      where: where,
      limit: event.rows ? event.rows : defaultRows,
      skip: event.first,
      order: [`${sortField} ${sortOrder}`]
    };

    console.log('Filter object: ', filterObj);
    return filterObj;
  }

  ngOnDestroy(): void {

  }
}
