<div class="wrapper" [ngClass]="{'sidebar-inactive-l':menuInactiveDesktop, 'sidebar-active-m':menuActiveMobile,
     'p-ripple-disabled': !app.ripple, 'p-input-filled': app.inputStyle === 'filled'}"
    *ngIf="session.checkSession()">
    <div class="topbar clearfix">
        <a id="omega-menu-button" href="#" (click)="toggleMenu($event)">
            <span class="pi pi-bars"></span>
        </a>
        <span class="topbar-title">
            <h1 *ngIf="env.titlebar">{{env.titlebar}}</h1>
        </span>
        <a id="options-menu-button" href="#" (click)="toggleTopMenu($event)">
            <span class="pi pi-ellipsis-h"></span>
        </a>

        <div id="topbar-icons" class="animated"
            [ngClass]="{'topbar-icons-visible flipInX':topMenuActive,'flipOutX':topMenuLeaving}">
            <!--
            <span class="topbar-search" (click)="onSearchClick()">
                <input type="text" placeholder="Search" />
                <span class="topbar-search-icon pi pi-search"></span>
            </span>
            <a href="#">
                <span class="topbar-item-text">Messages</span>
                <span class="topbar-icon pi pi-envelope animated swing"></span>
                <span class="topbar-badge animated rubberBand">5</span>
            </a>
            <a href="#">
                <span class="topbar-item-text">Settings</span>
                <span class="topbar-icon pi pi-cog"></span>
            </a>
            <a href="#">
                <span class="topbar-item-text">User</span>
                <span class="topbar-icon pi pi-user"></span>
            </a>
            -->
            <a [routerLink]="['logout']">
                <span class="topbar-item-text">Logout</span>
                <span class="topbar-icon pi pi-sign-out"></span>
            </a>

        </div>
    </div>
    <div class="sidebar" (click)="onMenuClick()">
        <div class="sidebar-scroll-content">
            <div class="logo logo-no-image" routerLink="/" style="outline: none; cursor: pointer;">

                <!--                <img src="assets/layout/images/logo.png" alt="" width="113">-->
                Modern Parking, Inc.<br>Vendor Portal

            </div>
            <!--            <div class="profile">-->
            <!--                <img src="assets/layout/images/profile.png" alt="" />-->
            <!--                <a id="profile-button" href="#" (click)="toggleProfile($event)">-->
            <!--                    <span class="username">Peggy Olson</span>-->
            <!--                    <i class="pi pi-fw pi-cog"></i>-->
            <!--                </a>-->
            <!--                <ul [@submenu]="profileActive ? 'visible' : 'hidden'">-->
            <!--                    <li><a href="#"><i class="pi pi-fw pi-sliders-h"></i><span>Account</span></a></li>-->
            <!--                    <li><a href="#"><i class="pi pi-fw pi-bell"></i><span>Notifications</span><span class="menu-badge">2</span></a></li>-->
            <!--                    <li><a href="#"><i class="pi pi-fw pi-sign-out"></i><span>Logout</span></a></li>-->
            <!--                </ul>-->
            <!--            </div>-->
            <app-menu></app-menu>

            <div *ngIf="env.devMenuActive">
                <h1>Roles</h1>
                <pre>
                    {{ this.mpiApp.currentRoles | json}}
                </pre>
            </div>

            <!-- I think there should be an ending div here -->
        </div>
        <!--        </div>-->
    </div>
    <div class="main">
        <router-outlet></router-outlet>
    </div>

    <app-config></app-config>

    <div class="footer">
        <!--        <img src="assets/layout/images/logo-colored.png" alt="" width="32">-->
        <span class="footer-text">Modern Parking, Inc. Vendor Payment Portal version {{env.version}}.</span>
    </div>
</div>
