import { Vendor } from 'src/app/api/models/vendor';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuItem, Message, ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { MPIAppService } from 'src/services/mpiapp.service';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { User } from '../api/models/user';
import { ValidationService } from '../validation.service';
import { LocationsPartial } from '../api/models/locations-partial';
import { finalize } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { LoginModel } from '../models/login.model';
import { Subscription } from 'rxjs';
//import { stringify } from '@angular/compiler/src/util';
import { SessionService } from 'src/session.service';

@Component({
    selector: 'app-user-management',
    templateUrl: './user-management.component.html',
    styleUrls: ['./user-management.component.scss'],
    providers: [MessageService, ConfirmationService],
})
export class UserManagementComponent implements OnInit, OnDestroy {
    private subs: Subscription[];
    errorMsgs: Message[] = [];
    users: User[];

    userDialog: boolean = false;
    passwordDialog: boolean = false;
    nameDialog: boolean = false;
    locationDialog: boolean = false;
    roleDialog: boolean = false;
    deleteDialog: boolean = false;
    vendorDialog: boolean = false;
    userSettingDialog: boolean = false;

    selectedUser: User;

    locations: LocationsPartial[];
    vendors: Vendor[];

    pattern =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-#!$@%^&*()_+|~=`{}\[\]:";'<>?,.\/ ])[A-Za-z\d-#!$@%^&*()_+|~=`{}\[\]:";'<>?,.\/ ]{8,}$/;

    // Options to show in 'Actions' dropdown as Admin
    items: MenuItem[] = [
        {
            label: 'Change Password',
            icon: 'pi pi-user-edit',
            command: () => {
                this.openPasswordRest();
            },
        },
        {
            label: 'Change Name',
            icon: 'pi pi-user-edit',
            command: () => {
                this.openChangeName();
            },
        },
        {
            label: 'User Settings',
            icon: 'pi pi-cog',
            disabled: false,
            command: () => {
                this.openUserSettings();
            },
        },
        {
            label: 'Assign Locations',
            icon: 'pi pi-pencil',
            command: () => {
                this.openAssignLocations();
            },
        },
        {
            label: 'Assign Roles',
            icon: 'pi pi-user-edit',
            command: () => {
                this.openAssignRoles();
            },
        },
        {
            label: 'Assign Vendors',
            icon: 'pi pi-users',
            command: () => {
                this.openAssignVendors();
            },
        },
    ];

    vendorItems: MenuItem[] = [...this.items];

    // roles for new users to select from
    roles: any[] = [
        { name: 'Manager', code: 'manager' },
        { name: 'Corporate Office', code: 'corporate-office' },
        { name: 'Accounting', code: 'accounting' },
        { name: 'Accounts Payable', code: 'accounts-payable' },
        { name: 'Admin', code: 'admin' },
    ];

    // Dialog Form Groups
    newUserForm: UntypedFormGroup;
    passwordResetForm: UntypedFormGroup;
    changeNameForm: UntypedFormGroup;
    assignLocationForm: UntypedFormGroup;
    assignRoleForm: UntypedFormGroup;
    assignVendorForm: UntypedFormGroup;
    userSettingsForm: UntypedFormGroup;

    constructor(
        private formBuilder: UntypedFormBuilder,
        public mpiApp: MPIAppService,
        private messageService: MessageService,
        private validationService: ValidationService,
        private confirmationService: ConfirmationService,
        private session: SessionService
    ) {
        this.subs = [];
    }

    ngOnInit(): void {
        let currentUserRole: LoginModel = this.session.getUserProfile();

        this.vendorItems.push({
            label: 'Resend Invitation Link',
            icon: 'pi pi-send',
            command: () => this.resendInvitation(),
        });

        if (environment.production === false && currentUserRole?.role === 'admin') {
            // Don't allow delete user in production
            this.items.push({
                label: 'Delete User',
                icon: 'pi pi-times-circle',
                disabled: false,
                command: () => {
                    this.openConfirmDelete();
                },
            });
            this.vendorItems.push({
                label: 'Delete Vendor and Documents',
                icon: 'pi pi-send',
                command: () => this.openConfirmDelete(),
            });
        }

        this.initFormGroups();

        this.getUsers();

        this.getVendors();

        this.subs.push(
            this.mpiApp.getLocations().subscribe((data) => {
                this.locations = data;
            })
        );
    }

    ngOnDestroy(): void {
        this.subs.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    getUsers() {
        this.subs.push(
            this.mpiApp.getUsers().subscribe((data) => {
                this.users = data;
            })
        );
    }

    getVendors() {
        this.subs.push(
            this.mpiApp.getVendors().subscribe((data: Vendor[]) => {
                this.vendors = data;
            })
        );
    }

    setSelectedUser(rowUser) {
        this.selectedUser = rowUser;
    }

    resendInvitation() {
        const new_status = { status: 'resend-invite' };
        this.subs.push(
            this.mpiApp.updateVendor(this.selectedUser.roleID.toString(), new_status).subscribe(
                (data) => {
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: 'Vendor invitation resent',
                    });
                },
                (error) => {
                    console.error(error);
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Error resending vendor invitation',
                    });
                }
            )
        );
    }

    openConfirmDelete() {
        this.confirmationService.confirm({
            message:
                'This user and all associated documents will be permanently deleted. Are you sure you want to continue?',
            rejectButtonStyleClass: 'p-button-outlined',
            acceptButtonStyleClass: 'p-button-danger',
            accept: () => {
                this.deleteUser();
            },
        });
    }

    hideConfirmDelete() {
        this.deleteDialog = false;
    }

    deleteUser() {
        this.subs.push(
            this.mpiApp
                .updateUser('managementAction', 'deleteUserAccountAndDocuments', this.selectedUser.id)
                .pipe(
                    finalize(() => {
                        this.getUsers();
                    })
                )
                .subscribe({
                    next: (data) => {
                        this.passwordResetForm.reset();
                        this.passwordDialog = false;
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Success',
                            detail: 'User deleted',
                        });
                    },
                    error: (error: HttpErrorResponse) => {
                        console.log(error);
                        const loopbackError = error.message;
                        this.errorMsgs = [
                            {
                                severity: 'error',
                                summary: 'Error',
                                detail: loopbackError,
                            },
                        ];
                    },
                })
        );
    }

    openNewUser() {
        this.newUserForm.reset();
        this.userDialog = true;
    }

    hideNewUser() {
        this.userDialog = false;
    }

    submitNewUser() {
        /* Validate all fields first */
        if (!this.newUserForm.valid) {
            this.validationService.validateAllFormFields(this.newUserForm);
            return;
        }

        const payload: User = { email: '', role: '' };

        for (const field in this.newUserForm.controls) {
            if (field === 'confirmPassword') {
                continue;
            }
            payload[field] = this.newUserForm.get(field).value;
        }

        this.subs.push(
            this.mpiApp
                .newUser(payload)
                .pipe(
                    finalize(() => {
                        this.getUsers();
                    })
                )
                .subscribe({
                    next: (data) => {
                        this.newUserForm.reset();
                        this.userDialog = false;
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Success',
                            detail: 'New user successfully added',
                        });
                    },
                    error: (error) => {
                        console.log(error);
                        const loopbackError = error.error.error;
                        this.errorMsgs = [
                            {
                                severity: 'error',
                                summary: 'Error',
                                detail: loopbackError.message,
                            },
                        ];
                    },
                })
        );
    }

    openPasswordRest() {
        this.passwordResetForm.reset();
        this.passwordDialog = true;
    }

    hidePasswordReset() {
        this.passwordDialog = false;
    }

    submitPasswordReset() {
        /* Validate all fields first */
        if (!this.passwordResetForm.valid) {
            this.validationService.validateAllFormFields(this.passwordResetForm);
            return;
        }

        this.subs.push(
            this.mpiApp
                .updateUser('password', this.passwordResetForm.get('password').value, this.selectedUser.id)
                .subscribe({
                    next: (data) => {
                        this.passwordResetForm.reset();
                        this.passwordDialog = false;
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Success',
                            detail: 'User successfully updated',
                        });
                    },
                    error: (error) => {
                        console.log(error);
                        const loopbackError = error.error.error;
                        this.errorMsgs = [
                            {
                                severity: 'error',
                                summary: 'Error',
                                detail: loopbackError.message,
                            },
                        ];
                    },
                })
        );
    }

    openChangeName() {
        this.changeNameForm.reset();
        this.changeNameForm.controls.firstName.setValue(this.selectedUser.firstName);
        this.changeNameForm.controls.lastName.setValue(this.selectedUser.lastName);
        this.nameDialog = true;
    }

    hideChangeName() {
        this.nameDialog = false;
    }

    submitChangeName() {
        if (!this.changeNameForm.valid) {
            this.validationService.validateAllFormFields(this.changeNameForm);
            return;
        }

        this.subs.push(
            this.mpiApp
                .updateUser('', this.changeNameForm.value, this.selectedUser.id)
                .pipe(
                    finalize(() => {
                        // update users so new locations are reflected if this user is viewed agian
                        this.getUsers();
                    })
                )
                .subscribe({
                    next: (data: any) => {
                        this.changeNameForm.reset();
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Success',
                            detail: 'Name successfully updated',
                        });
                    },
                    error: (error: any) => {
                        console.error(error);
                        const loopbackError = error.error.error.message;
                        this.errorMsgs = [
                            {
                                severity: 'error',
                                summary: 'Error',
                                detail: loopbackError,
                            },
                        ];
                    },
                    complete: () => {
                        this.nameDialog = false;
                    },
                })
        );
    }

    openAssignLocations() {
        this.assignLocationForm.reset();
        this.assignLocationForm.get('locationsNameList').setValue(this.selectedUser.locationsNameList);
        this.locationDialog = true;
    }

    hideAssignLocations() {
        this.locationDialog = false;
    }

    submitAssignLocations() {
        if (!this.assignLocationForm.valid) {
            this.validationService.validateAllFormFields(this.assignLocationForm);
            return;
        }

        this.subs.push(
            this.mpiApp
                .updateUser(
                    'locationsNameList',
                    this.assignLocationForm.get('locationsNameList').value,
                    this.selectedUser.id
                )
                .pipe(
                    finalize(() => {
                        // update users so new locations are reflected if this user is viewed agian
                        this.getUsers();
                    })
                )
                .subscribe({
                    next: (data) => {
                        this.locationDialog = false;
                        this.assignLocationForm.reset();
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Success',
                            detail: 'User locations successfully updated',
                        });
                    },
                    error: (error: HttpErrorResponse) => {
                        console.error(error);
                        const loopbackError = error.message;
                        this.errorMsgs = [
                            {
                                severity: 'error',
                                summary: 'Error',
                                detail: loopbackError,
                            },
                        ];
                    },
                })
        );
    }

    openAssignRoles() {
        this.assignRoleForm.reset();
        this.assignRoleForm.get('rolesNameList').setValue(this.selectedUser.allowedRoles);
        console.log(this.assignRoleForm.controls.rolesNameList.value);
        this.roleDialog = true;
    }

    hideAssignRoles() {
        this.errorMsgs = [];
        this.roleDialog = false;
    }

    submitAssignRoles() {
        console.log('User Role Codes', this.assignRoleForm.controls.rolesNameList.value);

        let rolesFunc = 'setApplicableRoles';

        // if ((this.assignRoleForm.controls.rolesNameList.value as Array<string>).length === 0) {
        //     rolesFunc = 'clearApplicableRoles';
        // }
        this.subs.push(
            this.mpiApp
                .updateUser(rolesFunc, this.assignRoleForm.controls.rolesNameList.value, this.selectedUser.id)
                .pipe(
                    finalize(() => {
                        // update users
                        this.getUsers();
                    })
                )
                .subscribe({
                    next: (data) => {
                        this.roleDialog = false;
                        this.assignRoleForm.reset();
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Success',
                            detail: 'User role successfully updated',
                        });
                    },
                    error: (err: HttpErrorResponse) => {
                        console.error(err);
                        const loopbackError = err.error.error.message;
                        this.errorMsgs = [
                            {
                                severity: 'error',
                                summary: 'Error',
                                detail: loopbackError,
                            },
                        ];
                    },
                })
        );
    }

    openAssignVendors() {
        this.assignVendorForm.reset();
        this.assignVendorForm.controls.vendorsList.setValue(this.selectedUser.applicableVendors);
        console.log(this.assignVendorForm.controls.vendorsList.value);
        this.vendorDialog = true;
    }

    hideAssignVendors() {
        this.errorMsgs = [];
        this.vendorDialog = false;
    }

    submitAssignVendors() {
        let vendorsFunc = 'setApplicableVendors';

        this.mpiApp
            .updateUser(vendorsFunc, this.assignVendorForm.controls.vendorsList.value, this.selectedUser.id)
            .pipe(
                finalize(() => {
                    // update users
                    this.getUsers();
                })
            )
            .subscribe({
                next: (data) => {
                    this.vendorDialog = false;
                    this.assignVendorForm.reset();
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: 'User vendors successfully updated',
                    });
                },
                error: (err: HttpErrorResponse) => {
                    console.error(err);
                    const loopbackError = err.error.error.message;
                    this.errorMsgs = [
                        {
                            severity: 'error',
                            summary: 'Error',
                            detail: loopbackError,
                        },
                    ];
                },
            });
    }

    openUserSettings() {
        this.userSettingsForm.reset();
        this.userSettingsForm.controls.disableEmailNotifs.setValue(this.selectedUser.disableEmailNotifs);
        this.userSettingDialog = true;
    }

    hideUserSettings() {
        this.errorMsgs = [];
        this.userSettingDialog = false;
    }

    submitUserSettings() {
        this.mpiApp
            .updateUser(
                'disableEmailNotifs',
                this.userSettingsForm.controls.disableEmailNotifs.value,
                this.selectedUser.id
            )
            .pipe(
                finalize(() => {
                    this.getUsers();
                })
            )
            .subscribe({
                next: (data) => {
                    this.userSettingDialog = false;
                    this.userSettingsForm.reset();
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: 'User updated successfully.',
                    });
                },
                error: (err: HttpErrorResponse) => {
                    console.error(err);
                    const loopbackError = err.error.error.message;
                    this.errorMsgs = [
                        {
                            severity: 'error',
                            summary: 'Error',
                            detail: loopbackError,
                        },
                    ];
                },
            });
    }

    /**
     * Initializes all the form groups for the different dialog options.
     */
    initFormGroups() {
        this.newUserForm = this.formBuilder.group(
            {
                role: ['', Validators.required],
                email: ['', [Validators.required, Validators.email]],
                username: ['', Validators.required],
                locationsNameList: ['', Validators.required],
                password: ['', [Validators.required, this.validationService.passwordStrengthValidator()]],
                confirmPassword: ['', Validators.required],
            },
            { validators: this.validationService.matchingPasswords('password', 'confirmPassword') }
        );

        this.passwordResetForm = this.formBuilder.group(
            {
                password: ['', [Validators.required, this.validationService.passwordStrengthValidator()]],
                confirmPassword: ['', Validators.required],
            },
            { validators: this.validationService.matchingPasswords('password', 'confirmPassword') }
        );

        this.changeNameForm = this.formBuilder.group({
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
        });

        this.assignLocationForm = this.formBuilder.group({
            locationsNameList: ['', Validators.required],
        });

        this.assignRoleForm = this.formBuilder.group({
            rolesNameList: [''],
        });

        this.assignVendorForm = this.formBuilder.group({
            vendorsList: [''],
        });

        this.userSettingsForm = this.formBuilder.group({
            disableEmailNotifs: [false],
        });
    }
}
