import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../session.service';
import { LoginModel } from '../models/login.model';

/*
export interface FileInfo {
    fileName?: string;
    expirationDate?: string;
    verificationStatus?: string;
}
*/

@Component({
    selector: 'app-vendor-information',
    templateUrl: './vendor-information.component.html',
    styleUrls: ['./vendor-information.component.scss'],
})
export class VendorInformationComponent implements OnInit {
    id: number;

    constructor(public session: SessionService) {}

    ngOnInit(): void {
        /* Fetch user profile from localstorage */
        const profile: LoginModel = this.session.getUserProfile();
        if (profile?.roleID) {
            this.id = profile.roleID;
        }
    }
}
