<p-toast></p-toast>
<div class="dashboard grid p-fluid">
    <div class="col-12 md:col-12">
        <div class="card">
            <p class="text-2xl w-10">Invited and accepted vendors</p>
            <p-table
                #vendorsTable
                [value]="vendors"
                [globalFilterFields]="[
                    'name',
                    'vendorID',
                    'status'
                ]"
                dataKey="vendorID"
                responsiveLayout="scroll"
                [paginator]="true"
                [rows]="50"
                selectionMode="single"
                [(selection)]="selectedVendor"
                (onRowSelect)="selectVendor($event)"
                (onRowUnselect)="unselectVendor($event)">
                <ng-template pTemplate="caption">
                    <div class="flex align-items-center justify-content-end">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input
                                    pInputText
                                    type="text"
                                    (input)="vendorsTable.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <!--For the expand chevron-->
                        <th style="width: 3rem"></th>
                        <th pSortableColumn="name">Name <p-sortIcon field="name"></p-sortIcon></th>
                        <th pSortableColumn="vendorID">Vendor ID <p-sortIcon field="vendorID"></p-sortIcon></th>
                        <th pSortableColumn="status">Approval Status <p-sortIcon field="status"></p-sortIcon></th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-vendor let-expanded="expanded">
                    <tr [pSelectableRow]="vendor" [pRowToggler]="vendor">
                        <td>
                            <i *ngIf="!expanded" class="pi pi-chevron-right"></i>
                            <i *ngIf="expanded" class="pi pi-chevron-down"></i>
                        </td>
                        <td>
                            {{ vendor.name }}
                        </td>
                        <td>
                            {{ vendor.vendorID }}
                        </td>
                        <td>
                            {{ vendor.status }}
                        </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="rowexpansion" let-vendor>
                    <tr>
                        <td colspan="3">
                            <div class="grid fluid">
                                <div class="col-12 md:col-6">
                                    <div class="text-500 font-medium mb-2">Account Contact First Name</div>
                                    <div class="text-900">{{ vendor.accountContactFirstName }}</div>
                                </div>
                                <div class="col-12 md:col-6">
                                    <div class="text-500 font-medium mb-2">Account Contact Last Name</div>
                                    <div class="text-900">{{ vendor.accountContactLastName }}</div>
                                </div>
                                <div class="col-12 md:col-6">
                                    <div class="text-500 font-medium mb-2">
                                        Account Contact Email
                                        <span
                                            class="pi pi-question-circle"
                                            title="Used for PO notifications and account related emails"></span>
                                    </div>
                                    <div *ngIf="vendor.accountVerified; else notVerified" class="text-900">
                                        {{ vendor.accountContactEmail }}
                                    </div>
                                    <ng-template #notVerified>
                                        <div class="text-900">
                                            <input pInputText type="text" [(ngModel)]="vendor.accountContactEmail" class="p-inputtext-sm" />
                                        </div>
                                    </ng-template>
                                </div>
                                <div class="col-12 md:col-6">
                                    <div class="text-500 font-medium mb-2">Account Contact Phone Number</div>
                                    <div *ngIf="vendor.accountContactPhone" class="text-900">
                                        ({{ vendor.accountContactPhone | slice: 0:3 }})
                                        {{ vendor.accountContactPhone | slice: 3:6 }}-{{
                                            vendor.accountContactPhone | slice: 6
                                        }}
                                    </div>
                                </div>
                                <div class="col-12 md:col-6">
                                    <div class="text-500 font-medium mb-2">Billing Contact First Name</div>
                                    <div class="text-900">{{ vendor.billingContactFirstName }}</div>
                                </div>
                                <div class="col-12 md:col-6">
                                    <div class="text-500 font-medium mb-2">Billing Contact Last Name</div>
                                    <div class="text-900">{{ vendor.billingContactLastName }}</div>
                                </div>
                                <div class="col-12 md:col-6">
                                    <div class="text-500 font-medium mb-2">
                                        Billing Contact Email
                                        <span
                                            class="pi pi-question-circle"
                                            title="Used for billing related emails."></span>
                                    </div>
                                    <div class="text-900">{{ vendor.billingContactEmail }}</div>
                                </div>
                                <div class="col-12 md:col-6">
                                    <div class="text-500 font-medium mb-2">Billing Contact Phone Number</div>
                                    <div *ngIf="vendor.billingContactPhone" class="text-900">
                                        ({{ vendor.billingContactPhone | slice: 0:3 }})
                                        {{ vendor.billingContactPhone | slice: 3:6 }}-{{
                                            vendor.billingContactPhone | slice: 6
                                        }}
                                    </div>
                                </div>
                                <div class="col-12 md:col-6">
                                    <div class="text-500 font-medium mb-2">Billing Address</div>
                                    <div class="text-900">{{ vendor.billingAddress1 }}</div>
                                    <div class="text-900">{{ vendor.billingAddress2 }}</div>
                                    <div class="text-900">{{ vendor.billingCity }}, {{ vendor.billingState }}</div>
                                    <div class="text-900">{{ vendor.billingZip }}</div>
                                </div>
                                <div class="col-12 md:col-6">
                                    <div class="text-500 font-medium mb-2">Insurance Expiration</div>
                                    <div class="text-900">{{ vendor.insuranceExpiration | date: 'longDate' }}</div>
                                </div>
                                <div class="col-12 md:col-6">
                                    <div class="text-500 font-medium mb-2">Eligible Locations</div>
                                    <div *ngFor="let location of vendor.locationsNameListVar" class="text-900">
                                        {{ location }}
                                    </div>
                                </div>
                                <div class="col-12 md:col-6">
                                    <div class="text-500 font-medium mb-2">Verification Status</div>
                                    <div *ngIf="vendor.status !== null" class="text-900">{{ vendor.status }}</div>
                                    <div *ngIf="vendor.status === null" class="text-900">Null</div>
                                </div>
                                <div class="col-12 md:col-6">
                                    <div class="text-500 font-medium mb-2">User Status</div>
                                    <div *ngIf="vendor.accountVerified" class="text-900">Invitation Accepted</div>
                                    <div *ngIf="!vendor.accountVerified" class="mt-2">
                                        <button
                                            pButton
                                            type="button"
                                            label="Resend Invite Link"
                                            icon="pi pi-send"
                                            iconPos="left"
                                            style="max-width: fit-content"
                                            [loading]="isLoading"
                                            (click)="resendInvitation(vendor)"></button>
                                    </div>
                                </div>
                                <div class="col-12 md:col-6">
                                    <p-checkbox
                                        label="Insurance Info Required"
                                        class="mt-3"
                                        [(ngModel)]="vendor.insuranceDocumentRequired"
                                        [binary]="true"
                                        [disabled]="true"></p-checkbox>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
